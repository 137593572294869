import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import CustomTable from '../../../atoms/CustomTable/index';
import CustomTableToolbar from '../../../atoms/CustomTableToolbar';
import { Container, Card, Grid, Typography, Box } from '@mui/material';
import { applySortFilter } from '../../../utils/helper';
import Label from '../../../atoms/Label/index';
import TextInput from '../../../atoms/TextInput';
import NoData from '../../../../assets/nodata.svg';
import ACGButton from '../../../atoms/Button';
import useGetState from '../../../utils/hooks/useGetState';
import { executeACGAction, resetErr, resetStoreKey, updateScreenIdentifiers } from '../../../store/slice';
import { useDispatch } from 'react-redux';
import { ACTION_CODES, STORE_KEYS } from '../../../constants/apiConstants';
import { renameObjKeys } from '../../Login/helper';
import FilterDrawer from '../../../atoms/FilterDrawer';
import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import { MACHINE_SCHEMAS } from '../schema';
import DropDown from '../../../atoms/Dropdown';

type MachineType = {
    machineName: string | '';
    dropdownModelNameKey: string | '';
    dropdownMachineFamilyKey: string | '';
    machineSrNo: string | '';
};

const TABLE_HEAD = [
    { id: 'ParamCode', label: 'D Tags', alignRight: false },
    { id: 'ParamName', label: 'Parameter Name', alignRight: false },
    { id: 'ParamDesc', label: 'Parameter Description', alignRight: false },
    { id: 'SectionName', label: 'Section', alignRight: false },
    { id: 'ComponentName', label: 'Component', alignRight: false },
    { id: 'ParamLevel', label: 'Parameter Level', alignRight: false },
    { id: 'Category', label: 'Category', alignRight: false },
    { id: 'SubCategory', label: 'Sub Category', alignRight: false },
    { id: 'DataType', label: 'Data Type', alignRight: false },
    { id: 'Severity', label: 'Severity', alignRight: false },
    { id: 'AlarmType', label: 'Alarm Type', alignRight: false },
    { id: 'UOM', label: 'UOM', alignRight: false },
    { id: "IOTag", label: "IOTag", alignRight: false },
    { id: 'DowntimeFlag', label: 'Downtime Flag', alignRight: false }
];
const options = {
    AD_SUCCESS: {
        message: 'Machine Added successfully!',
        open: true,
        type: 'success'
    }
};
const AddMachine = (props: any) => {
    const { closeDrawer, isEdit, dtlData, setSnackbarOpts } = props;
    const [page, setPage] = useState<number>(0);
    const formRef = useRef<any>(null);
    const [validationSchema, setValidationSchema] = useState({});
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('ParamName');
    const [selected, setSelected] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [filterName, setFilterName] = useState<string>('');
    const [tableData, setTableData] = useState<any[]>([]);
    const [selectedModalData, setSelectedModalData] = useState('');
    const [machineModelNames, setMachineModelNames] = useState<any>([]);
    const [machineFamily, setMachineFamily] = useState<any>([]);
    // const [machineName, setMachineName] = useState<any>('');
    // const [machineSrNo, setMachineSrNo] = useState<any>('');
    const [selectedMachineFamily, setSelectedMachineFamily] = useState<any>('');
    const [severityDropdown, setseverityDropdown] = useState<any>([]);
    const [dataTypeDropdown, setDataTypeDropdown] = useState<any>([]);
    const [categoryDropdown, setCategoryDropdown] = useState<any>([]);
    const [filterOpen, setFilterOpen] = useState<boolean>(false);
    const [initialTableData, setInitialTableData] = useState<any[]>([]);
    const [selectedSeverityKey, setSeverityKey] = useState<any>('All');
    const [selectedDataType, setSelectedDataType] = useState<any>('All');
    const [selectedCategory, setSelectedCategory] = useState<any>('All');
    const filters = {
        mdFilter: (item: any) => item?.Severity === selectedSeverityKey,
        mfFilter: (item: any) => item?.DataType === selectedDataType,
        stFilter: (item: any) => item?.Category === selectedCategory,
        srchFilter: (item: any) => item?.ParamName?.toLowerCase()?.includes(filterName?.toLowerCase())
    };
    const [initialValues] = useState({
        machineName: '',
        dropdownMachineFamilyKey: '',
        machineSrNo: '',
        dropdownModelNameKey: ''
    });
    const reduxState = useGetState();
    const dispatch = useDispatch();

    useEffect(() => {
        setValidationSchema(Yup.object().shape(MACHINE_SCHEMAS));
        setTableData([]);
        setInitialTableData([]);
        getMachineModel();
        getMachineFamily();
    }, []);

    const getMachineModel = () => {
        if (!reduxState?.[STORE_KEYS.ADD_MACHINEMODEL_DROPDOWN]?.body?.data) {
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'GET',
                        urlPath: ACTION_CODES.GET_MACHINEMODEL_DROPDOWN
                    },
                    storeKey: STORE_KEYS.ADD_MACHINEMODEL_DROPDOWN,
                    uniqueScreenIdentifier: {
                        isLoading: true
                    }
                })
            );
        }
    };
    const handleFilter = async () => {
        await getSeverityDropdownData();
        await getCategoryDropdownData();
        await getDataTypeDropdownData();
        setFilterOpen(true);
    };

    const getSeverityDropdownData = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.GET_MACHINE_SEVERITY_DROPDOWN
                },
                uniqueScreenIdentifier: { severityDropdown: true },
                storeKey: STORE_KEYS.ADD_SEVERITY_DROPDOWN
            })
        );
    };

    useEffect(() => {
        if (
            reduxState?.[STORE_KEYS.ADD_SEVERITY_DROPDOWN]?.body?.data &&
            reduxState?.[STORE_KEYS.ADD_SEVERITY_DROPDOWN]?.statusCode === 200
        ) {
            const __severity = reduxState?.[STORE_KEYS.ADD_SEVERITY_DROPDOWN]?.body?.data;
            const arry = [{ code: 'All', name: 'All' }];
            setseverityDropdown([
                ...arry,
                ...__severity?.map((item: any) => {
                    return { code: item.Severity, name: item.Severity };
                })
            ]);
            dispatch(resetStoreKey({ storeKey: STORE_KEYS.ADD_SEVERITY_DROPDOWN }));
        }
    }, [reduxState?.[STORE_KEYS.ADD_SEVERITY_DROPDOWN]?.severityDropdown]);

    const getDataTypeDropdownData = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.GET_MACHINE_DATA_TYPE_DROPDOWN
                },
                uniqueScreenIdentifier: { dataTypeDropdown: true },
                storeKey: STORE_KEYS.ADD_DATATYPE
            })
        );
    };

    useEffect(() => {
        if (
            reduxState?.[STORE_KEYS.ADD_DATATYPE]?.body?.data &&
            reduxState?.[STORE_KEYS.ADD_DATATYPE]?.statusCode === 200
        ) {
            const __dataType = reduxState?.[STORE_KEYS.ADD_DATATYPE]?.body?.data;
            const arry = [{ code: 'All', name: 'All' }];
            setDataTypeDropdown([
                ...arry,
                ...__dataType?.map((item: any) => {
                    return { code: item.DataType, name: item.DataType };
                })
            ]);
            dispatch(resetStoreKey({ storeKey: STORE_KEYS.ADD_DATATYPE }));
        }
    }, [reduxState?.[STORE_KEYS.ADD_DATATYPE]?.dataTypeDropdown]);

    const getCategoryDropdownData = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.GET_MACHINE_CATEGORY_DROPDOWN
                },
                uniqueScreenIdentifier: { categoryDropdown: true },
                storeKey: STORE_KEYS.ADD_CATEGORY
            })
        );
    };

    useEffect(() => {
        if (
            reduxState?.[STORE_KEYS.ADD_CATEGORY]?.body?.data &&
            reduxState?.[STORE_KEYS.ADD_CATEGORY]?.statusCode === 200
        ) {
            const __category = reduxState?.[STORE_KEYS.ADD_CATEGORY]?.body?.data;
            const arry = [{ code: 'All', name: 'All' }];
            setCategoryDropdown([
                ...arry,
                ...__category?.map((item: any) => {
                    return { code: item.Category, name: item.Category };
                })
            ]);
            dispatch(resetStoreKey({ storeKey: STORE_KEYS.ADD_CATEGORY }));
        }
    }, [reduxState?.[STORE_KEYS.ADD_CATEGORY]?.categoryDropdown]);

    useEffect(() => {
        if (
            reduxState?.[STORE_KEYS.ADD_MACHINEMODEL_DROPDOWN]?.body?.data &&
            reduxState?.[STORE_KEYS.ADD_MACHINEMODEL_DROPDOWN]?.statusCode === 200
        ) {
            const modelNames = reduxState?.[STORE_KEYS.ADD_MACHINEMODEL_DROPDOWN]?.body?.data;
            setMachineModelNames(
                modelNames?.map((item: any) => {
                    const newVal = renameObjKeys(item, { MachineModelKey: 'code', MachineModelName: 'name' });
                    return newVal;
                })
            );
            dispatch(resetStoreKey({ storeKey: STORE_KEYS.ADD_MACHINEMODEL_DROPDOWN }));
        }
    }, [reduxState?.[STORE_KEYS.ADD_MACHINEMODEL_DROPDOWN]?.body?.data]);

    const getMachineFamily = () => {
        if (!reduxState?.[STORE_KEYS.ADD_MACHINE_FAMILY]?.body?.data) {
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'GET',
                        urlPath: ACTION_CODES.GET_MACHINE_FAMILY
                    },
                    storeKey: STORE_KEYS.ADD_MACHINE_FAMILY,
                    uniqueScreenIdentifier: {
                        isLoading: true
                    }
                })
            );
        }
    };

    useEffect(() => {
        if (
            reduxState?.[STORE_KEYS.ADD_MACHINE_FAMILY]?.body?.data &&
            reduxState?.[STORE_KEYS.ADD_MACHINE_FAMILY]?.statusCode === 200
        ) {
            const mchnFamily = reduxState?.[STORE_KEYS.ADD_MACHINE_FAMILY]?.body?.data;
            setMachineFamily(
                mchnFamily?.map((item: any) => {
                    const newVal = renameObjKeys(item, { MachineFamilyKey: 'code', MachineFamilyName: 'name' });
                    return newVal;
                })
            );
            dispatch(resetStoreKey({ storeKey: STORE_KEYS.ADD_MACHINE_FAMILY }));
        }
    }, [reduxState?.[STORE_KEYS.ADD_MACHINE_FAMILY]?.body?.data]);

    useEffect(() => {
        if (selectedModalData) {
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'GET',
                        urlPath: `${ACTION_CODES.GET_MODEL_PARMS}/${selectedModalData}`
                    },
                    storeKey: STORE_KEYS.MODEL_PARMS,
                    uniqueScreenIdentifier: {
                        isLoading: true
                    }
                })
            );
        }
    }, [selectedModalData]);

    useEffect(() => {
        if (
            reduxState?.[STORE_KEYS.MODEL_PARMS]?.body?.data &&
            reduxState?.[STORE_KEYS.MODEL_PARMS]?.statusCode === 200
        ) {
            const tmpTblData = reduxState?.[STORE_KEYS.MODEL_PARMS]?.body?.data;
            setTableData(tmpTblData);
            setInitialTableData(tmpTblData);
            dispatch(resetStoreKey({ storeKey: STORE_KEYS.MODEL_PARMS }));
        }
    }, [reduxState?.[STORE_KEYS.MODEL_PARMS]?.body?.data]);

    // const handleTextChange = (event: any) => {
    //     const { value, name } = event.target;
    //     if (name === 'machineName') {
    //         setMachineName(value);
    //     } else {
    //         setMachineSrNo(value);
    //     }
    // };
    const filteredList = applySortFilter(tableData, order, filterName, orderBy);

    const handleRequestSort = (event: any, property: any) => {
        setFilterName('');
        const isAsc = orderBy === event && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(event);
    };

    const handleSelectAllClick = (event: any) => {
        if (event.target.checked) {
            const newSelecteds = [];
            for (let i = 0; i < tableData.length; i++) {
                newSelecteds.push(tableData[i].MMParamKey);
            }
            const toRemove = tableData.slice(page, tableData.length);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (requestedBy: string) => {
        const selectedIndex = selected.indexOf(requestedBy);
        let newSelected: any[] | ((prevState: unknown[]) => unknown[]) = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, requestedBy);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const resetInput = () => {
        setFilterName('');
        let selectedFilter: any = [];
        if (selectedSeverityKey !== 'All') {
            selectedFilter = [...selectedFilter, filters.mdFilter];
        }
        if (selectedDataType !== 'All') {
            selectedFilter = [...selectedFilter, filters.mfFilter];
        }
        if (selectedCategory !== 'All') {
            selectedFilter = [...selectedFilter, filters.stFilter];
        }
        if (selectedFilter?.length !== 0) {
            const x = [...initialTableData]?.filter((ele: any) => selectedFilter?.every((f: any) => f(ele)));
            setTableData(x);
        } else {
            setTableData([...initialTableData]);
        }
    };

    const handleFilterByName = (event: { target: { value: React.SetStateAction<string> } }) => {
        setOrderBy('ParamName');
        setPage(0);
        setFilterName(event.target.value);
    };

    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    };

    const showRow = (row: any) => {
        const rowId = row['MMParamKey'];
        const isSelected = selected.length && selected.indexOf(rowId) !== -1;
        if (isSelected) {
            setSelected([...selected.filter((item: number) => item !== rowId)]);
        } else {
            setSelected([...selected, rowId]);
        }
    };

    const handleRoute = (row: any, ele: any) => {
        if (ele === 'Action') {
        }
    };

    const handleDropdownChange = (event: any) => {
        const { name, value } = event.target;
        if (name === 'dropdownModelNameKey') {
            setTableData([]);
            setSelectedModalData(event.target.value);
        } else {
            setSelectedMachineFamily(value);
        }
    };

    const handleSubmit = (values: any) => {
            const reqPayload = {
                payload: {
                    urlPath: ACTION_CODES.ADD_MACHINES,
                    requestType: 'POST',
                    reqObj: {
                        machineModelKey: values.dropdownModelNameKey,
                        machineSerialNo: values.machineSrNo,
                        machineName: values.machineName.replace(/'/g, "''"),
                        machineFamilyKey: values.dropdownMachineFamilyKey
                    }
                },
                uniqueScreenIdentifier: { machineAdded: true },
                storeKey: STORE_KEYS.ADD_RPT
            };
            dispatch(executeACGAction(reqPayload));
        return;
    };

    useEffect(() => {
        if (reduxState?.[STORE_KEYS.ADD_RPT]?.machineAdded) {
            if (reduxState?.[STORE_KEYS.ADD_RPT]?.statusCode === 200) {
                if(selected.length === 0) {
                        closeDrawer();
                        setSnackbarOpts(options.AD_SUCCESS);
                        dispatch(resetStoreKey({ storeKey: STORE_KEYS.ADD_RPT }));
                        getMachines();
                } else {
                const reqPayload = {
                    payload: {
                        urlPath: ACTION_CODES.ADD_MACHINE_PARAMS,
                        requestType: 'POST',
                        reqObj: {
                            machineKey: reduxState?.[STORE_KEYS.ADD_RPT]?.body?.data?.machineKey,
                            mmParamKeys: selected?.toString()
                        }
                    },
                    uniqueScreenIdentifier: { machineParamAdded: true },
                    storeKey: STORE_KEYS.ADD_RPT
                };
                dispatch(executeACGAction(reqPayload));
            }
            }
        }
    }, [reduxState?.[STORE_KEYS.ADD_RPT]?.machineAdded]);

    useEffect(() => {
        if (reduxState?.[STORE_KEYS.ADD_RPT]?.machineParamAdded) {
            if (reduxState?.[STORE_KEYS.ADD_RPT]?.statusCode === 200) {
                closeDrawer();
                setSnackbarOpts(options.AD_SUCCESS);
                getMachines();
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_RPT,
                    uniqueScreenIdentifier: {
                        machineParamAdded: false
                    }
                })
            );
            dispatch(resetStoreKey({ storeKey: STORE_KEYS.ADD_RPT }));
        }
    }, [reduxState?.[STORE_KEYS.ADD_RPT]?.machineParamAdded]);

    const getMachines = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: ACTION_CODES.GET_ALLMACHINES,
                    reqObj: {
                        onboardStatus: 'All',
                        customerKey: -1
                    }
                },
                uniqueScreenIdentifier: { mchsRecd: true },
                storeKey: STORE_KEYS.ALL_MACHINES
            })
        );
    };
    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };

        setSnackbarOpts(snackbarError);
    };

    useEffect(() => {
        if (reduxState?.err) {
            handleSnackbarError(reduxState?.err);
            dispatch(resetErr());
        }
    }, [reduxState?.err]);

    const clearFilter = () => {
        setPage(0);
        setTableData(initialTableData);
        setSeverityKey('All');
        setSelectedCategory('All');
        setSelectedDataType('All');
        let selectedFilter: any = [];
        if (selectedCategory !== 'All') {
            selectedFilter = [...selectedFilter, filters.stFilter];
        }
        if (filterName !== '') {
            selectedFilter = [...selectedFilter, filters.srchFilter];
        }
        if (selectedFilter?.length !== 0) {
            const x = [...initialTableData]?.filter((ele: any) => selectedFilter?.every((f: any) => f(ele)));
            setTableData(x);
        } else {
            setTableData([...initialTableData]);
        }
        setFilterOpen(false);
    };
    const applyFilter = () => {
        setPage(0);
        setFilterName('');
        setOrderBy('ParamName');
        setOrder('desc');
        let selectedFilter: any = [];
        if (selectedSeverityKey !== 'All') {
            selectedFilter = [...selectedFilter, filters.mdFilter];
        }
        if (selectedDataType !== 'All') {
            selectedFilter = [...selectedFilter, filters.mfFilter];
        }
        if (selectedCategory !== 'All') {
            selectedFilter = [...selectedFilter, filters.stFilter];
        }
        if (filterName !== '') {
            selectedFilter = [...selectedFilter, filters.srchFilter];
        }
        if (selectedFilter?.length !== 0) {
            const x = [...initialTableData]?.filter((ele: any) => selectedFilter?.every((f: any) => f(ele)));
            setTableData(x);
        } else {
            setTableData([...initialTableData]);
        }

        setFilterOpen(false);
    };

    const renderButtons = () => {
        return (
            <div className={'btns noWrap'} style={{ display: 'flex', float: 'right', marginTop: '15px' }}>
                <ACGButton
                    name={'Cancel'}
                    handleClick={closeDrawer}
                    secondary={true}
                    className={'first-btn'}
                    type="button"
                    variant="contained"
                    style={{ marginRight: '25px', padding: '5px', width: '100px', height: '35px' }}
                />
                <ACGButton
                    name={'Submit'}
                    handleClick={() => { }}
                    type="submit"
                    className="second-btn"
                    variant="contained"
                    style={{ marginRight: '25px', padding: '5px', width: '100px', height: '35px' }}
                    isLoading = {isLoading}
                />
            </div>
        );
    };
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values: any) => {
                handleSubmit(values);
            }}
            validateOnBlur={false}
            validateOnChange={false}
            innerRef={formRef}
        >
            {(formikprops: FormikProps<MachineType>) => {
                const { values, handleChange, errors, touched } = formikprops;
                return (
                    <Form>
                        <>
                            <div>
                                <Container
                                    maxWidth="xl"
                                    style={{
                                        paddingLeft: '3px',
                                        paddingRight: '3px'
                                    }}
                                >
                                    <div
                                        style={{
                                            padding: '24px',
                                            borderBottom: '1px solid rgb(43 45 66 / 100%)',
                                            marginBottom: '24px'
                                        }}
                                    >
                                        <Grid container spacing={2}>
                                            <Grid item xs={6} lg={3} md={4} sm={6}>
                                                <Label label="Model Name" className={'labelDiv'} />
                                                <DropDown
                                                    onChange={(event) => {
                                                        handleChange(event);
                                                        handleDropdownChange(event);
                                                    }}
                                                    className="inputDiv"
                                                    name="dropdownModelNameKey"
                                                    items={machineModelNames}
                                                    placeHolder="All Parameters"
                                                    value={values.dropdownModelNameKey}
                                                    helperText={
                                                        errors.dropdownModelNameKey && touched.dropdownModelNameKey
                                                            ? errors.dropdownModelNameKey
                                                            : ''
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={6} lg={3} md={4} sm={6}>
                                                <Label label="Machine Name" className={'labelDiv'} />
                                                <TextInput
                                                    variant="outlined"
                                                    fullWidth
                                                    type="text"
                                                    name="machineName"
                                                    value={values.machineName}
                                                    autoComplete="off"
                                                    formInput="inputDiv"
                                                    onChange={(event) => {
                                                        handleChange(event);
                                                    }}
                                                    onBlur={() => { }}
                                                    placeholder="Enter Machine Name"
                                                    helperText={
                                                        errors.machineName && touched.machineName
                                                            ? errors.machineName
                                                            : ''
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={6} lg={3} md={4} sm={6}>
                                                <Label label="Machine Serial Number" className={'labelDiv'} />
                                                <TextInput
                                                    variant="outlined"
                                                    fullWidth
                                                    type="text"
                                                    name="machineSrNo"
                                                    value={values.machineSrNo}
                                                    autoComplete="off"
                                                    formInput="inputDiv"
                                                    onChange={(event) => {
                                                        handleChange(event);
                                                    }}
                                                    placeholder="Enter Machine Serial Number"
                                                    helperText={
                                                        errors.machineSrNo && touched.machineSrNo
                                                            ? errors.machineSrNo
                                                            : ''
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={6} lg={3} md={4} sm={6}>
                                                <Label label="Machine Family" className={'labelDiv'} />
                                                <DropDown
                                                    onChange={(event) => {
                                                        handleChange(event);
                                                        handleDropdownChange(event);
                                                    }}
                                                    className="inputDiv"
                                                    name="dropdownMachineFamilyKey"
                                                    items={machineFamily}
                                                    placeHolder="All Parameters"
                                                    value={values.dropdownMachineFamilyKey}
                                                    helperText={
                                                        errors.dropdownMachineFamilyKey &&
                                                            touched.dropdownMachineFamilyKey
                                                            ? errors.dropdownMachineFamilyKey
                                                            : ''
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                    {selectedModalData != '' ? (
                                        <Card
                                            style={{
                                                borderRadius: '0px',
                                                backgroundColor: '#2b2d42',
                                                overflow: 'inherit'
                                            }}
                                        >
                                            <CustomTableToolbar
                                                drawerOpen={handleFilter}
                                                numSelected={selected?.length}
                                                filterName={filterName}
                                                countNoShow={true}
                                                onFilterName={handleFilterByName}
                                                resetInput={resetInput}
                                                displayInput={true}
                                                text={{
                                                    title: 'Total Parameters',
                                                    subTitle: `${selected?.length} selected`
                                                }}
                                                filterButton={true}
                                                customButton={false}
                                            />
                                        </Card>
                                    ) : null}
                                </Container>
                                {selectedModalData != '' ? (
                                    <div style={{ height: '45vh', overflow: 'overlay' }}>
                                        <CustomTable
                                            selectable={true}
                                            headers={TABLE_HEAD}
                                            body={filteredList}
                                            filteredUsers={filteredList}
                                            filterName={filterName}
                                            selected={selected}
                                            order={order}
                                            orderBy={orderBy}
                                            onRequestSort={handleRequestSort}
                                            onSelectAllClick={handleSelectAllClick}
                                            rowChange={(event: string) => handleClick(event)}
                                            rowCount={filteredList.length}
                                            onPageChange={handleChangePage}
                                            page={page}
                                            setPageNumber={setPage}
                                            selectRow={showRow}
                                            route={handleRoute}
                                            isRequestPage={true}
                                            link={true}
                                            idKey="MMParamKey"
                                        />
                                    </div>
                                ) : (
                                    <div style={{ height: '50vh', borderBottom: '1px solid rgb(43 45 66 / 100%)' }}>
                                        <div style={{ textAlign: 'center', marginTop: '5%' }}>
                                            <img src={NoData} alt="no data" />
                                            <Typography
                                                sx={{ mt: '2%', color: '#B1C2DF', fontSize: '18px' }}
                                                variant="h4"
                                            >
                                                No Parameters to show
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                style={{ color: 'rgb(177 194 223 / 60%)', fontSize: '12px' }}
                                            >
                                                Select Model Name to display Parameters
                                            </Typography>
                                        </div>
                                    </div>
                                )}
                                {renderButtons()}
                            </div>
                            <FilterDrawer
                                handlerClose={() => {
                                    setFilterOpen(false);
                                }}
                                status={filterOpen}
                                drawerHeader={'Filter'}
                                children={
                                    <Container className="createReport">
                                        <Box style={{ minHeight: '75vh' }}>
                                            {/* <Label label="Severity" className={'labelDiv'} /> */}
                                            <DropDown
                                                onChange={(event: any) => {
                                                    setSeverityKey(event.target.value);
                                                }}
                                                label="Severity"
                                                placeHolder="Select Severity"
                                                name="dropdownSeverity"
                                                className="inputDiv"
                                                items={severityDropdown}
                                                value={selectedSeverityKey}
                                            />
                                            <DropDown
                                                onChange={(event: any) => {
                                                    setSelectedDataType(event.target.value);
                                                }}
                                                label="Data Type"
                                                placeHolder="Select data type"
                                                name="dropdownDatatype"
                                                className="inputDiv"
                                                items={dataTypeDropdown}
                                                value={selectedDataType}
                                            />
                                            <DropDown
                                                onChange={(event: any) => {
                                                    setSelectedCategory(event.target.value);
                                                }}
                                                label="Category"
                                                placeHolder="Select Category"
                                                name="dropdownCategory"
                                                className="inputDiv"
                                                items={categoryDropdown}
                                                value={selectedCategory}
                                            />
                                        </Box>
                                    </Container>
                                }
                                clearFn={clearFilter}
                                applyFn={applyFilter}
                            />
                        </>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default AddMachine;
