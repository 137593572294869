import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Container } from '@mui/material';
import MapCustomTabs from '../../../app/atoms/ButtonTabs/MapCustomTabs';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../app/constants/apiConstants';
import { executeACGAction, updateScreenIdentifiers } from '../../../app/store/slice';
import Axios from 'axios';
import useGetState from '../../../app/utils/hooks/useGetState';
import { _noop } from '../../../app/utils/common';
import '../Admin/ClientManagement/index.scss';
import DrawerDropdown from '../../../app/atoms/DrawerDropdown';
import RightDrawer from '../../../app/atoms/tempDrawer/indexAlt';
import BDCreateConfig from './Breakdown/CreateConfig';
import EditConfig from './Breakdown/EditConfig';
import SRCreateConfig from './ServiceRequest/CreateConfig';
import { SCREEN_TITLES } from '../../../app/constants/StringConstants';
// import CreateRole from './CreateRole';
import BDConfig from './Breakdown/Config';
import SRConfig from './ServiceRequest/Config';
import SRAssignConfig from './ServiceRequest/AssignConfig';
import SRActionConfig from './ServiceRequest/ActionConfig';
import SRCloseConfig from './ServiceRequest/CloseConfig';
import PMConfig from './PreventiveMaintenance/Config';
import PMCreateConfig from './PreventiveMaintenance/CreateConfig/index';
import PMCreateChecklist from './PreventiveMaintenance/CreateChecklist/index';
import PMUpdateChecklist from './PreventiveMaintenance/UpdateChecklist/index';
import PRWOConfig from './preventiveMaintenanceWorkOrder/Config';
import PWWOCloseConfig from './preventiveMaintenanceWorkOrder/CloseConfig';
import Assign from './preventiveMaintenanceWorkOrder/Assign';
import SnackbarAlert from '../../atoms/Snackbar';
import { trackButtonEvent, trackErrorsInNewRelic, trackPageActionLog } from '../../utils/newRelicFuncations';

// const tabs = ['Breakdown Maintenance', 'Service Request', 'Preventive Maintenance Config', 'Preventive Maintenance Work Orders'];
// const tabs = [ 'Preventive Maintenance Config', 'Preventive Maintenance Work Orders'];

const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    }
};
const SystemConfiguration = () => {
    const [currTab, setTabChange] = useState(0);
    const [radioValue, setRadioValue] = useState('All');
    const [selectedClient, setSelectedClient] = useState(null);
    const [drawerHeader, setDrawerHeader] = useState('Create');
    const [clientList, setClientList] = useState<any[]>([]);
    const [createValue, setCreateValue] = useState('');
    const [createDrawerOpen, setCreateDrawerOpen] = useState(false);
    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const [reload, setReload] = useState(false);
    const [dtlData, setDtlData] = useState<any>([]);
    const [favoriteContactFlag, setfavoriteContactFlag] = useState();
    const [selectedRow, setSelectedRow] = useState<any>();
    const [userList, setUserList] = useState<any>([]);
    const [assetList, setAssetList] = useState<any[]>([]);
    const [actionTypePM, setActionTypePM] =  useState<any[]>([]);  
    const [categoryList, setCategoryList] = useState<any[]>([]);
    const [actionTypeList, setActionTypeList] = useState<any[]>([]);
    const [breakdownList, setBreakdownList] = useState<any[]>([]);
    const [serviceRequestList, setServiceRequestList] = useState<any[]>([]);
    const [preventiveMaintenanceList, setPreventiveMaintenanceList] = useState<any[]>([]);
    const [preventiveMaintenanceWorkOrderList, setPreventiveMaintenanceWorkOrderList] = useState<any[]>([]);
    const [breakdownKey, setBreakdownKey] = useState('');
    const [serviceRequestKey, setServiceRequestKey] = useState('');
    const [preventiveMaintenanceKey, setPreventiveMaintenanceKey] = useState('');
    const [preventiveMaintenanceWorkOrderKey, setPreventiveMaintenanceWorkOrderKey] = useState('');
    const [assignmentData, setAssignmentData] = useState<any[]>([]);
    const [assignPreventive, setAssignPreventive] = useState<Boolean>(false);
    const [isPMLoading,setIsPMLoading]=useState(false)
    const [isPMWOLoading,setIsPMWOLoading]=useState(false)
    const [tabs, setTabs] = useState<any>([]);
    const [tabsPermissions, setTabsPermissions] = useState<Record<string, string>>({});

    const dispatch = useDispatch();
    const state = useGetState();
    const token = state?.[STORE_KEYS.USER_DTL].token;
    const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;
    const getCreateValue = (ele: string) => {
        trackButtonEvent("Maintenance Module",`${ele}`,`Create ${ele}`,state[STORE_KEYS.USER_DTL]?.EmailId)
        setPreventiveMaintenanceWorkOrderKey('');
        setPreventiveMaintenanceKey('');
        setServiceRequestKey('');
        setBreakdownKey('');
        setDrawerHeader('Create');
        setCreateValue(ele);
        setCreateDrawerOpen(true);
    };

    const setCurrTab = (val: number) => {
        setPreventiveMaintenanceKey('');
        setPreventiveMaintenanceWorkOrderKey('');
        setAssignmentData([]);
        if(val === 1) getPreventiveMaintenanceWorkOrderList();
        setTabChange(val);
        if (radioValue == 'All' && currTab === 1) {
            setRadioValue('All');
        }
    };

    const handleRadioValue = (val: string) => {
        setRadioValue(val);
        setSelectedClient(null);
    };

    const getAssets = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: ACTION_CODES.GET_BREAKDOWN_MAINTENANCE_ASSETS,
                    reqObj: {
                        userId: state[STORE_KEYS.USER_DTL]?.LoggedUserKey,
                    }
                },
                storeKey: STORE_KEYS.GET_BREAKDOWN_MAINTENANCE_ASSETS,
                uniqueScreenIdentifier: {
                    isLoading: true
                }
            })
        );
    };

    const getActionTypes = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: ACTION_CODES.GET_PREVENTIVE_MAINTENANCE_ACTION_TYPE,
                    reqObj: {
                        userId: state[STORE_KEYS.USER_DTL]?.LoggedUserKey,
                    }
                },
                storeKey: STORE_KEYS.GET_PREVENTIVE_MAINTENANCE_ACTION_TYPE,
                uniqueScreenIdentifier: {
                    isLoading: true
                }
            })
        );
    };

    const getBreakdownCategory = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: ACTION_CODES.GET_BREAKDOWN_MAINTENANCE_CATEGORY,
                    reqObj: {
                        userId: state[STORE_KEYS.USER_DTL]?.LoggedUserKey,
                    }
                },
                storeKey: STORE_KEYS.GET_BREAKDOWN_MAINTENANCE_CATEGORY,
                uniqueScreenIdentifier: {
                    isLoading: true
                }
            })
        );
    };

    const getBreakdownActionType = () => {

        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: ACTION_CODES.GET_BREAKDOWN_MAINTENANCE_ACTION_TYPE,
                    reqObj: {
                        userId: state[STORE_KEYS.USER_DTL]?.LoggedUserKey,
                    }
                },
                storeKey: STORE_KEYS.GET_BREAKDOWN_MAINTENANCE_ACTION_TYPE,
                uniqueScreenIdentifier: {
                    isLoading: true
                }
            })
        );
    };

    const getBreakdownList = () => {

        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: ACTION_CODES.GET_BREAKDOWN_MAINTENANCE_LIST,
                    reqObj: {
                        userId: state[STORE_KEYS.USER_DTL]?.LoggedUserKey,
                    }
                },
                storeKey: STORE_KEYS.GET_BREAKDOWN_MAINTENANCE_LIST,
                uniqueScreenIdentifier: {
                    isLoading: true
                }
            })
        );
    };

    const fetchBreakdownOrderDetails = (id: number) => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: ACTION_CODES.GET_BREAKDOWN_MAINTENANCE_LIST_DETAILS,
                    reqObj: {
                        userId: state[STORE_KEYS.USER_DTL]?.LoggedUserKey,
                        orderKey: id
                    }
                },
                storeKey: STORE_KEYS.GET_BREAKDOWN_MAINTENANCE_LIST_DETAILS,
                uniqueScreenIdentifier: {
                    MMBDDetails: true
                }
            })
        );
    }

    const getServiceRequestList = () => {

        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: ACTION_CODES.GET_SERVICE_REQUEST_LIST,
                    reqObj: {
                        userId: state[STORE_KEYS.USER_DTL]?.LoggedUserKey,
                    }
                },
                storeKey: STORE_KEYS.GET_SERVICE_REQUEST_LIST,
                uniqueScreenIdentifier: {
                    isLoading: true
                }
            })
        );
    };

    const fetchServiceRequestOrderDetails = async (id: number) => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath:ACTION_CODES.GET_SERVICE_REQUEST_LIST_DETAILS,
                    reqObj: {
                        userId: state[STORE_KEYS.USER_DTL]?.LoggedUserKey,
                        orderKey: id
                    }
                },
                storeKey: STORE_KEYS.GET_SERVICE_REQUEST_LIST_DETAILS,
                uniqueScreenIdentifier: {
                    MMPMDetails: true
                }
            })
        );
        let result;
        const payload = {
            userId: loggedInUser,
            orderKey: id
        };
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.GET_SERVICE_REQUEST_LIST_DETAILS,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    result = response.data.data;
                }
            })
            .catch((error) => {
             trackErrorsInNewRelic({error: error})

                return {
                    status: error.response.status
                };
            });
        return result;
    }

    const getPreventiveMaintenanceList = () => {
        setIsPMLoading(true)
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: ACTION_CODES.GET_PREVENTIVE_MAINTENANCE_LIST,
                    reqObj: {
                        userId: state[STORE_KEYS.USER_DTL]?.LoggedUserKey,
                    }
                },
                storeKey: STORE_KEYS.GET_PREVENTIVE_MAINTENANCE_LIST,
                uniqueScreenIdentifier: {
                    isLoading: true
                }
            })
        );
    };

    const fetchPreventiveMaintenanceOrderDetails = async (id: number) => {
        const data = {
            payload: {
                urlPath:ACTION_CODES.GET_PREVENTIVE_MAINTENANCE_LIST_DETAILS,
                requestType: 'POST',
                reqObj: {
                    userId: state[STORE_KEYS.USER_DTL]?.LoggedUserKey,
                    orderKey: id
                }
            },
            uniqueScreenIdentifier: { MMSRDetails: true },
            storeKey:  STORE_KEYS.GET_PREVENTIVE_MAINTENANCE_LIST_DETAILS,
        };
        dispatch(executeACGAction(data));
    }

    useEffect(()=>{
        if (state?.[STORE_KEYS.GET_PREVENTIVE_MAINTENANCE_LIST_DETAILS]?.MMSRDetails) {
            const accessData = state?.[STORE_KEYS.GET_PREVENTIVE_MAINTENANCE_LIST_DETAILS]?.data;  
            setAssignmentData(accessData);
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.GET_PREVENTIVE_MAINTENANCE_LIST_DETAILS,
                    uniqueScreenIdentifier: {
                        MMSRDetails: false,
                        data: accessData
                    },

                })
            )
        }
    },[state?.[STORE_KEYS.GET_PREVENTIVE_MAINTENANCE_LIST_DETAILS]]);

    const getPreventiveMaintenanceWorkOrderList = () => {
        setIsPMWOLoading(true)
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: ACTION_CODES.GET_PREVENTIVE_MAINTENANCE_WORK_ORDER_LIST,
                    reqObj: {
                        userId: state[STORE_KEYS.USER_DTL]?.LoggedUserKey,
                    }
                },
                storeKey: STORE_KEYS.GET_PREVENTIVE_MAINTENANCE_WORK_ORDER_LIST,
                uniqueScreenIdentifier: {
                    isLoading: true
                }
            })
        );
    };

    const fetchPreventiveMaintenanceWorkOrderDetails = async (id: number) => {
        const data = {
            payload: {
                urlPath:ACTION_CODES.GET_PREVENTIVE_MAINTENANCE_WORK_ORDER_LIST_DETAILS,
                requestType: 'POST',
                reqObj: {
                    userId: state[STORE_KEYS.USER_DTL]?.LoggedUserKey,
                    orderKey: id
                }
            },
            uniqueScreenIdentifier: { PMWODetails: true },
            storeKey: STORE_KEYS.GET_PREVENTIVE_MAINTENANCE_WORK_ORDER_LIST_DETAILS,
        };
        dispatch(executeACGAction(data));    
    }
    useEffect(()=>{
        if (state?.[STORE_KEYS.GET_PREVENTIVE_MAINTENANCE_WORK_ORDER_LIST_DETAILS]?.PMWODetails) {
            const accessData = state?.[STORE_KEYS.GET_PREVENTIVE_MAINTENANCE_WORK_ORDER_LIST_DETAILS]?.data;  
            setAssignmentData(accessData);
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.GET_PREVENTIVE_MAINTENANCE_WORK_ORDER_LIST_DETAILS,
                    uniqueScreenIdentifier: {
                        PMWODetails: false,
                        data: accessData
                    },

                })
            )
        }
    },[state?.[STORE_KEYS.GET_PREVENTIVE_MAINTENANCE_WORK_ORDER_LIST_DETAILS]]);

    const getWorkOrderUsers = (key: any) => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: ACTION_CODES.GET_PREVENTIVE_MAINTENANCE_WORK_ORDER_USER_LIST,
                    reqObj: {
                        userId: state[STORE_KEYS.USER_DTL]?.LoggedUserKey,
                        orderKey: key
                    }
                },
                storeKey: STORE_KEYS.GET_PREVENTIVE_MAINTENANCE_WORK_ORDER_USER_LIST,
                uniqueScreenIdentifier: {
                    workOrderUser: true
                }
            })
        );
    };

    const getPMUsers = (key: any) => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: ACTION_CODES.GET_PREVENTIVE_MAINTENANCE_USER_LIST,
                    reqObj: {
                        userId: state[STORE_KEYS.USER_DTL]?.LoggedUserKey,
                        pmKey: key
                    }
                },
                storeKey: STORE_KEYS.GET_PREVENTIVE_MAINTENANCE_USER_LIST,
                uniqueScreenIdentifier: {
                    pmUser: true
                }
            })
        );
    };

    useEffect(() => {
        getSubModuleAccess();
        getAssets();
        getActionTypes();
        // getBreakdownCategory();
        // getBreakdownActionType();
        // getBreakdownList();
        // getServiceRequestList();
        getPreventiveMaintenanceList();
        getPreventiveMaintenanceWorkOrderList();
    }, []);

    const getSubModuleAccess = () => {
        const data = {
            payload: {
                urlPath: ACTION_CODES.SUBMODULE_ACCESS,
                requestType: 'POST',
                reqObj: {
                    EmailId: state?.[STORE_KEYS.USER_DTL].EmailId,
                }
            },
            uniqueScreenIdentifier: { isSubmodule: true },
            storeKey: STORE_KEYS.SUBMODULE_ACCESS
        };
        dispatch(executeACGAction(data));
    }

    useEffect(() => {
        if (state?.[STORE_KEYS.SUBMODULE_ACCESS]?.body?.data) {
            const accessData = state?.[STORE_KEYS.SUBMODULE_ACCESS]?.body?.data;           
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.SUBMODULE_ACCESS,
                    uniqueScreenIdentifier: {
                        isSubmodule: false,
                        data: accessData
                    },

                })
            )
        }
    }, [state?.[STORE_KEYS.SUBMODULE_ACCESS]?.body?.data]);

    useEffect(() => {
        const tmpTableData = state[STORE_KEYS.GET_BREAKDOWN_MAINTENANCE_ASSETS]?.data;
        if (tmpTableData && tmpTableData.length) {
            setAssetList([
                ...tmpTableData.map((item: any) => ({ code: item.MachineKey, name: item.MachineName }))
            ]);
        }
    }, [state[STORE_KEYS.GET_BREAKDOWN_MAINTENANCE_ASSETS]?.data]);

    useEffect(() => {
        const tmpTableData = state[STORE_KEYS.GET_PREVENTIVE_MAINTENANCE_ACTION_TYPE]?.data;
        if (tmpTableData && tmpTableData.length) {
            setActionTypePM([
                ...tmpTableData.map((item: any) => ({ code: item.MaintActionTypeKey, name: item.MaintActionTypeName }))
            ]);
        }
    }, [state[STORE_KEYS.GET_PREVENTIVE_MAINTENANCE_ACTION_TYPE]?.data]);

    useEffect(() => {
        const tmpTableData = state[STORE_KEYS.GET_BREAKDOWN_MAINTENANCE_CATEGORY]?.data;
        if (tmpTableData && tmpTableData.length) {
            setCategoryList([
                ...tmpTableData.map((item: any) => ({ code: item.MaintBreakdownCategoryKey, name: item.MaintBreakdownCategoryName }))
            ]);
        }
    }, [state[STORE_KEYS.GET_BREAKDOWN_MAINTENANCE_CATEGORY]?.data]);

    useEffect(() => {
        const tmpTableData = state[STORE_KEYS.GET_BREAKDOWN_MAINTENANCE_ACTION_TYPE]?.data;
        if (tmpTableData && tmpTableData.length) {
            setActionTypeList([
                ...tmpTableData.map((item: any) => ({ code: item.MaintActionTypeKey, name: item.MaintActionTypeName }))
            ]);
        }
    }, [state[STORE_KEYS.GET_BREAKDOWN_MAINTENANCE_ACTION_TYPE]?.data]);

    useEffect(() => {
        const tmpTableData = state[STORE_KEYS.GET_BREAKDOWN_MAINTENANCE_LIST]?.data;
        if (tmpTableData && tmpTableData.length) {
            setBreakdownList(tmpTableData);
        } else {
            setBreakdownList([]);
        }
    }, [state[STORE_KEYS.GET_BREAKDOWN_MAINTENANCE_LIST]?.data]);

    useEffect(() => {
        const tmpTableData = state[STORE_KEYS.GET_SERVICE_REQUEST_LIST]?.data;
        if (tmpTableData && tmpTableData.length) {
            setServiceRequestList(tmpTableData);
        } else {
            setServiceRequestList([]);
        }
    }, [state[STORE_KEYS.GET_SERVICE_REQUEST_LIST]?.data]);

    useEffect(() => {
        const tmpTableData = state[STORE_KEYS.GET_PREVENTIVE_MAINTENANCE_LIST]?.data;
        if(!tmpTableData){
            return
        }
        if (tmpTableData && tmpTableData.length) {
            setPreventiveMaintenanceList(tmpTableData);
        } else {
            setPreventiveMaintenanceList([]);
        }
        setIsPMLoading(false)
    }, [state[STORE_KEYS.GET_PREVENTIVE_MAINTENANCE_LIST]?.data]);

    useEffect(() => {
        const tmpTableData = state[STORE_KEYS.GET_PREVENTIVE_MAINTENANCE_WORK_ORDER_LIST]?.data;
        if(!tmpTableData){
            return
        }
        if (tmpTableData && tmpTableData.length) {
            setPreventiveMaintenanceWorkOrderList(tmpTableData);
        } else {
            setPreventiveMaintenanceWorkOrderList([]);
        }
        setIsPMWOLoading(false)
    }, [state[STORE_KEYS.GET_PREVENTIVE_MAINTENANCE_WORK_ORDER_LIST]?.data]);

    useEffect(() => {
        const tmpTableData = state[STORE_KEYS.GET_PREVENTIVE_MAINTENANCE_WORK_ORDER_USER_LIST]?.data;
        if (tmpTableData && tmpTableData.length) {
            setUserList([
                ...tmpTableData.map((item: any) => ({ code: item.AssignedToUserKey, name: item.AssignedTo }))
            ]);
        }
    }, [state[STORE_KEYS.GET_PREVENTIVE_MAINTENANCE_WORK_ORDER_USER_LIST]?.data]);

    useEffect(() => {
        const tmpTableData = state[STORE_KEYS.GET_PREVENTIVE_MAINTENANCE_USER_LIST]?.data;
        if (tmpTableData && tmpTableData.length) {
            setUserList([
                ...tmpTableData.map((item: any) => ({ code: item.AssignedToUserKey, name: item.AssignedTo }))
            ]);
        }
    }, [state[STORE_KEYS.GET_PREVENTIVE_MAINTENANCE_USER_LIST]?.data]);

    const navigateTab = () => {
        switch (currTab) {

            case 0:
                return (
                    <PMConfig
                            preventiveMaintenanceList={preventiveMaintenanceList}
                            setReload={setReload}
                            reload={reload}
                            radioValue={radioValue}
                            selectedClient={selectedClient}
                            setDtlData={setDtlData}
                            dtlData={dtlData}
                            selectedRow={selectedRow}
                            setSelectedRow={() => setSelectedRow}
                            setCreateDrawerOpen={setCreateDrawerOpen}
                            setCreateValue={setCreateValue}
                            setDrawerHeader={setDrawerHeader}
                            setPreventiveMaintenanceKey={setPreventiveMaintenanceKey}
                            getCreateValue={getCreateValue}
                            getPreventiveMaintenanceList={getPreventiveMaintenanceList}
                            fetchPreventiveMaintenanceOrderDetails={(id: any) => fetchPreventiveMaintenanceOrderDetails(id)}
                            setAssignmentData={setAssignmentData}
                            preventiveMaintenanceKey={preventiveMaintenanceKey}
                            getPMUsers={getPMUsers}
                            setAssignPreventive={setAssignPreventive}
                            isLoading={isPMLoading}
                            getPreventiveMaintenanceWorkOrderList={getPreventiveMaintenanceWorkOrderList}
                        />
                    // <BDConfig
                    //     breakdownList={breakdownList}
                    //     setReload={setReload}
                    //     reload={reload}
                    //     radioValue={radioValue}
                    //     selectedClient={selectedClient}
                    //     setDtlData={setDtlData}
                    //     dtlData={dtlData}
                    //     selectedRow={selectedRow}
                    //     setSelectedRow={() => setSelectedRow}
                    //     setCreateDrawerOpen={setCreateDrawerOpen}
                    //     setCreateValue={setCreateValue}
                    //     setDrawerHeader={setDrawerHeader}
                    //     setBreakdownKey={setBreakdownKey}
                    //     getCreateValue={getCreateValue}
                    //     getBreakdownList={getBreakdownList}
                    //     fetchBreakdownOrderDetails={(id: any) => fetchBreakdownOrderDetails(id)}
                    // />
                )
            case 1:
                return (
                    // <SRConfig
                    //     serviceRequestList={serviceRequestList}
                    //     setReload={setReload}
                    //     reload={reload}
                    //     radioValue={radioValue}
                    //     selectedClient={selectedClient}
                    //     setDtlData={setDtlData}
                    //     dtlData={dtlData}
                    //     selectedRow={selectedRow}
                    //     setSelectedRow={() => setSelectedRow}
                    //     setCreateDrawerOpen={setCreateDrawerOpen}
                    //     setCreateValue={setCreateValue}
                    //     setDrawerHeader={setDrawerHeader}
                    //     setServiceRequestKey={setServiceRequestKey}
                    //     getCreateValue={getCreateValue}
                    //     getServiceRequestList={getServiceRequestList}
                    //     fetchServiceRequestOrderDetails={(id: any) => fetchServiceRequestOrderDetails(id)}
                    //     setAssignmentData={setAssignmentData}
                    // />
                    <PRWOConfig
                    preventiveMaintenanceWorkOrderList={preventiveMaintenanceWorkOrderList}
                    setReload={setReload}
                    reload={reload}
                    radioValue={radioValue}
                    selectedClient={selectedClient}
                    setDtlData={setDtlData}
                    dtlData={dtlData}
                    selectedRow={selectedRow}
                    setSelectedRow={() => setSelectedRow}
                    setCreateDrawerOpen={setCreateDrawerOpen}
                    setCreateValue={setCreateValue}
                    setDrawerHeader={setDrawerHeader}
                    setPreventiveMaintenanceKey={setPreventiveMaintenanceKey}
                    getCreateValue={getCreateValue}
                    getPreventiveMaintenanceList={getPreventiveMaintenanceList}
                    getPreventiveMaintenanceWorkOrderList={getPreventiveMaintenanceWorkOrderList}
                    fetchPreventiveMaintenanceWorkOrderDetails={(id: any) => fetchPreventiveMaintenanceWorkOrderDetails(id)}
                    setAssignmentData={setAssignmentData}
                    getWorkOrderUsers={getWorkOrderUsers}
                    setPreventiveMaintenanceWorkOrderKey={setPreventiveMaintenanceWorkOrderKey}
                    alertType={createValue}
                    isLoading={isPMWOLoading}
                />
                )
                // case 2:
                //     return (
                //         <PMConfig
                //             preventiveMaintenanceList={preventiveMaintenanceList}
                //             setReload={setReload}
                //             reload={reload}
                //             radioValue={radioValue}
                //             selectedClient={selectedClient}
                //             setDtlData={setDtlData}
                //             dtlData={dtlData}
                //             selectedRow={selectedRow}
                //             setSelectedRow={() => setSelectedRow}
                //             setCreateDrawerOpen={setCreateDrawerOpen}
                //             setCreateValue={setCreateValue}
                //             setDrawerHeader={setDrawerHeader}
                //             setPreventiveMaintenanceKey={setPreventiveMaintenanceKey}
                //             getCreateValue={getCreateValue}
                //             getPreventiveMaintenanceList={getPreventiveMaintenanceList}
                //             fetchPreventiveMaintenanceOrderDetails={(id: any) => fetchPreventiveMaintenanceOrderDetails(id)}
                //             setAssignmentData={setAssignmentData}
                //             preventiveMaintenanceKey={preventiveMaintenanceKey}
                //         />
                //     ) 
                //     case 3:
                //         return (
                //             <PRWOConfig
                //                 preventiveMaintenanceWorkOrderList={preventiveMaintenanceWorkOrderList}
                //                 setReload={setReload}
                //                 reload={reload}
                //                 radioValue={radioValue}
                //                 selectedClient={selectedClient}
                //                 setDtlData={setDtlData}
                //                 dtlData={dtlData}
                //                 selectedRow={selectedRow}
                //                 setSelectedRow={() => setSelectedRow}
                //                 setCreateDrawerOpen={setCreateDrawerOpen}
                //                 setCreateValue={setCreateValue}
                //                 setDrawerHeader={setDrawerHeader}
                //                 setPreventiveMaintenanceKey={setPreventiveMaintenanceKey}
                //                 getCreateValue={getCreateValue}
                //                 getPreventiveMaintenanceList={getPreventiveMaintenanceList}
                //                 fetchPreventiveMaintenanceWorkOrderDetails={(id: any) => fetchPreventiveMaintenanceWorkOrderDetails(id)}
                //                 setAssignmentData={setAssignmentData}
                //                 getWorkOrderUsers={getWorkOrderUsers}
                //                 setPreventiveMaintenanceWorkOrderKey={setPreventiveMaintenanceWorkOrderKey}
                //                 alertType={createValue}
                //             />
                //         )    
            default:
                return (
                    // <BDConfig
                    //     breakdownList={breakdownList}
                    //     setReload={setReload}
                    //     reload={reload}
                    //     radioValue={radioValue}
                    //     selectedClient={selectedClient}
                    //     setDtlData={setDtlData}
                    //     dtlData={dtlData}
                    //     selectedRow={selectedRow}
                    //     setSelectedRow={() => setSelectedRow}
                    //     setCreateDrawerOpen={setCreateDrawerOpen}
                    //     setCreateValue={setCreateValue}
                    //     setDrawerHeader={setDrawerHeader}
                    //     setBreakdownKey={setBreakdownKey}
                    //     getBreakdownList={getBreakdownList}
                    //     fetchBreakdownOrderDetails={(id: any) => fetchBreakdownOrderDetails(id)}
                    // />
                    <PMConfig
                            preventiveMaintenanceList={preventiveMaintenanceList}
                            setReload={setReload}
                            reload={reload}
                            radioValue={radioValue}
                            selectedClient={selectedClient}
                            setDtlData={setDtlData}
                            dtlData={dtlData}
                            selectedRow={selectedRow}
                            setSelectedRow={() => setSelectedRow}
                            setCreateDrawerOpen={setCreateDrawerOpen}
                            setCreateValue={setCreateValue}
                            setDrawerHeader={setDrawerHeader}
                            setPreventiveMaintenanceKey={setPreventiveMaintenanceKey}
                            getCreateValue={getCreateValue}
                            getPreventiveMaintenanceList={getPreventiveMaintenanceList}
                            fetchPreventiveMaintenanceOrderDetails={(id: any) => fetchPreventiveMaintenanceOrderDetails(id)}
                            setAssignmentData={setAssignmentData}
                            preventiveMaintenanceKey={preventiveMaintenanceKey}
                        />
                );
        }
    };

    const { TotalRoles = 0, TotalUsers = 0 } = state[STORE_KEYS.USER_SUMMARY]?.body?.data?.[0] || {};
    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);

    // useEffect(() => {
    // const _details = JSON.parse(state[STORE_KEYS.USER_DTL]?.features[12]?.SubMenus);
    // const newPermissions = _details?.reduce((acc: Record<string, string>, i: any) => {
    //   acc[i.SubMenuCode] = i.IsEditable;
    //   return acc;
    // }, {});
    // setTabsPermissions(newPermissions || {});
    
    // const subMenu = _details?.map((i: any) => { return i.SubMenuName });
    // setTabs(subMenu);

    // }, [state[STORE_KEYS.USER_DTL]])

useEffect(() => {
    const _details:any = [
        {
            "SubMenuName": "Preventive Maintenance Config",
            "SubMenuCode": "PMC",
            "IsEditable": "Y"
        },
        {
            "SubMenuName": "Preventive Maintenance Work Orders",
            "SubMenuCode": "PMWO",
            "IsEditable": "Y"
        }
    ]
    const newPermissions = _details?.reduce((acc: Record<string, string>, i: any) => {
      acc[i.SubMenuCode] = i.IsEditable;
      return acc;
    }, {});
    setTabsPermissions(newPermissions || {});
    
    const subMenu = _details?.map((i: any) => { return i.SubMenuName });
    setTabs(subMenu);

    trackPageActionLog({
        actionName:"Maintenance Module",
        action:`Landed on Maintenance Module page`,
        LoggedInUser:state[STORE_KEYS.USER_DTL]?.EmailId,
    })
  }, [])

    return (
        <div className="dashboard">
            <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
            <Container maxWidth="xl" style={{ marginTop: '60px' }}>
                <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                    <div style={{ flex: '80%', display: 'flex' }}>
                        <div className="headinglabel" style={{ marginTop: '5px' }}>
                            Maintenance Module
                        </div>
                        <div
                            style={{
                                flex: '10%',
                                marginLeft: '20px',
                                // marginTop: '10px',
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                            className="dashboardDropdownDiv"
                        >
                           {/* <DrawerDropdown click={getCreateValue} items={state?.[STORE_KEYS.SUBMODULE_ACCESS]?.body?.data[0]?.PMSchCreateFlag === "Y" ? ['Breakdown Maintenance', 'Service Request', 'Preventive Maintenance'] : ['Breakdown Maintenance', 'Service Request'] } /> */}
                            {state?.[STORE_KEYS.SUBMODULE_ACCESS]?.body?.data[0]?.PMSchCreateFlag === "Y" ? <DrawerDropdown click={getCreateValue} items={state?.[STORE_KEYS.SUBMODULE_ACCESS]?.body?.data[0]?.PMSchCreateFlag === "Y" ? ['Preventive Maintenance Schedule'] : [] } /> : null }
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: '35px', zIndex: '0' }}>
                    <MapCustomTabs
                        navigateTab={navigateTab}
                        currTab={currTab}
                        setCurrTab={setCurrTab}
                        tabsArray={tabs}
                        className="tabs"
                        position="relative"
                        moduleName='Maintenance Module Tabs'
                    // type="nonCurve"
                    />
                </div>
            </Container>
            <RightDrawer
                alertType={createValue}
                handlerOpen={() => { }}
                handlerClose={() => { setCreateDrawerOpen(false); setDrawerHeader('Create'); setServiceRequestKey(''); setAssignPreventive(false); }}
                status={createDrawerOpen}
                drawerHeader={`${drawerHeader} ${createValue === "Custom Alerts" ? "" : createValue}`}
                children={
                    drawerHeader === 'Create' || drawerHeader === 'Edit' ? (
                        createValue === 'Breakdown Maintenance' ? (
                            <BDCreateConfig
                                breakdownKey={breakdownKey}
                                drawerClose={() => { setCreateDrawerOpen(false); setServiceRequestKey(''); setAssignmentData([]); }}
                                setCurrTab={setCurrTab}
                                setReload={setReload}
                                setDtlData={setDtlData}
                                dtlData={dtlData}
                                setSnackbarOpts={setSnackbarOptions}
                                isEdit={false}
                                selectedRow={selectedRow}
                                setSelectedRow={setSelectedRow}
                                assetList={assetList}
                                categoryList={categoryList}
                                actionTypeList={actionTypeList}
                                getBreakdownList={getBreakdownList}
                                fetchBreakdownOrderDetails={(id: any) => fetchBreakdownOrderDetails(id)}
                            />
                        ) : (
                            createValue === "Service Request" ?
                            <SRCreateConfig
                                serviceRequestKey={serviceRequestKey}
                                drawerClose={() => { setCreateDrawerOpen(false); }}
                                setCurrTab={setCurrTab}
                                setReload={setReload}
                                setDtlData={setDtlData}
                                dtlData={dtlData}
                                setSnackbarOpts={setSnackbarOptions}
                                isEdit={false}
                                selectedRow={selectedRow}
                                setSelectedRow={setSelectedRow}
                                assetList={assetList}
                                categoryList={categoryList}
                                actionTypeList={actionTypeList}
                                getServiceRequestList={getServiceRequestList}
                                fetchServiceRequestOrderDetails={(id: any) => fetchServiceRequestOrderDetails(id)}
                            />
                            :
                            <PMCreateConfig
                                preventiveMaintenanceKey={preventiveMaintenanceKey}
                                drawerClose={() => { setCreateDrawerOpen(false); setAssignmentData([]);}}
                                setCurrTab={setCurrTab}
                                setReload={setReload}
                                setDtlData={setDtlData}
                                dtlData={dtlData}
                                setSnackbarOpts={setSnackbarOptions}
                                isEdit={false}
                                selectedRow={selectedRow}
                                setSelectedRow={setSelectedRow}
                                assetList={assetList}
                                categoryList={categoryList}
                                actionTypeList={actionTypeList}
                                getPreventiveMaintenanceList={getPreventiveMaintenanceList}
                                fetchPreventiveMaintenanceOrderDetails={(id: any) => fetchPreventiveMaintenanceOrderDetails(id)}
                                actionTypePM={actionTypePM}
                                assignmentData={assignmentData}
                                drawerHeader={drawerHeader}
                                />
                        )
                    ) : (
                        
                        <>
                            {
                                drawerHeader === "Assign" ?
                                    (<SRAssignConfig
                                        serviceRequestKey={serviceRequestKey}
                                        drawerClose={() => { setCreateDrawerOpen(false); setSelectedRow([]);setAssignmentData([]); }}
                                        setCurrTab={setCurrTab}
                                        setReload={setReload}
                                        setDtlData={setDtlData}
                                        dtlData={dtlData}
                                        setSnackbarOpts={setSnackbarOptions}
                                        isEdit={false}
                                        selectedRow={selectedRow}
                                        setSelectedRow={setSelectedRow}
                                        assetList={assetList}
                                        categoryList={categoryList}
                                        actionTypeList={actionTypeList}
                                        getServiceRequestList={getServiceRequestList}
                                        fetchServiceRequestOrderDetails={(id: any) => fetchServiceRequestOrderDetails(id)}
                                        assignmentData={assignmentData}
                                    />)
                                    : drawerHeader === "Action" ?
                                    (<SRActionConfig
                                        serviceRequestKey={serviceRequestKey}
                                        drawerClose={() => { setCreateDrawerOpen(false); setSelectedRow([]); setAssignmentData([]);}}
                                        setCurrTab={setCurrTab}
                                        setReload={setReload}
                                        setDtlData={setDtlData}
                                        dtlData={dtlData}
                                        setSnackbarOpts={setSnackbarOptions}
                                        isEdit={false}
                                        selectedRow={selectedRow}
                                        setSelectedRow={setSelectedRow}
                                        assetList={assetList}
                                        categoryList={categoryList}
                                        actionTypeList={actionTypeList}
                                        getServiceRequestList={getServiceRequestList}
                                        fetchServiceRequestOrderDetails={(id: any) => fetchServiceRequestOrderDetails(id)}
                                        assignmentData={assignmentData}
                                    />)
                                    : drawerHeader === "Close" ?
                                    (<SRCloseConfig
                                        serviceRequestKey={serviceRequestKey}
                                        drawerClose={() => { setCreateDrawerOpen(false); setSelectedRow([]);setAssignmentData([]); }}
                                        setCurrTab={setCurrTab}
                                        setReload={setReload}
                                        setDtlData={setDtlData}
                                        dtlData={dtlData}
                                        setSnackbarOpts={setSnackbarOptions}
                                        isEdit={false}
                                        selectedRow={selectedRow}
                                        setSelectedRow={setSelectedRow}
                                        assetList={assetList}
                                        categoryList={categoryList}
                                        actionTypeList={actionTypeList}
                                        getServiceRequestList={getServiceRequestList}
                                        fetchServiceRequestOrderDetails={(id: any) => fetchServiceRequestOrderDetails(id)}
                                        assignmentData={assignmentData}
                                    />)
                                    : drawerHeader === "Preventive Maintenance Close" ?
                                    (<PWWOCloseConfig
                                        preventiveMaintenanceWorkOrderKey={preventiveMaintenanceWorkOrderKey}
                                        drawerClose={() => { setCreateDrawerOpen(false); setSelectedRow([]); setAssignmentData([]);}}
                                        setCurrTab={setCurrTab}
                                        setReload={setReload}
                                        setDtlData={setDtlData}
                                        dtlData={dtlData}
                                        setSnackbarOpts={setSnackbarOptions}
                                        isEdit={false}
                                        selectedRow={selectedRow}
                                        setSelectedRow={setSelectedRow}
                                        assetList={assetList}
                                        categoryList={categoryList}
                                        actionTypeList={actionTypeList}
                                        getPreventiveMaintenanceWorkOrderList={getPreventiveMaintenanceWorkOrderList}
                                        fetchPreventiveMaintenanceWorkOrderDetails={(id: any) => fetchPreventiveMaintenanceWorkOrderDetails(id)}
                                        assignmentData={assignmentData}
                                        getPreventiveMaintenanceList={getPreventiveMaintenanceList}
                                    />)
                                    : drawerHeader === "Preventive Maintenance Checklist" ?
                                    (<PMCreateChecklist
                                        preventiveMaintenanceKey={preventiveMaintenanceKey}
                                        drawerClose={() => { setCreateDrawerOpen(false); setSelectedRow([]); setPreventiveMaintenanceKey('');setAssignmentData([]); }}
                                        setCurrTab={setCurrTab}
                                        setReload={setReload}
                                        setDtlData={setDtlData}
                                        dtlData={dtlData}
                                        setSnackbarOpts={setSnackbarOptions}
                                        isEdit={false}
                                        selectedRow={selectedRow}
                                        setSelectedRow={setSelectedRow}
                                        setPreventiveMaintenanceKey={setPreventiveMaintenanceKey}
                                    />)
                                    : drawerHeader === "Preventive Maintenance Update Checklist" ?
                                    (<PMUpdateChecklist
                                        preventiveMaintenanceKey={preventiveMaintenanceKey}
                                        drawerClose={() => { setCreateDrawerOpen(false); setSelectedRow([]); setPreventiveMaintenanceKey(''); setAssignmentData([]); }}
                                        setCurrTab={setCurrTab}
                                        setReload={setReload}
                                        setDtlData={setDtlData}
                                        dtlData={dtlData}
                                        setSnackbarOpts={setSnackbarOptions}
                                        isEdit={false}
                                        selectedRow={selectedRow}
                                        setSelectedRow={setSelectedRow}
                                        setPreventiveMaintenanceKey={setPreventiveMaintenanceKey}
                                        getPreventiveMaintenanceWorkOrderList={getPreventiveMaintenanceWorkOrderList}
                                    />)
                                    : drawerHeader === "Assign Preventive Maintenance" ?
                                    <Assign 
                                    preventiveMaintenanceWorkOrderKey={preventiveMaintenanceWorkOrderKey}
                                    preventiveMaintenanceKey={preventiveMaintenanceKey}
                                        drawerClose={() => { setCreateDrawerOpen(false); setSelectedRow([]); setAssignmentData([]);}}
                                        setCurrTab={setCurrTab}
                                        setReload={setReload}
                                        setDtlData={setDtlData}
                                        dtlData={dtlData}
                                        setSnackbarOpts={setSnackbarOptions}
                                        isEdit={false}
                                        selectedRow={selectedRow}
                                        setSelectedRow={setSelectedRow}
                                        assetList={assetList}
                                        categoryList={categoryList}
                                        userList={userList}
                                        getPreventiveMaintenanceWorkOrderList={getPreventiveMaintenanceWorkOrderList}
                                        fetchPreventiveMaintenanceWorkOrderDetails={(id: any) => fetchPreventiveMaintenanceWorkOrderDetails(id)}
                                        assignPreventive={assignPreventive}
                                        getPreventiveMaintenanceList={getPreventiveMaintenanceList}
                                    />
                                    : null
                            }
                        </>
                    )
                }
            />

        </div>
    );
};

export default SystemConfiguration;
