import { useEffect, useRef, useState } from 'react';
import { Checkbox, Container, FormControl, ListItemText, MenuItem, Grid, Select, Typography, Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import Axios from 'axios';
import useGetState from '../../../../utils/hooks/useGetState';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../../constants/apiConstants';
import DropDown from '../../../../atoms/Dropdown';
import ACGButton from '../../../../atoms/Button';
import { Formik, Form, FormikProps } from 'formik';
import Label from '../../../../atoms/Label/index';
import CustomDatePicker from '../../../../atoms/CustomDatePicker';
import { trackErrorsInNewRelic } from '../../../../utils/newRelicFuncations';
import MultipleSelectCheckmarks from '../../../../atoms/MultiselectDropDown';
import { executeACGAction } from '../../../../store/slice';
type ComponentProps = {
    drawerClose: Function;
    handleSubmit: Function;
    initialValues: any;
    setInitialValues: Function;
    setTemperatureFilters: Function;
    setAllFilters: Function;
    setProductKey:any;
    productKey:any;
    setSelelctedMachine:any;
    selelctedMachine:any;
    recipeKey:any;
    setRecipeKey:any;
    setMachineNameKey:any;
    machineNameKey:any;
    setPlantKey:any;
    plantKey:any;
    setTempEndDate:any;
    tempEndDate:any;
    setTempStartDate:any;
    tempStartDate:any;
    allFilters:any;
    open:any
};

type ModuleType = {
    plantInputValue: string | '';
    machineNameInputValue: string | '';
    batchInputValue: string | '';
    recipeInputValue: string | '';
    startDate: any;
    endDate: any;
};

const CreateModule = (props: ComponentProps) => {
    const {
        drawerClose,
        initialValues,
        handleSubmit,
        setInitialValues,
        setAllFilters,
        setTemperatureFilters = () => {},
        setProductKey,
        productKey,
        setSelelctedMachine,
        selelctedMachine,
        recipeKey,
        setRecipeKey,
        setMachineNameKey,
        machineNameKey,
        setPlantKey,
        plantKey,
        setTempEndDate,
        tempEndDate,
        setTempStartDate,
        allFilters,
        tempStartDate,
        open,

    } = props;

    const [plantsList, setPlantsList] = useState<any[]>([]);
    const [machineNameList, setMachineNameList] = useState<any[]>([]);
    const [recipeList, setRecipeList] = useState<any[]>([]);
    const [productList, setproductList] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const formRef = useRef<any>(null);
    const state = useGetState();
    const token = state?.[STORE_KEYS.USER_DTL].token;
    const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;
    const dispatch = useDispatch();

    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 1);

    useEffect(() => {
        getPlants();
    }, []);

    const getPlants = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: ACTION_CODES.CPP_REPORT.GetPlantList
                },
                uniqueScreenIdentifier: { cppFilterPage: true },
                storeKey: STORE_KEYS.CPP_REPORT.GetPlantList
            })
        );
    };

    const getMachines = (key: any) => {
        const payload = {
            userId: loggedInUser,
            plantKey: key,
            type: 'machine_name'
        };

        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: ACTION_CODES.CPP_REPORT.GetMachineList,
                    reqObj: payload
                },
                uniqueScreenIdentifier: { cppFilterPage: true },
                storeKey: STORE_KEYS.CPP_REPORT.GetMachineList
            })
        );
    };

    const getRecipies = () => {
        const payload = {
            userId: loggedInUser,
            machineKey: machineNameKey,
            fromDate: tempStartDate,
            toDate: tempEndDate,
            plantKey: plantKey,
            custProductKey: productKey
        };

        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: ACTION_CODES.CPP_REPORT.GetRecipies,
                    reqObj: payload
                },
                uniqueScreenIdentifier: { cppFilterPage: true },
                storeKey: STORE_KEYS.CPP_REPORT.GetRecipies
            })
        );
    };

    const getProductList = () => {
        const payload = {
            userId: loggedInUser,
            machineKey: machineNameKey,
            fromDate: tempStartDate,
            toDate: tempEndDate,
            plantKey: plantKey
        };

        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: ACTION_CODES.CPP_REPORT.GetProductList,
                    reqObj: payload
                },
                uniqueScreenIdentifier: { cppFilterPage: true },
                storeKey: STORE_KEYS.CPP_REPORT.GetProductList
            })
        );
    };

    const handleChangeDropdown = (event: any, type: string) => {
        
        if (type === 'plants') {
            setPlantKey(event.target.value);
            getMachines(event.target.value);
            setMachineNameKey('');
            setProductKey("")
            setRecipeKey('');
        } else if (type === 'machine') {
            setMachineNameKey(event);
            setRecipeKey('');
        } else if (type === 'product') {
            setProductKey(event.target.value);
            setRecipeKey("");
        } else if (type === 'recipe') {
            setRecipeKey(event.target.value);
        } 
    };

    useEffect(() => {
        if (tempEndDate && tempStartDate) {
            getProductList();
        }
    }, [machineNameKey, tempEndDate, tempStartDate]);

    useEffect(() => {
        if (tempEndDate && tempStartDate) {
            getRecipies();
        }
    }, [machineNameKey, tempEndDate, tempStartDate, productKey]);

    useEffect(() => {
        const tmpTableData = state?.[STORE_KEYS.CPP_REPORT.GetPlantList]?.body?.data;
        if (tmpTableData) {
            setPlantsList([...tmpTableData?.map((item: any) => ({ code: item.PlantKey, name: item.PlantName }))]);
        }
    }, [state?.[STORE_KEYS.CPP_REPORT.GetPlantList]]);

    useEffect(() => {
        const tmpTableData = state?.[STORE_KEYS.CPP_REPORT.GetMachineList]?.body?.data;
        if (tmpTableData) {
            setMachineNameList([
                ...tmpTableData?.map((item: any) => ({ value: item.MachineKey, label: item.MachineName }))
            ]);
        }
    }, [state?.[STORE_KEYS.CPP_REPORT.GetMachineList]]);

    useEffect(() => {
        const tmpTableData = state?.[STORE_KEYS.CPP_REPORT.GetRecipies]?.body?.data;
        if (tmpTableData) {
            setRecipeList([...tmpTableData?.map((item: any) => ({ code: item.RecipeKey, name: item.RecipeName }))]);
        }
    }, [state?.[STORE_KEYS.CPP_REPORT.GetRecipies]]);

    useEffect(() => {
        const tmpTableData = state?.[STORE_KEYS.CPP_REPORT.GetProductList]?.body?.data;
        if (tmpTableData) {
            setproductList([
                ...tmpTableData?.map((item: any) => ({ code: item.CustProductKey, name: item.ProductName }))
            ]);
        }
    }, [state?.[STORE_KEYS.CPP_REPORT.GetProductList]]);

    const renderButtons = () => {
        return (
            <div className={'btns noWrap'}>
                <ACGButton
                    name={'Cancel'}
                    handleClick={drawerClose}
                    secondary={true}
                    className={'first-btn'}
                    type="button"
                    variant="contained"
                />
                <ACGButton
                    name={'Submit'}
                    handleClick={() => {}}
                    type="submit"
                    className="second-btn"
                    variant="contained"
                    disabled={!recipeKey}
                    isLoading = {isLoading}
                />
            </div>
        );
    };

    useEffect(()=>{
       if(!plantKey)  setMachineNameList([])
    },[plantKey])

    useEffect(()=>{
        allFilters?.plantKey && setPlantKey(allFilters?.plantKey)
        allFilters?.machineNameKey && setMachineNameKey(allFilters?.machineNameKey)
        allFilters?.tempEndDate && setTempEndDate(allFilters?.tempEndDate)
        allFilters?.tempStartDate && setTempStartDate(allFilters?.tempStartDate)
        allFilters?.productKey && setProductKey(allFilters?.productKey)
        allFilters?.recipeKey &&  setRecipeKey(allFilters?.recipeKey)
    },[open])

    return (
        <Container className="createReport">
            <Formik
                initialValues={initialValues}
                onSubmit={() => {
                    handleSubmit(plantKey, machineNameKey, tempStartDate, tempEndDate, productKey, recipeKey);
                    drawerClose()
                }}
                validateOnBlur={false}
                validateOnChange={false}
                innerRef={formRef}
            >
                {(formikprops: FormikProps<ModuleType>) => {
                    const { values, handleChange, handleBlur, errors, touched, setFieldValue } = formikprops;
                    return (
                        <Form className="cppClsMain">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                                    <Label label="Plants" className={'labelDiv mbtop'} />
                                    <DropDown
                                        onChange={(e) => handleChangeDropdown(e, 'plants')}
                                        className="inputDiv"
                                        name="dropdownModel"
                                        items={plantsList}
                                        placeHolder="Select Plants"
                                        value={plantKey}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                                    <Label label="Machine Name" className={'labelDiv'} />
                                    <MultipleSelectCheckmarks
                                        onChange={(e: any) => handleChangeDropdown(e, 'machine')}
                                        className="inputDiv"
                                        disabled
                                        options={machineNameList}
                                        placeHolder="Select Machine"
                                        value={selelctedMachine}
                                        setValue={setSelelctedMachine}
                                    />
                                </Grid>
                              
                                    <>
                                        <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                                            <Label label="Start Date" className={'labelDiv'} />
                                            <CustomDatePicker
                                                name="startDate"
                                                value={tempStartDate}
                                                className="inputDiv"
                                                onChange={(val: any) => {
                                                    setTempStartDate(val);
                                                    setFieldValue('startDate', val);
                                                }}
                                                minDate={minDate}
                                                inputFormat="dd/MM/yyyy"
                                                placeholder="Select Start Date"
                                                maxDate={new Date()}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                                            <Label label="End Date" className={'labelDiv'} />
                                            <CustomDatePicker
                                                name="endDate"
                                                value={values.endDate}
                                                className="inputDiv"
                                                onChange={(val: any) => {
                                                    setTempEndDate(val);
                                                    setFieldValue('endDate', val);
                                                }}
                                                minDate={tempEndDate}
                                                inputFormat="dd/MM/yyyy"
                                                placeholder="Select Start Date"
                                                maxDate={new Date()}
                                                PopperProps={{
                                                    placement: 'top-start', // Ensures the picker opens upward
                                                    modifiers: [
                                                      {
                                                        name: 'preventOverflow',
                                                        options: {
                                                          boundary: 'window',
                                                        },
                                                      },
                                                    ],
                                                  }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                                            <Label label="Product" className={'labelDiv'} />
                                            <DropDown
                                                onChange={(e) => handleChangeDropdown(e, 'product')}
                                                className="inputDiv"
                                                name="dropdownModel"
                                                items={productList}
                                                placeHolder="Select product"
                                                value={productKey}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                                            <Label label="Recipies" className={'labelDiv'} />
                                            <DropDown
                                                onChange={(e) => handleChangeDropdown(e, 'recipe')}
                                                className="inputDiv"
                                                name="dropdownModel"
                                                items={recipeList}
                                                placeHolder="Select Recipe"
                                                value={recipeKey}
                                            />
                                        </Grid>
                                    </>
                              
                            </Grid>
                            {renderButtons()}
                        </Form>
                    );
                }}
            </Formik>
        </Container>
    );
};
export default CreateModule;
