import React, { useEffect, useRef, useState } from "react";
import visavail from "visavail";
import "./styles.css";
import "visavail/visavail.min.css";
import "visavail/visavail.min.js";
import PropTypes from "prop-types";
import LabelCommonComponentForGraph from "../Visavali/LabelCommonComponentForGraph";

const VisavailChart = (props: any) => {
  const chartContainerRef = useRef(null);
  const chartRef = useRef<any>(null);
  const [isDataAvailable, setIsDataAvailable] = useState(true);
  const [isVisible, setIsVisible] = useState(true);

  // Dynamically generate unique ID for each chart
  const chartId = props.chartId || "default_chart";

  useEffect(() => {
    if (props.barChartData.length === 0) {
      setIsDataAvailable(false);
      return;
    }

    const alarmBarOptions = {
      id_div_container: `${chartId.replace(' ', '_').replace('/', '_')}_container`,  // Dynamically set the container ID
      id_div_graph: `${chartId.replace(' ', '_').replace('/', '_')}_div`,  // Dynamically set the graph ID
      date_in_utc: false,
      is_date_only_format: false,
      defined_blocks: true,
      margin: {
        left: 15,
        right: 15,
      },
      line_spacing: 10,
      custom_time_format: {
        format_millisecond: ".%L",
        format_second: ":%S",
        format_minute: "%H:%M",
        format_hour: "%H:%M",
        format_day: "%a %d",
        format_week: "%b %d",
        format_month: "%B",
        format_year: "%Y",
      },
      tooltip: {
        height: 18,
        width: 30,
        position: "overlay",
        left_spacing: 15,
        only_first_date: false,
        date_plus_time: false,
      },

      zoom: {
        enabled: false,
        ratio: 0.9,
      },
      responsive: {
        enabled: true,
      },
      show_y_title: false,
      title: {
        enabled: false,
      },
      sub_title: {
        enabled: false,
      },
      legend: {
        enabled: true,
      },
      graph: {
        height: 10,
        width: 100,
      },
    };

    const alarmBarDataset = [
      {
        measure: "Machine Wise",
        categories: {
          OB: {
            class: "rect_has_no_data_OB",
            tooltip_html: '<i class="fas fa-fw fa-check tooltip_has_data"></i>',
          },
          RUN: {
            class: "rect_has_data",
            tooltip_html:
              '<i class="fas fa-fw fa-exclamation-circle tooltip_has_no_data"></i>',
          },
          UPDT: {
            class: "rect_has_no_data",
            tooltip_html:
              '<i class="fas fa-fw fa-exclamation-circle tooltip_has_no_data"></i>',
          },
          PDT: {
            class: "rect_has_extra_data",
            tooltip_html:
              '<i class="fas fa-fw fa-exclamation-circle tooltip_has_no_data"></i>',
          },
        },
        interval_s: 60 * 60,
        data: props.barChartData,
        description: props.hoverdata,
      },
    ];

    chartRef.current = visavail.generate(alarmBarOptions, alarmBarDataset);

    return () => {
      chartRef.current.destroy();
    };
  }, [props.barChartData]);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.updateGraph([
        {
          measure: "test",
          categories: {
            OB: {
              class: "rect_has_no_data_OB",
              tooltip_html: '<i class="fas fa-fw fa-check tooltip_has_data"></i>',
            },
            RUN: {
              class: "rect_has_data",
              tooltip_html:
                '<i class="fas fa-fw fa-exclamation-circle tooltip_has_no_data"></i>',
            },
            UPDT: {
              class: "rect_has_no_data",
              tooltip_html:
                '<i class="fas fa-fw fa-exclamation-circle tooltip_has_no_data"></i>',
            },
            PDT: {
              class: "rect_has_extra_data",
              tooltip_html:
                '<i class="fas fa-fw fa-exclamation-circle tooltip_has_no_data"></i>',
            },
          },
          data: props.barChartData,
        },
      ]);
    }
  }, [props.barChartData]);

  return (
    <div style={{ position: "relative" }}>
      {isVisible && props?.machineShifttimeData[0]?.starttime && (
        <div>
          <div className="create_start_point">
            <div style={{ marginBottom: "3px" }} className="starting_time">
              {props?.machineShifttimeData[0]?.starttime}
            </div>
            <div className="vertical_line"></div>
          </div>
        </div>
      )}

      <div
        className="visavail"
        id={`${chartId.replace(' ', '_').replace('/', '_')}_container`}  // Use dynamic ID
        ref={chartContainerRef}
        key={chartId.replace(' ', '_').replace('/', '_')}  // Unique key for each chart
      >
        <div id={`${chartId.replace(' ', '_').replace('/', '_')}_div`}></div>
      </div>
      {isVisible && props?.machineShifttimeData[0]?.endtime && (
        <div>
          <div className="create_end_point">
            <div className="ending_time">
              {props?.machineShifttimeData[0]?.endtime}
            </div>
            <div className="vertical_line_end"></div>
          </div>
        </div>
      )}
      <LabelCommonComponentForGraph LEGEND_DATA={props?.legendData} />
    </div>
  );
};

export default VisavailChart;

VisavailChart.propTypes = {
  barChartData: PropTypes.any,
  hoverdata: PropTypes.any,
  machineShifttimeData: PropTypes.any,
  legendData: PropTypes.any,
  chartId: PropTypes.string,
  showLegend: PropTypes.any
};
