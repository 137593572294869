import React from 'react';

interface LegendData {
  title: string;
  color: string;
}

interface LabelCommonComponentForGraphProps {
  LEGEND_DATA: LegendData[];
  color?: string;
}

const LabelCommonComponentForGraph: React.FC<LabelCommonComponentForGraphProps> = (props) => {
  return (
    <div style={{ display: "flex", gap: "30px", justifyContent: "center" }}>
      {props.LEGEND_DATA.map((_e) => (
        <div
          style={{ display: "flex", alignItems: "center", gap: "8px" }}
          key={_e.title}
        >
          <div
            style={{
              width: "12px",
              height: "12px",
              backgroundColor: _e.color,
            }}
          ></div>
          <div className="legend_text" style={{ color: `${props?.color ? props?.color : 'none'}` }}>
            {_e.title}
          </div>
        </div>
      ))}
    </div>
  );
};

export default LabelCommonComponentForGraph;
