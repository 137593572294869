import { useState, useEffect, useRef } from 'react';
import { AnyIfEmpty, useDispatch } from 'react-redux';
import { Container } from '@mui/material';
import MapCustomTabs from '../../atoms/ButtonTabs/MapCustomTabs';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../constants/apiConstants';
import useGetState from '../../utils/hooks/useGetState';
import { _noop } from '../../utils/common';
import '../Admin/ClientManagement/index.scss';
import SnackbarAlert from '../../atoms/Snackbar';
import OEEDashboardHomePage from './Home';
import OEEDetails from './OperatorwiseDT';
import Axios from 'axios';

const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    }
};
const SystemConfiguration = () => {
    const state = useGetState();
    const [currTab, setTabChange] = useState(0);
    const [tabs, setTabs] = useState<any>([]);
    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const [defaultDate, setDefaultDate] = useState(new Date());
    const date = new Date();
    date.setDate(date.getDate() - 6)
    const [inDate, setInDate] = useState(date);
    const [endDate, setEndDate] = useState(new Date());
    const [plantsData, setPlantsData] = useState<any[]>([]);
    const [selectedMachineModel, setSelectedMachineModel] = useState<any>('');
    const [machineModel, setMachineModel] = useState<any>([]);
    const [machines, setMachines] = useState<any>([]);
    const [multipleDateMachines, setMultipleDateMachines] = useState<any>([]);
    const [selectedMachines, setSelectedMachines] = useState<any>([]);
    const [selectedMultipleDateMachine, setSelectedMultipleDateMachine] = useState('');
    const [operators, setOperators] = useState<any>([]);
    const [selectedOperator, setSelectedOperator] = useState('');
    const [selectedMultipleOperators, setSelectedMultipleOperators] = useState<any>([]);
    const token = state?.[STORE_KEYS.USER_DTL].token;
    const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;
    const [selectedPlant, setSelectedPlant] = useState<any>(null);
    const [shifts, setShifts] = useState<any>([]);
    const [selectedShift, setSelectedShift] = useState<any>('');
    const [plantVisibility, setPlantVIsibility] = useState<any>([]);
    const [productivity, setProductivity] = useState<any>([]);
    const [operatorVisibility, setOperatorVisibility] = useState<any>([]);
    const [isLoading,setIsLoading]=useState(false)
    const [isRedirect, setIsRedirect] = useState(false);
    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);

    const setCurrTab = (val: number) => {
        setTabChange(val);
    };

    const fetchShifts = (pKey: any) => {
        const payload = {
            plantKey: pKey,
            userId: loggedInUser,
        };
        Axios({
            url: BASEURL + '/' + ACTION_CODES.API_OEE_PLANT_VISIBILITY_SHIFTS,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;
                    const tmpTableData = result[0][0];
                    setShifts([
                        ...tmpTableData?.map((item: any) => ({ code: item.ShiftKey, name: item.ShiftName }))])
                        const currentHour = new Date().getHours();
    
                        if (currentHour >= 7 && currentHour < 16) {
                          setSelectedShift(tmpTableData[0]?.ShiftKey);
                        } else if (currentHour >= 16 && currentHour < 24) {
                          setSelectedShift(tmpTableData[1]?.ShiftKey);
                        } else {
                          setSelectedShift(tmpTableData[2]?.ShiftKey);
                        }
                }
            })
            .catch((error) => {
                if (window.newrelic) {
                    window.newrelic.noticeError(error?.message);
                }
                return {
                    status: error.response.status
                };
            });
    }

    useEffect(() => {
        if (selectedMachines.length > 0 && selectedShift != '' && selectedShift != undefined) {
            setPlantVIsibility([]);
            fetchPlantVisibilityData(selectedMachines, selectedShift)
        }

    }, [selectedMachines, selectedShift])

    useEffect(() => {
        if (selectedMachines.length > 0 && selectedShift !== '' && selectedShift !== undefined) {
          const interval = setInterval(() => {
            fetchPlantVisibilityData(selectedMachines, selectedShift);
          }, 300000);

          return () => clearInterval(interval);
        }
      }, [selectedShift, selectedMachines]);

    const fetchPlantVisibilityData = async (machines: any, shift: any) => {
        setIsLoading(true)
        const payload = {
            plantKey: selectedPlant,
            model: selectedMachineModel,
            machines: machines.toString(),
            date: new Date(defaultDate).toISOString().split('T')[0],
            shiftKey: shift
        };
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.API_OEE_PLANT_VISIBILITY,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;

                    const tmpTableData = result[0][0];
                    const groupedData = tmpTableData.reduce((acc: any, curr: any) => {
                        const serialNo = curr.MachineSerialNo;

                        if (!acc[serialNo]) {
                            acc[serialNo] = [];
                        }

                        acc[serialNo].push(curr);

                        return acc;
                    }, {});
                    setPlantVIsibility(groupedData);
                }
            })
            .catch((error: any) => {
                if (window.newrelic) {
                    window.newrelic.noticeError(error?.message);
                }
                return {
                    status: error
                };
            }).finally(()=>{
                setIsLoading(false)
            })
    }

    // useEffect(() => {
    //     const _details = JSON.parse(state[STORE_KEYS.USER_DTL]?.features[20]?.SubMenus);
    //     const subMenu = _details?.map((i: any) => { return i.SubMenuName });
    //     setTabs(subMenu);

    // }, [state[STORE_KEYS.USER_DTL]])

    useEffect(() => {
        const _details = [
            {
                "SubMenuName": "Plant Visibility",
                "SubMenuCode": "PVD",
                "IsEditable": "Y"
            },
            {
                "SubMenuName": "Operator Efficiency",
                "SubMenuCode": "OPF",
                "IsEditable": "Y"
            },
        ]
        const subMenu = _details?.map((i: any) => { return i.SubMenuName });
        setTabs(subMenu);

    }, [])

    useEffect(() => {
        setSelectedMachineModel('');
        setSelectedPlant('');
        setSelectedMachines([]);
        setMachines([]);
        setMachineModel([]);
        setPlantsData([]);
    }, [defaultDate])

    const handleChangePlant = (event: any) => {
        setSelectedPlant(event.target.value);
        setSelectedMachineModel('');
        setSelectedMachines([]);
    };

    const handleMachineModel = (event: any) => {
        setSelectedMachineModel(event.target.value);
        setSelectedMachines([]);
    }

    const handleChange = async (id: any) => {
        let data: any;
        setSelectedMachines((prevSelected: any) => {
            if (prevSelected.includes(id)) {
                data = prevSelected.filter((optionId: any) => optionId !== id);
                return data;
            } else {
                if (prevSelected.length < 5) {
                    data = [...prevSelected, id];
                    return data;
                } else {
                    return prevSelected;
                }
            }
        });
    };

    const handleChangeShift = async (data: any) => {
        setSelectedShift(data);
    };

    const handleChangeMultipleDate = async (data: any) => {
        setSelectedMultipleDateMachine(data);
    };

    const handleChangeOperator = async (data: any) => {
        setSelectedOperator(data);
    };

    useEffect(() => {
        (async () => {
            const payload = {
                userId: loggedInUser,
            };
            await Axios({
                url: BASEURL + '/' + ACTION_CODES.API_PMEC_GET_PLANTS,
                method: 'post',
                data: payload,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
                .then((response) => {
                    if (response.status == 200 && response?.data) {
                        const result = response?.data?.message;
                        const tmpTableData = result[0][0];
                        setPlantsData([
                            ...tmpTableData?.map((item: any) => ({ code: item.PlantKey, name: item.PlantName }))])
                        setSelectedPlant(tmpTableData[0]?.PlantKey)
                    }
                })
                .catch((error) => {
                    if (window.newrelic) {
                        window.newrelic.noticeError(error?.message);
                    }
                    return {
                        status: error.response.status
                    };
                });

        })();
    }, [defaultDate]);

    useEffect(() => {
        if (selectedPlant) {
            setProductivity([]);
            setOperatorVisibility([]);
            setSelectedOperator('');
            setSelectedMultipleDateMachine('');
            setMultipleDateMachines([]);
            setOperators([]);
            fetchShifts(selectedPlant)
            const payload = {
                userId: loggedInUser,
                plantKey: selectedPlant
            };

            (async () => {
                await Axios({
                    url: BASEURL + '/' + ACTION_CODES.API_PMEC_GET_MACHINE_MODEL,
                    method: 'post',
                    data: payload,
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })
                    .then((response) => {
                        if (response.status == 200 && response?.data) {
                            const result = response?.data?.message;
                            const tmpTableData: any = result[0][0];
                            setMachineModel([
                                ...tmpTableData?.map((item: any) => ({ code: item.MachineModelKey, name: item.MachineModelName }))])
                            setSelectedMachineModel(tmpTableData[0]?.MachineModelKey)
                        }
                    })
                    .catch((error) => {
                        if (window.newrelic) {
                            window.newrelic.noticeError(error?.message);
                        }
                        return {
                            status: error.response.status
                        };
                    });
            })();

        }
    }, [selectedPlant])

    const fetchMachines = async (model: any) => {

        const payload = {
            userId: loggedInUser,
            model: model[0]?.code,
            plantKey: selectedPlant,
            date: new Date(defaultDate).toISOString().split('T')[0],
        };
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.API_OEE_GET_SINGLE_DATE_MACHINE,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;

                    const tmpTableData = result[0][0];
                    setMachines(tmpTableData);
                }
            })
            .catch((error: any) => {
                if (window.newrelic) {
                    window.newrelic.noticeError(error?.message);
                }
                return {
                    status: error
                };
            });
    }

    const fetchMultipleDateMachines = async (model: any) => {
        const payload = {
            userId: loggedInUser,
            model: selectedMachineModel != "" ? selectedMachineModel : model[0]?.code,
            plantKey: selectedPlant,
            from: new Date(inDate).toISOString().split('T')[0],
            to: new Date(endDate).toISOString().split('T')[0],
        };

        await Axios({
            url: BASEURL + '/' + ACTION_CODES.API_OEE_GET_MULTIPLE_DATE_MACHINE,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;

                    const tmpTableData = result[0][0];
                    setMultipleDateMachines([
                        ...tmpTableData?.map((item: any) => ({ code: item.MachineKey, name: item.MachineSerialNo }))])
                }
            })
            .catch((error: any) => {
                if (window.newrelic) {
                    window.newrelic.noticeError(error?.message);
                }
                return {
                    status: error
                };
            });
    }

    const fetchOperator = async (model: any) => {
        const payload = {
            userId: loggedInUser,
            model: selectedMachineModel != "" ? selectedMachineModel : model[0]?.code,
            plantKey: selectedPlant,
            from: new Date(inDate).toISOString().split('T')[0],
            to: new Date(endDate).toISOString().split('T')[0],
        };

        await Axios({
            url: BASEURL + '/' + ACTION_CODES.API_OEE_GET_OPERATOR,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;

                    const tmpTableData = result[0][0];
                    setOperators([
                        ...tmpTableData?.map((item: any) => ({ code: item.CustOperatorKey, name: item.OperatorName }))])
                }
            })
            .catch((error: any) => {
                if (window.newrelic) {
                    window.newrelic.noticeError(error?.message);
                }
                return {
                    status: error
                };
            });
    }

    useEffect(() => {
        if (machines.length > 0) {
            setSelectedMachines(machines.slice(0, 8).map((machine: any) => machine.MachineKey));
        }
    }, [machines]);

    useEffect(() => {
        if (multipleDateMachines.length > 0) {
            setSelectedMultipleDateMachine(multipleDateMachines[0]?.code);
        }
    }, [multipleDateMachines]);

    useEffect(() => {
        if (operators.length > 0) {
            setSelectedOperator(operators[0]?.code);
        }
    }, [operators]);

    useEffect(() => {
        if (selectedMachineModel !== "" && selectedPlant) {
            fetchMachines(machineModel);
            fetchMultipleDateMachines(machineModel);
            fetchOperator(machineModel);
        }
    }, [selectedMachineModel, selectedPlant]);

    useEffect(() => {
        if (selectedMachineModel !== "") {
            setSelectedMultipleDateMachine('');
            setSelectedOperator('');
            setOperators([]);
            setMultipleDateMachines([]);
            fetchMultipleDateMachines(selectedMachineModel);
            fetchOperator(selectedMachineModel)
        }
    }, [inDate, endDate]);

    useEffect(() => {
        if (selectedMultipleDateMachine != '') {
            setProductivity([]);
            fetchProductivity(selectedMultipleDateMachine);
        }
    }, [selectedMultipleDateMachine]);

    useEffect(() => {
        if (selectedOperator != '') {
            setOperatorVisibility([]);
            fetchOperatorVisibility(selectedOperator);
        }
    }, [selectedOperator])

    const handleChangeOperators = (id: any) => {
        let data: any;
        setSelectedMultipleOperators((prevSelected: any) => {
            if (prevSelected.includes(id)) {
                data = prevSelected.filter((optionId: any) => optionId !== id);
                return prevSelected.filter((optionId: any) => optionId !== id);
            } else {
                data = [...prevSelected, id];
                return [...prevSelected, id];
            }
        });
    };

    const fetchProductivity = async (mKey: any) => {
        const payload = {
            plantKey: selectedPlant,
            model: selectedMachineModel,
            from: new Date(inDate).toISOString().split('T')[0],
            to: new Date(endDate).toISOString().split('T')[0],
            machineKey: mKey 
        };

        await Axios({
            url: BASEURL + '/' + ACTION_CODES.API_OEE_GET_PRODUCTIVITY,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;

                    const tmpTableData = result[0][0];
                    setProductivity(tmpTableData);
                }
            })
            .catch((error: any) => {
                if (window.newrelic) {
                    window.newrelic.noticeError(error?.message);
                }
                return {
                    status: error
                };
            });
    }

    const fetchOperatorVisibility = async (oKey: any) => {
        const payload = {
            plantKey: selectedPlant,
            model: selectedMachineModel,
            from: new Date(inDate).toISOString().split('T')[0],
            to: new Date(endDate).toISOString().split('T')[0],
            operatorKey: oKey 
        };

        await Axios({
            url: BASEURL + '/' + ACTION_CODES.API_OEE_GET_OPERATOR_VISIBILITY,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;

                    const tmpTableData = result[0][0];
                    setOperatorVisibility(tmpTableData);
                }
            })
            .catch((error: any) => {
                if (window.newrelic) {
                    window.newrelic.noticeError(error?.message);
                }
                return {
                    status: error
                };
            });
    }

    const navigateTab = () => {
        switch (currTab) {
            case 0:
                return <OEEDashboardHomePage
                    defaultDate={defaultDate}
                    setDefaultDate={setDefaultDate}
                    inDate={inDate}
                    setInDate={setInDate}
                    setSelectedMachines={setSelectedMachines}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    plantsData={plantsData}
                    selectedPlant={selectedPlant}
                    machineModel={machineModel}
                    shifts={shifts}
                    selectedShift={selectedShift}
                    handleMachineModel={handleMachineModel}
                    handleChange={handleChange}
                    handleChangeShift={handleChangeShift}
                    handleChangeMultipleDate={handleChangeMultipleDate}
                    machines={machines}
                    selectedMachines={selectedMachines}
                    multipleDateMachines={multipleDateMachines}
                    selectedMultipleDateMachine={selectedMultipleDateMachine}
                    selectedMachineModel={selectedMachineModel}
                    handleChangePlant={handleChangePlant}
                    operators={operators}
                    selectedOperator={selectedOperator}
                    handleChangeOperator={handleChangeOperator}
                    setCurrTab={setCurrTab}
                    currTab={currTab}
                    productivity={productivity}
                    operatorVisibility={operatorVisibility}
                    isLoading={isLoading}
                    plantVisibility={plantVisibility}
                    setSelectedMultipleDateMachine={setSelectedMultipleDateMachine}
                    selectedMultipleOperators={selectedMultipleOperators}
                    setSelectedMultipleOperators={setSelectedMultipleOperators}
                    setTabChange={setTabChange}
                    setIsRedirect={setIsRedirect}
                    isRedirect={isRedirect}
                />;
            default:
                return <OEEDetails
                    selectedPlant={selectedPlant}
                    multipleDateMachines={multipleDateMachines}
                    selectedMultipleDateMachine={selectedMultipleDateMachine}
                    machineModels={machineModel}
                    handleMachineModel={handleMachineModel}
                    selectedMachineModel={selectedMachineModel}
                    handleChange={handleChange}
                    inDate={inDate}
                    setInDate={setInDate}
                    setEndDate={setEndDate}
                    endDate={endDate}
                    setCurrTab={setCurrTab}
                    setSelectedMultipleDateMachine={setSelectedMultipleDateMachine}
                    operators={operators}
                    selectedOperator={selectedOperator}
                    selectedMultipleOperators={selectedMultipleOperators}
                    setSelectedMultipleOperators={setSelectedMultipleOperators}
                    handleChangeOperators={handleChangeOperators}
                    setIsRedirect={setIsRedirect}
                    isRedirect={isRedirect}
                />;
        }
    };

    return (
        <div className="dashboard clcMain">
            <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
            <Container maxWidth="xl" style={{ marginTop: '60px' }}>
                <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                    <div style={{ flex: '80%', display: 'flex' }}>
                        <div className="headinglabel" style={{ marginTop: '5px' }}>
                            Plant Visibility Dashboard
                        </div>
                        <div
                            style={{
                                flex: '10%',
                                marginLeft: '20px',
                                // marginTop: '10px',
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                            className="dashboardDropdownDiv"
                        >
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: '35px', zIndex: '0' }}>
                    <MapCustomTabs
                        navigateTab={navigateTab}
                        currTab={currTab}
                        setCurrTab={setCurrTab}
                        tabsArray={tabs}
                        className="tabs"
                        position="relative"
                        moduleName='OEE Dashboard v2 Tabs'
                    // type="nonCurve"
                    />

                </div>
            </Container>
        </div>
    );
};

export default SystemConfiguration;
