import { useState, useEffect } from 'react';
import FD from 'js-file-download';
import { AnyIfEmpty, useDispatch } from 'react-redux';
import { Container } from '@mui/material';
import MapCustomTabs from '../../../app/atoms/ButtonTabs/MapCustomTabs';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../app/constants/apiConstants';
import { executeACGAction, updateScreenIdentifiers } from '../../../app/store/slice';
import Axios from 'axios';
import useGetState from '../../../app/utils/hooks/useGetState';
import { _noop } from '../../../app/utils/common';
import '../Admin/ClientManagement/index.scss';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DrawerDropdown from '../../../app/atoms/DrawerDropdown';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import RightDrawer from '../../../app/atoms/tempDrawer/indexAlt';
import { SCREEN_TITLES } from '../../../app/constants/StringConstants';
import NewTrial from './NewTrial/NewTrialCreateConfig';
import Trials from './NewTrial/Config';
import RawMaterials from './NewTrial/RawMaterialsCreateConfig';
import RawMaterialsValues from './NewTrial/RawMaterialValuesCreateConfig';
import RawMaterialValidation from './NewTrial/RawMaterialValidationCreateConfig';
import CAQ from './NewTrial/CAQCreateConfig';
import BPR from './NewTrial/BPRCreateConfig';
import SnackbarAlert from '../../atoms/Snackbar';
import TrailAnalysisService from './trialAnalysisServices';

const steps = ['New/Edit Trail', 'Create Raw Materials', 'Add Raw Materials Values', 'Raw Materials Review', 'CQA Entry', 'BPR Limit Entry'];
const tabs = ['Trial Alanysis'];
const options = {
  DEFAULT: {
    message: '',
    open: false,
    type: ''
  }
};
const SystemConfiguration = () => {
  const [currTab, setTabChange] = useState(0);
  const [radioValue, setRadioValue] = useState('All');
  const [drawerHeader, setDrawerHeader] = useState('Create');
  const [clientList, setClientList] = useState<any[]>([]);
  const [createValue, setCreateValue] = useState('');
  const [createDrawerOpen, setCreateDrawerOpen] = useState(false);
  const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
  const [reload, setReload] = useState(false);
  const [dtlData, setDtlData] = useState<any>({});
  const [selectedRow, setSelectedRow] = useState<any>();
  const [trailKey, setTrailKey] = useState('');
  const [trailAnalyisData, setTrailAnalysisData] = useState([]);
  const date = new Date();
  date.setDate(date.getDate())
  const [inDate, setInDate] = useState(date);
  const [endDate, setEndDate] = useState(date)
  const [errorDate, setErrorFalse] = useState(false);
  const [editing, setEditing] = useState(false);
  const [newKey, setNewKey] = useState('');
  const [calendarFilterOpen, setCalendarFilter] = useState(false);
  const [productsData, setProductsData] = useState<any>([]);
  const [customersData, setCustomersData] = useState<any>([]);
  const [cppData, setCppData] = useState<any>([]);
  const [processData, setProcessData] = useState<any>([]);
  const [psdData, setPsdData] = useState<any>([]);
  const [bprOperators, setBprOperators] = useState<any>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileError, setFileError] = useState(false);
  const [uploadFileName, setUploadFileName] = useState('');
  const [fileValidationError, setFileValidationError] = useState(false);
  const [newTrialNotification, setNewTrialNotification] = useState(false);
  const [isTrailLoading,setIsTrailLoading]=useState(false)
  const dispatch = useDispatch();
  const state = useGetState();
  const token = state?.[STORE_KEYS.USER_DTL].token;
  const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;
  const getCreateValue = (ele: string) => {
    setNewTrialNotification(true);
    setDrawerHeader('Create');
    setCreateValue(ele);
    setCreateDrawerOpen(true);
    setTrailKey('');
    setActiveStep(0);
  };
  const getGraph = (ele: string) => {
    setDrawerHeader('Graph');
    setCreateValue(ele);
    setCreateDrawerOpen(true);
    setTrailKey('');
  };
  const setCurrTab = (val: number) => {
    setTabChange(val);
    if (radioValue == 'All' && currTab === 1) {
      setRadioValue('All');
    }
  };

  useEffect(() => {
    fetchTrailAnalysis('');
    fetchAll();
    fetchPSD();
    fetchBPROperators();
  }, [])

  const fetchAll = () => {
    let data
    TrailAnalysisService.getTrailAnalysisProcess({ token: token })
      .then(async (response: any) => {
        const result = await response?.data.message[0];
        const tmpTableData = result[0];
        setProcessData([
          ...tmpTableData?.map((item: any) => ({ code: item.TrialProcessKey, name: item.TrialProcessName }))

        ]);
        data = ['100']
      })
      .catch((err) => {
        console.log(err);
      });
    TrailAnalysisService.getTrailAnalysisCpp({ token: token })
      .then(async (response: any) => {
        const result = await response?.data.message[0];
        const tmpTableData = result[0];
        setCppData(tmpTableData);
      })
      .catch((err) => {
        console.log(err);
      });
    TrailAnalysisService.getTrailAnalysisCustomers({ token: token })
      .then(async (response: any) => {
        const result = await response?.data.message[0];
        const tmpTableData = result[0];
        setCustomersData([
          ...tmpTableData?.map((item: any) => ({ code: item.TrialCustomerKey, name: item.TrialCustomerName }))

        ]);
      })
      .catch((err) => {
        console.log(err);
      });
    TrailAnalysisService.getTrailAnalysisProducts({ token: token })
      .then(async (response: any) => {
        const result = await response?.data.message[0];
        const tmpTableData = result[0];
        setProductsData([
          ...tmpTableData?.map((item: any) => ({ code: item.TrialProductKey, name: item.TrialProductName }))

        ]);
      })
      .catch((err) => {
        console.log(err);
      });
    return data;
  }

  const fetchPSD = () => {
    TrailAnalysisService.getTrailAnalysisPSDData({ token: token })
      .then(async (response: any) => {
        const result1: any = response.data[0];
        const result2 = response.data[1];
        const result3 = response.data[2];
        const data1: any | null = result1[0];
        const data2: any = result2[0];
        const data3: any = result3[0];
        setPsdData([data1[0], data2[0], data3[0]]);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const fetchBPROperators = () => {
    TrailAnalysisService.getTrailAnalysisBPRLimitOperators({ token: token })
      .then(async (response: any) => {
        const result = await response.data;
        setBprOperators(result);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (!errorDate) {
      if (inDate === endDate) {
        inDate === endDate && calendarFilterOpen ? fetchTrailAnalysis(inDate) : fetchTrailAnalysis('');
      }
      else {
        fetchTrailAnalysis(inDate)
      }

    }
  }, [inDate, endDate])

  const fetchTrailAnalysis = (startDate: any) => {
    setIsTrailLoading(true)
    const body = { userId: loggedInUser, startDate: startDate ? startDate : '', endDate: endDate, token: token }
    TrailAnalysisService.getTrailAnalysis(body)
      .then((response: any) => {
        const result = response?.data?.message[0];
        setTrailAnalysisData(result[0]);
      })
      .catch((err) => {
        console.log(err);
      }).finally(()=>{
        setIsTrailLoading(false)
      })
  }

  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});

  const totalSteps = () => {
    return steps?.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
        // find the first step that has been completed
        steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: any) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted: any = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
    setNewKey('');
    fetchTrailAnalysis('');
    setTrailKey('');
  };

  const editTrail = async (id: any) => {
    setTrailKey(id);
  }

  const deleteTrial = async (id: any) => {
    const data = { "userId": loggedInUser, "trialKey": id, token: token }
    TrailAnalysisService.deleteTrailAnalysisTrial(data)
      .then((response: any) => {
        // notify("Trial Deleted");
        fetchTrailAnalysis('');
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const maxDate = () => {
    var future = new Date();
    let dat = future.setDate(future.getDate() - 2);
    return dat
  }

  const minDate = () => {
    var future = new Date();
    let dat = future.setDate(future.getDate() - 3);
    return dat
  }

  const disabledate = (date: any) => {
    var past = new Date();
    past.setDate(past.getDate() - 365);
    return past;
  }

  const handleInDateChange = (e: any) => {

    setInDate(new Date(e.target.value))
    let ed = endDate.getTime();
    let sd = new Date(e.target.value).getTime();
    if (ed < sd) {
      setErrorFalse(true);
    } else {
      setErrorFalse(false);
    }
  }

  const handleEndDateChange = (e: any) => {
    setEndDate(new Date(e.target.value));
    let ed = new Date(e.target.value);
    let sd = inDate;
    if (ed < sd) {
      setErrorFalse(true);
    } else {
      setErrorFalse(false);
    }
  }

  const onClickDownload = (key: any, name: any) => {
    const body = {
      "userId": loggedInUser,
      "trialKey": key,
      "token": token
    }
    TrailAnalysisService.downloadTrailAnalysisTemplate(body)
      .then((response: any) => {
        FD(response.data, name + "_Template_Trial_Analysis.xlsx")
        // notify("Template Downloaded");
        fetchTrailAnalysis('');
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const onClickUpload = (key: any, name: any) => {
    setFileError(false);
    setSelectedFile(null);
    setOpenDialog(true);
    setTrailKey(key);
    setUploadFileName(name);
    setFileValidationError(false);
  }

  const uploadExcelTemplate = (file: any) => {
    setFileError(false);
    setFileValidationError(false);
    const checkValidFile = file.name.split("_Trial_Analysis")[0];

    if (file.name.split(".")[1] !== "xlsx") {
      setFileValidationError(false);
      setFileError(true);
      return;
    } else if (file.name.split(".")[1] === "xlsx") {
      if (checkValidFile.split("_")[0] === uploadFileName) {
        const data = new FormData()
        data.append('file', file);
        data.append('userId', loggedInUser);
        data.append('trialKey', trailKey);
        data.append('token', token);
        TrailAnalysisService.validateTrailAnalysisTemplate(data)
          .then((response: any) => {
            if (response?.data.message.result) {
              TrailAnalysisService.uploadTrailAnalysisTemplate(data)
                .then((response: any) => {
                  setOpenDialog(false);
                  // notify("Template Uploaded");
                  fetchTrailAnalysis('');
                })
                .catch((err) => {
                  console.log(err);
                });
              setUploadFileName('');
              setFileValidationError(false);
            } else {
              setFileError(true);
              setFileValidationError(true);
            }

          })
          .catch((err) => {
            console.log(err);
          });

      } else {
        setFileError(true);
        setFileValidationError(true);
      }
    }

  }

  const onClickDownloadReport = (key: any, name: any) => {
    const body = {
      "trialKey": key,
      token: token
    }

    TrailAnalysisService.downloadTrailAnalysisReport(body)
      .then((response: any) => {
        FD(response?.data, name + "_Trial_Analysis_Report.xlsx")
        // notify("Report Downloaded");
        fetchTrailAnalysis('');
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleCloseDialog = async () => {
    await setFileError(false);
    setOpenDialog(false);
    setSelectedFile(null);
    setTrailKey('');
    setUploadFileName('');
    setFileValidationError(false);
  }

  const navigateTab = () => {
    switch (currTab) {
      case 0:
        return (
          <Trials
            trailKey={trailKey}
            setCreateValue={setCreateValue}
            setDrawerHeader={setDrawerHeader}
            trailAnalyisData={trailAnalyisData}
            editTrail={(id: any) => editTrail(id)}
            deleteTrial={(id: any) => deleteTrial(id)}
            drawerClose={() => { setCreateDrawerOpen(false); }}
            drawerOpen={() => { setCreateDrawerOpen(true); }}
            disabledate={disabledate}
            handleInDateChange={handleInDateChange}
            minDate={minDate}
            maxDate={maxDate}
            handleEndDateChange={handleEndDateChange}
            errorDate={errorDate}
            inDate={inDate}
            endDate={endDate}
            setInDate={setInDate}
            setEndDate={setEndDate}
            setCalendarFilter={setCalendarFilter}
            onClickDownload={(key: any, name: any) => onClickDownload(key, name)}
            onClickUpload={(key: any, name: any) => onClickUpload(key, name)}
            onClickDownloadReport={(key: any, name: any) => onClickDownloadReport(key, name)}
            setReload={setReload}
            reload={reload}
            radioValue={radioValue}
            setSnackbarOpts={setSnackbarOptions}
            setDtlData={setDtlData}
            dtlData={dtlData}
            selectedRow={selectedRow}
            setSelectedRow={() => setSelectedRow}
            setActiveStep={setActiveStep}
            setTrailKey={setTrailKey}
            fetchTrailAnalysis={fetchTrailAnalysis}
            setNewTrialNotification={setNewTrialNotification}
            isLoading={isTrailLoading}
          />
        )

      default:
        return (
          <Trials
          isLoading={isTrailLoading}
          trailKey={trailKey}
          setCreateValue={setCreateValue}
          setDrawerHeader={setDrawerHeader}
          trailAnalyisData={trailAnalyisData}
          editTrail={(id: any) => editTrail(id)}
          deleteTrial={(id: any) => deleteTrial(id)}
          drawerClose={() => { setCreateDrawerOpen(false); }}
          drawerOpen={() => { setCreateDrawerOpen(true); }}
          disabledate={disabledate}
          handleInDateChange={handleInDateChange}
          minDate={minDate}
          maxDate={maxDate}
          handleEndDateChange={handleEndDateChange}
          errorDate={errorDate}
          inDate={inDate}
          endDate={endDate}
          setInDate={setInDate}
          setEndDate={setEndDate}
          setCalendarFilter={setCalendarFilter}
          onClickDownload={(key: any, name: any) => onClickDownload(key, name)}
          onClickUpload={(key: any, name: any) => onClickUpload(key, name)}
          onClickDownloadReport={(key: any, name: any) => onClickDownloadReport(key, name)}
          setReload={setReload}
          reload={reload}
          radioValue={radioValue}
          setSnackbarOpts={setSnackbarOptions}
          setDtlData={setDtlData}
          dtlData={dtlData}
          selectedRow={selectedRow}
          setSelectedRow={() => setSelectedRow}
          setActiveStep={setActiveStep}
          setTrailKey={setTrailKey}
          fetchTrailAnalysis={fetchTrailAnalysis}
          setNewTrialNotification={setNewTrialNotification}
          />
        )
    }
  };

  const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);

  const renderScreen = (screenId: any) => {
    switch (screenId) {
      case 0:
        return (<NewTrial
          setCurrTab={setCurrTab}
          drawerClose={() => { setCreateDrawerOpen(false); }}
          setReload={setReload}
          setDtlData={setDtlData}
          dtlData={dtlData}
          setSnackbarOpts={setSnackbarOptions}
          isEdit={false}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          handleNext={handleNext}
          trailKey={trailKey}
          setTrailKey={setTrailKey}
          setNewKey={setNewKey}
          newKey={newKey}
          handleReset={handleReset}
          activeStep={activeStep}
          productsData={productsData}
          processData={processData}
          cppData={cppData}
          customersData={customersData}
        />);
      case 1:
        return (<RawMaterials
          drawerClose={() => { setCreateDrawerOpen(false); }}
          handleNext={handleNext}
          handleReset={handleReset}
          newKey={newKey}
          trailKey={trailKey}

        />);

      case 2:
        return (<RawMaterialsValues
          drawerClose={() => { setCreateDrawerOpen(false); }}
          handleNext={handleNext}
          handleReset={handleReset}
          newKey={newKey}
          trailKey={trailKey}
        />)

      case 3:
        return (<RawMaterialValidation
          drawerClose={() => { setCreateDrawerOpen(false); }}
          handleNext={handleNext}
          handleBack={handleBack}
          handleReset={handleReset}
          newKey={newKey}
          trailKey={trailKey}
        />)

      case 4:
        return (
          <CAQ
            drawerClose={() => { setCreateDrawerOpen(false); }}
            handleNext={handleNext}
            handleReset={handleReset}
            newKey={newKey}
            trailKey={trailKey}
            psdData={psdData}
          />)
      case 5:
        return (
          <BPR
            drawerClose={() => { setCreateDrawerOpen(false); }}
            handleNext={handleNext}
            handleReset={handleReset}
            newKey={newKey}
            trailKey={trailKey}
            bprOperators={bprOperators}
            fetchBPROperators={fetchBPROperators}
            fetchTrailAnalysis={fetchTrailAnalysis}
            setSnackbarOpts={setSnackbarOptions}
            newTrialNotification={newTrialNotification}
          />)

      default:
        return (<NewTrial
          setCurrTab={setCurrTab}
          drawerClose={() => { setCreateDrawerOpen(false); }}
          setReload={setReload}
          setDtlData={setDtlData}
          dtlData={dtlData}
          setSnackbarOpts={setSnackbarOptions}
          isEdit={false}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          handleNext={handleNext}
          trailKey={trailKey}
          setTrailKey={setTrailKey}
          setNewKey={setNewKey}
          newKey={newKey}
          handleReset={handleReset}
          activeStep={activeStep}
          productsData={productsData}
          processData={processData}
          cppData={cppData}
          customersData={customersData}
        />);
    }
  }

  return (
    <div className="dashboard">
      <Dialog open={openDialog} className="popupuploaded">
        <DialogTitle>Upload Trial Analysis Template</DialogTitle>
        <DialogContent dividers>
          <Button
            variant="contained"
            component="label"

          >
            Upload File
            <input
              type="file"
              hidden
              onChange={(e:any) => { uploadExcelTemplate(e.target.files[0]) }}
            />
          </Button>
          {fileError ? <Typography color="red" mt={2}> {!fileValidationError ? "File Unsupported" : "Invalid Trial Template"}!</Typography> : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseDialog()}>Close</Button>
        </DialogActions>
      </Dialog>
      <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
      <Container maxWidth="xl" style={{ marginTop: '60px' }}>
        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <div style={{ flex: '80%', display: 'flex' }}>
            <div className="headinglabel" style={{ marginTop: '5px' }}>
              Trial Analysis
            </div>
            <div
              style={{
                flex: '10%',
                marginLeft: '20px',
                // marginTop: '10px',
                display: 'flex',
                justifyContent: 'flex-end'
              }}
              className="dashboardDropdownDiv"
            >
              <DrawerDropdown click={getCreateValue} items={['Trial']} />
            </div>
          </div>
        </div>
        <div style={{ marginTop: '35px', zIndex: '0' }}>
          <MapCustomTabs
            navigateTab={navigateTab}
            currTab={currTab}
            setCurrTab={setCurrTab}
            tabsArray={tabs}
            className="tabs"
            position="relative"
              moduleName='Trial Analysis Tabs'
          // type="nonCurve"
          />
        </div>
      </Container>
      <RightDrawer
        activeStep={activeStep}
        completed={completed}
        handleStep={handleStep}
        trailKey={trailKey}
        alertType={"Custom Alerts"}
        trailAnalysis={true}
        handlerOpen={() => { }}
        handlerClose={() => { setCreateDrawerOpen(false); setDrawerHeader('Create'); }}
        status={createDrawerOpen}
        drawerHeader={`${drawerHeader} ${createValue}`}
        children={
          drawerHeader === 'Create' || drawerHeader === 'Edit' ? (
            createValue === 'Trial' ?

              renderScreen(activeStep)

              : null
          ) : null
        }
      />

    </div>
  );
};

export default SystemConfiguration;
