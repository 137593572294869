import Drawer from '@mui/material/Drawer'
import * as Yup from 'yup';
import { Container } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import Label from '../../../../atoms/Label/index';
import ACGButton from '../../../../atoms/Button';
import { useEffect, useRef, useState } from 'react';
import CustomDatePicker from '../../../../atoms/CustomDatePicker';
import './index.scss';
import DropDown from '../../../../atoms/Dropdown';
import { VALIDATION_YUPSCHEMA } from './ValidationSchema';
import { executeACGAction, updateScreenIdentifiers } from '../../../../store/slice';
import { ACTION_CODES, STORE_KEYS } from '../../../../constants/apiConstants';
import useGetState from '../../../../utils/hooks/useGetState';
import { renameObjKeys } from '../../../Login/helper';
import { useDispatch } from 'react-redux';
import dateFormat from 'dateformat';
//import GetPDFFile from './AlarmAnalysysPDF';
import { pdf } from '@react-pdf/renderer';

type Props = {
    status: any;
    handlerOpen: any;
    handlerClose: any;
    data?: any;
    drawerHeader?: any;
    block?: boolean;
    type?: string;
};

type AlarmParams = {
    dropdownModel: string | '';
    dropdownPlant: string | '';
    dateFromSelect: string | '';
    dateToSelect: string | '';
    // email: string | '';
}

type SummaryParams = {
    machineKey: String
    fromDate: String
    toDate: String
}

export default function AlarmAnalysisDrawer(props: Props) {

    const {
        status,
        handlerClose,
        type,
    } = props;

    const drawerClose = () => {
        handlerClose();
    };

    const [alarmParams, setAlarmParams] = useState<AlarmParams>(
        {
            dropdownModel: '',
            dropdownPlant: '',
            dateFromSelect: '',
            dateToSelect: '',
        }
    );

    const [validationSchema, setValidationSchema] = useState({});
    const [plantsList, setPlantsList] = useState<any>();
    const [machineModels, setMachineModels] = useState<any>();
    const [isLoading, setIsLoading] = useState(false);
    const [pdfProps, setPdfProps] = useState<any>();

    const state = useGetState();
    const dispatch = useDispatch();
    const formRef = useRef<any>(null);

    const REPORT_CODE = 'MPRBQS';

    const handleSubmit = async (vals: any) => {

        const { dropdownModel, dropdownPlant, dateFromSelect, dateToSelect } = vals;

        if (dropdownModel && dateFromSelect && dateToSelect) {

            const params: SummaryParams = {
                fromDate: dateFormat(dateFromSelect, 'yyyy-mm-dd'),
                toDate: dateFormat(dateToSelect, 'yyyy-mm-dd'),
                machineKey: dropdownModel
            }

            const summaryBundle = {
                payload: {
                    urlPath: ACTION_CODES.DN_ALMANYS_BQS_MQR_SUMMARY,
                    requestType: 'POST',
                    reqObj: { fromDate: params.fromDate, toDate: params.toDate, machineKey: params.machineKey }
                },
                uniqueScreenIdentifier: { summaryAdded: true },
                storeKey: STORE_KEYS.DN_ALMANYS_BQS_MQR_SUMMARY
            };

            const descSummaryBundle = {
                payload: {
                    urlPath: ACTION_CODES.DN_ALMANYS_BQS_MQR_DESC_SUMMARY,
                    requestType: 'POST',
                    reqObj: { fromDate: params.fromDate, toDate: params.toDate, machineKey: params.machineKey }
                },
                uniqueScreenIdentifier: { summaryAdded: true },
                storeKey: STORE_KEYS.DN_ALMANYS_BQS_MQR_DESC_SUMMARY
            };

            const dateWisebundle = {
                payload: {
                    urlPath: ACTION_CODES.DN_ALMANYS_BQS_MQR_DATE_SUMMARY,
                    requestType: 'POST',
                    reqObj: { fromDate: params.fromDate, toDate: params.toDate, machineKey: params.machineKey }
                },
                uniqueScreenIdentifier: { summaryAdded: true },
                storeKey: STORE_KEYS.DN_ALMANYS_BQS_MQR_DATE_SUMMARY
            };

            const dateSummary = dispatch(executeACGAction(dateWisebundle));

            const descSummary = dispatch(executeACGAction(descSummaryBundle));

            const summary = dispatch(executeACGAction(summaryBundle));

            await Promise.all([summary, descSummary, dateSummary]);

            const summaryData = state?.[STORE_KEYS.DN_ALMANYS_BQS_MQR_SUMMARY]?.body?.data;
            const descSummaryData = state?.[STORE_KEYS.DN_ALMANYS_BQS_MQR_DESC_SUMMARY]?.body?.data;
            const dateSummaryData = state?.[STORE_KEYS.DN_ALMANYS_BQS_MQR_DATE_SUMMARY]?.body?.data;

            if (summaryData && descSummaryData && dateSummaryData) {
                const PDFProps = {
                    "KPIDataProp": summaryData,
                    "MonthlyDataProp": descSummaryData,
                    "DaywiseDataProp": dateSummaryData,
                    "machineKeyProp": params.machineKey,
                    "data": {
                        "startDate": "2021-07-01",
                        "endDate": "2021-07-31",
                        "PlantName": "Plant 1",
                        "MachineModelName": "Model1",
                        "MachineSerialNo": "Machine1",
                        "CustomerName": "Customer1",
                        "MachineKey": "Machine1"
                    }
                };

                // console.log('reached PDF', PDFProps);
                // setPdfProps(PDFProps);
                // const blob = await pdf(<GetPDFFile {...PDFProps} />).toBlob();
                // console.log('blob', blob);
            }
        }
        else {
            alert('Please select all the fields or bad request');
        }
    };

    const getMachineModels = (plantKey: String) => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: ACTION_CODES.DN_ALMANLS_REPORT_MACHINE_MODEL_DATA,
                    reqObj: {
                        reportCode: REPORT_CODE,
                        plantKey: plantKey
                    }
                },
                uniqueScreenIdentifier: { modelsLoaded: true },
                storeKey: STORE_KEYS.DN_ALMANLS_REPORT_MACHINE_MODEL_DATA
            })
        );
    };

    useEffect(() => {
        if (state?.[STORE_KEYS.DN_ALMANLS_REPORT_MACHINE_MODEL_DATA]?.modelsLoaded) {
            const data = state?.[STORE_KEYS.DN_ALMANLS_REPORT_MACHINE_MODEL_DATA]?.body?.data;
            const models = data?.map((item: any) => {
                const newVal = renameObjKeys(item, { MachineModelKey: 'code', MachineModelName: 'name' });
                return newVal;
            });
            setMachineModels(models);
        }
    }, [state?.[STORE_KEYS.DN_ALMANLS_REPORT_MACHINE_MODEL_DATA]?.modelsLoaded]);

    useEffect(() => {
        if (state?.[STORE_KEYS.DN_REPORT_PLANTS_DATA]?.plantsLoaded) {
            const data = state?.[STORE_KEYS.DN_REPORT_PLANTS_DATA]?.body?.data;
            const plants = data?.map((item: any) => {
                const newVal = renameObjKeys(item, { PlantKey: 'code', PlantName: 'name' });
                return newVal;
            });
            setPlantsList(plants);
        }
        else {
        }
    }, [state?.[STORE_KEYS.DN_REPORT_PLANTS_DATA]?.plantsLoaded]);

    useEffect(() => {

        setValidationSchema(Yup.object().shape(VALIDATION_YUPSCHEMA));

        if (status) {
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.DN_ALMANLS_REPORT_MACHINE_MODEL_DATA,
                    uniqueScreenIdentifier: {
                        modelsLoaded: false
                    }
                })
            );

            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.DN_REPORT_PLANTS_DATA,
                    uniqueScreenIdentifier: {
                        plantsLoaded: false
                    }
                })
            );

            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'POST',
                        urlPath: ACTION_CODES.DN_REPORT_PLANTS_DATA,
                        reqObj: {
                            reportCode: REPORT_CODE
                        }
                    },
                    uniqueScreenIdentifier: { plantsLoaded: true },
                    storeKey: STORE_KEYS.DN_REPORT_PLANTS_DATA
                })
            );
        }
    }, [status]);

    const renderButtons = () => {
        return (
            <div className={'btns noWrap'}>
                <ACGButton
                    name={'Reset'}
                    handleClick={() => { }}
                    secondary={true}
                    className={'first-btn'}
                    type="button"
                    variant="contained"
                />
                <ACGButton
                    name={'Submit'}
                    handleClick={() => { }}
                    type="submit"
                    className="second-btn"
                    variant="contained"
                    isLoading = {isLoading}
                />
            </div>
        );
    };

    return (
        <Drawer anchor={'right'}
            open={status}
            onClose={drawerClose}
            style={{ zIndex: '9999' }} className='allreports'>
            <Container className={'alm-dn-report-form1'}>
                <div className={'dn-rp-heading-label'}>PDF Report</div>
                <div
                    onClick={() => {
                        drawerClose();
                    }}
                    style={{
                        border: '1px solid #5D97F6',
                        borderRadius: '50px',
                        height: '25px',
                        width: '25px',
                        position: 'absolute',
                        left: '5px',
                        top: '20px',
                        cursor: 'pointer'
                    }}
                >
                    <span style={{ position: 'relative', fontSize: '12px', color: '#5D97F6', left: '35%', top: '-3%' }}>
                        X
                    </span>
                </div>
                <Formik
                    initialValues={alarmParams}
                    validationSchema={validationSchema}
                    onSubmit={(values: any) => {
                        handleSubmit(values);
                    }}
                    validateOnBlur={false}
                    validateOnChange={false}
                    innerRef={formRef}>

                    {(formikprops: FormikProps<any>) => {
                        const { values, handleChange, handleBlur, isValid, errors, touched, setFieldValue } = formikprops;
                        console.log('form-errors', errors, touched);
                        console.log('touched', touched);
                        return (
                            <Form>
                                <Label label="From Date" className={'labelDiv'} />
                                <CustomDatePicker
                                    name="dateFromSelect"
                                    value={values.dateFromSelect}
                                    maxDate={new Date()}
                                    minDate={new Date().setDate(-30)}
                                    className="inputDiv"
                                    onChange={(val: any) => {
                                        setFieldValue('dateFromSelect', val);
                                    }}
                                    inputFormat="dd/MM/yyyy"
                                    placeholder="Select From Date"
                                    helperText={errors.dateFromSelect && touched.dateFromSelect ? errors.dateFromSelect : ''}
                                />

                                <Label label="To Date" className={'labelDiv'} />
                                <CustomDatePicker
                                    name="dateToSelect"
                                    maxDate={new Date()}
                                    minDate={new Date().setDate(-30)}
                                    value={values.dateToSelect}
                                    className="inputDiv"
                                    onChange={(val: any) => {
                                        setFieldValue('dateToSelect', val);
                                    }}
                                    inputFormat="dd/MM/yyyy"
                                    placeholder="Select To Date"
                                    helperText={errors.dateToSelect && touched.dateToSelect ? errors.dateToSelect : ''}
                                />

                                <Label label="Plants" className={'labelDiv'} />
                                <DropDown
                                    onChange={(plantEvent: any) => {
                                        handleChange(plantEvent);
                                        console.log(values);
                                        const plant = plantsList.find((item: any) => item.code === plantEvent.target.value);
                                        if (plant) {
                                            getMachineModels(plant.code.toString());
                                        }
                                    }}
                                    className="inputDiv"
                                    name="dropdownPlant"
                                    items={plantsList}
                                    placeHolder="Select Plants"
                                    value={values.dropdownPlant}
                                    helperText={errors.dropdownPlant && touched.dropdownPlant ? errors.dropdownPlant : ''}
                                />

                                <Label label="Machine Model" className={'labelDiv'} />
                                <DropDown
                                    onChange={(modelEvent: any) => {
                                        handleChange(modelEvent);
                                        console.log(values);
                                    }}
                                    className="inputDiv"
                                    name="dropdownModel"
                                    items={machineModels}
                                    placeHolder="Select Model"
                                    value={values.dropdownModel}
                                    helperText={errors.dropdownModel && touched.dropdownModel ? errors.dropdownModel : ''}
                                />
                                {renderButtons()}
                            </Form>
                        );
                    }}
                </Formik>
            </Container>
        </Drawer>
    );
}