import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import CustomTable from '../../../../atoms/CustomTable/index';
import CustomTableToolbar from '../../../../atoms/CustomTableToolbar';
import { Container, Card } from '@mui/material';
import TemporaryDrawer from '../../../../atoms/tempDrawer';
import DetailDrawer from '../../../../atoms/DetailDrawer';
import { ACTION_CODES, STORE_KEYS } from '../../../../constants/apiConstants';
import { executeACGAction, resetErr, resetStoreKey, updateScreenIdentifiers } from '../../../../store/slice';
import useGetState from '../../../../utils/hooks/useGetState';
import { applySortFilter } from '../../../../utils/helper';
import { badgeTableDataFormater } from '../../../../utils/formatData';
import '../../ClientManagement/index.scss';
import AlertBox from '../../../../atoms/AlertBox';
import SnackbarAlert from '../../../../atoms/Snackbar';

const TABLE_HEAD = [
    { id: 'RoleName', label: 'Name', alignRight: false },
    { id: 'CustomerName', label: 'Customer', alignRight: false },
    { id: 'TotalFeatures', label: 'Features', alignRight: false },
    { id: 'TotalUers', label: 'Users', alignRight: false },
    { id: 'Action', label: 'Action', alignLeft: true }
];

type Anchor = 'right';

const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    },
    RM_SUCCESS: {
        message: 'Role deleted successfully!',
        open: true,
        type: 'success'
    },
    ADD_SUCCESS: {
        message: 'Role Added successfully!',
        open: true,
        type: 'success'
    },
    ED_SUCCESS: {
        message: 'Role Edited successfully!',
        open: true,
        type: 'success'
    },
    SA_ACCESS_LIMIT: {
        message: 'Super Admin Role cannot be viewed',
        open: true,
        type: 'reject'
    }
};

const Roles = (props: any) => {
    const {
        getSummary,
        radioValue,
        selectedClient,
        setDtlData,
        dtlData,
        subDtlData,
        setCreateValue,
        setDrawerHeader,
        setCreateDrawerOpen: setEditDrawerOpen,
        setTabChange,
        setSubDtlData,
        close,
        setClose,
        setSelectedSubRow,
        selectedSubRow,
        setSelectedRow,
        selectedRow
    } = props;

    const dispatch = useDispatch();
    const [drawerState, setDrawerState] = useState({
        right: false
    });
    const [page, setPage] = useState<number>(0);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('RoleName');
    const [selected, setSelected] = useState<any>([]);
    const [filterName, setFilterName] = useState<string>('');
    const [tableData, setTableData] = useState<any[]>([]);
    const [createDrawerOpen, setCreateDrawerOpen] = useState(false);
  
    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const [removeBxOpen, setRemoveBxOpen] = useState(false);
    const [userKey, setUserKey] = useState<any>();
    const [rmUser, setRmUser] = useState(false);
    const [isRolesLoading,setIsRolesLoading]=useState(false)

    const state = useGetState();

    const closeRmBx = () => {
        setRemoveBxOpen(false);
    };

    const removeFn = (ele: any) => {
        setUserKey(ele?.CustRoleKey);
        setRemoveBxOpen(true);
    };

    const getList = () => {
        setIsRolesLoading(true)
        const loginData = state[STORE_KEYS.USER_DTL];
        let queryString;

        if (loginData?.IsACGUser === 'Y') {
            if (radioValue === 'ACG') {
                queryString = '-1';
            } else if (radioValue === 'Customer' && selectedClient) {
                queryString = selectedClient;
            }
        } else {
            queryString = loginData?.CustomerKey;
        }

        queryString &&
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'GET',
                        urlPath: ACTION_CODES.GET_ROLES + queryString
                    },
                    storeKey: STORE_KEYS.ROLE_LIST
                })
            );
    };

    useEffect(() => {
        setTableData([]);
        getList();
    }, [
        radioValue,
        selectedClient,
        // state?.[STORE_KEYS.ADD_ROLEPT]?.roleFeatureAdded,
        // state?.[STORE_KEYS.ADD_ROLEPT]?.roleFeatureEdited
    ]);

    useEffect(() => {
        const tmpTableData = state[STORE_KEYS.ROLE_LIST]?.body?.data;
        if(!tmpTableData) return
        tmpTableData && setTableData(tmpTableData);
        setIsRolesLoading(false)
    }, [state[STORE_KEYS.ROLE_LIST]?.body?.data]);

    useEffect(() => {
        if (state[STORE_KEYS.DELETE]?.rowDeleted) {
            getList();
            getSummary();
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.DELETE,
                    uniqueScreenIdentifier: {
                        rowDeleted: false
                    }
                })
            );
        }
    }, [state[STORE_KEYS.DELETE]?.rowDeleted]);

    useEffect(() => {
        if (rmUser) {
            closeRmBx();
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'DELETE',
                        urlPath: ACTION_CODES.DELETE_ROLE + userKey
                    },
                    uniqueScreenIdentifier: {
                        rowDeleted: true
                    },
                    storeKey: STORE_KEYS.DELETE
                })
            );
            setRmUser(false);
        }
    }, [rmUser]);

    useEffect(() => {
        if (state?.[STORE_KEYS.DELETE]?.rowDeleted) {
            if (state?.[STORE_KEYS.DELETE]?.statusCode === 200) {
                setSnackbarOptions(options.RM_SUCCESS);
                closeDrawer();
                getList();
                getSummary();
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_RPT,
                    uniqueScreenIdentifier: {
                        rowDeleted: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.DELETE]?.rowDeleted]);

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_ROLEPT]?.roleFeatureAdded) {
            if (state?.[STORE_KEYS.ADD_ROLEPT]?.statusCode === 200) {
                setSnackbarOptions(options.ADD_SUCCESS);
                setTabChange(0);
                closeDrawer();
                setTimeout(() => {
                    getList();
                    getSummary();
                }, 1500)
                // getList();
                // getSummary();
                // setTabChange(1);
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_ROLEPT,
                    uniqueScreenIdentifier: {
                        roleFeatureAdded: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.ADD_ROLEPT]?.roleFeatureAdded]);

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_ROLEPT]?.roleFeatureEdited) {
            if (state?.[STORE_KEYS.ADD_ROLEPT]?.statusCode === 200) {
                setSnackbarOptions(options.ED_SUCCESS);
                // handleRoute(selectedRow, 'Action');
                setTabChange(0);
                closeDrawer();
                setTimeout(() => {
                    getList();
                    getSummary();
                }, 1500)
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_ROLEPT,
                    uniqueScreenIdentifier: {
                        roleFeatureEdited: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.ADD_ROLEPT]?.roleFeatureEdited]);

    const handleRequestSort = (event: any, property: any) => {
        const isAsc = orderBy === event && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(event);
    };

    const handleSelectAllClick = (event: any) => {
        if (event.target.checked) {
            const newSelecteds = [];
            for (let i = 0; i < tableData.length; i++) {
                newSelecteds.push(tableData[i].id);
            }
            const toRemove = tableData.slice(page, tableData.length);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (requestedBy: string) => {
        const selectedIndex = selected.indexOf(requestedBy);
        let newSelected: any[] | ((prevState: unknown[]) => unknown[]) = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, requestedBy);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setDrawerState({ ...drawerState, [anchor]: open });
    };

    const filteredList = applySortFilter(tableData, order, filterName, orderBy);

    const handleFilterByName = (event: { target: { value: string } }) => {
        setPage(0);
        setFilterName(event.target.value);
        setOrderBy('RoleName');
    };

    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    };

    const showRow = (event: any) => { };

    const handleRoute = (row: any, ele: any) => {
        if (row?.RoleName == 'Super Admin') {
            setSnackbarOptions(options.SA_ACCESS_LIMIT);
        } else {
            if (ele === 'Action') {
                const reqPayload = {
                    payload: {
                        urlPath: `${ACTION_CODES.GET_ROLES_DETAILS}/${row?.CustRoleKey}`,
                        requestType: 'GET'
                    },
                    storeKey: STORE_KEYS.ROLE_DETAIL
                };
                dispatch(executeACGAction(reqPayload));
            }
            setSelectedRow({ ...row, name: row?.RoleName });
            setDtlData({ ...row, name: row?.RoleName });
            setCreateDrawerOpen(true);
        }
    };

    useEffect(() => {
        if (state?.[STORE_KEYS.ROLE_DETAIL]?.statusCode === 200) {
            const tmpTableData = state[STORE_KEYS.ROLE_DETAIL]?.body?.data;
            if (tmpTableData) {
                const formatedData = badgeTableDataFormater(tmpTableData);
                selectedRow.badgeTableData = formatedData;
                selectedRow.actualData = tmpTableData;
                setDtlData(selectedRow);
                // dispatch(resetStoreKey({ storeKey: STORE_KEYS.ROLE_DETAIL }));
                dispatch(
                    updateScreenIdentifiers({
                        storeKey: STORE_KEYS.ROLE_DETAIL,
                        uniqueScreenIdentifier: {
                            statusCode: false
                        }
                    })
                );
            }
        }
    }, [state?.[STORE_KEYS.ROLE_DETAIL]?.body?.data]);

    useEffect(() => {
        if(state?.[STORE_KEYS.ADD_SUB_ROLEPT]?.subroleEdited) {
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_SUB_ROLEPT,
                    uniqueScreenIdentifier: { subroleEdited: false },
                })
            );
            closeDrawer();
        }

    },[state?.[STORE_KEYS.ADD_SUB_ROLEPT]?.subroleEdited])

    const closeDrawer = () => {
        setDtlData([]);
        setCreateDrawerOpen(false);
        setClose(false);
    };
    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };
        setSnackbarOptions(snackbarError);
    };

    useEffect(() => {
        if (state?.err) {
            handleSnackbarError(state?.err);
            dispatch(resetErr());
        }
    }, [state?.err]);
    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);
    const resetInput = () => {
        setFilterName('');
    };

    return (
        <div>
            <div>
                <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
                <Container
                    maxWidth="xl"
                    style={{
                        paddingLeft: '3px',
                        paddingRight: '3px'
                    }}
                >
                    <Card
                        style={{
                            borderRadius: '0px',
                            backgroundColor: '#2b2d42',
                            overflow: 'inherit'
                        }}
                    >
                        <CustomTableToolbar
                            drawerOpen={toggleDrawer('right', true)}
                            numSelected={selected?.length}
                            filterName={filterName}
                            onFilterName={handleFilterByName}
                            resetInput={resetInput}
                            displayInput={true}
                            text={{
                                title: 'Role List',
                                subTitle: `${filteredList?.length} roles`
                            }}
                            filterButton={false}
                            customButton={false}
                        />
                    </Card>
                </Container>
                <CustomTable
                    selectable={false}
                    headers={TABLE_HEAD}
                    body={filteredList}
                    isLoading={isRolesLoading}
                    filteredUsers={filteredList}
                    filterName={filterName}
                    selected={selected}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    rowChange={(event: string) => handleClick(event)}
                    rowCount={filteredList.length}
                    onPageChange={handleChangePage}
                    page={page}
                    setPageNumber={setPage}
                    selectRow={showRow}
                    route={handleRoute}
                    isRequestPage={true}
                    link={true}
                    removeFn={removeFn}
                    {...(radioValue === 'Customer' && !selectedClient
                        ? { noDataText: 'Selected client to display roles' }
                        : {})}
                />
            </div>
            <TemporaryDrawer
                handlerOpen={toggleDrawer('right', true)}
                handlerClose={toggleDrawer('right', false)}
                status={state['right']}
            />
            <DetailDrawer hasImage={false}
                handlerOpen={() => { }}
                handlerClose={closeDrawer}
                status={createDrawerOpen}
                data={dtlData}
                subData={subDtlData}
                block={false}
                type={'Client noTop'}
                isRole={true}
                editFn={() => {
                    setEditDrawerOpen(true);
                    setCreateValue('New Role');
                    setDrawerHeader('Edit');
                    setClose(false);
                }}
                deleteFn={() => removeFn(dtlData)}
                closeSubMenu={close}
                setClose={ () => setClose(!close)}
                hasSubMenu={true}
                setSelectedRow={setSelectedSubRow}
            />
            <AlertBox
                isOpen={removeBxOpen}
                handleClose={closeRmBx}
                leftBtnPress={closeRmBx}
                rightBtnPress={() => setRmUser(true)}
                modalHeader="Delete Role"
                modalContent="Are you sure you want to delete role?"
            />
        </div>
    );
};

export default Roles;
