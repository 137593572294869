import React, { useState, useEffect } from 'react';
import 'chartjs-plugin-datalabels';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  LineController,
  BarController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  LineController,
  BarController
);
import { getRandomHexColor } from '../../../../utils/helper';
const Stacked = (props: any) => {
  const [labelSet, setLabelSet] = useState<any>([]);
  const [opdata1, setOPData1] = useState<any>([]);
  const [opdata2, setOPData2] = useState<any>([]);
  const [opdata3, setOPData3] = useState<any>([]);
  const [opdata4, setOPData4] = useState<any>([]);
  const [hiddenDatasets, setHiddenDatasets] = useState<string[]>([]);

  const metricName: any = props?.oeeDetails?.map((i: any) => { return i.DTCategoryDesc });
  const uniqueArray = metricName.reduce((acc: any, value: any) => {
    if (!acc.includes(value)) {
      acc.push(value);
    }
    return acc;
  }, []);

  const groupedData = props?.oeeDetails.reduce((acc: any, curr: any) => {
    const { TimeName, DTCategoryDesc, DTCategoryValueInMins, } = curr;
    const defaultData = uniqueArray.reduce((acc: any, current: any) => {
      acc[current] = 0;
      return acc;
    }, {});
    if (!acc[TimeName]) {
      acc[TimeName] = {
        DTCategoryValueInMins,
        data: defaultData
      };
    }

    acc[TimeName].data[DTCategoryDesc] += DTCategoryValueInMins;
    return acc;
  }, {});

  const machines = Object.keys(groupedData);
  const labels = machines;

  const dataSet: any = [];

  uniqueArray.map((metric: any) => {
    const color = props.oeeDetails?.find((i:any) => i.DTCategoryDesc === metric);
    dataSet.push({
      label: metric,
      data: machines.map((machine) => groupedData[machine]?.data[metric]),
      borderColor: color.MetricColor,
      borderWidth: 2,
      yAxisID: 'y',
    });
  });

 const options = {
    plugins: {
      datalabels: { color: 'white', display: false },
      legend: {
        display: false,
      },
      tooltip: {
        intersect: false,
        callbacks: {
          label: function (tooltipItem: any) {
            let label = tooltipItem.dataset.label || '';
            label += ': ' + tooltipItem.raw + '(in mins)';
            return [label];
          },
        },
      },
    },
    responsive: true,
    scales: {
      x: {
        // stacked: true,
        ticks: {
          color: 'white',
        },
        grid: {
          color: 'transparent',
        },
      },
      y: {
        beginAtZero: true,
        // stacked: true,
        display: true,
        position: 'left' as const,
        ticks: {
          color: 'white',
        },
        grid: {
          color: '#878f99',
        },
        title: {
          display: true,
          text: 'Downt time duration (in mins).',
          color: 'white',
          font: {
            size: 12
          }
        },
      },
    },
  };

  const chartData = {
    labels,
    datasets: dataSet,
  };
  
  const toggleDataset = (label: string) => {
    setHiddenDatasets((prevHidden) =>
      prevHidden.includes(label)
        ? prevHidden.filter((item) => item !== label)
        : [...prevHidden, label]
    );
  };

  const renderCustomLegend = () => {
    return (
      <div style={{ display: 'grid', justifyContent: 'flex-start' }} className='legendcls'>
        {chartData.datasets?.map((dataset:any, index:number) => (
          <div
            key={index}
            // onClick={() => toggleDataset(dataset.label)}
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              marginRight: '15px',
              opacity: hiddenDatasets.includes(dataset.label) ? 0.5 : 1,
            }}
          >
            <div
              style={{
                backgroundColor: dataset.borderColor as string,
                width: '35px',
                height: '10px',
                marginRight: '5px',
              }}
            />
            <span style={{ color: '#e0e0e0', fontSize: '10px', fontFamily: 'montserrat' }}>
              {dataset.label}
            </span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
        
       <Chart type="line" data={chartData} options={options} style={{marginBottom: "0px"}}/>
       {renderCustomLegend()}
    </div>
  );
};

export default Stacked;
