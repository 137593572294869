type ComponentProps = { error: any, route?:string };

export const trackErrorsInNewRelic = ({error, route}:ComponentProps)=> {

    if (window.newrelic) {
        window.newrelic.noticeError(error?.message);
    }
}

export const trackButtonEvent=(moduleName:string,subModuleName:string,buttonText:string,LoggedInUser:string)=>{
    window.newrelic.addPageAction(moduleName, {
        action: subModuleName,
        additionalInfo: `User clicked on ${buttonText} button.`,
        LoggedIn: LoggedInUser,
        LoggedInUser: LoggedInUser,
    });
}

type PageActionLogProps = {
    actionName: string;
    action: string;
    LoggedInUser: string;
    additionalInfo?: string;
    loginTime?: string;
    logoutTime?: string;
};

export const trackPageActionLog = (props: PageActionLogProps) => {
    window.newrelic.addPageAction(props.actionName, {
        action: props.action,
        additionalInfo: props.additionalInfo,
        LoggedIn: props.LoggedInUser,
        LoggedInUser: props.LoggedInUser,
        LoginTime: props.loginTime,
        LogoutTime:props.logoutTime
    });
};