import { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import MapCustomTabs from '../../atoms/ButtonTabs/MapCustomTabs';
import RightDrawer from '../../atoms/tempDrawer/indexAlt';
import MachineManagementTopCard from '../../atoms/MachineMonitoringTopCard';
import AllAlarms from './AllAlarms';
import ActiveAlarms from './ActiveAlarms';
import useGetState from '../../utils/hooks/useGetState';
import { useDispatch } from 'react-redux';
import { executeACGAction, resetStoreKey, updateScreenIdentifiers } from '../../store/slice';
import { ACTION_CODES, STORE_KEYS } from '../../constants/apiConstants';
import BackArrowIcon from '../../../assets/images/BackArrowIcon.svg';
import { paths } from '../../constants/paths';
import { useHistory } from 'react-router-dom';

const AlarmDetails = () => {
    const [currTab, setCurrTab] = useState(0);
    const [createValue, setCreateValue] = useState('');
    const [createDrawerOpen, setCreateDrawerOpen] = useState(false);
    const [drawerHeader, setDrawerHeader] = useState('Create');
    const [alarmData, setAlarmData] = useState<any[]>([]);
    const reduxState = useGetState();
    const dispatch = useDispatch();

    const getCreateValue = (ele: string) => {
        setDrawerHeader('Create');
        setCreateValue(ele);
        setCreateDrawerOpen(true);
    };
    const tabs = ['Active Alarms', 'All Alarms'];
    const navigateTab = () => {
        switch (currTab) {
            case 0:
                return <ActiveAlarms />;

            case 1:
                return <AllAlarms />;

            default:
                return <ActiveAlarms />;
        }
    };

    useEffect(() => {
        getAlarmSummary();
    }, []);
    const getAlarmSummary = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: ACTION_CODES.GET_ALARM_SUMMARY,
                    reqObj: {
                        severityKey: -1,
                        timePeriodKey: 1
                    }
                },
                uniqueScreenIdentifier: { alarmSummary: true },
                storeKey: STORE_KEYS.ADD_ALARM_SUMMARY
            })
        );
    };

    useEffect(() => {
        if (
            reduxState?.[STORE_KEYS.ADD_ALARM_SUMMARY]?.body?.data &&
            reduxState?.[STORE_KEYS.ADD_ALARM_SUMMARY]?.statusCode === 200
        ) {
            const __alarmSummary = reduxState?.[STORE_KEYS.ADD_ALARM_SUMMARY]?.body?.data?.[0];
            const formateddata = [
                {
                    title: 'Total Alarms',
                    count: __alarmSummary.TotalAlarms
                },
                { title: 'Active', count: __alarmSummary.Active },
                { title: 'Critical', count: __alarmSummary.Critical },
                { title: 'Major', count: __alarmSummary.Major },
                { title: 'Minor', count: __alarmSummary.Minor }
            ];
            setAlarmData(formateddata);
            dispatch(resetStoreKey({ storeKey: STORE_KEYS.ADD_ALARM_SUMMARY }));
        }
        dispatch(
            updateScreenIdentifiers({
                storeKey: STORE_KEYS.ADD_ALARM_SUMMARY,
                uniqueScreenIdentifier: {
                    alarmSummary: false
                }
            })
        );
    }, [reduxState?.[STORE_KEYS.ADD_ALARM_SUMMARY]?.alarmSummary]);
    const history = useHistory();

    return (
        <div className="dashboard">
            <Container maxWidth="xl" style={{ marginTop: '50px' }}>
                <div style={{ display: 'flex' }}>
                    <img
                        style={{ cursor: 'pointer', height: '16px', alignSelf: "center" }}
                        src={BackArrowIcon}
                        alt="Arrow"
                        onClick={() => history.push(paths.MACHINE_MONITOR.path)}
                    />
                    <div className="headinglabel">Alarms</div>
                </div>
                <br />

                <MachineManagementTopCard data={alarmData} type="alarms" />

                <div style={{ marginTop: '35px', zIndex: '0' }}>
                    <MapCustomTabs
                        navigateTab={navigateTab}
                        currTab={currTab}
                        setCurrTab={setCurrTab}
                        tabsArray={tabs}
                        className="tabs"
                        position="relative"
                        moduleName='Alarm Details Tabs'
                    />
                </div>
                <RightDrawer
                    handlerOpen={() => { }}
                    handlerClose={() => setCreateDrawerOpen(false)}
                    status={createDrawerOpen}
                    drawerHeader={`${drawerHeader} ${createValue}`}
                    children={null}
                />
            </Container>
        </div>
    );
};

export default AlarmDetails;
