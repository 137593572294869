import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";
import "./../index.module.css";

const ChartComponent = (props: any) => {

  const transformData = (data: any) => {
    const formattedData: any = [];

    data.forEach((item: any) => {
      const existingCategory = formattedData.find(
        (category: any) => category.name === item.Category
      );

      if (existingCategory) {
        // If the category already exists, add the subcategory value
        existingCategory[item.SubCategory] = item.MetricValue;
      } else {
        // If the category doesn't exist, create a new category
        formattedData.push({
          name: item.Category,
          [item.SubCategory]: item.MetricValue,
        });
      }
    });

    // Filter out categories with no data (all bars with 0 or undefined values)
    return formattedData.filter((item: any) =>
      Object.values(item).some((value: any) => value > 0) // Keep only categories with non-zero data
    );
  };

  const formattedData: any = transformData(props.chart1);
  const handleBarClick = (data: any, index: number) => {
    let result;
    if (props.selectedMultipleOperators.includes(props.selectedOperator)) {
      result = [props.selectedOperator];
      props.setSelectedMultipleOperators(result);
    } else {
      result = [props.selectedOperator];
      props.setSelectedMultipleOperators(result);
    }
    props.setIsRedirect(true);
    props.setTabChange(1);
  };
  const combinedLegend = [
    { label: "Calendar Time", color: "#0055ff" },
    { label: "Uptime", color: "#6699ff" },
    { label: "Good Blisters", color: "#00b33c" },
    { label: "Total Production", color: "#33ff77" },
    { label: "Actual Speed", color: "#b32d00" },
    { label: "Rated Speed", color: "#ff794d" },
  ];

  return (
    <div className="responsive-charts-container">
      <div className="common-legend">
        {combinedLegend.map((item, index) => (
          <div key={index} style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
            <span
              style={{
                backgroundColor: item.color,
                width: '35px',
                height: '10px',
                display: 'inline-block',
                marginRight: '5px',
              }}
            ></span>
            <span style={{ color: '#e0e0e0', fontFamily: 'montserrat', fontSize: '12px' }}>
              {item.label}
            </span>
          </div>
        ))}
      </div>

      {/* Availability Container */}
      <div className="chart-container availability">
        {console.log(formattedData)}
        <ResponsiveContainer width="100%" height={100}>
          <BarChart
            width={500}
            height={300}
            data={[formattedData[0]]}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5
            }}
            layout="vertical"
          >
            <XAxis
              type="number"
              tick={false}
              tickLine={false}
              axisLine={false}
            />
            <YAxis
              dataKey="name"
              type="category"
              tick={{ fill: "white", fontSize: 11 }}
            />
            <Tooltip
              contentStyle={{ backgroundColor: "black", border: "none", fontSize: "10px" }}
              itemStyle={{ color: "white", fontSize: "10px" }}
              labelStyle={{ color: "white", fontSize: "12px" }}
            />
            <Bar dataKey="Calendar Time" fill="#0055ff" barSize={8} onClick={(data, index) => handleBarClick(data, index)}
              style={{ cursor: "pointer" }} />
            <Bar dataKey="Uptime" fill="#6699ff" barSize={8} onClick={(data, index) => handleBarClick(data, index)}
              style={{ cursor: "pointer" }} />
          </BarChart>
        </ResponsiveContainer>
      </div>

      {/* Quality Chart Container */}
      <div className="chart-container quality">
        <ResponsiveContainer width="100%" height={100}>
          <BarChart
            width={500}
            height={300}
            data={[formattedData[1]]}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5
            }}
            layout="vertical"
          >
            <XAxis
              type="number"
              tick={false}
              tickLine={false}
              axisLine={false}
            />
            <YAxis
              dataKey="name"
              type="category"
              tick={{ fill: "white", fontSize: 11 }}
            />
            <Tooltip
              contentStyle={{ backgroundColor: "black", border: "none", fontSize: "10px" }}
              itemStyle={{ color: "white", fontSize: "10px" }}
              labelStyle={{ color: "white", fontSize: "12px" }}
            />
            <Bar dataKey="Good Blisters" fill="#00b33c" barSize={8} onClick={(data, index) => handleBarClick(data, index)}
              style={{ cursor: "pointer" }} />
            <Bar dataKey="Total Production" fill="#33ff77" barSize={8} onClick={(data, index) => handleBarClick(data, index)}
              style={{ cursor: "pointer" }} />
          </BarChart>
        </ResponsiveContainer>
      </div>

      {/* Performance Chart Container */}
      <div className="chart-container performance">
        <ResponsiveContainer width="100%" height={100}>
          <BarChart
            width={500}
            height={300}
            data={[formattedData[2]]}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5
            }}
            layout="vertical"
          >
            <XAxis
              type="number"
              tick={false}
              tickLine={false}
              axisLine={false}
            />
            <YAxis
              dataKey="name"
              type="category"
              tick={{ fill: "white", fontSize: 11 }}
            />
            <Tooltip
              contentStyle={{ backgroundColor: "black", border: "none", fontSize: "10px" }}
              itemStyle={{ color: "white", fontSize: "10px" }}
              labelStyle={{ color: "white", fontSize: "12px" }}
            />
            <Bar dataKey="Actual Speed" fill="#b32d00" barSize={8} onClick={(data, index) => handleBarClick(data, index)}
              style={{ cursor: "pointer" }} />
            <Bar dataKey="Rated Speed" fill="#ff794d" barSize={8} onClick={(data, index) => handleBarClick(data, index)}
              style={{ cursor: "pointer" }} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default ChartComponent;
