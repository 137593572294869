import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Container } from '@mui/material';
import MapCustomTabs from '../../../app/atoms/ButtonTabs/MapCustomTabs';
import UserManagementSelectionBar from '../../../app/atoms/UserManagementSelectionBar';
import { ACTION_CODES, STORE_KEYS } from '../../../app/constants/apiConstants';
import { executeACGAction, updateScreenIdentifiers } from '../../../app/store/slice';
import useGetState from '../../../app/utils/hooks/useGetState';
import { _noop } from '../../../app/utils/common';
import '../Admin/ClientManagement/index.scss';
import CustomRadioGroup from '../../../app/atoms/CustomRadioGroup';
import DropDown from '../../../app/atoms/Dropdown/index';
import Label from '../../../app/atoms/Label/index';
import { Grid } from '@material-ui/core';
import DrawerDropdown from '../../../app/atoms/DrawerDropdown';
import RightDrawer from '../../../app/atoms/tempDrawer/indexAlt';
import CreateUser from './CreateUser';
import Contacts from './Contacts';
import CallHistory from './CallHistory'

const tabs = ['Contacts', 'Call History'];
const ALL_CLIENT = { code: null, name: '--Select Client--' };
const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    }
};
const RemoteAssistance = () => {
    const [currTab, setTabChange] = useState(0);
    const [radioValue, setRadioValue] = useState('All');
    const [selectedClient, setSelectedClient] = useState(null);
    const [drawerHeader, setDrawerHeader] = useState('Create');
    const [clientList, setClientList] = useState<any[]>([]);
    const [createValue, setCreateValue] = useState('');
    const [createDrawerOpen, setCreateDrawerOpen] = useState(false);
    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const [reload, setReload] = useState(false);
    const [dtlData, setDtlData] = useState<any>({});
    const [favoriteContactFlag, setfavoriteContactFlag] = useState();

    const dispatch = useDispatch();
    const state = useGetState();

    const getCreateValue = (ele: string) => {
        setDrawerHeader('Create');
        setCreateValue(ele);
        setCreateDrawerOpen(true);
    };

    const setCurrTab = (val: number) => {
        setTabChange(val);
        if (radioValue == 'All' && currTab === 1) {
            setRadioValue('All');
        }
    };

    const handleRadioValue = (val: string) => {
        setRadioValue(val);
        setSelectedClient(null);
    };

    const getSummary = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.GET_USER_SUMMARY
                },
                storeKey: STORE_KEYS.USER_SUMMARY
            })
        );
    };

    const getClients = () => {
        const tmpTableData = state[STORE_KEYS.CLIENT_DROPDOWN]?.body?.data;

        if (tmpTableData?.length) {
            setClientList([
                ALL_CLIENT,
                ...tmpTableData.map((item: any) => ({ code: item.CustomerKey, name: item.CustomerName }))
            ]);
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.CLIENT_DROPDOWN,
                    uniqueScreenIdentifier: {
                        isLoading: false
                    }
                })
            );
        } else if (!state[STORE_KEYS.CLIENT_DROPDOWN]?.isLoading) {
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'GET',
                        urlPath: ACTION_CODES.GET_CLIENT_DROPDOWN
                    },
                    storeKey: STORE_KEYS.CLIENT_DROPDOWN,
                    uniqueScreenIdentifier: {
                        isLoading: true
                    }
                })
            );
        }
    };

    // useEffect(getSummary, []);
    // useEffect(getSummary, [reload]);
    useEffect(() => {
        if (radioValue === 'Customer' && !clientList.length) {
            getClients();
        }
    }, [radioValue, state[STORE_KEYS.CLIENT_DROPDOWN]?.body?.data]);
    const navigateTab = () => {
        switch (currTab) {
            case 1:
                return (
                    <CallHistory
                        getSummary={getSummary}
                        radioValue={radioValue}
                        selectedClient={selectedClient}
                        setDtlData={setDtlData}
                        dtlData={dtlData}
                        setCreateDrawerOpen={setCreateDrawerOpen}
                        setCreateValue={setCreateValue}
                        setDrawerHeader={setDrawerHeader}
                    />
                );

            default:
                return (
                    <Contacts
                        getSummary={getSummary}
                        radioValue={radioValue}
                        selectedClient={selectedClient}
                        setDtlData={setDtlData}
                        reload={reload}
                        dtlData={dtlData}
                        setReload={setReload}
                        setCreateDrawerOpen={setCreateDrawerOpen}
                        setCreateValue={setCreateValue}
                        setDrawerHeader={setDrawerHeader}
                    />
                );
        }
    };

    const { TotalRoles = 0, TotalUsers = 0 } = state[STORE_KEYS.USER_SUMMARY]?.body?.data?.[0] || {};

    return (
        <div className="dashboard">
            <Container maxWidth="xl" style={{ marginTop: '60px' }}>
                <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                    <div style={{ flex: '80%', display: 'flex' }}>
                        <div className="headinglabel" style={{ marginTop: '7px' }}>
                            {/* {SCREEN_TITLES.USER_MANAGEMENT} */}
                            Remote Assistance
                        </div>
                        <div
                            style={{
                                flex: '10%',
                                marginLeft: '20px',
                                // marginTop: '10px',
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                            className="dashboardDropdownDiv"
                        >
                            <DrawerDropdown click={getCreateValue} items={['New Contact']} />
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: '35px', zIndex: '0' }}>
                    {state[STORE_KEYS.USER_DTL]?.IsACGUser === 'Y' && (
                        <UserManagementSelectionBar>
                            <Grid container className="section-buttons" spacing={2}>
                                <Grid item>
                                    <Label label="Type of User" />
                                    <CustomRadioGroup
                                        value={radioValue}
                                        fields={[
                                            { label: 'All', value: 'All' },
                                            { label: 'ACG', value: 'ACG' },
                                            { label: 'Client', value: 'Customer' }
                                        ]}
                                        onChange={handleRadioValue}
                                    />
                                </Grid>
                                {radioValue === 'Customer' && (
                                    <Grid item xs={3} >
                                        <Label label="Client" />
                                        <DropDown
                                            onChange={(event: any) => {
                                                setSelectedClient(event?.target?.value);
                                            }}
                                            className="user-client-dropdown"
                                            name="client"
                                            items={clientList}
                                            value={selectedClient}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </UserManagementSelectionBar>
                    )}
                    <MapCustomTabs
                        navigateTab={navigateTab}
                        currTab={currTab}
                        setCurrTab={setCurrTab}
                        tabsArray={tabs}
                        className="tabs"
                        position="relative"
                        type="nonCurve"
                        moduleName='Remote Assistance Tabs'
                    />
                </div>
            </Container>
            <RightDrawer
                handlerOpen={() => { }}
                handlerClose={() => setCreateDrawerOpen(false)}
                status={createDrawerOpen}
                drawerHeader={`${drawerHeader} ${createValue}`}
                children={
                    drawerHeader === 'Create' ? (
                        createValue === 'New Contact' ? (
                            <CreateUser
                                drawerClose={() => setCreateDrawerOpen(false)}
                                setCurrTab={setCurrTab}
                                setReload={setReload}
                                dtlData={dtlData}
                                setSnackbarOpts={setSnackbarOptions}
                                isEdit={false}
                            />
                        ) : (
                            <> <div>test 3</div></>
                        )
                    ) : createValue === 'New User' ? (
                        <><div>test 3</div></>
                    ) : (
                        <>   <CreateUser
                            drawerClose={() => setCreateDrawerOpen(false)}
                            setCurrTab={setCurrTab}
                            setReload={setReload}
                            dtlData={dtlData}
                            setSnackbarOpts={setSnackbarOptions}
                            isEdit={true}
                        /></>
                    )
                }
            />
        </div>
    );
};

export default RemoteAssistance;
