import { useState, useEffect, useRef, useMemo } from 'react';
import { Grid } from '@mui/material';
import AcgButton from '../../../../atoms/Button/index';
import Label from '../../../../atoms/Label/index';
import { ACTION_CODES, STORE_KEYS } from '../../../../constants/apiConstants';
import { executeACGAction, resetErr, updateScreenIdentifiers } from '../../../../store/slice';
import { useDispatch } from 'react-redux';
import useGetState from '../../../../utils/hooks/useGetState';
import DetailLabel from '../../../../atoms/DetailLabel';
import DetailValue from '../../../../atoms/DetailValue';
import { _noop } from '../../../../utils/common';
import BadgeTable from './BadgeTable';
import { badgeTableDataFormater } from '../../../../utils/formatData';

type Props = {
    drawerClose: Function;
    setCurrTab: Function;
    setReload: Function;
    setSnackbarOpts: Function;
    setStep: Function;
    step1Vals: any;
    setStep1Vals: Function;
    isEdit: boolean;
    dtlData: any;
    close?: any;
    setClose?: any;
    setSelectedSubRow?: any;
    selectedSubRow?: any;
    subDtlData?: any;
    setpreSubSelectedKeys?: any;
    preSubSelectedKeys?: any;
    CustRoleKey?: any;
};
const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    },
    AD_ROLE: {
        message: 'Role created successfully!',
        open: true,
        type: 'success'
    },
    ED_ROLE: {
        message: 'Role edited successfully!',
        open: true,
        type: 'success'
    },
    SET_HOMEPAGE: {
        message: 'Please set atleast one feature as Home page',
        open: true,
        type: 'remark'
    }
};

const AssignFeature = (props: Props) => {
    const { drawerClose, setCurrTab, setSnackbarOpts, setStep, setStep1Vals, step1Vals, isEdit, dtlData, subDtlData, setClose, selectedSubRow, setSelectedSubRow, close, setpreSubSelectedKeys, preSubSelectedKeys,CustRoleKey } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState<any>({});
    const [subTableData, setSubTableData] = useState<any>({});
                const [preSelectedKeys, setpreSelectedKeys] = useState<any>([]);

    const __formatData = (__dt: any) => {
        return {
            ModuleName: __dt.ModuleName,
            FeatureKey: __dt.FeatureKey,
            IsEditable: __dt.IsEditable,
            IsHomePage: __dt.IsHomePage,
            id: __dt.id
        };
    };
    const __formatDataNew = (__dt: any) => {
        return {
            ModuleName: __dt.ModuleName,
            FeatureKey: __dt.FeatureKey,
            IsEditable: __dt.IsEditable,
            IsHomePage: __dt.IsHomePage
        };
    };
    const state = useGetState();
    const dispatch = useDispatch();

    const [assignFeatures, setAssignFeatures] = useState<any>(
        state?.[STORE_KEYS.ROLE_DETAIL]?.body?.data?.map((item: any) =>
            __formatData({ ...item, id: item?.FeatureKey + item?.ModuleKey })
        ) || []
    );

    const validateFeatureHasSetHomePage = () => {
        const dt = [...assignFeatures];
        console.log(dt, 'dt');
        const isValid = dt?.some((d: any) => d?.IsHomePage === 'Y');
        return isValid;
    };

    useEffect(() => {
        if(close) {
        const checked: any = [];
        (async () => {
            await state?.[STORE_KEYS.ROLE_SUB_FTRS]?.body.data?.map((i: any) => {
                checked.push(i?.SubFeatureKey)
            })
            setpreSubSelectedKeys(checked);
        })();
    }
    }, [subDtlData])

    const handleSubmit = () => {
        setIsLoading(true);
        if (validateFeatureHasSetHomePage()) {
            const reqPayload = {
                payload: {
                    urlPath: isEdit ? ACTION_CODES.EDIT_ROLE : ACTION_CODES.ADD_ROLE,
                    requestType: isEdit ? 'PUT' : 'POST',
                    reqObj: {
                        customerKey: isEdit ? dtlData?.CustomerKey : step1Vals?.dropdownClient || -1,
                        roleCode: step1Vals?.roleCode,
                        roleName: step1Vals?.roleName
                    }
                },
                uniqueScreenIdentifier: !isEdit ? { roleAdded: true } : { roleEdited: true },
                storeKey: STORE_KEYS.ADD_ROLEPT
            };
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ROLE_DETAIL,
                    uniqueScreenIdentifier: {
                        body: {
                            data: []
                        }
                    }
                })
            );
            dispatch(executeACGAction(reqPayload));
        } else {
            setSnackbarOpts(options.SET_HOMEPAGE);
        }
    };

    const handleSubmitSubFeatured = () => {
        const custData: any = Object.values(subTableData)[0];
        const subData = custData.map((item: any) => ({
            ModuleName: item.ModuleName,
            MenuName: item.FeatureType,
            SubFeatureKey: item.SubFeatureKey,
            IsEditable: item.IsEditable
        }));
        const reqPayload = {
            payload: {
                urlPath: ACTION_CODES.EDIT_SUB_ROLE,
                requestType: 'POST',
                reqObj: {
                    CustRoleKey: CustRoleKey,
                    RoleSubFeatures: subData.filter((i:any) => preSubSelectedKeys.includes(i.SubFeatureKey))
                }
            },
            uniqueScreenIdentifier: { subroleEdited: true },
            storeKey: STORE_KEYS.ADD_SUB_ROLEPT
        };

        dispatch(executeACGAction(reqPayload));
    };

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_SUB_ROLEPT]?.subroleEdited) {
            if (state?.[STORE_KEYS.ADD_SUB_ROLEPT]?.statusCode === 200) {
                drawerClose();
                setSnackbarOpts(options.ED_ROLE);
            }
        }
    }, [state?.[STORE_KEYS.ADD_SUB_ROLEPT]?.subroleEdited]);

    useEffect(() => {
        if (isEdit) {
            const allSelectedKeys: Array<any> = [];
            assignFeatures?.forEach((key: any) => {
                allSelectedKeys.push(key?.id);
            });
            allSelectedKeys?.length && setpreSelectedKeys(allSelectedKeys || []);
        }
    }, [isEdit, assignFeatures]);

    const renderButtons = () => {
        return (
            <div className={'btns noWrap'}>
                <AcgButton
                    name="Back"
                    handleClick={() => { setStep(0); setClose(false) }}
                    type="button"
                    secondary={true}
                    className="first-btn"
                    variant="contained"
                />
                <AcgButton
                    name={'Submit'}
                    handleClick={close ? handleSubmitSubFeatured : handleSubmit}
                    type="submit"
                    className="second-btn"
                    variant="contained"
                    isLoading={isLoading}
                    disabled={close && Object.keys(subTableData).length === 0 || close && preSubSelectedKeys.length === 0 ? true : false}
                />
            </div>
        );
    };

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_ROLEPT]?.roleAdded) {
            if (state?.[STORE_KEYS.ADD_ROLEPT]?.statusCode === 200) {
                const newData = assignFeatures?.map((ele: any) => {
                    const elem = __formatDataNew(ele);
                    return elem;
                });
                const reqPayload = {
                    payload: {
                        urlPath: ACTION_CODES.ADD_ROLE_FEATURES,
                        requestType: 'POST',
                        reqObj: {
                            custRoleKey: state?.[STORE_KEYS.ADD_ROLEPT]?.body?.data?.CustRoleKey,
                            // roleFeatures: [...assignFeatures]
                            roleFeatures: newData
                        }
                    },
                    uniqueScreenIdentifier: { roleAdded: false, roleFeatureAdded: true },
                    storeKey: STORE_KEYS.ADD_ROLEPT
                };
                dispatch(executeACGAction(reqPayload));
                setSnackbarOpts(options.AD_ROLE);
                // alert('hello');
                drawerClose();
            }
        }
    }, [state?.[STORE_KEYS.ADD_ROLEPT]?.roleAdded]);

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_ROLEPT]?.roleEdited) {
            if (state?.[STORE_KEYS.ADD_ROLEPT]?.statusCode === 200) {
                console.log(assignFeatures, 'assignFeatures');
                const newData = assignFeatures?.map((ele: any) => {
                    const elem = __formatDataNew(ele);
                    return elem;
                });
                console.log(newData, 'sd');
                const reqPayload = {
                    payload: {
                        urlPath: ACTION_CODES.ADD_ROLE_FEATURES,
                        requestType: 'POST',
                        reqObj: {
                            custRoleKey: state?.[STORE_KEYS.ADD_ROLEPT]?.body?.data?.CustRoleKey,
                            // roleFeatures: [...assignFeatures]
                            roleFeatures: newData
                        }
                    },
                    uniqueScreenIdentifier: { roleEdited: false, roleFeatureEdited: true },
                    storeKey: STORE_KEYS.ADD_ROLEPT
                };
                dispatch(executeACGAction(reqPayload));
            }
        }
    }, [state?.[STORE_KEYS.ADD_ROLEPT]?.roleEdited]);

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_ROLEPT]?.roleFeatureAdded) {
            if (state?.[STORE_KEYS.ADD_ROLEPT]?.statusCode === 200) {
                // setCurrTab(3);
                // location.reload()
                dispatch(
                    updateScreenIdentifiers({
                        storeKey: STORE_KEYS.ROLE_DETAIL,
                        uniqueScreenIdentifier: {
                            body: {
                                data: []
                            }
                        }
                    })
                );
                drawerClose();
            }

            // dispatch(
            //     updateScreenIdentifiers({
            //         storeKey: STORE_KEYS.ROLE_DETAIL,
            //         uniqueScreenIdentifier: {
            //             body: {
            //                 data: []
            //             }
            //         }
            //     })
            // );

            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_ROLEPT,
                    uniqueScreenIdentifier: {
                        roleFeatureAdded: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.ADD_ROLEPT]?.roleFeatureAdded]);

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_ROLEPT]?.roleFeatureAdded === false) {
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ROLE_DETAIL,
                    uniqueScreenIdentifier: {
                        body: {
                            data: []
                        }
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.ADD_ROLEPT]?.roleFeatureAdded]);

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_ROLEPT]?.roleFeatureEdited) {
            if (state?.[STORE_KEYS.ADD_ROLEPT]?.statusCode === 200) {
                // setCurrTab(3);
                dispatch(
                    updateScreenIdentifiers({
                        storeKey: STORE_KEYS.ROLE_DETAIL,
                        uniqueScreenIdentifier: {
                            body: {
                                data: []
                            }
                        }
                    })
                );
                drawerClose();
            }
            // dispatch(
            //     updateScreenIdentifiers({
            //         storeKey: STORE_KEYS.ROLE_DETAIL,
            //         uniqueScreenIdentifier: {
            //             body: {
            //                 data: []
            //             }
            //         }
            //     })
            // );
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_ROLEPT,
                    uniqueScreenIdentifier: {
                        roleFeatureEdited: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.ADD_ROLEPT]?.roleFeatureEdited]);

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_ROLEPT]?.roleFeatureEdited === false) {
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ROLE_DETAIL,
                    uniqueScreenIdentifier: {
                        body: {
                            data: []
                        }
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.ADD_ROLEPT]?.roleFeatureEdited]);

    const getList = () => {
        const listData = state[STORE_KEYS.ROLE_FEATURES]?.data;

        !listData &&
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'GET',
                        urlPath: ACTION_CODES.GET_ROLE_FEATURES
                    },
                    storeKey: STORE_KEYS.ROLE_FEATURES
                })
            );
    };

    const getListofClient = (__customerKey: any) => {
        const listData = state[STORE_KEYS.ROLE_FEATURES]?.data;

        !listData &&
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'GET',
                        urlPath: `${ACTION_CODES.GET_CLNTFEATURES}/${__customerKey}`
                    },
                    storeKey: STORE_KEYS.ROLE_FEATURES
                })
            );
    };
    //
    useEffect(() => {
        if (state?.[STORE_KEYS.USER_DTL]?.IsACGUser === 'N') {
            getListofClient(state?.[STORE_KEYS.USER_DTL]?.CustomerKey);
        } else {
            if (
                (dtlData?.CustomerKey && dtlData?.CustomerKey !== -1) ||
                (step1Vals?.dropdownClient && step1Vals?.dropdownClient !== -1)
            ) {
                getListofClient(isEdit ? dtlData?.CustomerKey : step1Vals?.dropdownClient);
            } else {
                getList();
            }
        }
    }, []);

    useEffect(() => {
        if (state[STORE_KEYS.ROLE_FEATURES]?.body?.data) {
            const __tmpTableData = [...state[STORE_KEYS.ROLE_FEATURES]?.body?.data];
            const tmpTableData = __tmpTableData.map((item: any) => {
                return { ...item, IsHomePage: 'N', IsEditable: 'N', id: item?.FeatureKey + item?.ModuleKey };
            });
            if (tmpTableData) {
                if (isEdit && dtlData?.actualData) {
                    const __selectedData = dtlData?.actualData?.map((__item: any) => {
                        return { ...__item, isSelected: true, id: __item?.FeatureKey + __item?.ModuleKey };
                    });
                    const merge = (a: any, b: any, k: any) =>
                        a?.filter((d: any) => !b.find((c: any) => d[k] == c[k])).concat(b);
                    const __merged = merge(tmpTableData, __selectedData, 'id');
                    const data = badgeTableDataFormater(__merged);

                    setTableData(data);
                } else {
                    const data = badgeTableDataFormater(tmpTableData);

                    setTableData(data);
                }

                // setOriginalData(tmpTableData);
            }
        }
    }, [state[STORE_KEYS.ROLE_FEATURES]?.body?.data]);

    // useEffect(() => {
    //     if (state[STORE_KEYS.ROLE_SUB_FTRS]?.body?.data && subDtlData) {
    //         const __tmpTableData = [...state[STORE_KEYS.ROLE_SUB_FTRS]?.body?.data];
    //         const tmpTableData = __tmpTableData.map((item: any) => {
    //             return { ...item, id: item?.SubFeatureKey };
    //         });
    //         if (tmpTableData) {
    //             if (isEdit && subDtlData?.actualData) {
    //                 const __selectedData = subDtlData?.actualData?.map((__item: any) => {
    //                     return { ...__item, isSelected: __item.IsEditable === "Y" ? true : false, id: __item?.SubFeatureKey };
    //                 });
    //                 const merge = (a: any, b: any, k: any) =>
    //                     a?.filter((d: any) => !b.find((c: any) => d[k] == c[k])).concat(b);
    //                 const __merged = merge(tmpTableData, __selectedData, 'id');
    //                 const data: any = badgeTableDataFormater(__merged);
    //                 setSubTableData(data);
    //             } else {
    //                 const data = badgeTableDataFormater(tmpTableData);
    //                 setSubTableData(data);
    //             }

    //         } else {
    //             setSubTableData([]);
    //         }
    //     }
    // }, [state[STORE_KEYS.ROLE_SUB_FTRS]?.body?.data]);

    useEffect(() => {
        if (state[STORE_KEYS.CLIENT_SUB_FTRS]?.body?.data) {
            // Start with CLIENT_SUB_FTRS data
            const clientData = [...state[STORE_KEYS.CLIENT_SUB_FTRS]?.body?.data];
            const roleData = state[STORE_KEYS.ROLE_SUB_FTRS]?.body?.data || [];
            
            // Map through client data and add isEditable based on role data
            const tmpTableData = clientData.map((clientItem: any) => {
                // Find matching item in role data
                const matchingRoleItem = roleData.find((roleItem: any) => 
                    roleItem.SubFeatureKey === clientItem.SubFeatureKey
                );
                
                // Add isEditable field - use value from role data if exists, otherwise "N"
                return { 
                    ...clientItem, 
                    id: clientItem.SubFeatureKey,
                    IsEditable: matchingRoleItem ? matchingRoleItem.IsEditable : "N",
                };
            });
    
            if (tmpTableData) {
                if (isEdit && subDtlData?.actualData) {
                    const __selectedData = subDtlData?.actualData?.map((__item: any) => {
                        return { 
                            ...__item, 
                            isSelected: __item.IsEditable === "Y" ? true : false, 
                            id: __item?.SubFeatureKey 
                        };
                    });
                    
                    const merge = (a: any, b: any, k: any) =>
                        a?.filter((d: any) => !b.find((c: any) => d[k] == c[k])).concat(b);
                    const __merged = merge(tmpTableData, __selectedData, 'id');
                    const data: any = badgeTableDataFormater(__merged);
                    setSubTableData(data);
                               } else {
                    const data = badgeTableDataFormater(tmpTableData);
                    setSubTableData(data);
                                }
            } else {
                setSubTableData([]);
            }
        }
    }, [state[STORE_KEYS.CLIENT_SUB_FTRS]?.body?.data, state[STORE_KEYS.ROLE_SUB_FTRS]?.body?.data]);

    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };

        setSnackbarOpts(snackbarError);
    };

    useEffect(() => {
        if (state?.err) {
            handleSnackbarError(state?.err);
            dispatch(resetErr());
        }
    }, [state?.err]);

    //setTableFormatedSelectedData will reset the table data with ishomepage and ieditable updated flag and pass it to Table
    const setTableFormatedSelectedData = (data: any, from: any, deleteId: any) => {
        if (from === 'select') {
            const __tableData = tableData?.[data?.ModuleName]?.map((item: any) => {
                if (item.id == data.id && item.ModuleName == data.ModuleName && deleteId) {
                    return { ...item, IsEditable: 'N', IsHomePage: 'N', id: item?.FeatureKey + item?.ModuleKey };
                }
                return item;
            });
            const formatedTblData: any = badgeTableDataFormater(__tableData);

            setTableData({
                ...tableData,
                [data?.ModuleName]: formatedTblData?.[data?.ModuleName]
            });
        } else {
            if (from == 'radio') {
                Object.keys(tableData)?.forEach((key: any) => {
                    tableData[key]?.forEach((item: any) => {
                        item.IsHomePage = 'N';
                    });
                });
            }
            const __tableData = tableData?.[data?.ModuleName]?.map((item: any) => {
                if (item.id == data.id && item.ModuleName == data.ModuleName && !deleteId) {
                    return { ...item, ...data };
                }
                return item;
            });
            const formatedTblData: any = badgeTableDataFormater(__tableData);

            setTableData({
                ...tableData,
                [data?.ModuleName]: formatedTblData?.[data?.ModuleName]
            });
        }
    };

    //setAssignedSelectedFeatures function will keep assigned record with updated editable/ishomepage flag to pass API in req. payload format
    const setAssignedSelectedFeatures = (data: any, from: any, deleteId: any) => {
        const __data = assignFeatures?.some((item: any) => {
            return item.id === data.id;
        });
        if (__data) {
            const dt = assignFeatures
                .map((item: any) => {
                    console.log(item)
                    if (data.id == item.id) {
                        item.IsHomePage = item.IsHomePage;
                        return __formatData(data);
                    } else if (from == 'input') {
                        item.IsHomePage = item.IsHomePage;
                        // return __formatData(item);
                    } else if (from == 'select') {
                        item.IsHomePage = item.IsHomePage;
                        // return __formatData(item);
                    } else {
                        item.IsHomePage = 'N';
                        // return __formatData(item);
                    }
                    return __formatData(item);
                })
                ?.filter((d: any) => d.id != deleteId);
            setAssignFeatures(dt);
        }
        else {
            setAssignFeatures([...assignFeatures, { ...__formatData(data) }]);
        }
    };

    const handleSelectedData = (data: any, deleteId: any, from: any) => {
        setTableFormatedSelectedData(data, from, deleteId);
        setAssignedSelectedFeatures(data, from, deleteId);
    };

    const handleSubSelectedData = (data: any, type: any) => {
        if (type !== "input") {
            const updatedMachine = subTableData.Machine.map((item: any) => {
                if (item.id === data.id) {
                    if (preSubSelectedKeys.includes(data.id)) {
                        const temp = preSubSelectedKeys.filter((item: any) => item !== data.id);
                        setpreSubSelectedKeys(temp)
                    } else {
                        setpreSubSelectedKeys([...preSubSelectedKeys, data.id])
                    }
                    return {
                        ...item,
                        isSelected: !item.isSelected,
                    };
                }
                return item;
            });

            const formatedTblData: any = badgeTableDataFormater(updatedMachine);
            setSubTableData({
                [data?.ModuleName]: formatedTblData?.[data?.ModuleName]
            });
        } else {
            const dynamicKey = Object.keys(subTableData)
            const updatedObject = {
                ...subTableData,
                [dynamicKey[0]]: subTableData[dynamicKey[0]].map((item:any) =>
                    item.id === data.id ? { ...item, ...data } : item
                )
            };
            setSubTableData(updatedObject);

        }
    }

    const handleAllSelectedData = (all: any) => {
        //Not using made functionality disabled
        const __all = all.map((item: any) => {
            return {
                ModuleName: item.ModuleName,
                FeatureKey: item.FeatureKey,
                IsEditable: item.IsEditable,
                IsHomePage: item.IsHomePage
            };
        });
        setAssignFeatures(__all);
    };

    return (
        <>
            <Grid container spacing={2} direction="column">
                {step1Vals?.radioValue !== 'ACG' && (
                    <>
                        <Grid item>
                            <DetailLabel label="Client Name" className={'labelDiv'} />
                        </Grid>
                        <Grid item>
                            <DetailValue label={step1Vals?.customerName} />
                        </Grid>
                    </>
                )}
                <Grid item>
                    <Label label="Select Features" className={'labelDiv'} />
                </Grid>
            </Grid>

            <BadgeTable
                data={tableData}
                subData={subTableData}
                preSelectedKeys={preSelectedKeys}
                preSubSelectedKeys={preSubSelectedKeys}
                handleSelectedData={close ? handleSubSelectedData : handleSelectedData}
                handleAllSelectedData={handleAllSelectedData}
                isEdit={isEdit}
                closeSubMenu={close}
                setClose={() => setClose(false)}
                hasSubMenu={true}
                setSelectedRow={setSelectedSubRow}
                subDtlData={subDtlData}
                selectedRow={selectedSubRow}
                setStep={setStep}
            />
            {renderButtons()}
        </>
    );
};

export default AssignFeature;