import { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Axios from 'axios';
import { Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import Label from '../../../atoms/Label/index';
import TextInput from '../../../atoms/TextInput';
import DownArrow from '../../../../assets/down-arrow.svg';
import DropDown from '../../../atoms/Dropdown';
import InputLabel from '@mui/material/InputLabel';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { QueryBuilderMaterial } from '@react-querybuilder/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import HelpIcon from '@mui/icons-material/Help';
import Autocomplete from '@mui/material/Autocomplete';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import ACGButton from '../../../atoms/Button';
import useGetState from '../../../utils/hooks/useGetState';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../constants/apiConstants';
import FMEAService from '../fmeaServices';
import "../cm.scss";
import "./style.css";
import QueryBuilder, { defaultOperators, RuleValidator, ValidationResult, formatQuery } from 'react-querybuilder';
import { Grid } from '@mui/material';
import { AnyARecord } from 'dns';
import { fontWeight } from '@mui/system';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const muiTheme = createTheme();
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const initialQuery = { combinator: 'and', rules: [] };
export default function CreateForm(props: any) {
  const [query, setQuery] = useState<any>(initialQuery);

  const editing = props.customKey !== '' ? true : false;
  const [groupNameData, setGroupNameData] = useState<any>([]);
  const [groupKey, setGroupKey] = useState('');
  const [groupKeyNames, setGroupKeyNames] = useState<any[]>([]);
  const [parameterData, setParameterData] = useState([]);
  const [parameterNames, setParameterNames] = useState<any[]>([]);
  const [queryBuildData, setQueryBuildData] = useState([]);
  const [customizedQuery, setCustomizedQuery] = useState('');
  const [operators, setOperators] = useState([]);
  const ref = useRef(null);
  const state = useGetState();
  const [isLoading, setIsLoading] = useState(false);
  const token = state?.[STORE_KEYS.USER_DTL].token;
  const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;
  const [paramLength, setParamLength] = useState(0);
  useEffect(() => {
    FMEAService.getFMEAOperators({ token: token })
      .then((response: any) => {
        const result = response?.data?.message[0];
        setOperators(result[0]);
      })
      .catch((err) => {
        console.log(err);
      });
    }, []);

    useEffect(() => {
    if(props.editableCustomData.length)  getGroupName(props.editableCustomData[0]?.MachineModelKey);
      }, [props.editableCustomData]);

  useEffect(() => {
    if ((editing && props.editableCustomData[0] && props.failureIndicatorsData[0] && props.customKey !== '' && !props.newEntry) || (props.editableCustomData[0] && props.customKey !== '' && props.showQuery)) {
      (async function () {
        let r, rKeys: any, pKeys: any;
        let reversPKeys: any = [];
        const p = await getParameters(props.editableCustomData[0]?.MachineModelKey, props?.groupKeyNames ?? props?.failureIndicatorsData?.[0]?.MachMdlParamGrpKeys?.toString() ?? -1).then(async(result: any) => {
          pKeys = !props.newEntry && props.queryData || props.showQuery && props.queryData ? props.queryData[0]?.paramCodes : (props.failureIndicatorsData[0]?.ParamCodes).split(',');
          await result?.map((k: any) => {
            if (pKeys.includes(k.ParamCode.toString())) {
              reversPKeys.push(k)
              handleChangeParameterName(k, result);
            }
          })
          setParamLength(reversPKeys.length)
          setParameterNames(reversPKeys);

        });
        const qry: any = await !props.newEntry && props.queryData || props.showQuery && props.queryData ? JSON.parse(String(props.queryData[0]?.jsonCond)) : JSON.parse(String(props.failureIndicatorsData[0]?.ConditionJson))
        setQuery(qry);

      })()
    }
  }, [props.customKey])

  useEffect(() => {
    if (editing && props?.groupKeyNames && props?.groupKeyNames?.length) {
      const filteredArray = groupNameData.filter((item: any) => props.groupKeyNames.split(",").includes(String(item.MachMdlParamGrpKey)));
      setGroupKeyNames(filteredArray)
    } else if(editing && props?.failureIndicatorsData?.[0]?.MachMdlParamGrpKeys) {
      const filteredArray = groupNameData.filter((item: any) => props?.failureIndicatorsData?.[0]?.MachMdlParamGrpKeys.split(",").includes(String(item.MachMdlParamGrpKey)));
      setGroupKeyNames(filteredArray)
    }

  }, [props.customKey, groupNameData])

  useEffect(() => {
    if (query.rules.length === 0) {
      setQuery(initialQuery);
    }

    let qry = formatQuery(query, { format: 'sql', parseNumbers: true }), referenceQuery = formatQuery(query, { format: 'sql', parseNumbers: true });
    for (let i = 0; i < qry.length; i++) {
      if (qry[i] === 'D') {
        const actualName = parameterNames?.find((j: any) => (j.ParamCode === qry.substring(i, i + 5)))?.ParamName;
        referenceQuery = referenceQuery.replace(qry.substring(i, i + 5), actualName)
      }
    }
    setCustomizedQuery(referenceQuery);

  }, [query])

  const getGroupName = async (args: any) => {
    let finalResult;
    const body = { userId: loggedInUser, machineModelKey: args, token: token }
    FMEAService.getFMEAGroupNames(body)
      .then((response: any) => {
        const result = response?.data?.message[0];
        setGroupNameData(result[0]);
        finalResult = result[0];
      })
      .catch((err) => {
        console.log(err);
      });
    return finalResult;
  }

  const handleChangeGroupName = async (id: any) => {
    let newSelected: any = [];
    if (props.customKey === '') {
      const selectedIndex = groupKeyNames.indexOf(id);
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(groupKeyNames, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(groupKeyNames.slice(1));
      } else if (selectedIndex === groupKeyNames.length - 1) {
        newSelected = newSelected.concat(groupKeyNames.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          groupKeyNames.slice(0, selectedIndex),
          groupKeyNames.slice(selectedIndex + 1)
        );
      }
    }
    else {
      const selectedIndex = groupKeyNames.findIndex((t) => t.MachMdlParamGrpKey === id.MachMdlParamGrpKey);
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(groupKeyNames, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(groupKeyNames.slice(1));
      } else if (selectedIndex === groupKeyNames.length - 1) {
        newSelected = newSelected.concat(groupKeyNames.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          groupKeyNames.slice(0, selectedIndex),
          groupKeyNames.slice(selectedIndex + 1)
        );
      }
    }

    setGroupKeyNames(newSelected);
    setParameterData([]);
    let keys: any = [];
    newSelected?.map((i: any) => keys.push(i.MachMdlParamGrpKey))
    if(keys.includes(-1)){
      keys=[-1]
    }
    await getParameters(props.editableCustomData[0]?.MachineModelKey, keys.toString());
  }

  const getParameters = async (args: any, args2: any) => {
    let finalResult;
    const body = { userId: loggedInUser, machineModelKey: args, groupKey: args2, token: token }
    await FMEAService.getParameterList(body)
      .then(async (response: any) => {
        const result = await response?.data?.message[0];
        setParameterData(result[0]);
        finalResult = await result[0];
      })
      .catch((err) => {
        console.log(err);
      });
    return finalResult;
  }

  const handleChangeParameterName = (id: any, args: any) => {
    let newSelected: any = [];
    if (props.customKey === '') {
      const selectedIndex = parameterNames.indexOf(id);
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(parameterNames, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(parameterNames.slice(1));
      } else if (selectedIndex === parameterNames.length - 1) {
        newSelected = newSelected.concat(parameterNames.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          parameterNames.slice(0, selectedIndex),
          parameterNames.slice(selectedIndex + 1)
        );
      }
    }
    else {
      const selectedIndex = parameterNames.findIndex((t) => t.MMParamKey === id.MMParamKey);
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(parameterNames, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(parameterNames.slice(1));
      } else if (selectedIndex === parameterNames.length - 1) {
        newSelected = newSelected.concat(parameterNames.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          parameterNames.slice(0, selectedIndex),
          parameterNames.slice(selectedIndex + 1)
        );
      }
    }
    setParamLength(newSelected.length)
    setParameterNames(newSelected);
  }

  useEffect(() => {
    if((parameterNames.length === paramLength)) {
      getQueryBuilderData(parameterNames, parameterData)
    }
    let qry = formatQuery(query, { format: 'sql', parseNumbers: true }), referenceQuery = formatQuery(query, { format: 'sql', parseNumbers: true });
    for (let i = 0; i < qry.length; i++) {
      if (qry[i] === 'D') {
        const actualName = parameterNames?.find(j => (j.ParamCode === qry.substring(i, i + 5)))?.ParamName;
        referenceQuery = referenceQuery.replace(qry.substring(i, i + 5), actualName)
      }
    }
    setCustomizedQuery(referenceQuery);
  }, [parameterNames])

  const getQueryBuilderData = async (args: any, args2: any) => {
    const mPKeys: any = [];
    let finalResult;
    const fields: any = [];
     args?.map(async (i: any) => await mPKeys.push(i.MMParamKey))
    const body = { machineParamKeys: mPKeys.toString(), token: token }
     await FMEAService.getParameterValueTypes(body)
      .then(async(response: any) => {
        const result = await response?.data?.message[0];
        
        for (let i of result[0]) {
          if (i.UIType === "Textbox") {
            if (i.DataType === "FLOAT") {
              const opList: any = [];
              props.mainOperators?.filter((op: any) => { if (op.DataType === "FLOAT") { opList.push(op.RuleEngCondOperatorSymbol) } });

              const conName = args2.filter((p: any) => p.MMParamKey === i.MMParamKey);
              await fields.push({
                "name": conName[0].ParamCode,
                "label": i.ParamName,
                "placeholder": 'Please enter',
                operators: defaultOperators.filter((op:any) => opList.includes(op.name) || op.name === '!='),
                inputType: 'number',
                "groupNumber": 'group1',
                "valueSources": ['value', 'field'],
                "comparator": 'groupNumber',
              })
            } else {
              const opList: any = [];
              props.mainOperators?.filter((op: any) => { if (op.DataType === "STRING") { opList.push(op.RuleEngCondOperatorSymbol) } });

              const conName = args2.filter((p: any) => p.MMParamKey === i.MMParamKey);
             await fields.push({
                "name": conName[0].ParamCode,
                "label": i.ParamName,
                "placeholder": 'Please enter',
                operators: defaultOperators.filter(op => opList.includes(op.name) || op.name === '!='),
                inputType: 'text',
                "groupNumber": 'group1',
                "valueSources": ['value', 'field'],
                "comparator": 'groupNumber',
              })
            }
          }
          else {
            const paramsValueList: any = [], paramsNameValueList: any = [], paramLabels: any = []
            result[0]?.map((p: any) => {
              if (p.DataType === "Boolean" && i.UIType === "Dropdown") {
                if (i.MMParamKey === p.MMParamKey) {
                  paramsNameValueList.push({ "name": p.ParamValue, "label": p.ParamValueName });
                }
              } else {
                if (i.MMParamKey === p.MMParamKey) {
                  paramLabels.push({ label: p.ParamValueName, mmKey: p.MMParamKey })
                  paramsValueList.push(p.ParamValue);
                }
              }
            })
            const opList: any = [];
            props.mainOperators?.filter((op: any) => {
              if (op.DataType === "STRING") {
                opList.push(op.RuleEngCondOperatorSymbol)
              }
            });
            const conName: any = parameterData.filter((p: any) => p.MMParamKey === i.MMParamKey);
            await fields.push({
              "name": conName[0].ParamCode,
              "label": i.ParamName,
              values: i.DataType === "STRING" ? [{ "label": "Parameter name", data: paramsValueList }].map(({ label, data }) => ({
                label,
                options: data.map((s: any, ind: any) => ({ name: s, label: paramLabels[ind].label })
                ),
              })) : paramsNameValueList,
              valueEditorType: i.DataType === "STRING" ? 'select' : 'radio',
              operators: defaultOperators.filter(op => opList.includes(op.name) || op.name === '!='),
              "groupNumber": 'group1',
              "valueSources": ['value', 'field'],
              "comparator": 'groupNumber',
            })
          }
        }
        setQueryBuildData(fields);
      })
      
    return finalResult;
}

  const reset = async () => {
    setGroupNameData([]);
    setGroupKey('');
    setParameterData([]);
    setParameterNames([]);
    setQueryBuildData([]);
    setQuery(initialQuery);
    setGroupKeyNames([]);
  }

  const options = {
    AD_SUCCESS: {
      message: `Failure Indictor ${props.failureIndicatorsData.length === 0 ? 'created' : 'edited'} successfully!`,
      open: true,
      type: 'success'
    },
    AD_FAIL: {
      message: `Failure indicator failed to create`,
      open: true,
      type: 'remark'
    }
  };
  const createCustomAlert = () => {
    let fp: any = [];
    for (let i = 0; i < parameterNames.length; i++) {
      if (formatQuery(query, { format: 'sql', parseNumbers: true }).toString().includes(parameterNames[i].ParamCode)) {
        fp.push(parameterNames[i].ParamCode)
      }
    }
    fp = fp.filter((item: any,
      index: number) => fp.indexOf(item) === index);
    let formula = formatQuery(query, { format: 'sql', parseNumbers: true });
    formula = formula.replaceAll("'", '"');
    const result = groupKeyNames.map((item: any) => item.MachMdlParamGrpKey).join(',')
    const body = {
      userId: loggedInUser,
      condFormula: formula,
      jsonCond: formatQuery(query, 'json_without_ids'),
      MachMdlParamGrpKeys: result,
      paramCodes: fp.toString(),
      presetRuleKey: props.customKey ? props.customKey : null,
      type: props.newEntry ? 'Add' : "Edit",
      token: token
    }
    if (props.casualEntry) {
      props.setQueryData([{
        condFormula: formula,
        jsonCond: formatQuery(query, 'json_without_ids'),
        paramCodes: fp.toString()
      }]);
      props.setOpenCasual(false);
      props.setMachineGropuKey(result);
    } else {
      FMEAService.createFMEAFailureIndicatorRule(body)
        .then((response: any) => {
          if (response.status === 200) {
            reset();
            props.setCurrTab(1);
            const result = response?.data?.message[0];
            const checkIfExsist = result[0];
            const isError = checkIfExsist[0];
            if (isError.Result === 'Error') {
              props.setSnackbarOpts(options.AD_FAIL);
            }
            else {
              props.setSnackbarOpts(options.AD_SUCCESS);
            }
            props.drawerClose();
            props.fetchCustomAlerts();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  const check = (object: any) => {
    for (const value of Object.values(object)) {
      if (value === ""||(typeof value === "object" && check(value))) {
        return true;
      }
    }

    return false;
  }

  const yesDisabled = (
    check(query) ||
    parameterNames?.length === 0 ||
    query.rules.length === 0 ||
    query.rules[0].rules.length === 0) ? true : false;

  const DropDownIcon = (prop: any) => (
    <img style={{ marginRight: '5px', marginTop: '-2px' }} src={DownArrow} {...prop} />
  );

  const renderButtons = () => {
    return (
      <div className={'btns noWrap'} style={{ display: "flex", margin: "10px 0", justifyContent: "flex-end", width: "100%" }}>
        <ACGButton
          name={'Cancel'}
          handleClick={props.drawerClose}
          secondary={true}
          className={'first-btn'}
          style={{ height: '40px', marginRight: '10px' }}
          type="button"
          variant="contained"
        />
        <ACGButton
          name={'Submit'}
          handleClick={() => { createCustomAlert() }}
          type="submit"
          className="second-btn"
          style={{ height: '40px', fontWeight: '500' }}
          variant="contained"
          disabled={yesDisabled ? true : false}
          isLoading= {isLoading}
        />
      </div>
    );
  };

  return (
    <Container className="createReportt">
      <Box>
        <div className='formwrap all-labelcls'>
          <FormControl fullWidth>
            <Grid item xs={4}>
              <div className='labelfixed'>
                <FormControl fullWidth>
                  <Label label="Group Name" className={'CMlabelDiv'} />
                  <FormControl sx={{ width: '100%' }}>
                    {groupKeyNames?.length > 0 ? null : (
                      <InputLabel
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          paddingTop: '0px',
                          marginBottom: '0px',
                          paddingLeft: '12px',
                          color: '#b1c2df',
                          lineHeight: '1',
                          minHeight: '20px',
                          opacity: '0.7'
                        }}
                        id="demo-multiple-checkbox-label"
                      >
                        Select group name
                      </InputLabel>
                    )}
                    <Select
                      IconComponent={DropDownIcon}
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      sx={{ marginBottom: "20px", backgroundColor: "#1d1e2c", borderRadius: 3, marginTop: '10px', color: "#b1c2df" }}
                      multiple
                      value={groupKeyNames}
                      input={<OutlinedInput />}
                      renderValue={(selected) => selected.map((x) => x.GroupName).join(', ')}
                      MenuProps={MenuProps}
                    >
                      {groupNameData?.map((i: any) => (
                        <MenuItem value={i} onClick={() => handleChangeGroupName(i)}>
                          <Checkbox
                            checked={
                              groupKeyNames.findIndex(item => item.MachMdlParamGrpKey === i.MachMdlParamGrpKey) >= 0
                            }
                          />
                          <ListItemText primary={i.GroupName} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Label label="Parameters Name &nbsp; *" className={'CMlabelDiv'} />
                <FormControl sx={{ width: '100%' }} className='alignmultibox'>
                  {/* {parameterNames?.length > 0 ? null : (
                    <InputLabel
                      style={{
                        fontFamily: 'Montserrat',
                        fontSize: '12px',
                        paddingTop: '7px',
                        marginBottom: '0px',
                        paddingLeft: '12px',
                        color: '#b1c2df',
                        lineHeight: '1',
                        minHeight: '20px',
                        opacity: '0.7'
                      }}
                      id="demo-multiple-checkbox-label"
                    >
                      Select parameters
                    </InputLabel>
                  )} */}
                  <Autocomplete
                    sx={{ marginBottom: "20px", backgroundColor: "#1d1e2c", borderRadius: 3, marginTop: '10px', color: "#b1c2df" }}
                    multiple
                    limitTags={2}
                    key="parameter-code"
                    id="checkboxes-tags-demo"
                    className='alldropbox extracls'
                    options={parameterData}
                    disableCloseOnSelect
                    getOptionLabel={(option: any) => option.ParamName}
                    isOptionEqualToValue={(option: any, value: any) => option.value === value.value}
                    value={parameterNames}

                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => {
                        return (
                          <Chip
                            label={option.ParamName}
                            {...getTagProps({ index })}
                            onDelete={() => handleChangeParameterName(option, parameterData)}
                          />
                        )
                      })
                    }

                    renderOption={(props, option, { selected }, index) => (
                      <li {...props} key={option.ParamCode}
                        onClick={() => handleChangeParameterName(option, parameterData)}

                      >
                        <Checkbox

                          // key={index}
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          onChange={() => handleChangeParameterName(option, parameterData)}
                          checked={
                            parameterNames.findIndex(item => item.MMParamKey === option.MMParamKey) >= 0
                          }

                        />
                        {option.ParamName}
                      </li>
                    )}
                    // style={{ width: 500 }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder='Select parameters' />
                    )}
                  />
                </FormControl>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <Label label="Create Rule &nbsp; *" className={'CMlabelDiv'} />
                <div className='wrapper-cm fmeacard cardGroupList'>
                  <ThemeProvider theme={muiTheme}>
                    <QueryBuilderMaterial>
                      <QueryBuilder
                        key={query}
                        fields={queryBuildData}
                        query={query}
                        onQueryChange={(q) => { setQuery(q); }}
                        autoSelectField={false}
                        autoSelectOperator={false}
                        resetOnFieldChange={false}
                        showCombinatorsBetweenRules={true}
                        resetOnOperatorChange

                      />
                    </QueryBuilderMaterial>
                  </ThemeProvider>

                </div>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Label label="Rule Engine Formula &nbsp; *" className={'CMlabelDiv'} />
                <Label label={query.rules.length > 0 ? customizedQuery : "Please enter the conditions"} />
              </FormControl>
            </Grid>
          </FormControl>
        </div>
      </Box>
      <Grid style={{ display: "flex", marginTop: 20 }}>
        {renderButtons()}
      </Grid>
    </Container>
  );
}