import { useState, useEffect } from 'react';
import FD from 'js-file-download';
import { AnyIfEmpty, useDispatch } from 'react-redux';
import { Container } from '@mui/material';
import MapCustomTabs from '../../../app/atoms/ButtonTabs/MapCustomTabs';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../app/constants/apiConstants';
import { executeACGAction, updateScreenIdentifiers } from '../../../app/store/slice';
import Axios from 'axios';
import useGetState from '../../../app/utils/hooks/useGetState';
import { _noop } from '../../../app/utils/common';
import '../Admin/ClientManagement/index.scss';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DrawerDropdown from '../../../app/atoms/DrawerDropdown';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import RightDrawer from '../../../app/atoms/tempDrawer/indexAlertCLC';
import { SCREEN_TITLES } from '../../../app/constants/StringConstants';
import NewEntry from './NewEntry/CreateConfig';
import LifeCycleEntries from './NewEntry/Config';
import OnboardedEntries from './OnboardNewEntry/Config';
import LifeCycleMapping from './MachineMapping/CreateConfig';
import WorkOrderEntries from './WorkOrders/Config';
import SnackbarAlert from '../../atoms/Snackbar';
import Filter from './CreateFilterConfig';
import WOFilter from './WorkOrders/CloseWorkOrderConfig';
import CloseWO from './WorkOrders/CreateWorkOrderFilterConfig';
import ViewDetails from './ViewDetails';
import WorkOrderViewDetails from './ViewDetails';
import EditEntry from './EditEntry/CreateConfig';
import EditOnboardEntry from './EditOnboardEntry/CreateConfig';
import ReplacementAction from './ReplacementAction/CreateConfig';
import { trackButtonEvent } from '../../utils/newRelicFuncations';

const options = {
  DEFAULT: {
    message: '',
    open: false,
    type: ''
  },
  AD_SUCCESS: {
    message: 'Work Order Deleted.',
    open: true,
    type: 'success'
  },
  RM_SUCCESS: {
    message: 'Component Life Cycle Entry Deleted.',
    open: true,
    type: 'success'
},
  AD_SUCCESS_WO: {
    message: 'Work order closed. ',
    open: true,
    type: 'success'
  },
};
const SystemConfiguration = () => {
  const [appliedFilters, setAppliedFilters] = useState(true);
  const [appliedOMFilters, setAppliedOMFilters] = useState(true);
  const [appliedWOFilters, setWOAppliedFilters] = useState(false);
  const [currTab, setTabChange] = useState(0);
  const [radioValue, setRadioValue] = useState('All');
  const [drawerHeader, setDrawerHeader] = useState('Create');
  const [machineModelData, setMachineModelData] = useState<any[]>([]);
  const [CLCmachineModelData, setCLCMachineModelData] = useState<any[]>([]);
  const [machineSerialData, setMachineSerialData] = useState<any[]>([]);
  const [filteredMachine, setFilteredMachine] = useState<any>(11);
  const [filteredMachineTab2, setFilteredMachineTab2] = useState<any>(11);
  const [filteredMachineMapping, setFilteredMachineMapping] = useState<any>('');
  const [filteredSerial, setFilteredSerial] = useState(0);
  const [createValue, setCreateValue] = useState('');
  const [createDrawerOpen, setCreateDrawerOpen] = useState(false);
  const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
  const [reload, setReload] = useState(false);
  const [dtlData, setDtlData] = useState<any>({});
  const [selectedRow, setSelectedRow] = useState<any>();
  const [alertType, setAlertType] = useState("Custom Alerts")
  const [workOrderStatusList, setWorkOrderStatusList] = useState<any>([]);
  const [workOdersData, setWorkOrdersData] = useState<any[]>([]);
  const [clcMappingList, setCLCMappingList] = useState<any[]>([]);
  const date = new Date();
  date.setDate(date.getDate() - 6)
  const [workOrderFrom, setWorkOrderFrom] = useState(date);
  const [workOrderTo, setWorkOrderTo] = useState(new Date());
  const [workOrderStatsusKey, setWorkOrderStatusKey] = useState(0);
  const [componentLSKey, setComponentLSKey] = useState('');
  const [workOrderKey, setWorkOrderKey] = useState('');
  const [added, setAdded] = useState(false);
  const [CLCData, setCLCData] = useState([]);
  const [onBoardData, setOnBoardData] = useState([]);
  const [replacementDetails, setReplacementDetails] = useState("");
  const [replacementDate, setReplacementDate] = useState("");
  const [isEditing, setisEditing] = useState(false);
  const [isModelLoading,setIsModelLoading]=useState(false)
  const [isOnBoardedLoading,setIsOnBoardedLoading]=useState(false)
  const [isWOLoading,setIsWOLoading]=useState(false)
  const [tabs, setTabs] = useState<any>([]);
  const [tabsPermissions, setTabsPermissions] = useState<Record<string, string>>({});

  const dispatch = useDispatch();
  const state = useGetState();
  const token = state?.[STORE_KEYS.USER_DTL].token;
  const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;

  useEffect(() => {
    getWOStatus();
    getAllMachineModel();
    getCLCList(11);
    getWorkOrderList(workOrderStatsusKey, workOrderFrom, workOrderTo);
    getOnboardList(11);
  }, [])

  // useEffect(() => {
  //   if (appliedFilters || added) getCLCList(filteredMachine);
  //   if (currTab === 1) getWorkOrderList(workOrderStatsusKey, workOrderFrom, workOrderTo);
  // }, [appliedFilters, added])

  useEffect(() => {
    if (appliedWOFilters) getWorkOrderList(workOrderStatsusKey, workOrderFrom, workOrderTo);
  }, [appliedWOFilters])

  const getWOStatus = () => {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'GET',
          urlPath: ACTION_CODES.GET_WORK_ORDER_STATUS,
        },
        uniqueScreenIdentifier: { woStatus: true },
        storeKey: STORE_KEYS.GET_WORK_ORDER_STATUS
      })
    );
  };

  useEffect(() => {
    if (state?.[STORE_KEYS.GET_WORK_ORDER_STATUS]?.woStatus) {
      const rptsData = state?.[STORE_KEYS.GET_WORK_ORDER_STATUS]?.body?.data;
      setWorkOrderStatusList([
        ...rptsData?.map((item: any) => ({ code: item.MCLCWorkOrderStatusKey, name: item.MCLCWorkOrderStatusName }))

      ]);

      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.GET_WORK_ORDER_STATUS,
          uniqueScreenIdentifier: {
            woStatus: false
          }
        })
      );
    }
  }, [state?.[STORE_KEYS.GET_WORK_ORDER_STATUS]?.woStatus]);

  const getAllMachineModel = () => {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.GET_CLC_MACHINE_MODELS,
          reqObj: {
            "LoggedUserKey": loggedInUser,
          }
        },
        uniqueScreenIdentifier: { clcMachineModelKey: true },
        storeKey: STORE_KEYS.GET_CLC_MACHINE_MODELS
      })
    );
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.GET_CLC_MACHINE_MODELS_LIST,
          reqObj: {
            "LoggedUserKey": loggedInUser,
          }
        },
        uniqueScreenIdentifier: { clcMachineModelKeyList: true },
        storeKey: STORE_KEYS.GET_CLC_MACHINE_MODELS_LIST
      })
    );
  };

  useEffect(() => {
    if (state?.[STORE_KEYS.GET_CLC_MACHINE_MODELS_LIST]?.clcMachineModelKeyList) {
      const rptsData = state?.[STORE_KEYS.GET_CLC_MACHINE_MODELS_LIST]?.body?.data;
      setCLCMachineModelData([
        ...rptsData?.map((item: any) => ({ code: item.MachineModelKey, name: item.MachineModelName }))

      ]);

      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.GET_CLC_MACHINE_MODELS_LIST,
          uniqueScreenIdentifier: {
            clcMachineModelKeyList: false
          }
        })
      );
    }
  }, [state?.[STORE_KEYS.GET_CLC_MACHINE_MODELS_LIST]?.clcMachineModelKeyList]);

  useEffect(() => {
    if (state?.[STORE_KEYS.GET_CLC_MACHINE_MODELS]?.clcMachineModelKey) {
      const rptsData = state?.[STORE_KEYS.GET_CLC_MACHINE_MODELS]?.body?.data;
      setMachineModelData([
        ...rptsData?.map((item: any) => ({ code: item.MachineModelKey, name: item.MachineModelName }))

      ]);

      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.GET_CLC_MACHINE_MODELS,
          uniqueScreenIdentifier: {
            clcMachineModelKey: false
          }
        })
      );
    }
  }, [state?.[STORE_KEYS.GET_CLC_MACHINE_MODELS]?.clcMachineModelKey]);

  const getAllMachineSerial = (id: any, type: string) => {
    type === "mapping" ? setFilteredMachineMapping(id) : setFilteredMachineMapping(id.target.value);
    if (id) {
      dispatch(
        executeACGAction({
          payload: {
            requestType: 'POST',
            urlPath: ACTION_CODES.GET_CLC_MACHINE_SERIALS,
            reqObj: {
              "LoggedUserKey": loggedInUser,
              "MachineModelKey": type === "mapping" ? id : id.target.value
            }
          },
          uniqueScreenIdentifier: { clcMachineSerialKey: true },
          storeKey: STORE_KEYS.GET_CLC_MACHINE_SERIALS
        })
      );
    }

  };

  useEffect(() => {
    if (state?.[STORE_KEYS.GET_CLC_MACHINE_SERIALS]?.clcMachineSerialKey) {
      const rptsData = state?.[STORE_KEYS.GET_CLC_MACHINE_SERIALS]?.body?.data;
      setMachineSerialData([
        ...rptsData?.map((item: any) => ({ code: item.MachineKey, name: item.MachineName }))

      ]);

      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.GET_CLC_MACHINE_SERIALS,
          uniqueScreenIdentifier: {
            clcMachineSerialKey: false
          }
        })
      );
    }
  }, [state?.[STORE_KEYS.GET_CLC_MACHINE_SERIALS]?.clcMachineSerialKey]);

  const clearWOFilter: any = () => {
    setWorkOrderStatusKey(0);
    setWOAppliedFilters(false);
    const date = new Date();
    date.setDate(date.getDate() - 6);
    setWorkOrderFrom(date);
    setWorkOrderTo(new Date());
    getWorkOrderList(0, date, new Date())
  }

  const clearFilter: any = () => {
  }
  
  const resetFilter = () => {
    getCLCList(-1);
    setAppliedFilters(false);
    setFilteredMachine(-1);
  }

  const resetFilterOM = () => {
    getOnboardList(-1);
    setAppliedOMFilters(false);
    setFilteredMachineTab2(-1);
  }

  const closeWorkOrder: any = () => {
    trackButtonEvent("Component Life Cycle","Work Order List","Close work order",state[STORE_KEYS.USER_DTL]?.EmailId)
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.POST_CLOSE_WORK_ORDER,
          reqObj: {
            "LoggedUserKey": loggedInUser,
            "MCLCWorkOrderKey": parseInt(selectedRow?.MCLCWorkOrderKey),
            "ReplacedActionDesc": replacementDetails,
            "ReplacementDate": replacementDate
          }
        },
        uniqueScreenIdentifier: { closeWO: true },
        storeKey: STORE_KEYS.POST_CLOSE_WORK_ORDER
      })
    );
  }

  useEffect(() => {
    if (state?.[STORE_KEYS.POST_CLOSE_WORK_ORDER]?.closeWO) {
      setSnackbarOptions(options.AD_SUCCESS_WO)
      setReplacementDate("");
      setReplacementDetails("");
      getWorkOrderList(workOrderStatsusKey, workOrderFrom, workOrderTo);
      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.POST_CLOSE_WORK_ORDER,
          uniqueScreenIdentifier: {
            closeWO: false
          }
        })
      );
    }
  }, [state?.[STORE_KEYS.POST_CLOSE_WORK_ORDER]?.closeWO]);

  const getCreateValue = (ele: string) => {
    trackButtonEvent("Component Life Cycle","Component Life Cycle Create button",`${ele}`,state[STORE_KEYS.USER_DTL]?.EmailId)
    setDrawerHeader('Create');
    setCreateValue(ele);
    setCreateDrawerOpen(true);
    setComponentLSKey('');
    setAlertType("Custom Alerts")
    setAdded(false);
    setisEditing(false);
  };

  const setCurrTab = (val: number) => {
    setTabChange(val);
    if (radioValue == 'All' && currTab === 1) {
      setRadioValue('All');
    }
  };

  const handleReset = () => {
    setComponentLSKey('');
  };

  const editTrail = async (id: any) => {
    setComponentLSKey(id);
  }

  const editWorkOrder = (id: any) => {
    setWorkOrderKey(id);
  }

  const getCLCList = (m: any) => {
    setIsModelLoading(true)
    setFilteredMachine(m);
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.GET_CLC_LIST,
          reqObj: {
            "LoggedUserKey": loggedInUser,
            "MachineModelKey": parseInt(m),
          }
        },
        uniqueScreenIdentifier: { clcList: true },
        storeKey: STORE_KEYS.GET_CLC_LIST
      })
    );
  }

  useEffect(() => {
    if (state?.[STORE_KEYS.GET_CLC_LIST]?.clcList) {
      const response = state?.[STORE_KEYS.GET_CLC_LIST]?.body?.data;
      setCLCData(response);
      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.GET_CLC_LIST,
          uniqueScreenIdentifier: {
            clcList: false
          }
        })
      );
      setIsModelLoading(false)
    }
  }, [state?.[STORE_KEYS.GET_CLC_LIST]?.clcList]);

  const getOnboardList = (id: any) => {
    setIsOnBoardedLoading(true)
    setFilteredMachineTab2(id == null ? -1 : id);
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.GET_CLC_ONBOARD_LIST,
          reqObj: {
            "LoggedUserKey": loggedInUser,
            "MachineModelKey": parseInt(id)
          }
        },
        uniqueScreenIdentifier: { clcOnboardList: true },
        storeKey: STORE_KEYS.GET_CLC_ONBOARD_LIST
      })
    );
  }

  useEffect(() => {
    if (state?.[STORE_KEYS.GET_CLC_ONBOARD_LIST]?.clcOnboardList) {
      const response = state?.[STORE_KEYS.GET_CLC_ONBOARD_LIST]?.body?.data;
      setOnBoardData(response);
      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.GET_CLC_ONBOARD_LIST,
          uniqueScreenIdentifier: {
            clcOnboardList: false
          }
        })
      );
      setIsOnBoardedLoading(false)
    }
  }, [state?.[STORE_KEYS.GET_CLC_ONBOARD_LIST]?.clcOnboardList]);

  const getWorkOrderList = (statusKey: any, from: any, to: any) => {
    setIsWOLoading(true)
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.GET_WORK_ODER_LIST,
          reqObj: {
            "LoggedUserKey": loggedInUser,
            "WorkOrderStatusKey": statusKey,
            "FromDate": from,
            "ToDate": to
          }
        },
        uniqueScreenIdentifier: { woList: true },
        storeKey: STORE_KEYS.GET_WORK_ODER_LIST
      })
    );
  }

  useEffect(() => {
    if (state?.[STORE_KEYS.GET_WORK_ODER_LIST]?.woList) {
      const response = state?.[STORE_KEYS.GET_WORK_ODER_LIST]?.body?.data;
      setWorkOrdersData(response);
      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.GET_WORK_ODER_LIST,
          uniqueScreenIdentifier: {
            woList: false
          }
        })
      );
      setIsWOLoading(false)
    }
  }, [state?.[STORE_KEYS.GET_WORK_ODER_LIST]?.woList]);

  const deleteCLC = async (id: any) => {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.DELETE_CLC_ENTRY,
          reqObj: {
            "LoggedUserKey": loggedInUser,
            "TableName": "ModelComponentLifeCycle",
            "MCLCModelComponentKey": parseInt(id),
          } 
        },
        uniqueScreenIdentifier: { clcDelete: true },
        storeKey: STORE_KEYS.DELETE_CLC_ENTRY
      })
    );
  }

  useEffect(() => {
    if (state?.[STORE_KEYS.DELETE_CLC_ENTRY]?.clcDelete) {
      setSnackbarOptions(options.RM_SUCCESS)
      getCLCList(filteredMachine);
      getWorkOrderList(workOrderStatsusKey, workOrderFrom, workOrderTo)
      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.DELETE_CLC_ENTRY,
          uniqueScreenIdentifier: {
            clcDelete: false
          }
        })
      );
    }
  }, [state?.[STORE_KEYS.DELETE_CLC_ENTRY]?.clcDelete]);

  const deleteWorkOrder = async (id: any) => {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.DELETE_WO_ENTRY,
          reqObj:{
            "LoggedUserKey": loggedInUser,
            "TableName": "ModelComponentLifeCycleWorkOrder",
            "MCLCWorkOrderKey": parseInt(id),
          } 
        },
        uniqueScreenIdentifier: { woDelete: true },
        storeKey: STORE_KEYS.DELETE_WO_ENTRY
      })
    );
  }

  useEffect(() => {
    if (state?.[STORE_KEYS.DELETE_WO_ENTRY]?.woDelete) {
      setSnackbarOptions( options.AD_SUCCESS)
      getCLCList(filteredMachine);
      getWorkOrderList(workOrderStatsusKey, workOrderFrom, workOrderTo)
      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.DELETE_WO_ENTRY,
          uniqueScreenIdentifier: {
            woDelete: false
          }
        })
      );
    }
  }, [state?.[STORE_KEYS.DELETE_WO_ENTRY]?.woDelete]);

  const navigateTab = () => {
    switch (currTab) {
      case 0:
        return (
          <LifeCycleEntries
            CLCData={CLCData}
            componentLSKey={componentLSKey}
            setCreateValue={setCreateValue}
            setDrawerHeader={setDrawerHeader}
            editTrail={(id: any) => editTrail(id)}
            deleteCLC={(id: any) => deleteCLC(id)}
            drawerClose={() => { setCreateDrawerOpen(false); }}
            drawerOpen={() => { setCreateDrawerOpen(true); }}
            setReload={setReload}
            reload={reload}
            radioValue={radioValue}
            setSnackbarOpts={setSnackbarOptions}
            setDtlData={setDtlData}
            dtlData={dtlData}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            setComponentLSKey={setComponentLSKey}
            clearFilter={clearFilter}
            appliedFilters={appliedFilters}
            setCreateDrawerOpen={setCreateDrawerOpen}
            setAlertType={setAlertType}
            getCLCList={getCLCList}
            machineModelData={CLCmachineModelData}
            filteredMachine={filteredMachine}
            setAppliedFilters={setAppliedFilters}
            resetFilter={resetFilter}
            isLoading={isModelLoading}
            isEditAccess={tabsPermissions?.ModComp=="Y"}
          />
        )
      case 1:
        return (
          <OnboardedEntries
            currTab={currTab}
            CLCData={onBoardData}
            componentLSKey={componentLSKey}
            setCreateValue={setCreateValue}
            setDrawerHeader={setDrawerHeader}
            editTrail={(id: any) => editTrail(id)}
            deleteCLC={(id: any) => deleteCLC(id)}
            drawerClose={() => { setCreateDrawerOpen(false); }}
            drawerOpen={() => { setCreateDrawerOpen(true); }}
            setReload={setReload}
            reload={reload}
            radioValue={radioValue}
            setSnackbarOpts={setSnackbarOptions}
            setDtlData={setDtlData}
            dtlData={dtlData}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            setComponentLSKey={setComponentLSKey}
            clearFilter={clearFilter}
            appliedOMFilters={appliedOMFilters}
            setCreateDrawerOpen={setCreateDrawerOpen}
            setAlertType={setAlertType}
            getCLCList={getCLCList}
            machineModelData={CLCmachineModelData}
            filteredMachine={filteredMachineTab2}
            setAppliedOMFilters={setAppliedOMFilters}
            setisEditing={setisEditing}
            getOnboardList={getOnboardList}
            resetFilterOM={resetFilterOM}
            isLoading={isOnBoardedLoading}
            isEditAccess={tabsPermissions?.OnboardMach=="Y"}
          />
        )
      case 2:
        return (
          <WorkOrderEntries
            workOdersData={workOdersData}
            setAlertType={setAlertType}
            setCreateDrawerOpen={setCreateDrawerOpen}
            setCreateValue={setCreateValue}
            setDrawerHeader={setDrawerHeader}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            editTrail={(id: any) => editWorkOrder(id)}
            setWorkOrderKey={setWorkOrderKey}
            drawerClose={() => { setCreateDrawerOpen(false); }}
            drawerOpen={() => { setCreateDrawerOpen(true); }}
            clearWOFilter={clearWOFilter}
            appliedWOFilters={appliedWOFilters}
            deleteWorkOrder={(id: any) => deleteWorkOrder(id)}
            isLoading={isWOLoading}
          />
        )
      default:
        return (
          <LifeCycleEntries
            CLCData={CLCData}
            componentLSKey={componentLSKey}
            setCreateValue={setCreateValue}
            setDrawerHeader={setDrawerHeader}
            editTrail={(id: any) => editTrail(id)}
            deleteCLC={(id: any) => deleteCLC(id)}
            drawerClose={() => { setCreateDrawerOpen(false); }}
            drawerOpen={() => { setCreateDrawerOpen(true); }}
            setReload={setReload}
            reload={reload}
            radioValue={radioValue}
            setSnackbarOpts={setSnackbarOptions}
            setDtlData={setDtlData}
            dtlData={dtlData}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            setComponentLSKey={setComponentLSKey}
            clearFilter={clearFilter}
            appliedFilters={appliedFilters}
            setCreateDrawerOpen={setCreateDrawerOpen}
            setAlertType={setAlertType}
            getCLCList={getCLCList}
            machineModelData={CLCmachineModelData}
            filteredMachine={filteredMachine}
            setAppliedFilters={setAppliedFilters}
          />
        )
    }
  };

  const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);

  const renderScreen = (screenId: any) => {
    switch (screenId) {
      case 0:
        return (
          <NewEntry
            componentLSKey={componentLSKey}
            setCreateValue={setCreateValue}
            setDrawerHeader={setDrawerHeader}
            editTrail={(id: any) => editTrail(id)}
            deleteCLC={(id: any) => deleteCLC(id)}
            drawerClose={() => { setCreateDrawerOpen(false); }}
            drawerOpen={() => { setCreateDrawerOpen(true); }}
            setReload={setReload}
            reload={reload}
            radioValue={radioValue}
            setSnackbarOpts={setSnackbarOptions}
            setDtlData={setDtlData}
            dtlData={dtlData}
            selectedRow={selectedRow}
            setSelectedRow={() => setSelectedRow}
            setComponentLSKey={setComponentLSKey}
            machineModelData={machineModelData}
            setAdded={setAdded}
            getCLCList={getCLCList}
            filteredMachine={filteredMachine}
            getAllMachineModel={getAllMachineModel}
          />);
      case 1:
        return (
          <LifeCycleMapping
            machineModelData={CLCmachineModelData}
            machineSerialData={machineSerialData}
            getAllMachineSerial={getAllMachineSerial}
            filteredMachineTab2={filteredMachineTab2}
            filteredSerial={filteredSerial}
            setFilteredSerial={setFilteredSerial}
            setCurrTab={setCurrTab}
            drawerClose={() => { setCreateDrawerOpen(false); setSelectedRow([]); }}
            setReload={setReload}
            setDtlData={setDtlData}
            setDrawerHeader={setDrawerHeader}
            setCreateDrawerOpen={setCreateDrawerOpen}
            setAppliedFilters={setAppliedFilters}
            filteredMachineMapping={filteredMachineMapping}
            setCLCMappingList={setCLCMappingList}
            clcMappingList={clcMappingList}
            setFilteredMachineMapping={setFilteredMachineMapping}
            setMachineSerialData={setMachineSerialData}
            setSnackbarOpts={setSnackbarOptions}
            isEditing={isEditing}
            selectedRow={selectedRow}
            getOnboardList={getOnboardList}
          />);
      default:
        return (<NewEntry
          componentLSKey={componentLSKey}
          setCreateValue={setCreateValue}
          setDrawerHeader={setDrawerHeader}
          editTrail={(id: any) => editTrail(id)}
          deleteCLC={(id: any) => deleteCLC(id)}
          drawerClose={() => { setCreateDrawerOpen(false); }}
          drawerOpen={() => { setCreateDrawerOpen(true); }}
          setReload={setReload}
          reload={reload}
          radioValue={radioValue}
          setSnackbarOpts={setSnackbarOptions}
          setDtlData={setDtlData}
          dtlData={dtlData}
          selectedRow={selectedRow}
          setSelectedRow={() => setSelectedRow}
          setComponentLSKey={setComponentLSKey}
          machineModelData={machineModelData}
          setAdded={setAdded}
          getCLCList={getCLCList}
          filteredMachine={filteredMachine}
          getAllMachineModel={getAllMachineModel}
        />);
    }
  }

  useEffect(() => {
    if(createDrawerOpen && drawerHeader=="Close"){
      setReplacementDetails("")
      setReplacementDate("")
    }
  }, [drawerHeader,createDrawerOpen])

//   useEffect(() => {
//     const _details = JSON.parse(state[STORE_KEYS.USER_DTL]?.features[13]?.SubMenus);
//     const newPermissions = _details?.reduce((acc: Record<string, string>, i: any) => {
//       acc[i.SubMenuCode] = i.IsEditable;
//       return acc;
//     }, {});
//     setTabsPermissions(newPermissions || {});
    
//     const subMenu = _details?.map((i: any) => { return i.SubMenuName });
//     setTabs(subMenu);

// }, [state[STORE_KEYS.USER_DTL]])

useEffect(() => {
  const _details:any = [
    {
        "SubMenuName": "Model Component",
        "SubMenuCode": "ModComp",
        "IsEditable": "Y"
    },
    {
        "SubMenuName": "Onboarded Machines",
        "SubMenuCode": "OnboardMach",
        "IsEditable": "Y"
    },
    {
        "SubMenuName": "Work Orders",
        "SubMenuCode": "WO",
        "IsEditable": "Y"
    }
]
  const newPermissions = _details?.reduce((acc: Record<string, string>, i: any) => {
    acc[i.SubMenuCode] = i.IsEditable;
    return acc;
  }, {});
  setTabsPermissions(newPermissions || {});
  
  const subMenu = _details?.map((i: any) => { return i.SubMenuName });
  setTabs(subMenu);

}, [])

  return (
    <div className="dashboard clcMain">
      <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
      <Container maxWidth="xl" style={{ marginTop: '60px' }}>
        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <div style={{ flex: '80%', display: 'flex' }}>
            <div className="headinglabel" style={{ marginTop: '5px' }}>
              Component Life Cycle
            </div>
            <div
              style={{
                flex: '10%',
                marginLeft: '20px',
                // marginTop: '10px',
                display: 'flex',
                justifyContent: 'flex-end'
              }}
              className="dashboardDropdownDiv"
            >
              {tabsPermissions?.ModComp=="Y"?<DrawerDropdown click={getCreateValue} items={['Component Life Cycle Entry', 'Machine Components Mapping']} />:null}
            </div>
          </div>
        </div>
        <div style={{ marginTop: '35px', zIndex: '0' }}>
          <MapCustomTabs
            navigateTab={navigateTab}
            currTab={currTab}
            setCurrTab={setCurrTab}
            tabsArray={tabs}
            className="tabs"
            position="relative"
            moduleName='Component Life Cycle Tabs'
          // type="nonCurve"
          />
        </div>
      </Container>
      <RightDrawer
        componentLSKey={componentLSKey}
        handlerOpen={() => { }}
        handlerClose={() => { setCreateDrawerOpen(false); setDrawerHeader('Create'); setFilteredSerial(0); setFilteredMachineMapping(0); setCLCMappingList([]); setFilteredSerial(0); setMachineSerialData([]) }}
        status={createDrawerOpen}
        drawerHeader={`${drawerHeader} ${createValue}`}
        alertType={alertType}
        children={
          drawerHeader === 'Create' ? (
            createValue === 'Component Life Cycle Entry' ?
              renderScreen(0)
              : renderScreen(1)
          ) : (

            <>
              {
                drawerHeader === "View" ?
                  (
                    <ViewDetails
                      setSnackbarOpts={setSnackbarOptions}
                      setCurrTab={setCurrTab}
                      currTab={currTab}
                      drawerClose={() => { setCreateDrawerOpen(false); setSelectedRow([]); }}
                      setReload={setReload}
                      setDtlData={setDtlData}
                      dtlData={dtlData}
                      isEdit={false}
                      selectedRow={selectedRow}
                    />)
                  : drawerHeader === "View Work Order" ?
                    (
                      <WorkOrderViewDetails
                        setSnackbarOpts={setSnackbarOptions}
                        setCurrTab={setCurrTab}
                        drawerClose={() => { setCreateDrawerOpen(false); setSelectedRow([]); }}
                        setReload={setReload}
                        setDtlData={setDtlData}
                        dtlData={dtlData}
                        isEdit={false}
                        selectedRow={selectedRow}
                      />)
                    : drawerHeader === "Close" ?
                      (
                        <CloseWO
                          machineModelData={machineModelData}
                          machineSerialData={machineSerialData}
                          getAllMachineSerial={getAllMachineSerial}
                          filteredMachine={filteredMachine}
                          filteredSerial={filteredSerial}
                          setFilteredSerial={setFilteredSerial}
                          setCurrTab={setCurrTab}
                          drawerClose={() => { setCreateDrawerOpen(false); setSelectedRow([]); }}
                          setReload={setReload}
                          setDtlData={setDtlData}
                          setDrawerHeader={setDrawerHeader}
                          setCreateDrawerOpen={setCreateDrawerOpen}
                          replacementDetails={replacementDetails}
                          setReplacementDetails={setReplacementDetails}
                          replacementDate={replacementDate}
                          setReplacementDate={setReplacementDate}
                          closeWorkOrder={closeWorkOrder}
                        />)
                      : drawerHeader === "Filter" ?
                        (
                          null
                        )
                        : drawerHeader === "Work Orders Filter" ?
                          (
                            <WOFilter
                              machineModelData={machineModelData}
                              machineSerialData={machineSerialData}
                              getAllMachineSerial={getAllMachineSerial}
                              filteredMachine={filteredMachine}
                              filteredSerial={filteredSerial}
                              setFilteredSerial={setFilteredSerial}
                              setCurrTab={setCurrTab}
                              drawerClose={() => { setCreateDrawerOpen(false); setSelectedRow([]); }}
                              setReload={setReload}
                              setDtlData={setDtlData}
                              setDrawerHeader={setDrawerHeader}
                              setCreateDrawerOpen={setCreateDrawerOpen}
                              setWOAppliedFilters={setWOAppliedFilters}
                              workOrderFrom={workOrderFrom}
                              workOrderTo={workOrderTo}
                              setWorkOrderFrom={setWorkOrderFrom}
                              setWorkOrderTo={setWorkOrderTo}
                              workOrderStatusList={workOrderStatusList}
                              setWorkOrderStatusKey={setWorkOrderStatusKey}
                              workOrderStatsusKey={workOrderStatsusKey}
                            />)

                          : drawerHeader === "Edit" ?
                            (
                              <EditEntry
                                setSnackbarOpts={setSnackbarOptions}
                                setCurrTab={setCurrTab}
                                drawerClose={() => { setCreateDrawerOpen(false); setSelectedRow([]); }}
                                setReload={setReload}
                                setDtlData={setDtlData}
                                dtlData={dtlData}
                                isEdit={false}
                                selectedRow={selectedRow}
                                getCLCList={getCLCList}
                                filteredMachine={filteredMachine}
                              />
                            )
                            : drawerHeader === "Update Status" ?
                              (
                                <ReplacementAction
                                  setSnackbarOpts={setSnackbarOptions}
                                  setCurrTab={setCurrTab}
                                  drawerClose={() => { setCreateDrawerOpen(false); setSelectedRow([]); }}
                                  setReload={setReload}
                                  setDtlData={setDtlData}
                                  dtlData={dtlData}
                                  isEdit={false}
                                  selectedRow={selectedRow}
                                  getOnboardList={getOnboardList}
                                  filteredMachineTab2={filteredMachineTab2}
                                />
                              ) : null
              }
            </>)
        }
      />

    </div>
  );
};

export default SystemConfiguration;
