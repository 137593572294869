import { useState, useEffect } from 'react';
import { Container } from '@mui/material';
import AcgStepper from '../../../../atoms/Stepper';
import ClientInfo from './ClientInfo';
import AssignFeatures from './AssignFeatures';
import '../../FeatureManagement/CreateReport/index.scss';
import { ACTION_CODES, STORE_KEYS } from '../../../../constants/apiConstants';
import { useDispatch } from 'react-redux';
import useGetState from '../../../../utils/hooks/useGetState';
import { executeACGAction, resetErr, updateScreenIdentifiers } from '../../../../store/slice';

type Props = {
    drawerClose: Function;
    setCurrTab: Function;
    setReload: Function;
    setSnackbarOpts: Function;
    isEdit: boolean;
    dtlData: any;
    subDtlData?: any;
    setSubDtlData?: any;
};
// const options = {};
const CreateClient = (props: Props) => {
    const { drawerClose, setCurrTab, setReload = () => {}, setSnackbarOpts = () => {}, isEdit, dtlData, subDtlData, setSubDtlData } = props;
    const [step, setStep] = useState(0);
    const [stepState, setStepState] = useState({ info: true, assign: false });
    const [step1Vals, setStep1Vals] = useState<any>({});
    const [close, setClose] = useState<boolean>(false);
    const [selectedRow, setSelectedRow] = useState<any>([]);
    const state = useGetState();
    const dispatch = useDispatch();
    useEffect(() => {
        if (step === 0) {
            setStepState({ info: true, assign: false });
        } else {
            setStepState({ info: false, assign: true });
        }
    }, [step]);

    useEffect(() => {
        if(close && selectedRow.length > 0) {
            let reqObj = {
                'CustomerKey' : selectedRow?.CustomerKey,
                'FeatureKey': selectedRow?.FeatureKey
            }
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'POST',
                        urlPath: `${ACTION_CODES.GET_CLNT_SUB_FEATURES}`,
                        reqObj: reqObj
                    },
                    uniqueScreenIdentifier: { cltSubFtrsRecd: true },
                    storeKey: STORE_KEYS.CLIENT_SUB_FTRS
                })
            );
        }
    },[close])

    useEffect(() => {
        if (state?.[STORE_KEYS.CLIENT_SUB_FTRS]?.cltSubFtrsRecd) {
            const updatedList = state?.[STORE_KEYS.CLIENT_SUB_FTRS]?.body?.data?.map((ele: any) => {
                return { ...ele, id: ele?.SubFeatureKey + ele?.ModuleKey };
            });
            setSubDtlData(updatedList);
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.CLIENT_SUB_FTRS,
                    uniqueScreenIdentifier: {
                        cltSubFtrsRecd: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.CLIENT_SUB_FTRS]?.cltSubFtrsRecd]);

    return (
        <Container className="createClient">
            <div style={{ marginTop: '37px' }}>
                <AcgStepper
                    steps={[
                        { label: 'Client Info', active: stepState?.info },
                        { label: 'Assign Features', active: stepState?.assign }
                    ]}
                />
            </div>
            {step == 0 ? (
                <ClientInfo
                    drawerClose={drawerClose}
                    setCurrTab={setCurrTab}
                    setReload={setReload}
                    setSnackbarOpts={setSnackbarOpts}
                    setStep={setStep}
                    step1Vals={step1Vals}
                    setStep1Vals={setStep1Vals}
                    isEdit={isEdit}
                    dtlData={dtlData}
                />
            ) : (
                <AssignFeatures
                    drawerClose={drawerClose}
                    setCurrTab={setCurrTab}
                    setReload={setReload}
                    setSnackbarOpts={setSnackbarOpts}
                    setStep={setStep}
                    step1Vals={step1Vals}
                    setStep1Vals={setStep1Vals}
                    isEdit={isEdit}
                    dtlData={dtlData}
                    subDtlData={subDtlData}
                    setSubDtlData={setSubDtlData}
                    close={close}
                    setClose={setClose}
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectedRow}
                />
            )}
        </Container>
    );
};

export default CreateClient;
