import Drawer from '@mui/material/Drawer';
import * as Yup from 'yup';
import { Container } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import Label from '../../../../atoms/Label/index';
import ACGButton from '../../../../atoms/Button';
import { useEffect, useRef, useState } from 'react';
import CustomDatePicker from '../../../../atoms/CustomDatePicker';
import './index.scss';
import DropDown from '../../../../atoms/Dropdown';
import { VALIDATION_YUPSCHEMA } from './ValidationSchema';
import { executeACGAction, resetStoreKey, updateScreenIdentifiers } from '../../../../store/slice';
import { ACTION_CODES, STORE_KEYS } from '../../../../constants/apiConstants';
import useGetState from '../../../../utils/hooks/useGetState';
import { renameObjKeys } from '../../../Login/helper';
import { useDispatch } from 'react-redux';
import dateFormat from 'dateformat';
//import GetPDFFile from './AlarmAnalysysPDF';
import Box from '@mui/material/Box';
import { pdf } from '@react-pdf/renderer';

type Props = {
    status: any;
    handlerOpen: any;
    handlerClose: any;
    data?: any;
    drawerHeader?: any;
    block?: boolean;
};

type AlarmParams = {
    dropdownModel: string | '';
    dropdownPlant: string | '';
    dateFromSelect: string | '';
    dateToSelect: string | '';
    // email: string | '';
};

type SummaryParams = {
    machineKey: String;
    fromDate: String;
    toDate: String;
};

export default function AlarmAnalysisDrawer(props: Props) {
    const { status, handlerClose } = props;

    const drawerClose = () => {
        handlerClose();
    };

    const [alarmParams, setAlarmParams] = useState<AlarmParams>({
        dropdownModel: '',
        dropdownPlant: '',
        dateFromSelect: '',
        dateToSelect: ''
    });

    const [validationSchema, setValidationSchema] = useState({});

    const [plantsList, setPlantsList] = useState<any>();
    const [selectedPlant, setSelectedPlant] = useState<any>();

    const [machineModels, setMachineModels] = useState<any>();
    const [selectedMachineModel, setSelectedMachineModel] = useState<any>();

    const [pdfProps, setPdfProps] = useState<any>();
    const [isLoading, setIsLoading] = useState(false);
    const state = useGetState();
    const dispatch = useDispatch();
    const formRef = useRef<any>(null);

    const REPORT_CODE = 'ALMANLYS';

    const handleSubmit = async (values: any) => {
        const { dropdownModel, dropdownPlant, dateFromSelect, dateToSelect } = values;

        if (dropdownModel && dateFromSelect && dateToSelect && dropdownPlant) {
            const fromDate = dateFormat(values.dateFromSelect, 'yyyy-mm-dd');
            const toDate = dateFormat(values.dateToSelect, 'yyyy-mm-dd');
            const plant = plantsList.find((item: any) => item.code == values.dropdownPlant);
            const model = machineModels.find((item: any) => item.code == values.dropdownModel);

            const params: SummaryParams = {
                fromDate: fromDate,
                toDate: toDate,
                machineKey: model.code
            };

            const summaryBundle = {
                payload: {
                    urlPath: ACTION_CODES.DN_ALMANYS_BQS_MQR_SUMMARY,
                    requestType: 'POST',
                    reqObj: { fromDate: params.fromDate, toDate: params.toDate, machineKey: params.machineKey }
                },
                storeKey: STORE_KEYS.DN_ALMANYS_BQS_MQR_SUMMARY
            };

            const descSummaryBundle = {
                payload: {
                    urlPath: ACTION_CODES.DN_ALMANYS_BQS_MQR_DESC_SUMMARY,
                    requestType: 'POST',
                    reqObj: { fromDate: params.fromDate, toDate: params.toDate, machineKey: params.machineKey }
                },
                storeKey: STORE_KEYS.DN_ALMANYS_BQS_MQR_DESC_SUMMARY
            };

            const dateWisebundle = {
                payload: {
                    urlPath: ACTION_CODES.DN_ALMANYS_BQS_MQR_DATE_SUMMARY,
                    requestType: 'POST',
                    reqObj: { fromDate: params.fromDate, toDate: params.toDate, machineKey: params.machineKey }
                },
                storeKey: STORE_KEYS.DN_ALMANYS_BQS_MQR_DATE_SUMMARY
            };

            const dateSummary = dispatch(executeACGAction(dateWisebundle));

            const descSummary = dispatch(executeACGAction(descSummaryBundle));

            const summary = dispatch(executeACGAction(summaryBundle));

            await Promise.all([summary, descSummary, dateSummary]);

            const summaryData = state?.[STORE_KEYS.DN_ALMANYS_BQS_MQR_SUMMARY]?.body?.data;
            const descSummaryData = state?.[STORE_KEYS.DN_ALMANYS_BQS_MQR_DESC_SUMMARY]?.body?.data;
            const dateSummaryData = state?.[STORE_KEYS.DN_ALMANYS_BQS_MQR_DATE_SUMMARY]?.body?.data;
            const userDetail = state.userDtl;

            if (summaryData && descSummaryData && dateSummaryData) {
                const PDFProps = {
                    KPIDataProp: summaryData,
                    MonthlyDataProp: descSummaryData,
                    DaywiseDataProp: dateSummaryData,
                    machineKeyProp: params.machineKey,
                    data: {
                        startDate: params.fromDate,
                        endDate: params.toDate,
                        PlantName: plant.name,
                        MachineModelName: model.name,
                        MachineSerialNo: model.name, //replace serial no
                        CustomerName: userDetail.CustomerName,
                        MachineKey: model.code
                    }
                };
                setPdfProps(PDFProps);
                //  const blob = await pdf(<GetPDFFile {...PDFProps} />).toBlob();
            }
        } else {
            alert('Please select all the fields or bad request');
        }
    };

    const getMachineModels = (plantKey: String) => {
        setSelectedPlant(plantKey);
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: ACTION_CODES.DN_ALMANLS_REPORT_MACHINE_MODEL_DATA,
                    reqObj: {
                        reportCode: REPORT_CODE,
                        plantKey: plantKey
                    }
                },
                uniqueScreenIdentifier: { modelsLoaded: true },
                storeKey: STORE_KEYS.DN_ALMANLS_REPORT_MACHINE_MODEL_DATA
            })
        );
    };

    useEffect(() => {
        if (state?.[STORE_KEYS.DN_ALMANLS_REPORT_MACHINE_MODEL_DATA]?.modelsLoaded) {
            const data = state?.[STORE_KEYS.DN_ALMANLS_REPORT_MACHINE_MODEL_DATA]?.body?.data;
            const models = data?.map((item: any) => {
                const newVal = renameObjKeys(item, { MachineModelKey: 'code', MachineModelName: 'name' });
                return newVal;
            });
            setMachineModels(models);
            dispatch(resetStoreKey({ storeKey: STORE_KEYS.DN_ALMANLS_REPORT_MACHINE_MODEL_DATA }));
        }
    }, [state?.[STORE_KEYS.DN_ALMANLS_REPORT_MACHINE_MODEL_DATA]?.modelsLoaded]);

    useEffect(() => {
        if (state?.[STORE_KEYS.DN_REPORT_PLANTS_DATA]?.body?.data) {
            const data = state?.[STORE_KEYS.DN_REPORT_PLANTS_DATA]?.body?.data;
            const plants = data?.map((item: any) => {
                const newVal = renameObjKeys(item, { PlantKey: 'code', PlantName: 'name' });
                return newVal;
            });
            setPlantsList(plants);
            dispatch(resetStoreKey({ storeKey: STORE_KEYS.DN_REPORT_PLANTS_DATA }));
        }
    }, [state?.[STORE_KEYS.DN_REPORT_PLANTS_DATA]?.body?.data]);

    useEffect(() => {
        if (status) {
            setValidationSchema(Yup.object().shape(VALIDATION_YUPSCHEMA));
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'POST',
                        urlPath: ACTION_CODES.DN_REPORT_PLANTS_DATA,
                        reqObj: {
                            reportCode: REPORT_CODE
                        }
                    },
                    storeKey: STORE_KEYS.DN_REPORT_PLANTS_DATA
                })
            );
        }
    }, [status]);

    const renderButtons = () => {
        return (
            <div className={'btns noWrap'}>
                <ACGButton
                    name={'Reset'}
                    handleClick={() => {}}
                    secondary={true}
                    className={'first-btn'}
                    type="button"
                    variant="contained"
                />
                <ACGButton
                    name={'Submit'}
                    handleClick={() => {}}
                    type="submit"
                    className="second-btn"
                    variant="contained"
                    isLoading = {isLoading}
                />
            </div>
        );
    };

    return (
        <Drawer anchor={'right'} open={status} onClose={drawerClose} style={{ zIndex: '9999' }} className='allreports'>
            <Box role="presentation" className={'standardBox1'}>
                <div className="baseDiv">
                    <div
                        onClick={() => {
                            drawerClose();
                        }}
                        style={{
                            border: '1px solid #5D97F6',
                            borderRadius: '50px',
                            height: '25px',
                            width: '25px',
                            position: 'absolute',
                            left: '20px',
                            top: '16px',
                            cursor: 'pointer'
                        }}
                    >
                        <span
                            style={{
                                position: 'relative',
                                fontSize: '12px',
                                color: '#5D97F6',
                                left: '35%',
                                top: '-3%'
                            }}
                        >
                            X
                        </span>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                            width: '100%'
                            // padding:'10px'
                        }}
                    >
                        <div style={{ display: 'block', marginLeft: '45px' }}>
                            <h6
                                style={{
                                    color: '#DADCEE',
                                    fontSize: '16px',
                                    fontFamily: 'Montserrat'
                                    // marginTop: '-7px'
                                }}
                            >
                                Alarm Analysis
                            </h6>
                        </div>
                    </div>
                </div>
                <Container className={'alm-dn-report-form1'}>
                    {/* <div className={'dn-rp-heading-label'}>Alarm Analysis</div>
                <div
                    onClick={() => {
                        drawerClose();
                    }}
                    style={{
                        border: '1px solid #5D97F6',
                        borderRadius: '50px',
                        height: '25px',
                        width: '25px',
                        position: 'absolute',
                        left: '5px',
                        top: '20px',
                        cursor: 'pointer'
                    }}
                >
                    <span style={{ position: 'relative', fontSize: '12px', color: '#5D97F6', left: '35%', top: '-3%' }}>
                        X
                    </span>
                </div> */}
                    <Formik
                        initialValues={alarmParams}
                        validationSchema={validationSchema}
                        onSubmit={(values: any) => {
                            handleSubmit(values);
                        }}
                        validateOnBlur={false}
                        validateOnChange={false}
                        innerRef={formRef}
                    >
                        {(formikprops: FormikProps<any>) => {
                            const { values, handleChange, handleBlur, isValid, errors, touched, setFieldValue } =
                                formikprops;
                            return (
                                <Form className="detailForm">
                                    <Label label="From Date" className={'labelDiv'} />
                                    <CustomDatePicker
                                        name="dateFromSelect"
                                        maxDate={new Date()}
                                        minDate={new Date().setDate(-30)}
                                        value={values.dateFromSelect}
                                        className="inputDiv"
                                        onChange={(val: any) => {
                                            setFieldValue('dateFromSelect', val);
                                        }}
                                        inputFormat="dd/MM/yyyy"
                                        placeholder="Select From Date"
                                        helperText={
                                            errors.dateFromSelect && touched.dateFromSelect ? errors.dateFromSelect : ''
                                        }
                                    />

                                    <Label label="To Date" className={'labelDiv'} />
                                    <CustomDatePicker
                                        name="dateToSelect"
                                        maxDate={new Date()}
                                        minDate={new Date().setDate(-30)}
                                        value={values.dateToSelect}
                                        className="inputDiv"
                                        onChange={(val: any) => {
                                            setFieldValue('dateToSelect', val);
                                        }}
                                        inputFormat="dd/MM/yyyy"
                                        placeholder="Select To Date"
                                        helperText={
                                            errors.dateToSelect && touched.dateToSelect ? errors.dateToSelect : ''
                                        }
                                    />

                                    <Label label="Plants" className={'labelDiv'} />
                                    <DropDown
                                        onChange={(plantEvent: any) => {
                                            handleChange(plantEvent);
                                            console.log(values);
                                            const plant = plantsList.find(
                                                (item: any) => item.code === plantEvent.target.value
                                            );
                                            if (plant) {
                                                getMachineModels(plant.code.toString());
                                            }
                                        }}
                                        className="inputDiv"
                                        name="dropdownPlant"
                                        items={plantsList}
                                        placeHolder="Select Plants"
                                        value={values.dropdownPlant}
                                        helperText={
                                            errors.dropdownPlant && touched.dropdownPlant ? errors.dropdownPlant : ''
                                        }
                                    />

                                    <Label label="Machine Model" className={'labelDiv'} />
                                    <DropDown
                                        onChange={(modelEvent: any) => {
                                            handleChange(modelEvent);
                                        }}
                                        className="inputDiv"
                                        name="dropdownModel"
                                        items={machineModels}
                                        placeHolder="Select Model"
                                        value={values.dropdownModel}
                                        helperText={
                                            errors.dropdownModel && touched.dropdownModel ? errors.dropdownModel : ''
                                        }
                                    />
                                    {renderButtons()}
                                </Form>
                            );
                        }}
                    </Formik>
                </Container>
            </Box>
        </Drawer>
    );
}
