import { Container, Card } from '@mui/material';
import styles from './index.module.css'; // Import the CSS module
import Axios from 'axios';
import { isEqual } from 'lodash';
import { ArcElement, Chart, registerables } from "chart.js";
import { useEffect, useState, useRef } from 'react';
import FormControl from '@mui/material/FormControl';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import DownArrow from '../../../../assets/down-arrow.svg';
import { ACTION_CODES, BASEURL, STORE_KEYS } from '../../../../app/constants/apiConstants';
import useGetState from '../../../../app/utils/hooks/useGetState';
import BackArrowIcon from '../../../../assets/images/BackArrowIcon.svg';
import CustomDatePicker from '../../../atoms/CustomDatePicker';
import DropDown from '../../../atoms/Dropdown';
import SnackbarAlert from '../../../atoms/Snackbar';
import { formatOnlyDate } from '../../../utils/formatTime';
import '../../Admin/ClientManagement/index.scss';
import Stack2 from "./GraphOEEKPI/peretochart";
import Stack1 from "./GraphOEEKPI/stackedLine";
import Stack3 from "./GraphOEEKPI/stackedLine2";
import { trackErrorsInNewRelic, trackPageActionLog } from '../../../utils/newRelicFuncations';
import { calculateDateDifference, lastTwoMonthsDaterange, startDateOfMonth } from '../../../utils/helper';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const DropDownIcon = (prop: any) => (
    <img style={{ marginRight: '5px', marginTop: '-2px' }} src={DownArrow} {...prop} />
);

Chart.register(...registerables);
Chart.register(ArcElement);
const tabs = ['Machine List'];
const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    }
};
const SystemConfiguration = (props: any) => {
    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const date = new Date();
    date.setDate(date.getDate() - 14)
    const state = useGetState();
    const token = state?.[STORE_KEYS.USER_DTL].token;
    const [apq, setAPQ] = useState<any>([]);
    const [alarmTrends, setAlarmTrends] = useState<any>([]);
    const [operatorDT, setOperatorDT] = useState<any>([]);
    useEffect(() => {
        if (props.operators.length > 0) {
            setOperatorDT([]);
            setAlarmTrends([]);
            const _opr = props.operators.map((i: any) => i.code);
            if (!props.isRedirect) props.setSelectedMultipleOperators(_opr);
            props.setIsRedirect(false);
        }
    }, [props.operators])

    useEffect(() => {

        if (props.selectedMultipleOperators.length > 0) {
            fetchKPIData(props.selectedMultipleOperators);
        } else {
            setAPQ([]);
            setOperatorDT([]);
            setAlarmTrends([]);
        }
    }, [props.selectedMultipleOperators]);

    const handleChangeUpdateModel = () => {
        setOperatorDT([]);;
        setAlarmTrends([]);
        setAPQ([]);
    };

    const handleChangeUpdateMachine = () => {
        setOperatorDT([]);;
        setAlarmTrends([]);
        setAPQ([]);
    };

    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);

    const fetchKPIData = async (opr: any) => {

        const payload = {
            operators: opr.toString(),
            from: formatOnlyDate(props.inDate),
            to: formatOnlyDate(props.endDate)
        };
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.API_OEE_PLANT_VISIBILTY_OPERATOR_APQ,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;
                    const tmpTableData = result[0][0];
                    setAPQ(tmpTableData);
                }
            })
            .catch((error) => {
                trackErrorsInNewRelic({ error: error })

                return {
                    status: error
                };
            });
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.API_OEE_PLANT_VISIBILTY_OPERATOR_ALARMCOUNT,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;
                    const tmpTableData = result[0][0];
                    setAlarmTrends(tmpTableData);
                }
            })
            .catch((error) => {
                trackErrorsInNewRelic({ error: error })

                return {
                    status: error
                };
            });
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.API_OEE_PLANT_VISIBILTY_OPERATOR_DT,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;
                    const tmpTableData = result[0][0];
                    setOperatorDT(tmpTableData);
                }
            })
            .catch((error) => {
                trackErrorsInNewRelic({ error: error })

                return {
                    status: error
                };
            });
    }

    return (
        <div className="modelComponents">
            <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
            <Container
                maxWidth="xl"
                className={styles.cardContainer} // Use the class from CSS module
                style={{
                    paddingLeft: '3px',
                    paddingRight: '3px',
                }}
            >
                <div className='filterdiv' style={{ background: "#24263a", borderRadius: "0px" }}>
                    <div className='width_filter'>
                        <div className='lableTitle'>From</div>
                        <CustomDatePicker
                            name="dateFromSelect"
                            value={props.inDate}
                            maxDate={new Date()}
                            className="inputDiv"
                            onChange={(val: any) => {
                                props.setInDate(val);
                                props.setSelectedMultipleDateMachine('');
                                trackPageActionLog({
                                    actionName:'OEE-Dashboard',
                                    action:'From date changed',
                                    additionalInfo:`From Date changed to ${val}` ,
                                    LoggedInUser:state[STORE_KEYS.USER_DTL]?.EmailId,
                                })
                            }}
                            inputFormat="dd/MM/yyyy"
                            placeholder="Select From Date"
                            minDate={new Date("01-01-2023")}

                        />
                    </div>
                    <div className='width_filter'>
                        <div className='lableTitle'>To</div>
                        <CustomDatePicker
                            name="dateFromSelect"
                            value={props.endDate}
                            minDate={props.inDate}
                            maxDate={new Date()}
                            className="inputDiv"
                            onChange={(val: any) => {
                                props.setEndDate(val);
                                props.setSelectedMultipleDateMachine('')
                                trackPageActionLog({
                                    actionName:'OEE-Dashboard',
                                    action:'To date changed',
                                    additionalInfo:`To Date changed to ${val}` ,
                                    LoggedInUser:state[STORE_KEYS.USER_DTL]?.EmailId,
                                })
                            }}
                            inputFormat="dd/MM/yyyy"
                            placeholder="Select End Date"
                        />
                    </div>
                    <div className='width_filter'>
                        <div className='lableTitle'>Select Machine Model</div>
                        <DropDown
                            onChange={(e) => { props.handleMachineModel(); handleChangeUpdateModel() }}
                            className="inputDiv dropdownbx"
                            name="dropdownModel"
                            items={props.machineModels}
                            placeHolder="Select Machine Model"
                            value={props.selectedMachineModel}
                        />
                    </div>
                    <div className='width_filter'>
                        <div className='lableTitle'>Select Machine</div>
                        <DropDown
                            onChange={(e: any) => { props.handleChangeMultipleDate(e.target.value); handleChangeUpdateMachine() }}
                            className="inputDiv dropdownbx longtxt"
                            name="dropdownModel"
                            items={props.multipleDateMachines}
                            placeHolder="Select Machine Serial No."
                            value={props.selectedMultipleDateMachine}
                        />
                    </div>
                    <div className='width_filter widthIncrease'>
                        <div className='lableTitle'>Select Operator</div>
                        <FormControl sx={{ width: '100%' }}>
                            <Select
                                IconComponent={DropDownIcon}
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                className='chekboxmulti'
                                multiple
                                disabled={props.selectedMultipleDateMachine === "" ? true : false}
                                value={props.selectedMultipleOperators}
                                sx={{ marginBottom: "10px", backgroundColor: "#1d1e2c", borderRadius: 3, marginTop: '10px', color: "#b1c2df" }}
                                placeholder='Please select machines'
                                renderValue={(selected: any) => {
                                    const filteredOperators = props.selectedMultipleOperators.filter((opr: any) =>
                                        selected.some((item: any) => item === opr)
                                    );
                                    return filteredOperators.map((x: any) => {
                                        return (props.operators.find((i: any) => x === i.code))?.name
                                    }).join(', ')
                                }
                                }
                                MenuProps={MenuProps}

                            >
                                {props.operators?.map((i: any) => (
                                    <MenuItem value={i} onClick={() => props.handleChangeOperators(i.code)} className='ulSelectProduct'>
                                        <Checkbox
                                            checked={props.selectedMultipleOperators.includes(i.code)}

                                        />
                                        <ListItemText primary={i.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>

                <div className='OEEProdGraph batchWiseGraph'>
                    <div className='leftgraphPD'>
                        <h3 className='titleGraph font14'>Operatorwise A,P,Q</h3>
                        <div className='blockcls'>
                            <div className='innerKPIs'>
                                <div className='valuesKPIs' style={{ color: "#00ffFF" }}>{apq[0]?.OverAllOEE?.toFixed(2)}</div>
                                <div className='textKPIs'>Overall OEE %</div>
                            </div>
                            <div className='innerKPIs'>
                                <div className='valuesKPIs' style={{ color: "#ffc107" }}>{apq[0]?.OverAllAvailability?.toFixed(2)}</div>
                                <div className='textKPIs'>Overall Availability %</div>
                            </div>
                            <div className='innerKPIs'>
                                <div className='valuesKPIs' style={{ color: "#0d6efd" }}>{apq[0]?.OverAllPerformance?.toFixed(2)}</div>
                                <div className='textKPIs'>Overall Performance %</div>
                            </div>
                            <div className='innerKPIs'>
                                <div className='valuesKPIs' style={{ color: "#198754" }}>{apq[0]?.OverAllQuality?.toFixed(2)}</div>
                                <div className='textKPIs'>Overall Quality %</div>
                            </div>
                        </div>
                        <div>
                            <Stack1 apq={apq} />
                        </div>
                    </div>
                </div>
                <div className='graphcontainer'>
                    <div className='leftgraph'>
                        <h3 className='titleGraph font14'>Alarm Counts By Operator</h3>
                        <div>
                            <Stack2 alarmTrends={alarmTrends} />
                        </div>
                    </div>
                    <div className='rightgraph'>
                        <h3 className='titleGraph font14'>Avg. Downtime minutes by Operator</h3>
                        <div>
                            <Stack3 operatorDT={operatorDT} />
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default SystemConfiguration;
