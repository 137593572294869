import { SetStateAction, useEffect, useRef, useState } from 'react';
import {
    Checkbox,
    Container,
    FormControl,
    Box,
    Grid,
    Divider,
    Typography
} from '@mui/material';
import Edit from '../../../../../assets/images/edit.svg';
import Attachments from '../../../../atoms/Attachments';
import { fetchBlobImage } from '../../../../utils/helper';
import { convertBlobFileToBase64 } from '../../../../utils/helper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import DownArrow from '../../../../../assets/down-arrow.svg';
import useGetState from '../../../../utils/hooks/useGetState';
import { useDispatch } from 'react-redux';
import CustomDatePicker from '../../../../atoms/CustomDatePicker';
import { executeACGAction, resetErr, updateScreenIdentifiers } from '../../../../store/slice';
import ACGButton from '../../../../atoms/Button';
import Label from '../../../../atoms/Label/index';
import TextInput from '../../../../atoms/TextInput';
import Axios from 'axios';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../../constants/apiConstants';
import { Draggable } from "react-drag-reorder";
import PlusIcon from '../../../../../assets/images/PlusIcon.svg';
import BinIcon from '../../../../../assets/images/bin.svg';
import { object } from 'yup';
import { trackButtonEvent, trackErrorsInNewRelic } from '../../../../utils/newRelicFuncations';

type ComponentProps = {
    drawerClose: Function;
    setCurrTab: Function;
    setReload: Function;
    setSnackbarOpts: Function;
    dtlData: any;
    selectedRow: any;
    isEdit: boolean;
    setDtlData: Function;
    setSelectedRow: Function;
    preventiveMaintenanceKey: any;
    setPreventiveMaintenanceKey: Function;
    getPreventiveMaintenanceWorkOrderList: Function;
};

const CreateModule = (props: ComponentProps) => {
    const { drawerClose, setCurrTab, setReload = () => { }, setSnackbarOpts = () => { }, dtlData, isEdit, setDtlData = () => { }, selectedRow, setSelectedRow = () => { }, preventiveMaintenanceKey, setPreventiveMaintenanceKey = () => { }, getPreventiveMaintenanceWorkOrderList = () => { } } = props;
    const [checklistFields, setChecklistFields] = useState<any[]>([]);
    const [editImage, setEditImage] = useState(false);
    const [fName, setFname] = useState('');
    const [formError, setFormError] = useState({ modelCode: '', modelName: '', paramsUpload: '', modelImage: '', hasError: false });
    const [selectedModelImage, setSelectedModelImage] = useState<any>([]);
    const [rearrange, setRearrange] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const state = useGetState();
    const [isLoading, setIsLoading] = useState(false);
    const token = state?.[STORE_KEYS.USER_DTL].token;
    const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;
    const dispatch = useDispatch();
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250
            }
        }
    };

    const options = {
        AD_SUCCESS: {
            message: `Inspection action checklist updated successfully!`,
            open: true,
            type: 'success'
        },
        AD_FAIL: {
            message: `No action taken!`,
            open: true,
            type: 'remark'
        }
    };

    const handleFormChange = (val: any, index: any) => {
        let data: any = [...checklistFields];
        if (!val.target.checked) {
            const key = data[index]?.MaintPREVReqInspChkLstKey;
            data.splice(index, 1, { "MaintPREVReqInspChkLstKey": key, "InspectionDate": null, "TaskDesc": data[index]["TaskDesc"], "InspRemark": "" })
            data.push()
            setChecklistFields(data);
        } else {
            const key = data[index]?.MaintPREVReqInspChkLstKey;
            data.splice(index, 1, { "MaintPREVReqInspChkLstKey": key, "InspectionDate": new Date(), "TaskDesc": data[index]["TaskDesc"], "InspRemark": "" })
            data.push()
            setChecklistFields(data);
        }
    }

    const handleFormChangeDate = (val: any, index: any) => {
        let data: any = [...checklistFields];
        const key = data[index]?.MaintPREVReqInspChkLstKey;
        data.splice(index, 1, { "MaintPREVReqInspChkLstKey": key, "InspectionDate": val, "TaskDesc": data[index]["TaskDesc"], "InspRemark": data[index]["InspRemark"] })
        data.push()
        setChecklistFields(data);
    }

    const handleFormChangeRemarks = (val: any, index: any) => {
        let data: any = [...checklistFields];
        const key = data[index]?.MaintPREVReqInspChkLstKey;
        data.splice(index, 1, { "MaintPREVReqInspChkLstKey": key, "InspectionDate": data[index]["InspectionDate"], "TaskDesc": data[index]["TaskDesc"], "InspRemark": val.target.value })
        data.push()
        setChecklistFields(data);
    }

    const removeFields = (index: any) => {
        let data = [...checklistFields];
        data.splice(index, 1)
        setChecklistFields(data);
    }
    const addFields = (index: any) => {
        if (checklistFields.length < 20) {
            let object = {
                TaskDesc: ''
            }
            setChecklistFields([...checklistFields, object]);
        }
    }

    const renderButtons = () => {
        return (
            <div className={'btns noWrap'}>
                <ACGButton
                    name={'Cancel'}
                    handleClick={drawerClose}
                    secondary={true}
                    className={'first-btn'}
                    type="button"
                    variant="contained"
                />
                <ACGButton
                    name={'Submit'}
                    handleClick={() => { handleSubmit() }}
                    type="submit"
                    className="second-btn"
                    variant="contained"
                    isLoading = {isLoading}
                // disabled={isDisabled ? true : false}
                />
            </div>
        );
    };

    const handleSubmit = async () => {
        trackButtonEvent("Machine Monitoring","Edit Preventive Maintenance Checklist","Edit Checklist Submit",state[STORE_KEYS.USER_DTL]?.EmailId)
        setIsLoading(true);
        let strImage;
        if (selectedModelImage.length > 0 && typeof (selectedModelImage) !== "string") {
            const base64Image = await convertBlobFileToBase64(selectedModelImage[0]);
            strImage = base64Image?.replace(/^data:image\/[a-z]+;base64,/, "");
        }
        const hasNullValues = checklistFields.filter((obj: any) => Object.values(obj).includes(null));
        if (hasNullValues.length === checklistFields.length) {
            setReload(true);
            setCurrTab(0);
            setSnackbarOpts(options.AD_FAIL);
            drawerClose();
            setPreventiveMaintenanceKey('');
        } else {
            const result = checklistFields.map(({ TaskDesc, MaintPREVWorkOrderKey, ...rest }) => ({ ...rest }));
            const actionList = {
                "MaintPREVWorkOrderKey": preventiveMaintenanceKey,
                "InspChecklist": result
            }
            const bundle = {
                payload: {
                    urlPath: ACTION_CODES.UPDATE_PREVENTIVE_INSPECTION_LIST_MAINTENANCE,
                    requestType: 'POST',
                    reqObj: {
                        userId: loggedInUser,
                        actionList: JSON.stringify(actionList),
                        image: strImage ? strImage : null,
                        fname: checklistFields[0]?.ImagePath ? checklistFields[0]?.ImagePath : null,
                        updateRequire: fName === selectedModelImage ? false : true
                    }
                },
                uniqueScreenIdentifier: { preventiveMaintmoduleInspectionListUpdated: true },
                storeKey: STORE_KEYS.UPDATE_PREVENTIVE_INSPECTION_LIST_MAINTENANCE
            };
            dispatch(executeACGAction(bundle));
        }
    };

    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };
        setSnackbarOpts(snackbarError);
    };

    useEffect(() => {
        if (state?.[STORE_KEYS.UPDATE_PREVENTIVE_INSPECTION_LIST_MAINTENANCE]?.preventiveMaintmoduleInspectionListUpdated) {
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.UPDATE_PREVENTIVE_INSPECTION_LIST_MAINTENANCE,
                    uniqueScreenIdentifier: {
                        preventiveMaintmoduleInspectionListUpdated: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.UPDATE_PREVENTIVE_INSPECTION_LIST_MAINTENANCE]?.data]);

    useEffect(() => {
        if (state?.[STORE_KEYS.UPDATE_PREVENTIVE_INSPECTION_LIST_MAINTENANCE]?.preventiveMaintmoduleInspectionListUpdated) {
            if (state?.[STORE_KEYS.UPDATE_PREVENTIVE_INSPECTION_LIST_MAINTENANCE]?.status === "Success") {
                setReload(true);
                setCurrTab(0);
                setSnackbarOpts(options.AD_SUCCESS);
                drawerClose();
                getPreventiveMaintenanceWorkOrderList();
                setPreventiveMaintenanceKey('');
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.UPDATE_PREVENTIVE_INSPECTION_LIST_MAINTENANCE,
                    uniqueScreenIdentifier: {
                        preventiveMaintmoduleInspectionListUpdated: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.UPDATE_PREVENTIVE_INSPECTION_LIST_MAINTENANCE]?.preventiveMaintmoduleInspectionListUpdated]);

    useEffect(() => {
        if (state?.err) {
            handleSnackbarError(state?.err);
            dispatch(resetErr());
        }
    }, [state?.err]);

    useEffect(() => {
        if (preventiveMaintenanceKey) {
            (async function () {
                const payload = {
                    userId: loggedInUser,
                    orderKey: preventiveMaintenanceKey
                }
                await Axios({
                    url: BASEURL + '/' + ACTION_CODES.GET_PREVENTIVE_UPDATED_INSPECTION_LIST_MAINTENANCE,
                    method: 'post',
                    data: payload,
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })
                    .then(async (response:any) => {
                        if (response.status == 200 && response?.data) {
                            const tmpTableData = response.data.data;
                            setChecklistFields(tmpTableData);
                            setFname(tmpTableData[0]?.ImagePath);
                            setSelectedModelImage(tmpTableData.length === 0 || tmpTableData[0]?.ImagePath === null ? [] : tmpTableData[0]?.ImagePath);
                        }
                    })
                    .catch((error) => {
                      trackErrorsInNewRelic({error: error})
                        return {
                            status: error.response.status
                        };
                    });
            })();
        }
    }, [preventiveMaintenanceKey])

    const DropDownIcon = (prop: any) => (
        <img style={{ marginRight: '5px', marginTop: '-2px' }} src={DownArrow} {...prop} />
    );
    const swapArrayLocs = (index1: number, index2: number) => {
        var temp = checklistFields[index1];
        checklistFields[index1] = checklistFields[index2];
        checklistFields[index2] = temp;
    }
    const getChangedPos = (currentPos: any, newPos: any) => {
        swapArrayLocs(currentPos, newPos);
    };

    let component;

    if (checklistFields.length > 0 && (checklistFields[0]?.ImagePath === null || checklistFields[0]?.ImagePath === undefined)) {
        component = (
            <>
                <Label label="Upload Image" className={'labelDiv'} />
                <Attachments
                    viewOnly={false}
                    setError={selectedModelImage}
                    isSetFile={setSelectedModelImage}
                /></>)
    } else if (checklistFields.length > 0 && checklistFields[0]?.ImagePath !== null && checklistFields[0]?.ImagePath !== undefined) {
        if (editImage) {
            component = (
                <Grid item xs={3} lg={1}>
                    <Label label="Upload Image" className={'labelDiv'} />
                    <Attachments
                        viewOnly={false}
                        setError={selectedModelImage}
                        isSetFile={setSelectedModelImage}
                    />
                </Grid>
            )
        } else {
        component = (
            <Box className="attachment_wrapper" style={{ marginTop: 30 }}>
            <Label label="Edit Image" className={'taskLabel'} />

            {<img onClick={() => setEditImage(true)} src={Edit} alt="X" className="closeBtn" />}

                <>
                    <img
                        src={selectedModelImage.length > 0 ? fetchBlobImage(selectedModelImage).url : undefined}
                        alt={'avtar'}
                    />

                </>
            </Box>
        )
        }
    } else component = null;

    return (
        <Container className="createReport updatechecklist">
            <Box>
                <div className='formwrap all-labelcls'>
                    <FormControl fullWidth>
                        <div style={{ display: "flex", marginTop: "20px" }}>
                            <span className='taskHeaders'>Task Id</span>
                            <span className='taskHeaders' style={{ marginLeft: "20px" }}>Task description</span>
                            <span className='taskHeaders' style={{ marginLeft: "80px" }}>Status</span>
                            <span className='taskHeaders' style={{ marginLeft: "20px" }}>Completed Date</span>
                            <span className='taskHeaders' style={{ marginLeft: "130px" }}>Remarks</span>
                        </div>
                        <Divider style={{ backgroundColor: "#ffffff" }} />
                        {checklistFields.map((form, index) => {
                            return (

                                <div key={index}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: "10px", marginTop: "10px" }}>
                                        <Label label={`Task Id-${index + 1}`} className={'taskLabel'} />
                                        <div style={{ width: "200px" }}><Label label={`${form?.TaskDesc}`} className={'taskDesc'} /></div>
                                        <Box sx={{ width: "100%", marginLeft: '10px', display: 'flex' }}>
                                            <Checkbox
                                                key={index}
                                                checked={form?.InspectionDate === null ? false : true}
                                                sx={{ color: "#ffff" }}
                                                onChange={(val: any) => {
                                                    handleFormChange(val, index)
                                                }}
                                            />
                                            <Grid container>
                                                <Grid item md={4} mr={"10px"}>
                                                    <CustomDatePicker
                                                        name="startDate"
                                                        value={form?.InspectionDate === null ? null : new Date(form?.InspectionDate)}
                                                        className="inputDiv"
                                                        onChange={(val: any) => {
                                                            handleFormChangeDate(val, index)
                                                        }}
                                                        inputFormat="dd/MM/yyyy"
                                                        placeholder="Select End Date"
                                                        disabled={form?.InspectionDate === null ? true : false}
                                                    />
                                                </Grid>
                                                <Grid item md={7} mr={"10px"} >
                                                    <TextInput
                                                        variant="outlined"
                                                        sx={{ width: "100% !important" }}
                                                        fullWidth
                                                        name="gtId"
                                                        value={form?.InspRemark === null || form?.InspRemark === "" ? "" : form?.InspRemark}
                                                        autoComplete="off"
                                                        formInput="inputDiv"
                                                        placeholder='Enter Remarks'
                                                        onChange={(val: any) => {
                                                            handleFormChangeRemarks(val, index)
                                                        }}
                                                        maxLength={500}
                                                        disabled={form?.InspectionDate === null ? true : false}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Box>

                                </div>

                            )
                        })
                        }
                        <Grid item xs={3} lg={1}>
                            {
                                component
                            }

                        </Grid>
                    </FormControl>
                </div>
                <div className='butondrower'>
                    {renderButtons()}
                </div>
            </Box>
        </Container>
    );
};
export default CreateModule;
