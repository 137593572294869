import { useState, useEffect } from 'react';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../../constants/apiConstants';
import useGetState from '../../../../utils/hooks/useGetState';
import ACGButton from '../../../../atoms/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TextInput from '../../../../atoms/TextInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import { Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TrailAnalysisService from '../../trialAnalysisServices';
// import "../../cm.css"
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function CreateForm(props:any) {
    const [rawMaterialsData, setRawMaterialsData] = useState([{ name: "IntraGranular", elements: 20, label: "Raw Intra Granular" }, { name: "BinderCoating", elements: 10, label: "Raw Binder" }, { name: "ExtraGranularPreLubrication", elements: 10, label: "Raw Extra Granualar PreLubricant" }, { name: "ExtraGranularLubrication", elements: 10, label: "Raw Extra Granular Lubrication" }, { name: "SolventSolutionQty", elements: 10, label: "Raw Solution Quantity" }]);
    const [intraGranularFields, setIntraGranularFields] = useState([{ "MaterialSN": 1, "MaterialName": '' }])
    const [binderCoatingFields, setBinderCoatingFields] = useState([{ "MaterialSN": 1, "MaterialName": '' }])
    const [extraGranularPreLubricationFields, setExtraGranularPreLubricationFields] = useState([{ "MaterialSN": 1, "MaterialName": '' }])
    const [extraGranularLubricationFields, setExtraGranularLubricationFields] = useState([{ "MaterialSN": 1, "MaterialName": '' }])
    const [solventSolutionQtyFields, setSolventSolutionQtyFields] = useState([{ "MaterialSN": 1, "MaterialName": '' }])
    const [unitType, setUnitType] = useState("KG");
    const [canBeEdited, setCanbeEdited] = useState(false);
    const state = useGetState();
    const [isLoading, setIsLoading] = useState(false);
    const token:any = state?.[STORE_KEYS.USER_DTL].token;
    const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;

    const handleChangeUnitType = (event:any) => {
        setUnitType(event.target.value);
    }

    const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9]+$/;

    const handleFormChange = (event:any, index:any, type:any) => {
        const value = event.target.value;
        if (value !== ""  && !ALPHA_NUMERIC_DASH_REGEX.test(value)) {
            return;
        }
        if (type === "IntraGranular") {
            let data:any = [...intraGranularFields];
            data[index][event.target.name] = event.target.value;
            setIntraGranularFields(data);
        } else if (type === "BinderCoating") {
            let data:any = [...binderCoatingFields];
            data[index][event.target.name] = event.target.value;
            setBinderCoatingFields(data);
        } else if (type === "ExtraGranularPreLubrication") {
            let data:any = [...extraGranularPreLubricationFields];
            data[index][event.target.name] = event.target.value;
            setExtraGranularPreLubricationFields(data);
        } else if (type === "ExtraGranularLubrication") {
            let data:any = [...extraGranularLubricationFields];
            data[index][event.target.name] = event.target.value;
            setExtraGranularLubricationFields(data);
        } else if (type === "SolventSolutionQty") {
            let data:any = [...solventSolutionQtyFields];
            data[index][event.target.name] = event.target.value;
            setSolventSolutionQtyFields(data);
        }
    }

    const addFields = (index:any, count:any, type:any) => {
        if (intraGranularFields.length < count && type === "IntraGranular") {
            let object = {
                MaterialSN: index + 2,
                MaterialName: ''
            }
            setIntraGranularFields([...intraGranularFields, object]);
        } else if (binderCoatingFields.length < count && type === "BinderCoating") {
            let object = {
                MaterialSN: index + 2,
                MaterialName: ''
            }
            setBinderCoatingFields([...binderCoatingFields, object]);
        } else if (extraGranularPreLubricationFields.length < count && type === "ExtraGranularPreLubrication") {
            let object = {
                MaterialSN: index + 2,
                MaterialName: ''
            }
            setExtraGranularPreLubricationFields([...extraGranularPreLubricationFields, object]);
        } else if (extraGranularLubricationFields.length < count && type === "ExtraGranularLubrication") {
            let object = {
                MaterialSN: index + 2,
                MaterialName: ''
            }
            setExtraGranularLubricationFields([...extraGranularLubricationFields, object]);
        } else if (solventSolutionQtyFields.length < count && type === "SolventSolutionQty") {
            let object = {
                MaterialSN: index + 2,
                MaterialName: ''
            }
            setSolventSolutionQtyFields([...solventSolutionQtyFields, object]);
        }
    }

    const removeFields = (index:any, type:any) => {
        if (type === "IntraGranular") {
            let data = [...intraGranularFields];
            data.splice(index, 1)
            setIntraGranularFields(data);
        } else if (type === "BinderCoating") {
            let data = [...binderCoatingFields];
            data.splice(index, 1)
            setBinderCoatingFields(data);
        } else if (type === "ExtraGranularPreLubrication") {
            let data = [...extraGranularPreLubricationFields];
            data.splice(index, 1)
            setExtraGranularPreLubricationFields(data);
        } else if (type === "ExtraGranularLubrication") {
            let data = [...extraGranularLubricationFields];
            data.splice(index, 1)
            setExtraGranularLubricationFields(data);
        } else {
            let data = [...solventSolutionQtyFields];
            data.splice(index, 1)
            setSolventSolutionQtyFields(data);
        }
    }

    useEffect(() => {
    }, []);

    useEffect(() => {
        const data = { "trialKey": props.newKey, "type": "Y", token: token }
        TrailAnalysisService.getTrailAnalysisRawMaterialsInfo(data)
            .then((response:any) => {
                const values1:any = [], values2:any = [], values3:any = [], values4:any = [], values5:any = [];
                const result = response?.data.message[0];
                const unit = result[0];
                if (unit.length === 0) { setCanbeEdited(true); }
                setUnitType(unit[0].RawMaterialWeightIn);
                result[0]?.map((i:any, index:number) => {
                    if (i.TrialRawMatlCatName === "IntraGranular") {
                        let object = {
                            "MaterialSN": i.TrialRawMatlSN,
                            "MaterialName": i.TrialRawMatlName

                        }
                        values1.push(object);
                    } else if (i.TrialRawMatlCatName === "BinderCoating") {
                        let object = {
                            "MaterialSN": i.TrialRawMatlSN,
                            "MaterialName": i.TrialRawMatlName

                        }
                        values2.push(object);
                    } else if (i.TrialRawMatlCatName === "ExtraGranularPreLubrication") {
                        let object = {
                            "MaterialSN": i.TrialRawMatlSN,
                            "MaterialName": i.TrialRawMatlName

                        }
                        values3.push(object);
                    } else if (i.TrialRawMatlCatName === "ExtraGranularLubrication") {
                        let object = {
                            "MaterialSN": i.TrialRawMatlSN,
                            "MaterialName": i.TrialRawMatlName

                        }
                        values4.push(object);
                    } else {
                        let object = {
                            "MaterialSN": i.TrialRawMatlSN,
                            "MaterialName": i.TrialRawMatlName

                        }
                        values5.push(object);
                    }
                });
                setIntraGranularFields(values1);
                setBinderCoatingFields(values2);
                setExtraGranularPreLubricationFields(values3);
                setExtraGranularLubricationFields(values4);
                setSolventSolutionQtyFields(values5);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [props.trailKey])

    const reset = () => {
        setCanbeEdited(false);
        setUnitType("KG");
        setIntraGranularFields([{ "MaterialSN": 1, "MaterialName": '' }]);
        setBinderCoatingFields([{ "MaterialSN": 1, "MaterialName": '' }]);
        setExtraGranularPreLubricationFields([{ "MaterialSN": 1, "MaterialName": '' }]);
        setExtraGranularLubricationFields([{ "MaterialSN": 1, "MaterialName": '' }]);
        setSolventSolutionQtyFields([{ "MaterialSN": 1, "MaterialName": '' }]);
    }

    const dataValidation = (data:any) => {
        let result;
        data.forEach(function (v:any, i:any) {

            if (
                Object.keys(v).some(function (k) {
                    return v[k] == "";
                })
            ) {
                result = true;
            }
            else {
                result = false
            }

        });
        return result;
    }

    const yesDisabled = dataValidation(intraGranularFields) || dataValidation(binderCoatingFields) || dataValidation(extraGranularPreLubricationFields) || dataValidation(extraGranularLubricationFields) || dataValidation(solventSolutionQtyFields) ? true : false;

    const createRawMaterials = () => {
        setIsLoading(true);
        if ((props.trailKey !== "" && canBeEdited) || props.trailKey === "") {
            const formData = [
                {
                    "TrialKey": props.newKey,
                    "RawMaterialCategory": rawMaterialsData[0].name,
                    "RawMaterials": intraGranularFields
                },
                {
                    "TrialKey": props.newKey,
                    "RawMaterialCategory": rawMaterialsData[1].name,
                    "RawMaterials": binderCoatingFields
                },
                {
                    "TrialKey": props.newKey,
                    "RawMaterialCategory": rawMaterialsData[2].name,
                    "RawMaterials": extraGranularLubricationFields
                },
                {
                    "TrialKey": props.newKey,
                    "RawMaterialCategory": rawMaterialsData[3].name,
                    "RawMaterials": extraGranularLubricationFields
                },
                {
                    "TrialKey": props.newKey,
                    "RawMaterialCategory": rawMaterialsData[4].name,
                    "RawMaterials": solventSolutionQtyFields
                },
            ]
            const data = {
                userId: loggedInUser,
                trialKey: props.newKey,
                formData: JSON.stringify(formData),
                type: unitType,
                token: token
            }
            TrailAnalysisService.createRawMaterials(data)
                .then((response:any) => {
                    const result = response.data.message[0];
                    props.handleNext();
                    reset();
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            props.handleNext();
        }
    }

    const renderButtons = () => {
        return (
            <div className={'btns noWrap'}>
                <ACGButton
                    name={'Cancel'}
                    handleClick={()=>{props.drawerClose();  reset(); props.handleReset()}}
                    secondary={true}
                    className={'first-btn'}
                    type="button"
                    variant="contained"
                />
                <ACGButton
                    name={'Next'}
                    handleClick={() => { createRawMaterials() }}
                    type="submit"
                    className="second-btn"
                    variant="contained"
                    disabled={yesDisabled ? true : false}
                    isLoading = {isLoading}
                />
            </div>
        );
    };

    return (
        <Container className="createReport">
            <Box>
                <div className='formwrap all-labelcls'>
                    <FormControl fullWidth>
                        <Typography sx={{ color: "#ffffff", padding: "20px 0px" }}>Enter {rawMaterialsData[0].label} Raw Materials (Max-{rawMaterialsData[0].elements})</Typography>
                        {
                            intraGranularFields.map((form, index) => {
                                return (
                                    <div key={index}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: "10px" }}>
                                            <TextInput
                                                name='MaterialName'
                                                fullWidth={true}
                                                placeholder='Material Name'
                                                onChange={event => handleFormChange(event, index, "IntraGranular")}
                                                value={form.MaterialName}
                                                disabled={props.trailKey !== '' && canBeEdited === false ? true : false}
                                            />
                                            <Stack spacing={2} direction="row" sx={{ display: 'flex' }} className='addremovcls'>
                                                {index <= rawMaterialsData[0].elements ? <Button disabled={props.trailKey !== '' && canBeEdited === false ? true : false} onClick={() => addFields(index, rawMaterialsData[0].elements, "IntraGranular")}>Add</Button> : null}
                                                {index > 0 ? <Button disabled={props.trailKey !== '' && canBeEdited === false ? true : false} onClick={() => removeFields(index, "IntraGranular")}>Remove</Button> : null}
                                            </Stack>
                                        </Box>
                                    </div>
                                )
                            })
                        }
                        <Typography sx={{ color: "#ffffff", padding: "20px 0px" }}>Enter {rawMaterialsData[1].label} Raw Materials (Max-{rawMaterialsData[1].elements})</Typography>
                        {
                            binderCoatingFields.map((form, index) => {
                                return (
                                    <div key={index}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: "10px" }}>
                                            <TextInput
                                                name='MaterialName'
                                                fullWidth={true}
                                                placeholder='Material Name'
                                                onChange={event => handleFormChange(event, index, "BinderCoating")}
                                                value={form.MaterialName}
                                                disabled={props.trailKey !== '' && canBeEdited === false ? true : false}
                                            />
                                            <Stack spacing={2} direction="row" sx={{ display: 'flex' }} className='addremovcls'> 
                                                {index <= rawMaterialsData[1].elements ? <Button disabled={props.trailKey !== '' && canBeEdited === false ? true : false} onClick={() => addFields(index, rawMaterialsData[1].elements, "BinderCoating")}>Add</Button> : null}
                                                {index > 0 ? <Button disabled={props.trailKey !== '' && canBeEdited === false ? true : false} onClick={() => removeFields(index, "BinderCoating")}>Remove</Button> : null}
                                            </Stack>
                                        </Box>
                                    </div>
                                )
                            })
                        }
                        <Typography sx={{ color: "#ffffff", padding: "20px 0px" }}>Enter {rawMaterialsData[2].label} Raw Materials (Max-{rawMaterialsData[2].elements})</Typography>
                        {
                            extraGranularPreLubricationFields.map((form, index) => {
                                return (
                                    <div key={index}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: "10px" }}>
                                            <TextInput
                                                name='MaterialName'
                                                fullWidth={true}
                                                placeholder='Material Name'
                                                onChange={event => handleFormChange(event, index, "ExtraGranularPreLubrication")}
                                                value={form.MaterialName}
                                                disabled={props.trailKey !== '' && canBeEdited === false ? true : false}
                                            />
                                            <Stack spacing={2} direction="row" sx={{ display: 'flex' }} className='addremovcls'>
                                                {index <= rawMaterialsData[2].elements ? <Button disabled={props.trailKey !== '' && canBeEdited === false ? true : false} onClick={() => addFields(index, rawMaterialsData[2].elements, "ExtraGranularPreLubrication")}>Add</Button> : null}
                                                {index > 0 ? <Button disabled={props.trailKey !== '' && canBeEdited === false ? true : false} onClick={() => removeFields(index, "ExtraGranularPreLubrication")}>Remove</Button> : null}
                                            </Stack>
                                        </Box>
                                    </div>
                                )
                            })
                        }
                        <Typography sx={{ color: "#ffffff", padding: "20px 0px" }}>Enter {rawMaterialsData[3].label} Raw Materials (Max-{rawMaterialsData[3].elements})</Typography>
                        {
                            extraGranularLubricationFields.map((form, index) => {
                                return (
                                    <div key={index}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: "10px" }}>
                                            <TextInput
                                                name='MaterialName'
                                                fullWidth={true}
                                                placeholder='Material Name'
                                                onChange={event => handleFormChange(event, index, "ExtraGranularLubrication")}
                                                value={form.MaterialName}
                                                disabled={props.trailKey !== '' && canBeEdited === false ? true : false}
                                            />
                                            <Stack spacing={2} direction="row" sx={{ display: 'flex' }} className='addremovcls'>
                                                {index <= rawMaterialsData[3].elements ? <Button disabled={props.trailKey !== '' && canBeEdited === false ? true : false} onClick={() => addFields(index, rawMaterialsData[3].elements, "ExtraGranularLubrication")}>Add</Button> : null}
                                                {index > 0 ? <Button disabled={props.trailKey !== '' && canBeEdited === false ? true : false} onClick={() => removeFields(index, "ExtraGranularLubrication")}>Remove</Button> : null}
                                            </Stack>
                                        </Box>
                                    </div>
                                )
                            })
                        }
                        <Typography sx={{ color: "#ffffff", padding: "20px 0px" }}>Enter {rawMaterialsData[4].label} Raw Materials (Max-{rawMaterialsData[4].elements})</Typography>
                        {
                            solventSolutionQtyFields.map((form, index) => {
                                return (
                                    <div key={index}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: "10px" }}>
                                            <TextInput
                                                fullWidth={true}
                                                name='MaterialName'
                                                placeholder='Material Name'
                                                onChange={event => handleFormChange(event, index, "SolventSolutionQty")}
                                                value={form.MaterialName}
                                                disabled={props.trailKey !== '' && canBeEdited === false ? true : false}
                                            />
                                            <Stack spacing={2} direction="row" sx={{ display: 'flex' }} className='addremovcls'>
                                                {index <= rawMaterialsData[4].elements ? <Button disabled={props.trailKey !== '' && canBeEdited === false ? true : false} onClick={() => addFields(index, rawMaterialsData[4].elements, "SolventSolutionQty")}>Add</Button> : null}
                                                {index > 0 ? <Button disabled={props.trailKey !== '' && canBeEdited === false ? true : false} onClick={() => removeFields(index, "SolventSolutionQty")}>Remove</Button> : null}
                                            </Stack>
                                        </Box>
                                    </div>
                                )
                            })
                        }

                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group" className='radiolabls labcolor'

                        >
                            <FormControlLabel disabled={props.trailKey !== '' && canBeEdited === false ? true : false} value="KG" control={<Radio onChange={handleChangeUnitType} checked={unitType === 'KG' ? true : false} />} label="Weight in KG" />
                            <FormControlLabel disabled={props.trailKey !== '' && canBeEdited === false ? true : false} value="PCT" control={<Radio onChange={handleChangeUnitType} checked={unitType === 'PCT' ? true : false} />} label="% Composition" />
                        </RadioGroup>

                    </FormControl>
                </div>
                <div className='butondrower'>
                {renderButtons()}
                </div>
            </Box>
        </Container>
    );
}