import { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import Axios from 'axios';
import FD from 'js-file-download';
import { useDispatch } from "react-redux";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import CustomDateTimePicker from '../../../../atoms/CustomDateTimePicker';
import { executeACGAction, reset, resetStoreKey, updateScreenIdentifiers } from "../../../../store/slice";
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../../constants/apiConstants';
import ACGButton from '../../../../atoms/Button'
import Attachments from '../../../../atoms/ExcelAttachments';
import useGetState from '../../../../utils/hooks/useGetState';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import DropDown from '../../../../atoms/Dropdown';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import FormControl from '@mui/material/FormControl';
import Label from '../../../../atoms/Label/index';
import Grid from '@mui/material/Grid';
import "../../ta.css"
import { trackButtonEvent } from '../../../../utils/newRelicFuncations';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function CreateForm(props: any) {
    const dispatch = useDispatch();
    const editing = props.componentLSKey !== '' ? true : false;
    const [dateTime, setDateTime] = useState<any>(new Date())
    const [sheetResult, setSheetResult] = useState<any>([]);
    const [assemblyName, setAssemblyName] = useState('');
    const [componentName, setComponentName] = useState('');
    const [enabled, setEnabled] = useState('E');
    const [lih, setLih] = useState<any>('');
    const [isLoading, setIsLoading] = useState(false);
    const [oh, setOh] = useState<any>('');
    const [selectedBoth, setSelectedBoth] = useState('');
    const [replacementAlert, setReplacementAlert] = useState<any>('');
    const state = useGetState();
    const token = state?.[STORE_KEYS.USER_DTL].token;
    const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;

    useEffect(() => {
        if (props.componentLSKe !== '') {
            console.log(props.selectedRow)
            setAssemblyName(props.selectedRow?.AssemblyName);
            setComponentName(props.selectedRow?.ComponentName);
            setLih(props.selectedRow?.CalendarLifeInHours);
            setOh(props.selectedRow?.OperatingLifeInHours);
            setSelectedBoth(String(props.selectedRow?.AlertsBasisOperatingHours).toUpperCase());
            setReplacementAlert(props.selectedRow?.ReplacementAlertInHours);
        }
    }, [props.componentLSKey])

    useEffect(()=>{
        if(props.selectedRow) {
            setEnabled(props.selectedRow?.LifeCycleStatus)
        }
    },[props.selectedRow])

    const reset = async () => {
        setComponentName('');
        setAssemblyName('');
        setSelectedBoth('');
        setLih('');
        setOh('');
        setReplacementAlert('');
    }

    const options = {
        AD_SUCCESS: {
            message: 'Machine Status updated',
            open: true,
            type: 'success'
        },
        AD_FAIL: {
            message: 'Failed to update machine satus',
            open: true,
            type: 'remark'
        },
    };

    const createNewEntry = (type: string) => {
        trackButtonEvent("Component Life Cycle","Onboarded Machines","Update Status submit",state[STORE_KEYS.USER_DTL]?.EmailId)
        setIsLoading(true);
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: ACTION_CODES.CLC_STATUS_UPDATE,
                    reqObj: {
                        "LoggedUserKey": loggedInUser,
                        "MachineKey": props.selectedRow?.MachineKey,
                        "MachineModelKey":  props.selectedRow?.MachineModelKey,
                        "LifeCycleStatus": enabled
                    }
                },
                uniqueScreenIdentifier: { clcStatus: true },
                storeKey: STORE_KEYS.CLC_STATUS_UPDATE
            })
        );
    };

    useEffect(() => {
        if (state?.[STORE_KEYS.CLC_STATUS_UPDATE]?.clcStatus) {
            const response = state?.[STORE_KEYS.CLC_STATUS_UPDATE]?.body?.data;
            if (response[0].Result === "Success") {
                props.setSnackbarOpts(options.AD_SUCCESS)
            } else {
                props.setSnackbarOpts(options.AD_FAIL)
            }
            props.drawerClose();
            props.getOnboardList(props.filteredMachineTab2);
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.CLC_STATUS_UPDATE,
                    uniqueScreenIdentifier: {
                        clcStatus: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.CLC_STATUS_UPDATE]?.clcStatus]);

    const yesDisabled = (dateTime === "" || dateTime === null) ? true : false;

    const renderButtons = () => {
        return (
            <div className={'btns noWrap'}>
                <ACGButton
                    name={'Cancel'}
                    handleClick={() => { props.drawerClose(); reset() }}
                    secondary={true}
                    className={'first-btn'}
                    type="button"
                    variant="contained"
                />
                <ACGButton
                    name={'Submit'}
                    handleClick={() => { createNewEntry("Edit") }}
                    type="submit"
                    className="second-btn"
                    variant="contained"
                    disabled={yesDisabled ? true : false}
                    isLoading = {isLoading}
                />
            </div>
        );
    };

    const handleChangeBoth = (e: any) => {
        setSelectedBoth(e.target.value);
    }
    return (
        <Container className="createReport">
            <Box>
                <div className='formwrap all-labelcls'>
                    <FormControl fullWidth>
                        <Grid container spacing={2}>
                            <Grid item md={12}>
                                <FormControl fullWidth>
                                   
                                    <Label label="Component Status *" className={'CMlabelDiv'} />

                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group" className='radiolabls labcolor'

                                    >
                                        <FormControlLabel style={{ color: "#ffff" }} value="E" control={<Radio onChange={(e) => setEnabled(e.target.value)} checked={enabled === 'E' ? true : false} />} label="Enabled"  />
                                        <FormControlLabel style={{ color: "#ffff" }} value="D" control={<Radio onChange={(e) => setEnabled(e.target.value)} checked={enabled === 'D' ? true : false} />} label="Disabled"  />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>

                        </Grid>
                    </FormControl>
                </div>
                <div className='butondrower'>
                    {renderButtons()}
                </div>
            </Box>
        </Container>
    );
}