import { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import MapCustomTabs from '../../../atoms/ButtonTabs/MapCustomTabs';
import StatusCard from '../../../atoms/DashboardStatus/StatusCard';
import ModuleIcon from '../../../../assets/images/dashboardicons/Models.svg';
import MenusIcon from '../../../../assets/images/dashboardicons/Menus.svg';
import ReportsIcon from '../../../../assets/images/dashboardicons/Reports.svg';
import DashboardIcon from '../../../../assets/images/dashboardicons/Dashboard.svg';
import DNRPIcon from '../../../../assets/images/user-reports/rp-download.svg';
import Modules from './Modules';
import Menus from './Menus';
import SubMenus from './SubMenus';
import Reports from './Reports';
import Dashboards from './Dashboards';
import DrawerDropdown from '../../../atoms/DrawerDropdown';
import RightDrawer from '../../../atoms/tempDrawer/indexAlt';
import '../ClientManagement/index.scss';
import CreateReport from './CreateReport';
import { useDispatch } from 'react-redux';
import { executeACGAction } from '../../../store/slice';
import { ACTION_CODES, STORE_KEYS } from '../../../constants/apiConstants';
import useGetState from '../../../utils/hooks/useGetState';
import SnackbarAlert from '../../../atoms/Snackbar';
import CreateModule from './CreateModule/index';
import CreateMenu from './CreateMenu';
import CreateSubMenu from './CreateSubMenu';
import EditModule from './EditModule';
import EditReport from './EditReport/index';
import EditMenu from './EditMenu/index';
import EditSubMenu from './EditSubMenu/index';
import CreateDashboard from './CreateDashboard';
import { SCREEN_TITLES } from '../../../constants/StringConstants';
import EditDashboard from './EditDashboard';
import EditDownloadableReport from './EditDownloadableReport';
import DownloadableReports from './DownloadableReports';
import CreateDownloadableReport from './CreateDownloadableReport';
const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    }
};
const FeatureManagement = () => {
    const [currTab, setCurrTab] = useState(0);
    const state = useGetState();
    const [createValue, setCreateValue] = useState('');
    const [createDrawerOpen, setCreateDrawerOpen] = useState(false);
    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const [reload, setReload] = useState(false);
    const [drawerHeader, setDrawerHeader] = useState('Create');
    const [dtlData, setDtlData] = useState<any>();

    const tabs = ['Modules', 'Menus', 'Sub Menus', 'Reports', 'Dashboards', 'Downloadable Reports'];
    const navigateTab = () => {
        switch (currTab) {
            case 0:
                return (
                    <Modules
                        getSummary={getSummary}
                        setReload={setReload}
                        reload={reload}
                        dtlProps={{
                            setCreateValue: setCreateValue,
                            setCreateDrawerOpen: setCreateDrawerOpen,
                            drawerHeader: drawerHeader,
                            setDrawerHeader: setDrawerHeader,
                            dtlData: dtlData,
                            setDtlData: setDtlData
                        }}
                    />
                );
            case 1:
                return (
                    <Menus
                        getSummary={getSummary}
                        setReload={setReload}
                        reload={reload}
                        dtlProps={{
                            setCreateValue: setCreateValue,
                            setCreateDrawerOpen: setCreateDrawerOpen,
                            drawerHeader: drawerHeader,
                            setDrawerHeader: setDrawerHeader,
                            dtlData: dtlData,
                            setDtlData: setDtlData
                        }}
                    />
                );
            case 2:
                return (
                    <SubMenus
                        getSummary={getSummary}
                        setReload={setReload}
                        reload={reload}
                        dtlProps={{
                            setCreateValue: setCreateValue,
                            setCreateDrawerOpen: setCreateDrawerOpen,
                            drawerHeader: drawerHeader,
                            setDrawerHeader: setDrawerHeader,
                            dtlData: dtlData,
                            setDtlData: setDtlData
                        }}
                    />
                );
            case 3:
                return (
                    <Reports
                        getSummary={getSummary}
                        setReload={setReload}
                        reload={reload}
                        dtlProps={{
                            setCreateValue: setCreateValue,
                            setCreateDrawerOpen: setCreateDrawerOpen,
                            drawerHeader: drawerHeader,
                            setDrawerHeader: setDrawerHeader,
                            dtlData: dtlData,
                            setDtlData: setDtlData
                        }}
                    />
                );
            case 4:
                return (
                    <Dashboards
                        getSummary={getSummary}
                        setReload={setReload}
                        reload={reload}
                        dtlProps={{
                            setCreateValue: setCreateValue,
                            setCreateDrawerOpen: setCreateDrawerOpen,
                            drawerHeader: drawerHeader,
                            setDrawerHeader: setDrawerHeader,
                            dtlData: dtlData,
                            setDtlData: setDtlData
                        }}
                    />
                );
            case 5:
                return (
                    <DownloadableReports
                        getSummary={getSummary}
                        setReload={setReload}
                        reload={reload}
                        dtlProps={{
                            setCreateValue: setCreateValue,
                            setCreateDrawerOpen: setCreateDrawerOpen,
                            drawerHeader: drawerHeader,
                            setDrawerHeader: setDrawerHeader,
                            dtlData: dtlData,
                            setDtlData: setDtlData
                        }}
                    />
                );

            default:
                return (
                    <Modules
                        getSummary={getSummary}
                        setReload={setReload}
                        reload={reload}
                        dtlProps={{
                            setCreateValue: setCreateValue,
                            setCreateDrawerOpen: setCreateDrawerOpen,
                            drawerHeader: drawerHeader,
                            setDrawerHeader: setDrawerHeader,
                            dtlData: dtlData,
                            setDtlData: setDtlData
                        }}
                    />
                );
        }
    };

    const getCreateValue = (ele: string) => {
        setDrawerHeader('Create');
        setCreateValue(ele);
        setCreateDrawerOpen(true);
    };

    const dispatch = useDispatch();

    const getSummary = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.GET_FEATURE_SMRY
                },
                storeKey: STORE_KEYS.FEATURE_SMRY
            })
        );
    };

    useEffect(() => getSummary(), []);
    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);

    return (
        <div className="dashboard">
            <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
            <Container maxWidth="xl" style={{ marginTop: '60px' }}>
                <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                    <div style={{ flex: '70%' }}>
                        <div className="headinglabel">{SCREEN_TITLES.FEATURE_MANAGEMENT}</div>
                        <StatusCard
                            type="feature"
                            totalClients={[
                                {
                                    count: state?.[STORE_KEYS.FEATURE_SMRY]?.body?.data?.[0]?.Modules
                                        ? state?.[STORE_KEYS.FEATURE_SMRY]?.body?.data?.[0]?.Modules
                                        : 0,
                                    handleClick: () => { },
                                    image: ModuleIcon,
                                    text: 'Modules'
                                },
                                {
                                    count: state?.[STORE_KEYS.FEATURE_SMRY]?.body?.data?.[0]?.Menus
                                        ? state?.[STORE_KEYS.FEATURE_SMRY]?.body?.data?.[0]?.Menus
                                        : 0,
                                    handleClick: () => { },
                                    image: MenusIcon,
                                    text: 'Menus'
                                },
                                {
                                    count: state?.[STORE_KEYS.FEATURE_SMRY]?.body?.data?.[0]?.SubMenus
                                        ? state?.[STORE_KEYS.FEATURE_SMRY]?.body?.data?.[0]?.SubMenus
                                        : 0,
                                    handleClick: () => { },
                                    image: MenusIcon,
                                    text: 'Sub Menus'
                                },
                                {
                                    count: state?.[STORE_KEYS.FEATURE_SMRY]?.body?.data?.[0]?.Reports
                                        ? state?.[STORE_KEYS.FEATURE_SMRY]?.body?.data?.[0]?.Reports
                                        : 0,
                                    handleClick: () => { },
                                    image: ReportsIcon,
                                    text: 'Reports'
                                },
                                {
                                    count: state?.[STORE_KEYS.FEATURE_SMRY]?.body?.data?.[0]?.Dashboards
                                        ? state?.[STORE_KEYS.FEATURE_SMRY]?.body?.data?.[0]?.Dashboards
                                        : 0,
                                    handleClick: () => { },
                                    image: DashboardIcon,
                                    text: 'Dashboards'
                                },
                                {
                                    count: state?.[STORE_KEYS.FEATURE_SMRY]?.body?.data?.[0]?.DownloadableReports
                                        ? state?.[STORE_KEYS.FEATURE_SMRY]?.body?.data?.[0]?.DownloadableReports
                                        : 0,
                                    handleClick: () => { },
                                    image: DNRPIcon,
                                    text: 'Downloadable Reports'
                                }
                            ]}
                        />
                    </div>
                    <div
                        style={{
                            flex: '10%',
                            marginLeft: '20px',
                            marginTop: '-40px',
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                        className="dashboardDropdownDiv"
                    >
                        <DrawerDropdown
                            click={getCreateValue}
                            items={['Module', 'Menu', 'Sub Menu', 'Report', 'Dashboard', 'Downloadable Reports']}
                        />
                    </div>
                </div>
                <div style={{ marginTop: '35px', zIndex: '0' }}>
                    <MapCustomTabs
                        navigateTab={navigateTab}
                        currTab={currTab}
                        setCurrTab={setCurrTab}
                        tabsArray={tabs}
                        className="tabs"
                        position="relative"
                        moduleName='Feature Management Tabss'
                    />
                </div>
                <RightDrawer
                    handlerOpen={() => { }}
                    handlerClose={() => setCreateDrawerOpen(false)}
                    status={createDrawerOpen}
                    drawerHeader={`${drawerHeader} ${createValue}`}
                    children={
                        drawerHeader === 'Create' ? (
                            createValue === 'Report' ? (
                                <CreateReport
                                    drawerClose={() => setCreateDrawerOpen(false)}
                                    setCurrTab={setCurrTab}
                                    setReload={setReload}
                                    setSnackbarOpts={setSnackbarOptions}
                                />
                            ) : createValue === 'Module' ? (
                                <CreateModule
                                    drawerClose={() => setCreateDrawerOpen(false)}
                                    setCurrTab={setCurrTab}
                                    setReload={setReload}
                                    setSnackbarOpts={setSnackbarOptions}
                                />
                            ) : createValue === 'Menu' ? (
                                <CreateMenu
                                    drawerClose={() => setCreateDrawerOpen(false)}
                                    setCurrTab={setCurrTab}
                                    getSummary={getSummary}
                                    setReload={setReload}
                                    setSnackbarOpts={setSnackbarOptions}
                                />
                            ) : createValue === 'Sub Menu' ? (
                                <CreateSubMenu
                                    drawerClose={() => setCreateDrawerOpen(false)}
                                    setCurrTab={setCurrTab}
                                    getSummary={getSummary}
                                    setReload={setReload}
                                    setSnackbarOpts={setSnackbarOptions}
                                />
                            ) : createValue === 'Dashboard' ? (
                                <CreateDashboard
                                    drawerClose={() => setCreateDrawerOpen(false)}
                                    setCurrTab={setCurrTab}
                                    setReload={setReload}
                                    setSnackbarOpts={setSnackbarOptions}
                                />
                            ) : createValue === 'Downloadable Reports' ? (
                                <CreateDownloadableReport
                                    drawerClose={() => setCreateDrawerOpen(false)}
                                    setSnackbarOpts={setSnackbarOptions}
                                    setReload={setReload}
                                    setCurrTab={setCurrTab}
                                />
                            ) : (
                                <></>
                            )
                        ) : createValue === 'Module' ? (
                            <EditModule
                                drawerClose={() => setCreateDrawerOpen(false)}
                                setSnackbarOpts={setSnackbarOptions}
                                setReload={setReload}
                                dtlData={dtlData}
                                setDtlData={setDtlData}
                            />
                        ) : createValue === 'Report' ? (
                            <EditReport
                                drawerClose={() => setCreateDrawerOpen(false)}
                                setSnackbarOpts={setSnackbarOptions}
                                setReload={setReload}
                                dtlData={dtlData}
                                setDtlData={setDtlData}
                            />
                        ) : createValue === 'Menu' ? (
                            <EditMenu
                                drawerClose={() => setCreateDrawerOpen(false)}
                                setSnackbarOpts={setSnackbarOptions}
                                setReload={setReload}
                                dtlData={dtlData}
                                setDtlData={setDtlData}
                            />
                        ) : createValue === 'Sub Menu' ? (
                            <EditSubMenu
                                drawerClose={() => setCreateDrawerOpen(false)}
                                setSnackbarOpts={setSnackbarOptions}
                                setReload={setReload}
                                dtlData={dtlData}
                                setDtlData={setDtlData}
                            />
                        ) : createValue === 'Dashboard' ? (
                            <EditDashboard
                                drawerClose={() => setCreateDrawerOpen(false)}
                                setSnackbarOpts={setSnackbarOptions}
                                setReload={setReload}
                                dtlData={dtlData}
                                setDtlData={setDtlData}
                            />
                        ) : createValue === 'Downloadable Reports' ? (
                            <EditDownloadableReport
                                drawerClose={() => setCreateDrawerOpen(false)}
                                setSnackbarOpts={setSnackbarOptions}
                                setReload={setReload}
                                dtlData={dtlData}
                                setDtlData={setDtlData}
                            />
                        ) : null
                    }
                />
            </Container>
        </div>
    );
};

export default FeatureManagement;
