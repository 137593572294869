import React, { useEffect, useState } from 'react';
import { Container, Card } from '@mui/material';
import CustomTableToolbar from '../../../../atoms/CustomTableToolbar';
import CustomTable from '../../../../atoms/CustomTable/index';
// import './index.scss';
import { STORE_KEYS } from '../../../../constants/apiConstants';
import { applySortFilter } from '../../../../utils/helper';
import useGetState from '../../../../utils/hooks/useGetState';
import { _noop } from '../../../../utils/common';
import './index.scss';

type Anchor = 'right';
const FEATUE_KEY = 'id';
const TABLE_HEAD = [
    { id: 'FeatureName', label: 'Features', alignRight: false },
    { id: 'FeatureOrderId', label: 'Order ID', alignRight: false },
    { id: 'setPermission', label: 'Create/Edit', alignRight: false },
    { id: 'setHome', label: 'Set Home', alignRight: false },
    { id: 'Action', label: 'Action', alignLeft: true }
];
const TABLE_SUB_HEAD = [
    { id: 'SubFeatureName', label: 'Sub Features', alignRight: false },
    { id: 'setPermission', label: 'Create/Edit', alignRight: false },
];
const BadgeTable = (props: any) => {
    const { data, subData,subDtlData, handleSelectedData, handleAllSelectedData, preSelectedKeys, preSubSelectedKeys, isEdit, hasSubMenu, closeSubMenu, setClose, setSelectedRow, setStep } = props;
    const [state, setState] = useState({
        right: false
    });
    const [page, setPage] = useState<number>(0);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('FeatureName');
    const [selected, setSelected] = useState<any>([]);
    const [filterName, setFilterName] = useState<string>('');
    const [allData, setAllData] = useState<any>([]);
    const stateObj = useGetState();
    const [finalObj, setFinalObj] = useState<any>(data);

    useEffect(() => {
        if(closeSubMenu){
            setFinalObj({});
        }
       },[closeSubMenu])

    useEffect(() => {
        if (Object.values(data) && !filterName && !closeSubMenu) {
            //invoke on filter is clear and on mounting
            setFinalObj(data);
        }
    }, [data, filterName]);

    useEffect(() => {
        if (Object.values(subData) && !filterName && closeSubMenu) {
            //invoke on filter is clear and on mounting
            setFinalObj(subData);
        }
    }, [subData, filterName]);

    useEffect(() => {
        //set all data
        setAllData(stateObj?.[STORE_KEYS.ROLE_FEATURES]?.body?.data);
        if (isEdit && stateObj?.[STORE_KEYS.ROLE_FEATURES]?.body?.data) {
            setSelected(preSelectedKeys);
        }
    }, [stateObj?.[STORE_KEYS.ROLE_FEATURES]?.body?.data]);

    useEffect(() => {
        setAllData(stateObj?.[STORE_KEYS.ROLE_SUB_FTRS]?.body.data);
        if (isEdit && stateObj?.[STORE_KEYS.ROLE_SUB_FTRS]?.body.data) {
            setSelected(preSubSelectedKeys);
        }
    }, [preSubSelectedKeys]);

    const filteredList = applySortFilter(allData, order, filterName, orderBy);

    const filterData = (dt: any) => {
        const __data: any = {};
        Object.keys(dt).forEach((key: any) => {
            __data[key] = applySortFilter(dt[key], order, filterName, orderBy);
        });
        return __data;
    };
    useEffect(() => {
        setFinalObj(filterData(data));
    }, [order, filterName, orderBy]);

    const handleFilterByName = (event: { target: { value: string } }) => {
        setFilterName(event.target.value);
    };

    const handleRequestSort = (event: any, property: any) => {
        setFilterName('');
        const isAsc = orderBy === event && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(event);
    };

    const handleSelectAllClick = (event: any) => {
        if (event.target.checked) {
            const newSelecteds = [];
            for (let i = 0; i < allData?.length; i++) {
                newSelecteds.push(allData[i].FeatureKey);
            }
            const toRemove = allData.slice(page, allData.length);
            setSelected(newSelecteds);
            handleAllSelectedData(allData);
            return;
        }
        setSelected([]);
    };

    const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    };

    const resetInput = () => {
        setFilterName('');
    };

    const selectRow = (row: any) => {
        const rowId = row['id'];
        const rowData = { ...row };
        const id = selected.length && selected.indexOf(rowId) !== -1 ? rowId : null;
        const isSelected = selected.length && selected.indexOf(rowId) !== -1;
        if (isSelected) {
            setSelected([...selected.filter((item: number) => item !== rowId)]);
        } else {
            setSelected([...selected, rowId]);
        }
        handleSelectedData(rowData, id, 'select');
    };

    const checkDrodownSelection = (row: any, val: any, type: any, flag: boolean) => {
        const rowId = row['id'];
        if (type === 'radio') {
            if (val && !selected?.includes(rowId)) {
                setSelected([...selected, rowId]);
            }
            if (flag) handleSelectedData({ ...row, IsHomePage: val }, null, 'radio');
        } else {
            if(!close) {
            if (val && !selected?.includes(rowId)) {
                setSelected([...selected, rowId]);
            }
            if (flag) handleSelectedData({ ...row, IsEditable: val }, null, 'input');
        } else {
            if (flag) handleSelectedData({ ...row, IsEditable: val }, type);
        }
        }
    };

    return (
        <div>
            <div className="clientBadge">
                <Container
                    maxWidth="xl"
                    style={{
                        paddingLeft: '3px',
                        paddingRight: '3px'
                    }}
                >
                    <Card
                        style={{
                            borderRadius: '0px',
                            backgroundColor: '#2b2d42',
                            overflow: 'inherit'
                        }}
                    >
                        <CustomTableToolbar
                            drawerOpen={toggleDrawer('right', true)}
                            numSelected={selected?.length}
                            filterName={filterName}
                            onFilterName={handleFilterByName}
                            displayInput={true}
                            text={null}
                            filterButton={false}
                            customButton={false}
                            resetInput={resetInput}
                            smallSearch={true}
                            closeSubMenu={closeSubMenu}
                            setClose={ ()=>{
                                setStep(0)
                                setClose()
                            }}
                            isEdit={isEdit}
                        />
                    </Card>
                </Container>
                <CustomTable
                    selectable={true}
                    headers={closeSubMenu ? TABLE_SUB_HEAD : TABLE_HEAD}
                    body={filteredList}
                    filteredUsers={filteredList}
                    filterName={filterName}
                    selected={selected}
                    machineSelected={null}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    isSelectAll={false}
                    onSelectAllClick={handleSelectAllClick}
                    rowChange={(event: string) => { }}
                    machineRowChange={(event: string) => { }}
                    rowCount={allData?.length}
                    onPageChange={handleChangePage}
                    page={page}
                    setPageNumber={setPage}
                    selectRow={selectRow}
                    isRequestPage={true}
                    link={true}
                    type="multiple"
                    tableDataObj={finalObj}
                    idKey={FEATUE_KEY}
                    route={_noop}
                    isRadio={true}
                    isPermission={true}
                    checkDrodownSelection={checkDrodownSelection}
                    isPagination={false}
                    hasSubMenu={hasSubMenu}
                    viewSubMenu={setClose}
                    setSelectedRow={setSelectedRow}
                />
            </div>
        </div>
    );
};

export default BadgeTable;
