import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useHistory } from "react-router-dom";
import { trackPageActionLog } from '../../utils/newRelicFuncations';
import useGetState from '../../utils/hooks/useGetState';
import { STORE_KEYS } from '../../constants/apiConstants';

type ComponentProps = {
    data?: any;
    disabled?: boolean;
};

export default function Breadcrumb(props: ComponentProps) {
    const history = useHistory();
    const state = useGetState()

    const handleClick = (url:string) => {
        if(!props.disabled) {
            const mainData=props.data.find((item:any)=>item.url == url)
            trackPageActionLog({
            actionName:"OEE Dashboard Breadcrumb",
            action:`User clicked on ${mainData.label}`,
            LoggedInUser:state[STORE_KEYS.USER_DTL]?.EmailId,
            })
            history.push(url)
        }
    }
    return (
        <div role="presentation" style={{ marginBottom: 8 }}>
            <Breadcrumbs aria-label="breadcrumb"  separator="›"  style={{ color: "#ffff", fontSize: 12 }}>
                {props.data?.map((i: any) => {
                    return (
                        <Link underline="hover" color="#ffff" onClick={()=>handleClick(i.url)}>
                            <span style={window.location === i.url ? {fontSize: 12, fontWeight: 'bold', cursor: 'pointer'}: {fontSize: 12, cursor: 'pointer'}}>{i?.label}</span>
                        </Link>
                    )
                })}
            </Breadcrumbs>
        </div>
    );
}