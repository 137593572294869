import { useEffect, useState } from 'react';
import { Container, Card, Grid, Paper, Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
//import SendIcon from '@mui/icons-material/Send';
import SendIcon from '../../../assets/images/SendIcon.svg';
import AirportShuttleOutlinedIcon from '@material-ui/icons/AirportShuttleOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import CloudDoneOutlinedIcon from '@material-ui/icons/CloudDoneOutlined';
import CloudOffOutlinedIcon from '@material-ui/icons/CloudOffOutlined';
import BackArrowIcon from '../../../assets/images/BackArrowIcon.svg';
import '../Admin/ClientManagement/index.scss';
import './index.scss';
import MachineMonitoringCard from '../../atoms/MachineMonitoringCard';
import MachineManagementTopCard from '../../atoms/MachineMonitoringTopCard';
import { useDispatch } from 'react-redux';
import { executeACGAction, updateScreenIdentifiers } from '../../store/slice';
import { ACTION_CODES, STORE_KEYS } from '../../constants/apiConstants';
import useGetState from '../../utils/hooks/useGetState';
import { renameObjKeys } from '../Login/helper';
import { SCREEN_TITLES } from '../../constants/StringConstants';
import { trackPageActionLog } from '../../utils/newRelicFuncations';

const MachineMonitoring = () => {
    const history = useHistory();
    const [alarms, setAlarms] = useState<any>({});
    const [events, setEvents] = useState<any>({});
    const [monitoring, setMonitoring] = useState<any>({});
    const displayArray = [alarms, events];
    const state = useGetState();
    const handleRouteAlarms = () => {
        trackPageActionLog({
            actionName:'Recent Active Alarms',
            action:'Recent Active Alarms button clicked',
            LoggedInUser:state[STORE_KEYS.USER_DTL]?.EmailId,
        })
        localStorage.removeItem('selectedAlaramMachineKey');
        history.push('/machine-monitoring/alarm-detail');
    };
    const handleRouteEvents = () => {
        trackPageActionLog({
            actionName:'Recent Events',
            action:'Recent Events button clicked',
            LoggedInUser:state[STORE_KEYS.USER_DTL]?.EmailId,
        })
        history.push('/machine-monitoring/event-detail');
    };
    const dispatch = useDispatch();
    const getSummary = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.MACHMON_SMRY
                },
                uniqueScreenIdentifier: { smryRecd: true },
                storeKey: STORE_KEYS.MACMON_SMRY
            })
        );
    };
    const getActAlarms = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.ACTIVE_ALARMS
                },
                uniqueScreenIdentifier: { almsRecd: true },
                storeKey: STORE_KEYS.ACT_ALARMS
            })
        );
    };
    const getEvents = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.GET_EVENTS
                },
                uniqueScreenIdentifier: { eventsRecd: true },
                storeKey: STORE_KEYS.EVENTS
            })
        );
    };
    const getCondnLogs = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.GET_CONDLOGS
                },
                uniqueScreenIdentifier: { logsRecd: true },
                storeKey: STORE_KEYS.CONDN_LOGS
            })
        );
    };
    useEffect(() => {
        getSummary();
        getActAlarms();
        getEvents();
        getCondnLogs();
    }, []);
    useEffect(() => {
        if (state?.[STORE_KEYS.ACT_ALARMS]?.almsRecd) {
            const alrms = state?.[STORE_KEYS.ACT_ALARMS]?.body?.data?.map((item: any) => {
                const newVal = renameObjKeys(item, {
                    ParamName: 'title',
                    MachineName: 'line',
                    Severity: 'status',
                    PlantName: 'location',
                    LastTriggeredTime: 'time'
                });
                return newVal;
            });
            setAlarms({
                name: 'Recent Active Alarms',
                count: `${state?.[STORE_KEYS.ACT_ALARMS]?.body?.data?.length} Alarms`,
                data: alrms
            });
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ACT_ALARMS,
                    uniqueScreenIdentifier: {
                        almsRecd: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.ACT_ALARMS]?.almsRecd]);
    useEffect(() => {
        if (state?.[STORE_KEYS.EVENTS]?.eventsRecd) {
            const evnts = state?.[STORE_KEYS.EVENTS]?.body?.data?.map((item: any) => {
                const newVal = renameObjKeys(item, {
                    ParamName: 'title',
                    MachineName: 'line',
                    Severity: 'status',
                    PlantName: 'location',
                    LastTriggeredTime: 'time'
                });
                return newVal;
            });
            setEvents({
                name: 'Recent Events',
                count: `${state?.[STORE_KEYS.EVENTS]?.body?.data?.length} Events`,
                data: evnts
            });
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.EVENTS,
                    uniqueScreenIdentifier: {
                        eventsRecd: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.EVENTS]?.eventsRecd]);
    // useEffect(() => {
    //     if (state?.[STORE_KEYS.CONDN_LOGS]?.logsRecd) {
    //         const logsDt = state?.[STORE_KEYS.CONDN_LOGS]?.body?.data?.map((item: any) => {
    //             const newVal = renameObjKeys(item, {
    //                 ParamName: 'title',
    //                 MachineName: 'line',
    //                 ConditionType: 'status',
    //                 PlantName: 'location',
    //                 LastTriggeredTime: 'time'
    //             });
    //             return newVal;
    //         });
    //         setMonitoring({
    //             name: 'Condition Monitoring Log',
    //             count: `${state?.[STORE_KEYS.CONDN_LOGS]?.body?.data?.length} Logs`,
    //             data: logsDt
    //         });
    //         dispatch(
    //             updateScreenIdentifiers({
    //                 storeKey: STORE_KEYS.CONDN_LOGS,
    //                 uniqueScreenIdentifier: {
    //                     logsRecd: false
    //                 }
    //             })
    //         );
    //     }
    // }, [state?.[STORE_KEYS.CONDN_LOGS]?.logsRecd]);
    return (
        <div className="dashboard">
            <Container maxWidth="xl" className="machineMonitoring">
                <div className="headinglabel" style={{ marginBottom: '10px', display: 'flex' }}>
                      <img
                        style={{ cursor: 'pointer', height: '16px', marginTop: '6px' }}
                        src={BackArrowIcon}
                        alt="Arrow"
                        onClick={() => history.push("/machine-monitoring")}
                    />
                      <h4 className="headinglabel" style={{ fontSize: '18px' }}>Recent Activities</h4>
                </div>
                <br />
                <MachineManagementTopCard
                    type="machines"
                    data={[
                        {
                            title: 'Total Machines',
                            count: state?.[STORE_KEYS.MACMON_SMRY]?.body?.data?.[0]?.TotalMachines,
                            icon: AirportShuttleOutlinedIcon
                        },
                        // {
                        //     title: 'Critical',
                        //     count: state?.[STORE_KEYS.MACMON_SMRY]?.body?.data?.[0]?.CriticalMachines,
                        //     icon: ReportProblemOutlinedIcon
                        // },
                        // {
                        //     title: 'Failure',
                        //     count: state?.[STORE_KEYS.MACMON_SMRY]?.body?.data?.[0]?.TotalFailures,
                        //     icon: HighlightOffOutlinedIcon
                        // },
                        {
                            title: 'Online',
                            count: state?.[STORE_KEYS.MACMON_SMRY]?.body?.data?.[0]?.OnlineMachines,
                            icon: CloudDoneOutlinedIcon
                        },
                        {
                            title: 'Offline',
                            count: state?.[STORE_KEYS.MACMON_SMRY]?.body?.data?.[0]?.OffLineMachines,
                            icon: CloudOffOutlinedIcon
                        }
                    ]}
                />
                <br />
                <Grid container spacing={2}>
                    {displayArray?.map((ele: any) => {
                        return (
                            <Grid item xs={12} lg={6} md={6} sm={12}>
                                <Container maxWidth="xl" className="gridContainer">
                                    <Card className="gridContainerCard">
                                        <div className="topDiv">
                                            <div className="childDiv">
                                                <h5>{ele?.name}</h5>
                                                {/* <p>{ele?.count}</p> */}
                                            </div>
                                            <Button
                                                variant="outlined"
                                                //endIcon={<SendIcon />}
                                                endIcon={<img src={SendIcon} alt="arrow" />}
                                                className="topDivButton"
                                                onClick={
                                                    ele?.name?.toLowerCase()?.includes('alarms')
                                                        ? handleRouteAlarms
                                                        : ele?.name?.toLowerCase()?.includes('events')
                                                            ? handleRouteEvents
                                                            : () => { }
                                                }
                                            >
                                                View All
                                            </Button>
                                        </div>
                                        <div className="bottomDiv">
                                            {ele?.data?.map((elem: any) => {
                                                return <MachineMonitoringCard data={elem} />;
                                            })}
                                        </div>
                                    </Card>
                                </Container>
                            </Grid>
                        );
                    })}
                </Grid>
            </Container>
        </div>
    );
};

export default MachineMonitoring;
