import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { Typography } from '@mui/material';
import Label from '../../../../atoms/Label/index';
import TextInput from '../../../../atoms/TextInput';
import DownArrow from '../../../../../assets/down-arrow.svg';
import DropDown from '../../../../atoms/Dropdown';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import HelpIcon from '@mui/icons-material/Help';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import ACGButton from '../../../../atoms/Button';
import useGetState from '../../../../utils/hooks/useGetState';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../../constants/apiConstants';
import RuleEngineService from '../../ruleEngineServices';
import "../../cm.scss";
import { Grid } from '@mui/material';
import { trackButtonEvent } from '../../../../utils/newRelicFuncations';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export default function CreateForm(props: any) {
  const editing = props.presetKey !== '' ? true : false;
  const [modelsData, setModelsData] = useState<any>([]);
  const [presetRulesData, setPresetRulesData] = useState<any>([]);
  const [ruleEnginePresetKey, setRuleEnginePresetKey] = useState('');
  const [machineNameData, setMachineNameData] = useState([]);
  const [machineNames, setMachineNames] = useState<any>([]);
  const [recipeData, setRecipeData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [recipeNames, setRecipeNames] = useState<any>([]);
  const [customerKey, setCustomerKey] = useState('');
  const [machineModelKey, setMachineModelKey] = useState('');
  const [severityData, setSeverityData] = useState<any>([]);
  const [severityKey, setSeverityKey] = useState('');
  const [recommendedNotes, setRecommendedNotes] = useState('');
  const [durationData, setDurationData] = useState<any>([]);
  const [duration, setDuration] = useState('');
  const [notificationGroupsData, setNotificationGroupsData] = useState([]);
  const [notificationNames, setNotificationNames] = useState<any>([]);
  const [subject, setSubject] = useState('');
  const [notifyType, setNotifyType] = useState("P");
  const state = useGetState();
  const token = state?.[STORE_KEYS.USER_DTL].token;
  const getLoggedUserId = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;
  useEffect(() => {
    RuleEngineService.getRuleEngineSeverityList({ token: token })
      .then((response: any) => {
        const result = response.data.message[0];
        setSeverityData([
          ...result[0].map((item: any) => ({ code: item.SeverityKey, name: item.SeverityName }))
        ]);
      })
      .catch((err) => {
        console.log(err);
      });
    const body = { userId: getLoggedUserId, token: token }
    RuleEngineService.getRuleEngineTimeDuration(body)
      .then((response: any) => {
        const result = response.data.message[0];
        setDurationData([
          ...result[0].map((item: any) => ({ code: item.PresetCondTimeDurationKey, name: item.PresetCondTimeDurationName }))
        ]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [])

  useEffect(() => {
    console.log( props.editableData)
    if (editing && props.editableData[0] && props.presetKey !== '') {
      setMachineNames([]);
      setRecipeNames([]);
      setNotificationNames([]);
      const reverseMKeys: any = [];
      let reverseRKeys: any = [];
      let r, rKeys: any;
      setCustomerKey(props.editableData[0]?.CustomerKey);
      const n = getModels(props.editableData[0]?.CustomerKey);
      setMachineModelKey(props.editableData[0]?.MachineModelKey);
      const p = getPresetAlerts(props.editableData[0]?.MachineModelKey, props.editableData[0]?.CustomerKey);
      setRuleEnginePresetKey(props.editableData[0]?.PresetRuleKey);
      const m = getMachineModelNameList(props.editableData[0]?.CustomerKey, props.editableData[0]?.MachineModelKey);
      if (props.editableData[0]?.MachineKeys !== null) {
        const mKeys = (props.editableData[0]?.MachineKeys).split(',');
        m.then((res: any) => res?.map(((i: any, index: number) => {
          if (mKeys.includes(i.MachineKey.toString())) {
            reverseMKeys.push(i);
          }
          if (index === res.length - 1) {
            r = getReciepeList(props.editableData[0]?.CustomerKey, props.editableData[0]?.MachineModelKey, reverseMKeys).then(res => { return res });
            r.then((result: any) => {
              if (props.editableData[0]?.RecipeKeys !== null) {
                rKeys = (props.editableData[0]?.RecipeKeys).split(',');
                result.map((k: any) => {
                  if (rKeys.includes(k.RecipeKey.toString())) {
                    reverseRKeys.push(k)
                  }
                })
                setRecipeNames(reverseRKeys);
              }
            })

          }
        }
        )));
        setMachineNames(reverseMKeys);
      }

      setSubject(props.editableData[0]?.MailSubject);
      setSeverityKey(props.editableData[0]?.SeverityKey);
      setRecommendedNotes(props.editableData[0]?.RecommendedActions);
      setDuration(props.editableData[0]?.PresetCondTimeDurationKey);
      setNotifyType(props.editableData[0]?.AlertTypeCode)
      if (props.editableData[0]?.MailNotifGrpKeys !== null) {
        const nKeys = (props.editableData[0]?.MailNotifGrpKeys).split(','), reverseNKeys: any = [];
        n.then((res: any) => res?.map((i: any) => {
          if (nKeys.includes(i.RuleEngNotifGrpKey.toString())) {
            reverseNKeys.push(i)
          }
        }));
        setNotificationNames(reverseNKeys);
      }
    }
  }, [props.presetKey])

  useEffect(() => {
    if (props.presetKey === '') {
      reset();
    }
  }, [props.presetKey])

  const handleChangeNotifyType = (event: any) => {
    setNotifyType(event.target.value);
    setSubject('');
    setNotificationNames([]);
  };

  const handleChange = (event: any) => {
    setNotificationNames([]);
    setMachineModelKey('');
    setPresetRulesData([]);
    setRuleEnginePresetKey('');
    setMachineNameData([]);
    setMachineNames([]);
    setRecipeData([]);
    setRecipeNames([]);
    setCustomerKey(event.target.value);
    getModels(event.target.value)
  };

  const getModels = async (args: any) => {
    let finalResult;
    const body = { userId: getLoggedUserId, token: token, customerKey: args }
    RuleEngineService.getRuleEngineCustomerModels(body)
      .then((response: any) => {
        const result = response.data.message[0];
        setModelsData([
          ...result[0].map((item: any) => ({ code: item.MachineModelKey, name: item.MachineModelName }))
        ]);
      })
      .catch((err) => {
        console.log(err);
      });
    await RuleEngineService.getRuleEngineCustomerNotificationGroups(body)
      .then((response: any) => {
        const result = response.data.message[0];
        setNotificationGroupsData(result[0]);
        finalResult = result[0];
      })
      .catch((err) => {
        console.log(err);
      });
    return finalResult;
  }

  const handleChangeSeverity = (event: any) => {
    setSeverityKey(event.target.value);
  };

  const handleChangeDuration = (event: any) => {
    setDuration(event.target.value);
  }

  const handleChangeModel = (id: any) => {
    setRuleEnginePresetKey('');
    setMachineNameData([]);
    setMachineNames([]);
    setRecipeData([]);
    setRecipeNames([]);
    setMachineModelKey(id);
    console.log(customerKey, id)
    getMachineModelNameList(customerKey, id);
    getReciepeList(customerKey, id, []);
    getPresetAlerts(id, customerKey);
  };

  const getPresetAlerts = (args1: any, args2: any) => {
    let finalResult;
    const body = { userId: getLoggedUserId, token: token, customerKey: args2, machineModelKey: args1, type: props.presetKey === '' ? 'Add' : 'edit' }
    RuleEngineService.getPresetAlertList(body)
      .then((response: any) => {
        const result = response.data.message[0];
        setPresetRulesData([
          ...result[0].map((item: any) => ({ code: item.RuleEngPresetRuleKey, name: item.RuleEngPresetRuleName }))
        ]);
        finalResult = result[0];
        console.log(result[0])
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(finalResult)
    return finalResult;
  }

  const handleChangePreset = (event: any) => {
    setMachineNames([]);
    setRecipeData([]);
    setRecipeNames([])
    setRuleEnginePresetKey(event.target.value);

  };

  const getMachineModelNameList = async (args1: any, args2: any) => {
    let finalResult;
    const body = { userId: getLoggedUserId, token: token, customerKey: args1, machineModelKey: args2 }
    await RuleEngineService.getMachineList(body)
      .then(async (response: any) => {
        const result = response.data.message[0];
        setMachineNameData(result[0]);
        finalResult = result[0];
      })
      .catch((err) => {
        console.log(err);
      });
    return finalResult;
  }

  const handleChangeMachineNames = (id: any) => {
    setRecipeData([]);
    setRecipeNames([]);
    let newSelected: any = [];
    if (props.presetKey === '') {
      const selectedIndex = machineNames.indexOf(id);
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(machineNames, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(machineNames.slice(1));
      } else if (selectedIndex === machineNames.length - 1) {
        newSelected = newSelected.concat(machineNames.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          machineNames.slice(0, selectedIndex),
          machineNames.slice(selectedIndex + 1)
        );
      }
    }
    else {
      const selectedIndex = machineNames.findIndex((t: any) => t.MachineKey === id.MachineKey);
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(machineNames, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(machineNames.slice(1));
      } else if (selectedIndex === machineNames.length - 1) {
        newSelected = newSelected.concat(machineNames.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          machineNames.slice(0, selectedIndex),
          machineNames.slice(selectedIndex + 1)
        );
      }
    }
    setMachineNames(newSelected)

  }

  const getReciepeList = async (args1: any, args2: any, args3: any) => {
    const mKeys: any = [], reverseMKeys = [], tempArray = [];
    let finalResult;
    args3?.map((i: any) => mKeys.push(i.MachineKey))
    const body = { userId: getLoggedUserId, token: token, customerKey: args1 ? args1 : -1, machineModelKey: args2 ? args2 : -1, machineKeys: mKeys.length > 0 ? mKeys.toString() : -1 }
    await RuleEngineService.getRecipeList(body)
      .then((response: any) => {
        const result = response.data.message[0];
        setRecipeData(result[0]);
        finalResult = result[0];
      })
      .catch((err) => {
        console.log(err);
      });
    return finalResult;
  }

  const handleChangeRecipeNames = (id: any) => {
    let newSelected: any = [];
    if (props.presetKey === '') {
      const selectedIndex = recipeNames.indexOf(id);
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(recipeNames, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(recipeNames.slice(1));
      } else if (selectedIndex === recipeNames.length - 1) {
        newSelected = newSelected.concat(recipeNames.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          recipeNames.slice(0, selectedIndex),
          recipeNames.slice(selectedIndex + 1)
        );
      }
    }
    else {
      const selectedIndex = recipeNames.findIndex((t: any) => t.RecipeKey === id.RecipeKey);
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(recipeNames, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(recipeNames.slice(1));
      } else if (selectedIndex === recipeNames.length - 1) {
        newSelected = newSelected.concat(recipeNames.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          recipeNames.slice(0, selectedIndex),
          recipeNames.slice(selectedIndex + 1)
        );
      }
    }
    setRecipeNames(newSelected);
  }

  const handleChangeNotificationNames = (id: any) => {
    let newSelected: any = [];
    if (props.presetKey === '') {
      const selectedIndex = notificationNames.indexOf(id);
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(notificationNames, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(notificationNames.slice(1));
      } else if (selectedIndex === notificationNames.length - 1) {
        newSelected = newSelected.concat(notificationNames.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          notificationNames.slice(0, selectedIndex),
          notificationNames.slice(selectedIndex + 1)
        );
      }
    }
    else {
      const selectedIndex = notificationNames.findIndex((t: any) => t.RuleEngNotifGrpKey === id.RuleEngNotifGrpKey);
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(notificationNames, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(notificationNames.slice(1));
      } else if (selectedIndex === notificationNames.length - 1) {
        newSelected = newSelected.concat(notificationNames.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          notificationNames.slice(0, selectedIndex),
          notificationNames.slice(selectedIndex + 1)
        );
      }
    }
    console.log(notificationNames)
    setNotificationNames(newSelected);
  }

  const reset = async () => {
    props.setPresetKey('');
    setSeverityKey('');
    setModelsData([]);
    setMachineModelKey('');
    setDuration('');
    setNotificationGroupsData([]);
    setNotificationNames([]);
    setMachineNameData([]);
    setMachineNames([]);
    setRecipeData([]);
    setRecipeNames([]);
    setRecommendedNotes('');
    setCustomerKey('');
    setPresetRulesData([]);
    setRuleEnginePresetKey('');
    setSubject('');
    setNotifyType('P')
  }

  const options = {
    AD_SUCCESS: {
      message: `Preset alert ${props.presetKey === "" ? 'created' : 'edited'} successfully!`,
      open: true,
      type: 'success'
    },
    AD_FAIL: {
      message: `Preset alert with same name alredy exsist !`,
      open: true,
      type: 'remark'
    }
  };

  const createNotificationGroup = () => {
    setIsLoading(true);
    const mKeys: any = [], rKeys: any = [], nKeys: any = [];
    machineNames?.map((i: any) => mKeys.push(i.MachineKey));
    recipeNames?.map((i: any) => rKeys.push(i.RecipeKey));
    notificationNames?.map((i: any) => nKeys.push(i.RuleEngNotifGrpKey))
    const body = {
      userId: getLoggedUserId, token: token,
      customerKey: customerKey,
      machineModelKey: machineModelKey,
      presetRuleKey: ruleEnginePresetKey,
      machineKeys: mKeys ? mKeys.toString() : null,
      recipeKeys: rKeys ? rKeys.toString() : null,
      severityKey: severityKey,
      recommendedNotes: recommendedNotes,
      duration: duration,
      alertType: notifyType,
      notifiyGroups: nKeys.toString(),
      subject: subject,
      type: props.presetKey !== '' ? 'Edit' : "Add"
    }

    trackButtonEvent("Rules & Alerts",`Preset Alerts tabs`,"Create Preset Alert Submit",state[STORE_KEYS.USER_DTL]?.EmailId)

    RuleEngineService.createRuleEnginePresetRule(body)
      .then((response: any) => {
        if (response.status === 200) {
        // const result = response.data.message[0];
        // const checkIfExsist = result[0];
        // const isError = checkIfExsist[0];
        // if (isError.Result === 'Error') {
        //   props.notify(checkIfExsist[0].ResultMessage);
        // }
        // else {
        //   props.notify(checkIfExsist[0].ResultMessage);
        // }
        reset();
        props.setCurrTab(0);
        props.setSnackbarOpts(options.AD_SUCCESS);
        props.drawerClose();
        props.fetchPresetAlerts();
        }
        else {
          props.setCurrTab(0);
          props.setSnackbarOpts(options.AD_FAIL);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const yesDisabled = (customerKey === '' || machineModelKey === '' || ruleEnginePresetKey === '' || severityKey === '' || recommendedNotes === '' || duration === '') ? true : false;
  let optionalDisabled:boolean;
  if (notifyType === "P") {
    optionalDisabled = false;
  }
  else {
    if (subject === '' || notificationNames.length === 0) {
      optionalDisabled = true
    }
    else {
      optionalDisabled = false;
    }
  }
  const DropDownIcon = (prop: any) => (
    <img style={{ marginRight: '5px', marginTop: '-2px' }} src={DownArrow} {...prop} />
  );
  
  const renderButtons = () => {
    return (
      <div className={'btns noWrap'} style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
        <ACGButton
          name={'Cancel'}
          handleClick={props.drawerClose}
          secondary={true}
          className={'first-btn'}
          type="button"
          variant="contained"
        />
        <ACGButton
          name={'Submit'}
          handleClick={() => { createNotificationGroup() }}
          type="submit"
          className="second-btn"
          variant="contained"
          disabled={yesDisabled || optionalDisabled ? true : false}
          isLoading = {isLoading}
        />
      </div>
    );
  };
  return (
    <Container className="createReport">
      <Box>
        <div className='formwrap all-labelcls'>
          <FormControl fullWidth>
            <Label label="Customer Name &nbsp; *" className={'CMlabelDiv'} />
            <DropDown
              onChange={handleChange}
              className="inputDiv"
              name="dropdownModel"
              items={props.customersData}
              placeHolder="Select Customer Name"
              value={customerKey}
            />
            <Label label="Model &nbsp; *" className={'CMlabelDiv'} />
            <DropDown
              onChange={(e) => handleChangeModel(e.target.value)}
              className="inputDiv"
              name="dropdownModel"
              items={modelsData}
              placeHolder="Select Model"
              value={machineModelKey}
            />
            <Label label="Preset Rules &nbsp; *" className={'CMlabelDiv'} />
            <DropDown
              className="inputDiv"
              name="dropdownModel"
              items={presetRulesData}
              placeHolder="Select preset rules"
              value={ruleEnginePresetKey}
              disabled={props.presetKey === '' ? false : true}
              onChange={handleChangePreset}
            />
            <div className='labelfixed'>
            <Label label="Machine Name (optional)" className={'CMlabelDiv'} />
            <FormControl sx={{ width: '100%' }}>
              {machineNames?.length > 0 ? null : (
                <InputLabel
                style={{
                  fontFamily: 'Montserrat',
                  fontSize: '12px',
                  paddingTop: '0px',
                  marginBottom: '0px',
                  paddingLeft: '12px',
                  lineHeight: '1',
                  minHeight: '20px',
                  color: '#b1c2df',
                  opacity: '0.7'
                }}
                  id="demo-multiple-checkbox-label"
                >
                  Select Machines
                </InputLabel>
              )}
              <Select
                IconComponent={DropDownIcon}
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox-preset"
                className="multibox"
                label=""
                multiple
                value={machineNames}
                sx={{ marginBottom: "10px", backgroundColor: "#1d1e2c", borderRadius: 3, marginTop: '10px', color: "#b1c2df" }}
                placeholder='Please select machines'
                // input={<OutlinedInput />}
                renderValue={(selected) => selected.map((x: any) => x.LineMachineName).join(', ')}
                MenuProps={MenuProps}
                onBlur={() => getReciepeList(customerKey, machineModelKey, machineNames)}

              >
                {machineNameData?.map((i: any) => (
                  <MenuItem value={i} onClick={() => handleChangeMachineNames(i)}>
                    <Checkbox
                      checked={
                        machineNames.findIndex((item: any) => item.MachineKey === i.MachineKey) >= 0
                      }

                    />
                    <ListItemText primary={i.LineMachineName} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            </div>
            <div className='labelfixed'>
            <Label label="Recipie Name (optional)" className={'CMlabelDiv'} />
            <FormControl sx={{ width: '100%' }}>
              {recipeNames?.length > 0 ? null : (
                <InputLabel
                style={{
                  fontFamily: 'Montserrat',
                  fontSize: '12px',
                  paddingTop: '0px',
                  marginBottom: '0px',
                  paddingLeft: '12px',
                  lineHeight: '1',
                  minHeight: '20px',
                  color: '#b1c2df',
                  opacity: '0.7'
                }}
                  id="demo-multiple-checkbox-label"
                >
                  Select Recipes
                </InputLabel>
              )}
              <Select
                IconComponent={DropDownIcon}
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                sx={{ marginBottom: "20px", backgroundColor: "#1d1e2c", borderRadius: 3, marginTop: '10px', color: "#b1c2df" }}
                multiple
                value={recipeNames}
                input={<OutlinedInput />}
                renderValue={(selected) => selected.map((x: any) => x.RecipeName).join(', ')}
                MenuProps={MenuProps}
              >
                {recipeData?.map((i: any) => (
                  <MenuItem value={i} onClick={() => handleChangeRecipeNames(i)}>
                    <Checkbox
                      checked={
                        recipeNames.findIndex((item: any) => item.RecipeKey === i.RecipeKey) >= 0
                      }
                    />
                    <ListItemText primary={i.RecipeName} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Label label="Severity &nbsp; *" className={'CMlabelDiv'} />
              <Tooltip title={<Typography fontSize={12} sx={{zIndex : '9999 !important' }} className='tooltiptxt'>If condition is continuously true for a period of time -
                Critical Alerts will be triggered once in every 5 minutes.
                Major Alerts will be triggered once in every 10 minutes.
                Minor Alerts will be triggered once in every 15 minutes.
              </Typography>} arrow>
                <HelpIcon sx={{ justifyContent: 'center', color: '#ffffff', marginLeft: "2px" , height: "20px", width: "20px"}} />
              </Tooltip>
            </div>
            <DropDown
              className="inputDiv"
              name="dropdownModel"
              items={severityData}
              placeHolder="Select severity"
              value={severityKey}
              style={{ marginBottom: "10px" }}
              onChange={handleChangeSeverity}
            />
            <Label label="Recommendation &nbsp; *" className={'CMlabelDiv'} />
            <Box
            className='multilineheight mtop'
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 0, width: '100%' },
              }}
              noValidate
              autoComplete="off"
            >
              <TextInput
                fullWidth
                multiline
                maxRows={4}
                sx={{ margin: '0px', padding: '2px', marginBottom: "20px" }}
                id="outlined-basic"
                className='textareaheight'
                variant="outlined"
                inputProps={{ maxLength: 50, shrink: true }}
                value={recommendedNotes}
                onChange={(e) => setRecommendedNotes(e.target.value)}
                placeholder='Please enter recommendation'
              />
            </Box>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Label label="Time Duration &nbsp; *" className={'CMlabelDiv'} />
              <Tooltip title={<Typography fontSize={12} className='tooltiptxt'>If the created condition is true more than once in a span of time selected in the time duration field, then alert will be triggered</Typography>} arrow>
                <HelpIcon sx={{ justifyContent: 'center', color: '#ffffff', marginLeft: "2px", height: "20px", width: "20px" }} />
              </Tooltip>
            </div>
            <DropDown
              className="inputDiv"
              name="dropdownModel"
              items={durationData}
              placeHolder="Select time duration"
              value={duration}
              style={{ marginBottom: "10px" }}
              onChange={handleChangeDuration}
            />
            <Label label="Notification type &nbsp; *" className={'CMlabelDiv'} />
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group" className='radiolabls fontSizeSpan'

            >
              <FormControlLabel value="P" control={<Radio onChange={handleChangeNotifyType} checked={notifyType === 'P' ? true : false} />} label="Portal" />
              {/*<FormControlLabel value="M" control={<Radio onChange={handleChangeNotifyType} checked={notifyType === 'M' ? true : false} />} label="Email" /> */}
              <FormControlLabel value="MP" control={<Radio onChange={handleChangeNotifyType} checked={notifyType === 'MP' ? true : false} />} label="Portal & Email" />

            </RadioGroup>
            <div className='labelfixed'>
            <Label label="Notification Groups &nbsp; *" className={'CMlabelDiv'} />
                <FormControl sx={{ width: '100%' }}>
              {notificationNames?.length > 0 ? null : (
                <InputLabel
                style={{
                  fontFamily: 'Montserrat',
                  fontSize: '12px',
                  paddingTop: '0px',
                  marginBottom: '0px',
                  paddingLeft: '12px',
                  lineHeight: '1',
                  minHeight: '20px',
                  color: '#b1c2df',
                  opacity: '0.7'
                }}
                  id="demo-multiple-checkbox-label"
                >
                  Select notification groups
                </InputLabel>
              )}
              <Select
                IconComponent={DropDownIcon}
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                sx={{ marginBottom: "20px", backgroundColor: "#1d1e2c", borderRadius: 3, marginTop: '10px', color: "#b1c2df" }}
                multiple
                value={notificationNames}
                input={<OutlinedInput />}
                renderValue={(selected) => selected.map((x:any) => x.RuleEngNotifGroupName).join(', ')}
                MenuProps={MenuProps}
              >
                {notificationGroupsData?.map((i:any) => (
                    <MenuItem value={i} onClick={()=>handleChangeNotificationNames(i)}>
                      <Checkbox
                        checked={
                          notificationNames.findIndex((item:any) => item.RuleEngNotifGrpKey === i.RuleEngNotifGrpKey) >= 0
                        }
                      />
                      <ListItemText primary={i.RuleEngNotifGroupName} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            </div>
            {notifyType !== "P" ?
              <>
                <Label label="Email Subject &nbsp; *" className={'CMlabelDiv'} />
                <TextInput
                  fullWidth
                  sx={{ margin: '0px', padding: '2px', marginBottom: "20px" }}
                  id="outlined-basic"
                  className='textareaheight'
                  variant="outlined"
                  inputProps={{ maxLength: 50, shrink: true }}
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  placeholder='Please enter email subject'
                />
              </>
              :
              null
            }
          </FormControl>
        </div>
      </Box>
      <Grid style={{ display: "flex", marginTop: 20 }}>
        {renderButtons()}
      </Grid>
    </Container>
  );
}