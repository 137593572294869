import { useState, useEffect, useRef, useMemo } from 'react';
import { Grid } from '@mui/material';
import AcgButton from '../../../../atoms/Button/index';
import Label from '../../../../atoms/Label/index';
import { ACTION_CODES, STORE_KEYS } from '../../../../constants/apiConstants';
import { executeACGAction, resetErr, updateScreenIdentifiers } from '../../../../store/slice';
import { useDispatch } from 'react-redux';
import useGetState from '../../../../utils/hooks/useGetState';
import DetailLabel from '../../../../atoms/DetailLabel';
import DetailValue from '../../../../atoms/DetailValue';
import { _noop } from '../../../../utils/common';
import BadgeTable from './BadgeTable';
import { badgeTableDataFormater, generateFormatedData, generateFormatedData2 } from '../../../../utils/formatData';
import { fDateISO } from '../../../../utils/formatTime';

type Props = {
    drawerClose: Function;
    setCurrTab: Function;
    setReload: Function;
    setSnackbarOpts: Function;
    setStep: Function;
    step1Vals: any;
    setStep1Vals: Function;
    isEdit: boolean;
    dtlData: any;
    subDtlData?: any;
    setSubDtlData?: any;
    close?: any;
    setClose?: any;
    selectedRow?: any;
    setSelectedRow?: any;
};
const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    }
};

type AssignFeatureType = {
    features: any;
};

const AssignFeature = (props: Props) => {
    const { drawerClose, setCurrTab, setSnackbarOpts, setStep, setStep1Vals, step1Vals, isEdit, dtlData, subDtlData, setSubDtlData, close, setClose,selectedRow, setSelectedRow } = props;
    const state = useGetState();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState<any>({});
    const [subtableData, setSubtableData] = useState<any[]>([]);
    const [tmpFeatures, setTmpFeatures] = useState<any>(
        isEdit
            ? state?.[STORE_KEYS.CLIENT_FTRS]?.body?.data.map((ele: any) => {
                  return { ...ele, id: ele?.FeatureKey + ele?.ModuleKey };
              }) || []
            : []
    );
    const [tmpSubFeatures, setTmpSubFeatures] = useState<any>([]);
    const [preSelectedKeys, setpreSelectedKeys] = useState<any>([]);
    const [preSelectedSubKeys, setpreSelectedSubKeys] = useState<any>([]);

    const [assignFeatures, setAssignFeatures] = useState<any>(
        isEdit
            ? {
                  CustomerKey: dtlData?.CustomerKey,
                  Features: generateFormatedData([])
              }
            : {
                  CustomerKey: state?.[STORE_KEYS.ADD_RPT]?.body?.data?.CustomerKey,
                  Features: generateFormatedData([])
              }
    );

    const [assignSubFeatures, setAssignSubFeatures] = useState<any>(
        isEdit
            ? {
                  CustomerKey: subDtlData?.CustomerKey,
                  Features: generateFormatedData([])
              }
            : {
                  CustomerKey: state?.[STORE_KEYS.ADD_RPT]?.body?.data?.CustomerKey,
                  Features: generateFormatedData([])
              }
    );

    useEffect(() => {
        if (isEdit) {
            const allSelectedKeys: Array<any> = [];
            assignFeatures.Features?.forEach((arr: any) => {
                arr.id?.forEach((key: any) => {
                    allSelectedKeys.push(key);
                });
            });

            allSelectedKeys?.length && setpreSelectedKeys(allSelectedKeys || []);
        }
    }, [assignFeatures.Features]);

    useEffect(() => {
        if (isEdit && state?.[STORE_KEYS.CLIENT_SUB_FTRS]?.existingSubMenu) {
    
            const dynamicKey: any = Object.keys(subtableData)[0];
            const allSelectedKeys: Array<any> = [];
    
            state?.[STORE_KEYS.CLIENT_SUB_FTRS]?.body?.data?.forEach((arr: any) => {
                const matchedData = subtableData?.[dynamicKey]?.find(
                    (subData: any) => subData.SubFeatureKey === arr.SubFeatureKey
                );
    
                if (matchedData) {
                    allSelectedKeys.push(matchedData.id);
                }
            });
    
            setpreSelectedSubKeys(allSelectedKeys);
    
            // Combine pre-selected values with tmpSubFeatures
            setTmpSubFeatures((prev:any) => {
                const combinedData = [
                    ...prev,
                    ...subtableData?.[dynamicKey]?.filter((subData: any) =>
                        allSelectedKeys.includes(subData.id)
                    )
                ];
    
                // Remove duplicates by filtering out entries with the same ID
                return combinedData.filter(
                    (item, index, self) => index === self.findIndex((t) => t.id === item.id)
                );
            });
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.CLIENT_SUB_FTRS,
                    uniqueScreenIdentifier: {
                        existingSubMenu: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.CLIENT_SUB_FTRS]]);
    
    const handleSubmit = async () => {
        setIsLoading(true);
        const reqPayload = {
            payload: {
                urlPath: isEdit ? ACTION_CODES.EDIT_CLIENT : ACTION_CODES.ADD_CLIENT,
                requestType: isEdit ? 'PUT' : 'POST',
                reqObj: {
                    ...step1Vals,
                    customerEndDate: fDateISO(step1Vals.customerEndDate),
                    customerImagePath: 'https://picsum.photos/200/300' //!important random path - need to confirm from Sandeep is it optional or need to adde upload feature and share blob in API
                }
            },
            uniqueScreenIdentifier: !isEdit ? { clientAdded: true } : { clientEdited: true },
            storeKey: STORE_KEYS.ADD_RPT
        };
        await dispatch(executeACGAction(reqPayload));
    };
    const renderButtons = () => {
        return (
            <div className={'btns noWrap'}>
                <AcgButton
                    name="Back"
                    handleClick={() => setStep(0)}
                    type="button"
                    secondary={true}
                    className="first-btn"
                    variant="contained"
                />
                <AcgButton
                    name={'Submit'}
                    handleClick={handleSubmit}
                    type="submit"
                    className="second-btn"
                    variant="contained"
                    isLoading = {isLoading}
                />
            </div>
        );
    };

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_RPT]?.clientAdded) {
            if (state?.[STORE_KEYS.ADD_RPT]?.statusCode === 200) {
                const data = generateFormatedData2([...assignFeatures.Features]);
                const reqPayload = {
                    payload: {
                        urlPath: ACTION_CODES.ADD_FEATURES,
                        requestType: 'POST',
                        reqObj: {
                            customerKey: state?.[STORE_KEYS.ADD_RPT]?.body?.data?.CustomerKey,
                            customerFeatures: {
                                CustomerKey: state?.[STORE_KEYS.ADD_RPT]?.body?.data?.CustomerKey,
                                // Features: [...assignFeatures.Features]
                                Features: data
                            }
                        }
                    },
                    uniqueScreenIdentifier: { featureAdded: true },
                    storeKey: STORE_KEYS.ADD_RPT
                };
                dispatch(executeACGAction(reqPayload));
            }
        }
    }, [state?.[STORE_KEYS.ADD_RPT]?.clientAdded]);

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_RPT]?.clientEdited) {
            if (state?.[STORE_KEYS.ADD_RPT]?.statusCode === 200) {
                const data = generateFormatedData2([...assignFeatures.Features]);

                const subData = tmpSubFeatures.map((item:any) => ({
                    ModuleName: item.ModuleName,
                    MenuKey: item.MenuKey,
                    SubFeatureKey: item.SubFeatureKey
                }));

                if(close) {
                    const reqPayload = {
                        payload: {
                            urlPath: ACTION_CODES.ADD_SUB_FEATURES,
                            requestType: 'POST',
                            reqObj: {
                                customerKey: state?.[STORE_KEYS.ADD_RPT]?.body?.data?.CustomerKey,
                                customerFeatures: {
                                    CustomerKey: state?.[STORE_KEYS.ADD_RPT]?.body?.data?.CustomerKey,
                                    Features: subData
                                }
                            }
                        },
                        uniqueScreenIdentifier: { subfeatureEdited: true },
                        storeKey: STORE_KEYS.ADD_RPT
                    };
                    dispatch(executeACGAction(reqPayload));
                } else {
                const reqPayload = {
                    payload: {
                        urlPath: ACTION_CODES.ADD_FEATURES,
                        requestType: 'POST',
                        reqObj: {
                            customerKey: state?.[STORE_KEYS.ADD_RPT]?.body?.data?.CustomerKey,
                            customerFeatures: {
                                CustomerKey: state?.[STORE_KEYS.ADD_RPT]?.body?.data?.CustomerKey,
                                Features: data
                            }
                        }
                    },
                    uniqueScreenIdentifier: { featureEdited: true },
                    storeKey: STORE_KEYS.ADD_RPT
                };
                dispatch(executeACGAction(reqPayload));
            }
           
            }
        }
    }, [state?.[STORE_KEYS.ADD_RPT]?.clientEdited]);

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_RPT]?.featureAdded) {
            if (state?.[STORE_KEYS.ADD_RPT]?.statusCode === 200) {
                setCurrTab(0);
                drawerClose();
            }
        }
    }, [state?.[STORE_KEYS.ADD_RPT]?.featureAdded]);

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_RPT]?.featureAdded) {
            if (state?.[STORE_KEYS.ADD_RPT]?.statusCode === 200) {
                setCurrTab(0);
                drawerClose();
            }
        }
    }, [state?.[STORE_KEYS.ADD_RPT]?.featureAdded]);

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_RPT]?.featureEdited) {
            if (state?.[STORE_KEYS.ADD_RPT]?.statusCode === 200) {
                setCurrTab(0);
                drawerClose();
            }
        }
    }, [state?.[STORE_KEYS.ADD_RPT]?.featureEdited]);

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_RPT]?.subfeatureEdited) {
            if (state?.[STORE_KEYS.ADD_RPT]?.statusCode === 200) {
                setCurrTab(0);
                setClose(false);
                drawerClose();
            }
        }
    }, [state?.[STORE_KEYS.ADD_RPT]?.subfeatureEdited]);

    const getList = () => {
        const listData = state[STORE_KEYS.CLIENT_FEATURES]?.data;
        !listData &&
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'GET',
                        urlPath: ACTION_CODES.GET_CLIENT_FEATURES
                    },
                    storeKey: STORE_KEYS.CLIENT_FEATURES
                })
            );
    };

    const getSubMenuList = () => {
        const listData = state[STORE_KEYS.CLIENT_SUB_FEATURES]?.data;
        let reqObj = {};
        reqObj = {
            'FeatureKey': selectedRow?.FeatureKey
        };
        !listData &&
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'POST',
                        urlPath: ACTION_CODES.GET_CLIENT_SUB_FEATURES,
                        reqObj: reqObj
                    },
                    storeKey: STORE_KEYS.CLIENT_SUB_FEATURES,
                    uniqueScreenIdentifier: {
                        menuList: true
                    }
                })
            );
    };

    const getExistingSubMenus = () => {
        let reqObj = {
            'CustomerKey' : selectedRow?.CustomerKey,
            'FeatureKey': selectedRow?.FeatureKey
        }
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: `${ACTION_CODES.GET_CLNT_SUB_FEATURES}`,
                    reqObj: reqObj
                },
                storeKey: STORE_KEYS.CLIENT_SUB_FTRS,
                uniqueScreenIdentifier: {
                    existingSubMenu: true
                }
            })
        );
    };

    useEffect(() => {
        getList();
    }, []);

    useEffect(() => {
        if(close){
            getSubMenuList();
        }
    },[close])

    useEffect(() => {
        // const tmpTableData = state[STORE_KEYS.CLIENT_FEATURES]?.body?.data;
        const tmpTableData = state[STORE_KEYS.CLIENT_FEATURES]?.body?.data?.map((ele: any) => {
            return { ...ele, id: ele?.FeatureKey + ele?.ModuleKey };
        });
        if (tmpTableData) {
            if (isEdit && dtlData?.features) {
                const __selectedData = dtlData?.features?.map((__item: any) => {
                    return { ...__item, isSelected: true };
                });
                const merge = (a: any, b: any, k: any) =>
                    a?.filter((d: any) => !b.find((c: any) => d[k] == c[k])).concat(b);
                const __merged = merge(tmpTableData, __selectedData, 'id');
                const data: any = badgeTableDataFormater(__merged);
                setTableData(data);
            } else {
                const data: any = badgeTableDataFormater(tmpTableData);

                setTableData(data);
            }
        }
    }, [state[STORE_KEYS.CLIENT_FEATURES]?.body?.data, dtlData?.features]);

    useEffect(() => {
        if(state[STORE_KEYS.CLIENT_SUB_FEATURES]?.menuList){
        const tmpTableData = state[STORE_KEYS.CLIENT_SUB_FEATURES]?.body?.data?.map((ele: any) => {
            return { ...ele, id: ele?.SubFeatureKey + ele?.ModuleKey, isSelected: true };
        });
        if (tmpTableData) {
            if (isEdit && subDtlData?.length > 0) {
                const __selectedData = subDtlData?.map((__item: any) => {
                    return { ...__item, isSelected: true };
                });
                const merge = (a: any, b: any, k: any) =>
                    a?.filter((d: any) => !b.find((c: any) => d[k] == c[k])).concat(b);
                const __merged = merge(tmpTableData, __selectedData, 'id');
                const data: any = badgeTableDataFormater(__merged);
                setSubtableData(data);
            } else {
                const data: any = badgeTableDataFormater(tmpTableData);

                setSubtableData(data);
            }
            getExistingSubMenus()
        }
        dispatch(
            updateScreenIdentifiers({
                storeKey: STORE_KEYS.CLIENT_SUB_FEATURES,
                uniqueScreenIdentifier: {
                    menuList: false
                }
            })
        );
    }
    }, [state[STORE_KEYS.CLIENT_SUB_FEATURES]?.menuList, subDtlData]);

    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };

        setSnackbarOpts(snackbarError);
    };

    useEffect(() => {
        if (state?.err) {
            handleSnackbarError(state?.err);
            dispatch(resetErr());
        }
    }, [state?.err]);

    const handleSelectedData = (data: any) => {
        if(close) {
            const x = tmpSubFeatures?.find((item: any) => item.id === data.id);
            if (!!x) {
                const dt = [...tmpSubFeatures, data].filter((item: any) => item.id !== data.id);
                setTmpSubFeatures(dt);
            } else {
                const dt = [...tmpSubFeatures, data];
                setTmpSubFeatures(dt);
            }

        } else {
        const x = tmpFeatures?.find((item: any) => item.id === data.id);
        if (!!x) {
            const dt = [...tmpFeatures, data].filter((item: any) => item.id !== data.id);
            setTmpFeatures(dt);
        } else {
            const dt = [...tmpFeatures, data];
            setTmpFeatures(dt);
        }
    }
    };

    useEffect(() => {
        if (tmpFeatures?.length) {
            const dt = generateFormatedData([...tmpFeatures]);
            const __data = [...dt];
            setAssignFeatures({
                CustomerKey: null,
                Features: __data
            });
        }
    }, [tmpFeatures]);

    useEffect(() => {
        if (tmpSubFeatures?.length) {
            const dt = generateFormatedData([...tmpSubFeatures]);
            const __data = [...dt];
            setAssignSubFeatures({
                CustomerKey: null,
                Features: __data
            });
        }
    }, [tmpSubFeatures]);

    const handleAllSelectedData = (all: any) => {
        close ? setTmpSubFeatures(all) : setTmpFeatures(all);
    };

    // const closeSnackbar = () => setSnackbarOpts(options.DEFAULT);

    return (
        <>
            <Grid container spacing={2} direction="column">
                <Grid item>
                    <DetailLabel label="Client Name" className={'labelDiv'} />
                </Grid>
                <Grid item>
                    <DetailValue label={step1Vals.customerName} />
                </Grid>
                <Grid item>
                    <Label label="Select Features" className={'labelDiv'} />
                </Grid>
            </Grid>
            {tableData && (
                <BadgeTable
                    data={tableData || []}
                    subData={subtableData || []}
                    preSelectedKeys={close ? preSelectedSubKeys : preSelectedKeys}
                    handleSelectedData={handleSelectedData}
                    handleAllSelectedData={handleAllSelectedData}
                    isEdit={isEdit}
                    closeSubMenu={close}
                    setClose={ () => setClose(!close)}
                    hasSubMenu={true}
                    setSelectedRow={setSelectedRow}
                    subDtlData={subDtlData}
                    selectedRow={selectedRow}
                    setTmpSubFeatures={setTmpSubFeatures}
                />
            )}
            {renderButtons()}
        </>
    );
};

export default AssignFeature;
