import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'chartjs-plugin-datalabels';
import { ChartData } from 'chart.js';
import { useHistory } from 'react-router-dom';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from 'chart.js';
import { Bar, Chart } from 'react-chartjs-2';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);
interface CustomLegendProps {
  datasets: ChartData['datasets']; // This uses the type from chart.js for datasets
}

const CustomLegend: React.FC<CustomLegendProps> = ({ datasets }) => {
  return (
    <div className="custom-legend">
      <ul style={{ display: 'flex', justifyContent: 'center', listStyleType: 'none', padding: 0 }} className='legendcls'>
      {datasets.map((dataset:any, index:any) => (
        
        <li key={index} style={{ display: 'flex', alignItems: 'center', marginRight: '20px'}}>
        <span style={{
            backgroundColor: dataset.backgroundColor as string,
            width: '35px',
            height: '10px',
            display: 'inline-block',
            marginRight: '5px'
          }}></span>
          <span style={{ color: '#e0e0e0', fontFamily: 'montserrat', fontSize: '10px' }}>{dataset.label}</span>
        </li>
        
      ))}
      </ul>
    </div>
  );
};

const Stacked = (props: any) => {
  const history = useHistory();
  const [labelSet, setLabelSet] = useState<any>([]);
  const [opdata1, setOPData1] = useState<any>([]);
  const [opdata2, setOPData2] = useState<any>([]);
  const [opdata3, setOPData3] = useState<any>([]);
  const [batchProduct, setBatchProduct] = useState<any>([]);

  useEffect(() => {
    const lab: any = [], d1: any = [], d2: any = [], d3: any = [], d4: any = [];

    props?.avlMetrics.map((i: any) => {
      if (!lab.includes(i.BatchNo)) {
        lab.push(i.BatchNo);
        d4.push(i.ActualBatchNo2);
      }
      if (i.MetricName === "BatchTime") d1.push(i.MetricValuePer);
      if (i.MetricName === "PlannedDT") d2.push(i.MetricValuePer);
      if (i.MetricName === "UnPlannedDT") d3.push(i.MetricValuePer);
    });
    setLabelSet(lab);
    setOPData1(d1);
    setOPData2(d2);
    setOPData3(d3);
    setBatchProduct(d4);
  }, [props])

  const options = {
    plugins: {
      datalabels: { color: 'white', display: false },
      legend: {
        events: [],
        // labels: { 
        //   color: "#e0e0e0",
        //   font: {
        //     size: 10,
        //     family: "montserrat",
        //   }
        // }
        display: false,
      },
      tooltip: {
        intersect: false,
        callbacks: {
          label: function(context: any) {
            const value = context.raw;
            return [
              `${context.dataset.label}: ${value.toFixed(2)}`,
              'Product Batch: ' + batchProduct[context.dataIndex]  
            ];
          }
        }
      }
    },  
    responsive: true,
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: 'white',
        },
        grid: {
          color: 'transparent',
        },
      },
      y: {
        stacked: true,
        ticks: {
          color: 'white',
        },
        grid: {
          color: '#878f99',
        },
      },
    },
    onClick: (evt: any, elements: any, chart: any) => {
      const checkedPoint = chart.tooltip.title[0];
      const prKey = props?.productKey.map((i:any) => i.ProductKey);
      props.setDetailsRedirection(true);
      props.setSelectedProduct(prKey);
      props.setSelectedBatch([checkedPoint])
      props.setCurrTab(2);
    },
  };

  const labels = labelSet;

  const data = {
    labels,
    datasets: [
      {
        label: 'Run Time %',
        data: opdata1,
        backgroundColor: '#008000',
        borderColor: '#008000',
        borderWidth: 0,
        type: 'bar' as const,
      },
      {
        label: 'Planned Downtime %',
        data: opdata2,
        backgroundColor: '#0dcaf0',
        borderColor: '#0dcaf0',
        borderWidth: 0,
        type: 'bar' as const,
      },
      {
        label: 'Unplanned Downtime %',
        data: opdata3,
        backgroundColor: '#ffc107',
        borderColor: '#ffc107',
        borderWidth: 0,
        type: 'bar' as const,
      },
    ],
  };

  return (
    <>
    <CustomLegend datasets={data.datasets} />
     <Chart style={{cursor: "pointer"}} type='bar' data={data} options={options} plugins={[ChartDataLabels]} />;
  </>
  )
}

export default Stacked;
