import { filter } from 'lodash';
import { BlobServiceClient, BlobItem } from "@azure/storage-blob";
function isValidDate(dateObject: any) {
    return new Date(dateObject).toString() !== 'Invalid Date';
}

export function descendingComparator(a: string, b: string, orderBy: number) {
    let aVal = a[orderBy];
    let bVal = b[orderBy];

    // let aDate = new Date(aVal);
    // let bDate = new Date(bVal);

    const validDateA = isValidDate(new Date(aVal));
    const validDateB = isValidDate(new Date(bVal));

    if (validDateA && validDateB) {
        if (new Date(bVal) < new Date(aVal)) {
            return -1;
        }
        if (new Date(bVal) > new Date(aVal)) {
            return 1;
        }
        return 0;
    }

    if (typeof a[orderBy] === 'string') {
        aVal = a[orderBy]?.toLowerCase();
        bVal = b[orderBy]?.toLowerCase();
    }
    if (bVal < aVal) {
        return -1;
    }
    if (bVal > aVal) {
        return 1;
    }
    return 0;
}

export function getComparator(order: string, orderBy: number) {
    return order === 'desc'
        ? (a: string, b: string) => descendingComparator(a, b, orderBy)
        : (a: string, b: string) => -descendingComparator(a, b, orderBy);
}

export function applySortFilter(array: any[], order: any, query: any, orderBy: any, percentCompare?: boolean) {
    if (percentCompare) {
        const stabilizedThis = array?.sort(function (a: any, b: any) {
            var textA = a?.OEEPer;
            var textB = b?.OEEPer;
            if (order === "asc") {
                return (parseFloat(textA) < parseFloat(textB)) ? -1 : (parseFloat(textA) > parseFloat(textB)) ? 1 : 0;
            } else {
                return (parseFloat(textA) > parseFloat(textB)) ? -1 : (parseFloat(textA) < parseFloat(textB)) ? 1 : 0;
            }
        });
        return stabilizedThis;
    } else {
        const comparator = getComparator(order, orderBy);
        const stabilizedThis = array?.map((el, index) => [el, index]);
        stabilizedThis?.sort((a, b) => {
            const orderVal = comparator(a[0], b[0]);
            if (orderVal !== 0) return orderVal;
            return a[1] - b[1];
        });
        if (query) {
            // return filter(
            //     array,
            //     (_user) => _user?.[orderBy]?.toLowerCase()?.indexOf(query?.toString()?.toLowerCase()) !== -1
            // );
            const news: any[] = [];
            const arrayArray = array?.filter((ele: any) => {
                return Object.values(ele)
                    ?.map((elem: any) => {
                        return elem ? elem.toString().toLowerCase() : elem;
                    })
                    ?.filter((item: any) => {
                        if (item && item.toLowerCase().indexOf(query.toString().toLowerCase()) > -1 && !news.includes(ele)) {
                            news.push(ele);
                            return ele;
                        }
                    });
            });
            return news;
        }
        return stabilizedThis?.map((el) => el[0]);
    }
}
// For converting file to base64 string
export function convertBlobFileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
            resolve(fileReader.result as string);
        };
        fileReader.onerror = (error) => {
            reject(error);
        };
    });
}

export const fetchBlobImage = (imageName: any) => {
    const account = "stbtffuturednadlsqa";
    const sas = 'sp=racwle&st=2024-07-09T13:07:26Z&se=2025-07-08T21:07:26Z&spr=https&sv=2022-11-02&sr=c&sig=9xfDXquPJRY5MEOIawH6IuvF%2BMYHGivOGHpMn4xYZyo%3D';
    const blobServiceClient = new BlobServiceClient(`https://${account}.blob.core.windows.net?${sas}`);

    const containerName = 'smartconnected-files';
    const containerClient = blobServiceClient.getContainerClient(containerName);

    return containerClient.getBlobClient(imageName);
}

export const getLastSixMonths = () => {
    const d = new Date();
    return d.setDate(d.getDate() - 180);
}

export const getLastTwoMonths = () => {
    const d = new Date();
    return d.setDate(d.getDate() - 60);
}

export const reGroup = (list: any, key: any) => {
    const newGroup: any = {};
    list.forEach((item: any) => {
        const newItem: any = Object.assign({}, item);
        newGroup[item[key]] = newGroup[item[key]] || [];
        newGroup[item[key]].push(newItem);
    });
    return newGroup;
};

export const playSound = (audioRef: any) => {
    audioRef.current.play().catch((error: any) => {
        console.error('Error playing sound:', error);
    });
};

export const getRandomHexColor = () => {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    return "#" + randomColor.padStart(6, "0");
}

const formatDate = (date: any) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

export const lastTwoMonthsDaterange = () => {
    const today = new Date();
    const sixtyDaysAgo = new Date();

    if (today.getMonth() === 3 || today.getMonth() === 4) {
        const startOfApril = new Date(today.getFullYear(), 3, 1); // April 1st
        return { from: formatDate(startOfApril), to: formatDate(today) };
    } else {
        sixtyDaysAgo.setDate(today.getDate() - 60); // Subtract 60 days
        return { from: formatDate(sixtyDaysAgo), to: formatDate(today) };
    }
};

const formatStartDate = (date: any) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

export const startDateOfMonth = () => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    return { from: formatStartDate(firstDayOfMonth), to: formatStartDate(today) }
}

export const calculateDateDifference = (startDate: any, endDate: any) => {
    const start: any = new Date(startDate);
    const end: any = new Date(endDate);
    const diffTime = Math.abs(end - start);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

export const dateISO = (startdate: any) => {

    const date = new Date(startdate);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return formattedDate;
}

export const timeISO = (time: any) => {
    const date = new Date(time);
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
  
    return `${hours}:${minutes}`;
  };