import { useEffect } from 'react';
import ButtonTabs from './index';
import { trackPageActionLog } from '../../utils/newRelicFuncations';
import useGetState from '../../utils/hooks/useGetState';
import { STORE_KEYS } from '../../constants/apiConstants';

type Props = {
    navigateTab: Function;
    tabsArray: string[];
    currTab: number;
    setCurrTab: Function;
    className?: string;
    position?: string;
    type?: string;
    moduleName?: string;
};

const MapCustomTabs = (props: Props) => {
    const { tabsArray, currTab, setCurrTab, navigateTab, className, position, type, moduleName } = props;
    const state = useGetState()
    useEffect(() => {
        if (moduleName && tabsArray.length) {
            trackPageActionLog({
                actionName:moduleName,
                action:'User Clicked on Tab',
                additionalInfo: `User navigated to ${tabsArray[currTab]} tab`,
                LoggedInUser:state[STORE_KEYS.USER_DTL]?.EmailId,
            })
        }
    }, [currTab, moduleName]);

    return (
        <div className="pad-cont-10">
            <ButtonTabs
                tabsArray={tabsArray}
                selectedTab={currTab}
                setSelectedTab={setCurrTab}
                className={className}
                position={position}
                type={type}
            />
            {navigateTab()}
        </div>
    );
};

export default MapCustomTabs;
