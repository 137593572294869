import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Container } from '@mui/material';
import MapCustomTabs from '../../../app/atoms/ButtonTabs/MapCustomTabs';
import StatusCard from '../../../app/atoms/DashboardStatus/StatusCard';
import Roles from '../Admin/UserManagement/Roles';
import Users from '../Admin/UserManagement/Users';
import TotalRolesIcon from '../../../../src/assets/images/dashboardicons/TotalRoles.svg';
import TotalUserIcon from '../../../../src/assets/images/dashboardicons/TotalUsers.svg';
import UserManagementSelectionBar from '../../../app/atoms/UserManagementSelectionBar';
import { ACTION_CODES, STORE_KEYS } from '../../../app/constants/apiConstants';
import { executeACGAction, updateScreenIdentifiers } from '../../../app/store/slice';
import useGetState from '../../../app/utils/hooks/useGetState';
import { _noop } from '../../../app/utils/common';
import '../Admin/ClientManagement/index.scss';
import CustomRadioGroup from '../../../app/atoms/CustomRadioGroup';
import DropDown from '../../../app/atoms/Dropdown/index';
import Label from '../../../app/atoms/Label/index';
import { Grid } from '@material-ui/core';
import DrawerDropdown from '../../../app/atoms/DrawerDropdown';
import RightDrawer from '../../../app/atoms/tempDrawer/indexAlt';
import CreateConfig from './CreateConfig';
import EditConfig from './EditConfig'
import { SCREEN_TITLES } from '../../../app/constants/StringConstants';
// import CreateRole from './CreateRole';
import Config from './Config';
import SnackbarAlert from '../../atoms/Snackbar';

const tabs = ['System'];
const ALL_CLIENT = { code: null, name: '--Select Client--' };
const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    }
};
const SystemConfiguration = () => {
    const [currTab, setTabChange] = useState(0);
    const [radioValue, setRadioValue] = useState('All');
    const [selectedClient, setSelectedClient] = useState(null);
    const [drawerHeader, setDrawerHeader] = useState('Create');
    const [clientList, setClientList] = useState<any[]>([]);
    const [createValue, setCreateValue] = useState('');
    const [createDrawerOpen, setCreateDrawerOpen] = useState(false);
    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const [reload, setReload] = useState(false);
    const [dtlData, setDtlData] = useState<any>({});
    const [favoriteContactFlag, setfavoriteContactFlag] = useState();
    const [selectedRow, setSelectedRow] = useState<any>();
    const [editButtonClick, setEditButtonClick] = useState(false) 

    const dispatch = useDispatch();
    const state = useGetState();

    const getCreateValue = (ele: string) => {
        setDrawerHeader('Create');
        setCreateValue(ele);
        setCreateDrawerOpen(true);
    };

    const setCurrTab = (val: number) => {
        setTabChange(val);
        if (radioValue == 'All' && currTab === 1) {
            setRadioValue('All');
        }
    };

    const handleRadioValue = (val: string) => {
        setRadioValue(val);
        setSelectedClient(null);
    };

    const getSummary = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.GET_USER_SUMMARY
                },
                storeKey: STORE_KEYS.USER_SUMMARY
            })
        );
    };

    const getClients = () => {
        const tmpTableData = state[STORE_KEYS.CLIENT_DROPDOWN]?.body?.data;

        if (tmpTableData?.length) {
            setClientList([
                ALL_CLIENT,
                ...tmpTableData.map((item: any) => ({ code: item.CustomerKey, name: item.CustomerName }))
            ]);
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.CLIENT_DROPDOWN,
                    uniqueScreenIdentifier: {
                        isLoading: false
                    }
                })
            );
        } else if (!state[STORE_KEYS.CLIENT_DROPDOWN]?.isLoading) {
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'GET',
                        urlPath: ACTION_CODES.GET_CLIENT_DROPDOWN
                    },
                    storeKey: STORE_KEYS.CLIENT_DROPDOWN,
                    uniqueScreenIdentifier: {
                        isLoading: true
                    }
                })
            );
        }
    };

    useEffect(getSummary, []);
    useEffect(getSummary, [reload]);
    useEffect(() => {
        if (radioValue === 'Customer' && !clientList.length) {
            getClients();
        }
    }, [radioValue, state[STORE_KEYS.CLIENT_DROPDOWN]?.body?.data]);
    const navigateTab = () => {
        switch (currTab) {

            default:
                return (
                    <Config
                        getSummary={getSummary}
                        setReload={setReload}
                        reload={reload}
                        radioValue={radioValue}
                        selectedClient={selectedClient}
                        setDtlData={setDtlData}
                        dtlData={dtlData}
                        selectedRow={selectedRow}
                        setSelectedRow={() => setSelectedRow}
                        setCreateDrawerOpen={setCreateDrawerOpen}
                        setCreateValue={setCreateValue}
                        setDrawerHeader={setDrawerHeader}
                        editButtonClick={editButtonClick}
                    />
                );
        }
    };

    const { TotalRoles = 0, TotalUsers = 0 } = state[STORE_KEYS.USER_SUMMARY]?.body?.data?.[0] || {};
    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);

    return (
        <div className="dashboard">
            <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
            <Container maxWidth="xl" style={{ marginTop: '60px' }}>
                <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                    <div style={{ flex: '80%', display: 'flex' }}>
                        <div className="headinglabel" style={{ marginTop: '5px' }}>
                            {/* {SCREEN_TITLES.USER_MANAGEMENT} */}
                            System Configuration
                        </div>
                        <div
                            style={{
                                flex: '10%',
                                marginLeft: '20px',
                                // marginTop: '10px',
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                            className="dashboardDropdownDiv"
                        >
                            <DrawerDropdown click={getCreateValue} items={['System Configuration']} />
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: '35px', zIndex: '0' }}>
                    <MapCustomTabs
                        navigateTab={navigateTab}
                        currTab={currTab}
                        setCurrTab={setCurrTab}
                        tabsArray={tabs}
                        className="tabs"
                        position="relative"
                        moduleName='System Configuration Tabs'
                    // type="nonCurve"
                    />
                </div>
            </Container>
            <RightDrawer
                handlerOpen={() => { }}
                handlerClose={() => setCreateDrawerOpen(false)}
                status={createDrawerOpen}
                drawerHeader={`${drawerHeader} ${createValue}`}
                children={
                    drawerHeader === 'Create' ? (
                        createValue === 'System Configuration' ? (
                            <CreateConfig
                                drawerClose={() => setCreateDrawerOpen(false)}
                                setCurrTab={setCurrTab}
                                setReload={setReload}
                                getSummary={getSummary}
                                setDtlData={setDtlData}
                                dtlData={dtlData}
                                setSnackbarOpts={setSnackbarOptions}
                                isEdit={false}
                                selectedRow={selectedRow}
                                setSelectedRow={setSelectedRow}
                            />
                        ) : (
                            // <CreateRole
                            //     drawerClose={() => setCreateDrawerOpen(false)}
                            //     setCurrTab={setCurrTab}
                            //     setReload={setReload}
                            //     dtlData={dtlData}
                            //     setSnackbarOpts={setSnackbarOptions}
                            //     isEdit={false}
                            // />
                            <><div>test 2</div></>
                        )
                    ) : createValue === 'New User' ? (
                        // <CreateUser
                        //     drawerClose={() => setCreateDrawerOpen(false)}
                        //     setCurrTab={setCurrTab}
                        //     setReload={setReload}
                        //     dtlData={dtlData}
                        //     setSnackbarOpts={setSnackbarOptions}
                        //     isEdit={true}
                        // />
                        <><div>test 3</div></>
                    ) : (
                        // <CreateRole
                        //     drawerClose={() => setCreateDrawerOpen(false)}
                        //     setCurrTab={setCurrTab}
                        //     setReload={setReload}
                        //     dtlData={dtlData}
                        //     setSnackbarOpts={setSnackbarOptions}
                        //     isEdit={true}
                        // />
                        <>   <EditConfig
                            drawerClose={() => setCreateDrawerOpen(false)}
                            setCurrTab={setCurrTab}
                            setReload={setReload}
                            setDtlData={setDtlData}
                            getSummary={getSummary}
                            dtlData={dtlData}
                            setEditButtonClick={setEditButtonClick}
                            setSnackbarOpts={setSnackbarOptions}
                            isEdit={true}
                            selectedRow={selectedRow}
                            setSelectedRow={setSelectedRow}
                        /></>
                    )
                }
            />

        </div>
    );
};

export default SystemConfiguration;
