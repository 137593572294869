import { useEffect, useRef, useState } from 'react';
import { Grid } from '@mui/material';
import ACGButton from '../../../../atoms/Button';
import Label from '../../../../atoms/Label/index';
import TextInput from '../../../../atoms/TextInput';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { PLANTLINE_YUPSCHEMA } from '../schema';
import { useDispatch } from 'react-redux';
import { executeACGAction, updateScreenIdentifiers } from '../../../../store/slice';
import { ACTION_CODES, STORE_KEYS } from '../../../../constants/apiConstants';
import useGetState from '../../../../utils/hooks/useGetState';
import PlusIcon from '../../../../../assets/images/PlusIcon.svg';
import BinIcon from '../../../../../assets/images/bin.svg';
import '../../FeatureManagement/CreateReport/index.scss';
import dateFormat from 'dateformat';

type Props = {
    drawerClose: Function;
    setCurrTab: Function;
    setReload: Function;
    setSnackbarOpts: Function;
    setStep: Function;
    step1Vals: any;
};

const options = {
    AD_SUCCESS: {
        message: 'Plant created successfully!',
        open: true,
        type: 'success'
    }
};
const PlantLine = (props: Props) => {
    const { drawerClose, setCurrTab, setReload = () => { }, setSnackbarOpts = () => { }, setStep, step1Vals } = props;

    const [lines, setLines] = useState<any>([]);
    const formRef = useRef<any>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [validationSchema, setValidationSchema] = useState({});
    const [initialValues] = useState({
        lines: [
            {
                PlantLineNoCode: '',
                PlantLineNoName: ''
            }
        ]
    });

    const dispatch = useDispatch();
    const state = useGetState();

    useEffect(() => {
        setValidationSchema(Yup.object().shape({ lines: Yup.array().of(Yup.object().shape(PLANTLINE_YUPSCHEMA)) }));
    }, []);

    const handleSubmit = async (values: any) => {
        setIsLoading(true);
        const sanitizedValues = {
            ...step1Vals,
            plantAddress: step1Vals?.plantAddress?.replace(/'/g, "''"),
            plantName: step1Vals?.plantName?.replace(/'/g, "''"),
        };
    
        setLines(values?.lines);
    
        dispatch(
            executeACGAction({
                payload: {
                    urlPath: ACTION_CODES.ADD_PLANT,
                    requestType: 'POST',
                    reqObj: {
                        customerKey: sanitizedValues?.dropdownClient,
                        locationKey: sanitizedValues?.dropdownState,
                        plantCode: sanitizedValues?.plantCode,
                        plantName: sanitizedValues?.plantName,
                        plantAddress: `${sanitizedValues?.plantAddress}`,
                        plantGPSLocation: '1',
                        timeZoneKey: sanitizedValues?.dropdownTimeZone,
                        shiftStartTime: dateFormat(sanitizedValues?.time, 'HH:MM:ss').toString()
                    }
                },
                uniqueScreenIdentifier: { plantAdded: true },
                storeKey: STORE_KEYS.ADD_RPT
            })
        );
    };
    
    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_RPT]?.plantAdded) {
            if (state?.[STORE_KEYS.ADD_RPT]?.statusCode == 200) {
                dispatch(
                    executeACGAction({
                        payload: {
                            urlPath: ACTION_CODES.ADD_PLANTLINE,
                            requestType: 'POST',
                            reqObj: {
                                plantLineNos: {
                                    PlantKey: state?.[STORE_KEYS.ADD_RPT]?.body?.data?.plantKey,
                                    PlantLineNos: lines
                                }
                            }
                        },
                        uniqueScreenIdentifier: { lineAdded: true },
                        storeKey: STORE_KEYS.ADD_LINE
                    })
                );
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_RPT,
                    uniqueScreenIdentifier: {
                        plantAdded: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.ADD_RPT]?.plantAdded]);

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_LINE]?.lineAdded) {
            if (state?.[STORE_KEYS.ADD_LINE]?.statusCode === 200) {
                setReload(true);
                setCurrTab(1);
                setSnackbarOpts(options.AD_SUCCESS);
                drawerClose();
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_LINE,
                    uniqueScreenIdentifier: {
                        lineAdded: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.ADD_LINE]?.lineAdded]);

    const renderButtons = () => {
        return (
            <div className={'btns noWrap'}>
                <ACGButton
                    name={'Back'}
                    handleClick={() => setStep(0)}
                    secondary={true}
                    className={'first-btn'}
                    type="button"
                    variant="contained"
                />
                <ACGButton
                    name={'Submit'}
                    handleClick={() => { }}
                    type="submit"
                    className="second-btn"
                    variant="contained"
                    isLoading = {isLoading}
                />
            </div>
        );
    };

    return (
        <>
            <div className="userDetailDiv">
                <div className="detailDivChild firstChild">
                    <p>Client</p>
                    <h6>{step1Vals?.clientVal}</h6>
                </div>
                <div className={'detailDivChild '}>
                    <p>Location</p>
                    <h6>{`${step1Vals?.stateVal}, ${step1Vals?.ctryVal}`}</h6>
                </div>
            </div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values: any) => {
                    handleSubmit(values);
                }}
                validateOnBlur={false}
                validateOnChange={false}
                innerRef={formRef}
            >
                {(formikprops: any) => {
                    const { values, handleChange, handleBlur, errors, touched } = formikprops;
                    return (
                        <Form>
                            <FieldArray
                                name={'lines'}
                                render={(fieldArrayProps) => {
                                    return values?.lines?.map((line: any, idx: any) => (
                                        <>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
                                                    <Label label="Line Code" className={'labelDiv'} />
                                                    <TextInput
                                                        variant="outlined"
                                                        fullWidth
                                                        type="text"
                                                        name={`lines[${idx}].PlantLineNoCode`}
                                                        value={line.PlantLineNoCode}
                                                        autoComplete="off"
                                                        formInput="inputDiv"
                                                        onChange={(e: any) => {
                                                            handleChange(e);
                                                        }}
                                                        onBlur={handleBlur}
                                                        placeholder="Enter Line Code"
                                                        helperText={
                                                            errors?.lines?.[idx]?.PlantLineNoCode &&
                                                                touched?.lines?.[idx]?.PlantLineNoCode
                                                                ? errors?.lines?.[idx]?.PlantLineNoCode
                                                                : ''
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
                                                    <Label label="Line Name" className={'labelDiv'} />
                                                    <div className="lineName">
                                                        <TextInput
                                                            variant="outlined"
                                                            fullWidth
                                                            type="text"
                                                            name={`lines[${idx}].PlantLineNoName`}
                                                            value={line.PlantLineNoName}
                                                            autoComplete="off"
                                                            formInput="inputDiv"
                                                            onChange={(e: any) => {
                                                                handleChange(e);
                                                            }}
                                                            onBlur={handleBlur}
                                                            placeholder="Enter Line Name"
                                                            helperText={
                                                                errors?.lines?.[idx]?.PlantLineNoName &&
                                                                    touched?.lines?.[idx]?.PlantLineNoName
                                                                    ? errors?.lines?.[idx]?.PlantLineNoName
                                                                    : ''
                                                            }
                                                        />
                                                        {values?.lines?.length != 1 && (
                                                            <img
                                                                className="binIcon"
                                                                src={BinIcon}
                                                                alt="BinIcon"
                                                                onClick={() => {
                                                                    fieldArrayProps.remove(idx);
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            {idx == values?.lines?.length - 1 && (
                                                <div
                                                    className="plantLine"
                                                    style={{ marginTop: "30px" }}
                                                    onClick={() => {
                                                        fieldArrayProps.push({
                                                            PlantLineNoCode: '',
                                                            PlantLineNoName: ''
                                                        });
                                                    }}
                                                >
                                                    <img className="PlusIcon" src={PlusIcon} alt="PlusIcon" />
                                                    <span>{'Add Line'}</span>
                                                </div>
                                            )}
                                        </>
                                    ));
                                }}
                            />
                            {renderButtons()}
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};

export default PlantLine;
