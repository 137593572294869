import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Card } from '@mui/material';
import CustomTable from '../../../../atoms/CustomTable/index';
import CustomTableToolbar from '../../../../atoms/CustomTableToolbar';
import TemporaryDrawer from '../../../../atoms/tempDrawer';
import { ACTION_CODES, STORE_KEYS } from '../../../../constants/apiConstants';
import { executeACGAction, resetErr, updateScreenIdentifiers } from '../../../../store/slice';
import useGetState from '../../../../utils/hooks/useGetState';
import { applySortFilter } from '../../../../utils/helper';
import DetailDrawer from '../../../../atoms/DetailDrawer';
import { fDate } from '../../../../utils/formatTime';
import '../index.scss';
import AlertBox from '../../../../atoms/AlertBox';
import SnackbarAlert from '../../../../atoms/Snackbar';
import { badgeTableDataFormater } from '../../../../utils/formatData';

const TABLE_HEAD = [
    { id: 'CustomerName', label: 'Client', alignRight: false },
    { id: 'TotalPlants', label: 'Plants', alignRight: false },
    { id: 'TotalMachines', label: 'Machines', alignRight: false },
    { id: 'TotalActiveUsers', label: 'Users', alignRight: false },
    { id: 'CustomerEndDate', label: 'End Date', alignRight: false },
    { id: 'Action', label: 'Action', alignLeft: true }
];

const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    },
    RM_SUCCESS: {
        message: 'Client deleted successfully!',
        open: true,
        type: 'success'
    },
    AD_SUCCESS: {
        message: 'Client created successfully!',
        open: true,
        type: 'success'
    },
    ED_SUCCESS: {
        message: 'Client edited successfully!',
        open: true,
        type: 'success'
    }
};
type Anchor = 'right';

type Props = { getClientSummary: any; dtlProps: any };

const Clients = (props: Props) => {
    const { getClientSummary, dtlProps } = props;
    const dispatch = useDispatch();
    const [drawerState, setDrawerState] = useState({
        right: false
    });
    const [page, setPage] = useState<number>(0);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('CustomerName');
    const [selected, setSelected] = useState<any>([]);
    const [filterName, setFilterName] = useState<string>('');
    const [tableData, setTableData] = useState<any[]>([]);
    const [createDrawerOpen, setCreateDrawerOpen] = useState(false);
    const [dtlDrawerOpen, setDtlDrawerOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState<any>([]);
    const [close, setClose] = useState<boolean>(false);
    const [isLoading,setLoading]=useState(false)
    const state = useGetState();
    const getClientList = () => {
        setLoading(true)
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.GET_CLIENTS
                },
                uniqueScreenIdentifier: { clientsCusList: true },
                storeKey: STORE_KEYS.CLIENT_LIST
            })
        );
    };
    useEffect(getClientList, []);

    useEffect(() => {
        const tmpTableData = state[STORE_KEYS.CLIENT_LIST]?.body?.data;
        
        if(tmpTableData){
            setTableData(
                tmpTableData.map((item: any) => ({
                    ...item,
                    CustomerEndDate: fDate(item.CustomerEndDate, 'MMM dd, yyyy')
                }))
            );
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.CLIENT_LIST,
                    uniqueScreenIdentifier: {
                        clientsCusList: false
                    }
                })
            );
            setLoading(false)
        }
    }, [state[STORE_KEYS.CLIENT_LIST]?.body?.data]);

    useEffect(() => {
        if (state[STORE_KEYS.DELETE]?.rowDeleted) {
            getClientList();
            getClientSummary();
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.DELETE,
                    uniqueScreenIdentifier: {
                        rowDeleted: false
                    }
                })
            );
        }
    }, [state[STORE_KEYS.DELETE]?.rowDeleted]);

    const handleRequestSort = (event: any, property: any) => {
        setFilterName('');
        const isAsc = orderBy === event && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(event);
    };

    const handleSelectAllClick = (event: any) => {
        if (event.target.checked) {
            const newSelecteds = [];
            for (let i = 0; i < tableData.length; i++) {
                newSelecteds.push(tableData[i].id);
            }
            const toRemove = tableData.slice(page, tableData.length);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (requestedBy: string) => {
        const selectedIndex = selected.indexOf(requestedBy);
        let newSelected: any[] | ((prevState: unknown[]) => unknown[]) = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, requestedBy);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setDrawerState({ ...drawerState, [anchor]: open });
    };

    const filteredList = applySortFilter(tableData, order, filterName, orderBy);

    const handleFilterByName = (event: { target: { value: string } }) => {
        setOrderBy('CustomerName');
        setPage(0);
        setFilterName(event.target.value);
    };

    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    };

    const showRow = (event: any) => {};

    const handleRoute = (row: any, ele: any) => {
        if (ele === 'Action') {
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'GET',
                        urlPath: `${ACTION_CODES.GET_CLNTFEATURES}/${row?.CustomerKey}`
                    },
                    uniqueScreenIdentifier: { cltFtrsRecd: true },
                    storeKey: STORE_KEYS.CLIENT_FTRS
                })
            );
            
            dtlProps?.setDtlData({ ...row, name: row?.CustomerName });
        }
    };
    useEffect(() => {
        if (state?.[STORE_KEYS.CLIENT_FTRS]?.cltFtrsRecd) {
            // dtlProps?.setDtlData({ ...dtlProps?.dtlData, features: state?.[STORE_KEYS.CLIENT_FTRS]?.body?.data });
            const updatedList = state?.[STORE_KEYS.CLIENT_FTRS]?.body?.data?.map((ele: any) => {
                return { ...ele, id: ele?.FeatureKey + ele?.ModuleKey };
            });
            dtlProps?.setDtlData({ ...dtlProps?.dtlData, features: updatedList });
            setDtlDrawerOpen(true);
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.CLIENT_FTRS,
                    uniqueScreenIdentifier: {
                        cltFtrsRecd: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.CLIENT_FTRS]?.cltFtrsRecd]);

    useEffect(() => {
      if(close && Object.keys(selectedRow).length > 0) {
        let reqObj = {
            'CustomerKey' : selectedRow?.CustomerKey,
            'FeatureKey': selectedRow?.FeatureKey
        }
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: `${ACTION_CODES.GET_CLNT_SUB_FEATURES}`,
                    reqObj: reqObj
                },
                uniqueScreenIdentifier: { cltSubFtrsRecd: true },
                storeKey: STORE_KEYS.CLIENT_SUB_FTRS
            })
        );
      }
    },[close])

    useEffect(() => {
        if (state?.[STORE_KEYS.CLIENT_SUB_FTRS]?.cltSubFtrsRecd) {
            const updatedList = state?.[STORE_KEYS.CLIENT_SUB_FTRS]?.body?.data?.map((ele: any) => {
                return { ...ele, id: ele?.SubFeatureKey + ele?.ModuleKey };
            });
            dtlProps?.setSubDtlData(updatedList);
            setDtlDrawerOpen(true);
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.CLIENT_SUB_FTRS,
                    uniqueScreenIdentifier: {
                        cltSubFtrsRecd: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.CLIENT_SUB_FTRS]?.cltSubFtrsRecd]);

    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const [removeBxOpen, setRemoveBxOpen] = useState(false);
    const [rmClnt, setRmClnt] = useState(false);
    const [clntKey, setClntKey] = useState<any>();
    const [badgeTableData, setBadgeTableData] = useState<any>([]);
    const [subMenuBadgeTableData, setSubMenuBadgeTableData] = useState<any>([]);

    const closeRmBx = () => {
        setRemoveBxOpen(false);
    };
    const removeFn = (ele: any) => {
        setClntKey(ele?.CustomerKey);
        setRemoveBxOpen(true);
    };
    useEffect(() => {
        if (rmClnt) {
            closeRmBx();
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'DELETE',
                        urlPath: `${ACTION_CODES.DELETE_CLIENT}/${clntKey}`
                    },
                    uniqueScreenIdentifier: {
                        rowDeleted: true
                    },
                    storeKey: STORE_KEYS.DELETE
                })
            );
            setRmClnt(false);
        }
    }, [rmClnt]);

    useEffect(() => {
        if (state?.[STORE_KEYS.DELETE]?.rowDeleted) {
            if (state?.[STORE_KEYS.DELETE]?.statusCode === 200) {
                setSnackbarOptions(options.RM_SUCCESS);
                closeDtlDrawer();
                getClientList();
                getClientSummary();
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.DELETE,
                    uniqueScreenIdentifier: {
                        rowDeleted: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.DELETE]?.rowDeleted]);

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_RPT]?.featureAdded) {
            if (state?.[STORE_KEYS.ADD_RPT]?.statusCode === 200) {
                setSnackbarOptions(options.AD_SUCCESS);
                getClientList();
                getClientSummary();
                setDtlDrawerOpen(false);
                setPage(0);
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_RPT,
                    uniqueScreenIdentifier: {
                        featureAdded: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.ADD_RPT]?.featureAdded]);

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_RPT]?.featureEdited) {
            if (state?.[STORE_KEYS.ADD_RPT]?.statusCode === 200) {
                setSnackbarOptions(options.ED_SUCCESS);
                getClientList();
                getClientSummary();
                setDtlDrawerOpen(false);
                setPage(0);
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_RPT,
                    uniqueScreenIdentifier: {
                        featureEdited: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.ADD_RPT]?.featureEdited]);

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_RPT]?.subfeatureEdited) {
            if (state?.[STORE_KEYS.ADD_RPT]?.statusCode === 200) {
                setSnackbarOptions(options.ED_SUCCESS);
                getClientList();
                getClientSummary();
                setDtlDrawerOpen(false);
                setPage(0);
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_RPT,
                    uniqueScreenIdentifier: {
                        subfeatureEdited: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.ADD_RPT]?.subfeatureEdited]);

    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };
        setSnackbarOptions(snackbarError);
    };

    useEffect(() => {
        if (state?.[STORE_KEYS.CLIENT_FTRS]?.body?.data) {
            const dt = badgeTableDataFormater(state?.[STORE_KEYS.CLIENT_FTRS]?.body?.data);
            setBadgeTableData(dt);
        }
    }, [state?.[STORE_KEYS.CLIENT_FTRS]?.body?.data]);

    useEffect(() => {
        if (state?.[STORE_KEYS.CLIENT_SUB_FTRS]?.body?.data) {
            const dt = badgeTableDataFormater(state?.[STORE_KEYS.CLIENT_SUB_FTRS]?.body?.data);
            setSubMenuBadgeTableData(dt);
        }
    }, [state?.[STORE_KEYS.CLIENT_SUB_FTRS]?.body?.data]);

    useEffect(() => {
        if (state?.err) {
            handleSnackbarError(state?.err);
            dispatch(resetErr());
        }
    }, [state?.err]);

    const closeDtlDrawer = () => {
        setDtlDrawerOpen(false);
        setClose(false);
    };
    const resetInput = () => {
        setFilterName('');
        setPage(0);
    };
    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);

    console.log("====>", close)

    return (
        <div>
            <div>
                <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
                <Container
                    maxWidth="xl"
                    style={{
                        paddingLeft: '3px',
                        paddingRight: '3px'
                    }}
                >
                    <Card
                        style={{
                            borderRadius: '0px',
                            backgroundColor: '#2b2d42',
                            overflow: 'inherit'
                        }}
                    >
                        <CustomTableToolbar
                            drawerOpen={toggleDrawer('right', true)}
                            numSelected={selected?.length}
                            filterName={filterName}
                            onFilterName={handleFilterByName}
                            resetInput={resetInput}
                            displayInput={true}
                            text={{
                                title: 'Client List',
                                subTitle: `${tableData?.length} clients`
                            }}
                            filterButton={false}
                            customButton={false}
                        />
                    </Card>
                </Container>
                <CustomTable
                    selectable={false}
                    headers={TABLE_HEAD}
                    body={filteredList}
                    isLoading={isLoading}
                    filteredUsers={filteredList}
                    filterName={filterName}
                    selected={selected}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    rowChange={(event: string) => handleClick(event)}
                    rowCount={filteredList?.length}
                    onPageChange={handleChangePage}
                    page={page}
                    setPageNumber={setPage}
                    selectRow={showRow}
                    route={handleRoute}
                    isRequestPage={true}
                    link={true}
                    removeFn={removeFn}
                />
            </div>
            <AlertBox
                isOpen={removeBxOpen}
                handleClose={closeRmBx}
                leftBtnPress={closeRmBx}
                rightBtnPress={() => setRmClnt(true)}
                modalHeader="Delete Client"
                modalContent="Are you sure you want to delete client?"
            />
            <DetailDrawer hasImage={false} 
                handlerOpen={() => {}}
                handlerClose={closeDtlDrawer}
                status={dtlDrawerOpen}
                data={{
                    details: dtlProps?.dtlData,
                    badgeTableData: badgeTableData,
                    subMenuBadgeTableData: subMenuBadgeTableData
                }}
                type={'Client'}
                editFn={() => {
                    dtlProps?.setCreateDrawerOpen(true);
                    dtlProps?.setCreateValue('Client');
                    dtlProps?.setDrawerHeader('Edit');
                }}
                deleteFn={() => removeFn(dtlProps?.dtlData)}
                closeSubMenu={close}
                setClose={ () => setClose(!close)}
                hasSubMenu={true}
                setSelectedRow={setSelectedRow}
                clientModule={true}
            />
        </div>
    );
};

export default Clients;
