import { Paper, Typography, IconButton } from '@mui/material';
import { Formik, Form } from 'formik';
import { useState, useEffect, useRef } from 'react';
import { createStructuredSelector } from 'reselect';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import TextInput from '../../../../atoms/TextInput/index';
import Label from '../../../../atoms/Label/index';
import Button from '../../../../atoms/Button/index';
import VisibilityIcon from '../../../../../assets/visibilityIcon.svg';
import VisibilityOffIcon from '../../../../../assets/visibilityOffIcon.svg';
import { LOGIN_YUPSCHEMA } from './schema';
import { executeACGAction, resetErr, setStoreKey, updateScreenIdentifiers } from '../../../../store/slice';
import { useDispatch, useSelector } from 'react-redux';
import { ACTION_CODES, STORE_KEYS } from '../../../../constants/apiConstants';
import { paths, ROUTES_PATH } from '../../../../constants/paths';
import { acgSelector } from '../../../../store/selector';
import Snackbar from '../../../../atoms/Snackbar/index';
import { getFromLocalStorage } from '../../../../../utilities/storageUtility';
import AcgTimePicker from '../../../../atoms/TimePicker';
import { trackPageActionLog } from '../../../../utils/newRelicFuncations';

const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    }
};

type ComponentProps = { data: any };
const SimpleLoginTemplate = (props: ComponentProps) => {
    const { data } = props;
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [validationSchema, setValidationSchema] = useState({});
    const [disableLogin, setDisableLogin] = useState(true);
    const dispatch = useDispatch();
    const formRef = useRef<any>();
    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const acgStateSelector = createStructuredSelector({
        acgSlice: acgSelector()
    });
    const { acgSlice: state } = useSelector(acgStateSelector);

    const handleClick = () => {
        setShowPassword((prev) => !prev);
    };
    const [initialValues] = useState({
        emailId: '',
        password: ''
    });

    useEffect(() => {
        setValidationSchema(Yup.object().shape(LOGIN_YUPSCHEMA));
    }, []);

    const handleSubmit = async () => {
        setIsLoading(true);
        const bundle = {
            payload: {
                urlPath: ACTION_CODES.LOGIN,
                requestType: 'POST',
                reqObj: {
                    email: formRef?.current?.values?.emailId,
                    password: formRef?.current?.values?.password
                }
            },
            uniqueScreenIdentifier: { isLoggedIn: true, emailForOTP: formRef?.current?.values?.emailId },
            storeKey: STORE_KEYS.LOGIN_DATA
        };
        dispatch(executeACGAction(bundle));
    };

    const getRoutes = (__data: any) => {
        const newRoutes: any = [];
        console.log('-----routes', __data);
        // if(__data && __data.length > 0){
        // __data =   [...__data,
        //     {
        //         FeatureCode:"GOLDBATCH",
        //         FeatureHeaderName: "Golden Batch",
        //         FeatureName: "Golden Batch",
        //         FeatureType: "Admin",
        //         IsEditable: "Y",
        //         IsHomePage: "N",
        //         ModuleCode: "ADMIN",
        //         ModuleName: "Admin"
        //     }
        // ]
        // }
        __data?.forEach((newroute: any) => {
            ROUTES_PATH?.forEach((route: any) => {
                const __route = { ...route };
                if (newroute.FeatureCode === route.FeartureCode) {
                    __route.isAccess = true;
                    __route.isEditable = newroute?.IsEditable === 'Y';
                    __route.isHomePage = newroute?.IsHomePage === 'Y';
                    __route.title = newroute?.FeatureName;
                    __route.Module = newroute?.ModuleName;
                    newRoutes.push(__route);
                }
            });
        });
        return newRoutes.filter((v: any, i: any, self: any) => i === self?.findIndex((t: any) => t.path == v.path));
    };

    useEffect(() => {
        if (state?.loginData?.isLoggedIn) {
            if (state?.loginData?.body?.data?.isOTPSend) {
                data?.setTemplateType('validateOTP');
            } else {
                if (state?.loginData?.statusCode === 200) {
                    trackPageActionLog({
                        actionName:"LoggedInUser",
                        action:"User Logged In",
                        LoggedInUser:state?.loginData?.body?.data?.EmailId,
                        loginTime:new Date().toISOString()
                    })
                    const newRoutes = getRoutes(state?.loginData?.body?.data?.features);
                    dispatch(
                        setStoreKey({
                            storeKey: STORE_KEYS.USER_DTL,
                            value: state?.loginData?.body?.data
                        })
                    );
                    dispatch(
                        setStoreKey({
                            storeKey: STORE_KEYS.DEFAULT_PAGE,
                            value: newRoutes?.filter((d: any) => d.isHomePage)?.[0]?.path || newRoutes?.[0]?.path
                        })
                    );
                    const { body, ...newLoginData } = state?.loginData;
                    dispatch(
                        setStoreKey({
                            storeKey: STORE_KEYS.LOGIN_DATA,
                            value: newLoginData
                        })
                    );
                    dispatch(
                        setStoreKey({
                            storeKey: STORE_KEYS.ROUTES_PRIVILEGES,
                            value: newRoutes
                        })
                    );
                    const defaultPath = newRoutes?.filter((d: any) => d.isHomePage)?.[0]?.path || newRoutes?.[0]?.path;
                    history.push(defaultPath);
                } else if (state?.loginData?.statusCode === 202) {
                    data?.setValues({
                        ...data?.values,
                        email: formRef?.current?.values?.emailId,
                    });
                    data?.setTemplateType('validateOTPRestPassword');
                }
            }
        }
    }, [state?.loginData?.isLoggedIn]);

    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };
        setSnackbarOptions(snackbarError);
    };

    useEffect(() => {
        if (state?.err) {
            if (state?.err?.message?.toLowerCase()?.includes('is locked')) {
                data?.setTemplateType('lockAccount');
                dispatch(resetErr());
            } else {
                if (window.newrelic) {
                    window.newrelic.noticeError(state?.err.message);
                }
                handleSnackbarError(state?.err);
                dispatch(resetErr());
            }
            setIsLoading(false); 
        }
    }, [state?.err]);

    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);

    return (
        <Paper elevation={2} className="loginPaper simpleLogin">
            <Snackbar className="login-snackbar" options={snackbarOptions} handleClose={closeSnackbar} />
            <div className="loginFormHeader">
                <Typography className="welcomeText">Welcome !</Typography>
                <Typography className="loginText">Enter your login details</Typography>
            </div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={() => {
                    handleSubmit();
                }}
                validateOnBlur={false}
                innerRef={formRef}
            >
                {(formikprops) => {
                    const { values, handleChange, handleBlur, isValid, errors, touched } = formikprops;
                    return (
                        <Form>
                            <Label label="Email ID" />
                            <TextInput
                                variant="outlined"
                                maxLength={50}
                                autoFocus
                                fullWidth
                                type="text"
                                name="emailId"
                                value={values.emailId}
                                formInput="textBoxDiv"
                                onChange={(e) => {
                                    setDisableLogin(false);
                                    handleChange(e);
                                }}
                                onBlur={handleBlur}
                                placeholder="Enter Email ID"
                                helperText={errors.emailId && touched.emailId ? errors.emailId : ''}
                            />

                            <Label className="password-class" label="Password" />
                            <TextInput
                                variant="outlined"
                                maxLength={50}
                                fullWidth
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                formInput="textBoxDiv"
                                onChange={(e) => {
                                    setDisableLogin(false);
                                    handleChange(e);
                                }}
                                placeholder="Enter Password"
                                endAdornment={
                                    <IconButton tabIndex={-1} className="password-eye" onClick={handleClick}>
                                        {showPassword ? (
                                            <img src={VisibilityIcon} alt="logo" />
                                        ) : (
                                            <img src={VisibilityOffIcon} alt="logo" />
                                        )}
                                    </IconButton>
                                }
                                value={values.password}
                                onBlur={handleBlur}
                                helperText={errors.password && touched.password ? errors.password : ''}
                            />
                            <p className="forgotPwd">
                                <span onClick={() => history.push(paths.FORGOT_PWD.path)} className="forgotPwdText">
                                    Forgot Password?
                                </span>
                            </p>
                            <Button
                                formInput="buttonDiv"
                                className="simpleLoginButton"
                                fullWidth
                                name="Login"
                                disabled={disableLogin || !isValid}
                                type="submit"
                                variant="contained"
                                secondary={false}
                                isLoading = {isLoading}
                            />
                        </Form>
                    );
                }}
            </Formik>
        </Paper>
    );
};

export default SimpleLoginTemplate;
