import { useState, useEffect } from 'react';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../../constants/apiConstants';
import ACGButton from '../../../../atoms/Button';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import DownArrow from '../../../../../assets/down-arrow.svg';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import useGetState from '../../../../utils/hooks/useGetState';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import DropDown from '../../../../atoms/Dropdown';
import Autocomplete from '@mui/material/Autocomplete';
import CustomDatePicker from '../../../../atoms/CustomDatePicker';
import TextInput from '../../../../atoms/TextInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Label from '../../../../atoms/Label/index';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import { Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TrailAnalysisService from '../../trialAnalysisServices';
import "../../ta.css"

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function CreateForm(props: any) {
    const editing = props.trailKey !== '' ? true : false;
    const date = new Date();
    date.setDate(date.getDate())
    const [inDate, setInDate] = useState(date);
    const d = new Date();
    const [endDate, setEndDate] = useState(new Date(d.setDate(d.getDate())))
    const [errorDate, setErrorFalse] = useState(false);
    const [lotNumber, setLotNumber] = useState<any>("");
    const [batchSize, setBatchSize] = useState<any>("");
    const [processKey, setProcessKey] = useState('');
    const [cppNames, setCppNames] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [customerKey, setCustomerKey] = useState('');
    const [productsKey, setProductsKey] = useState('');
    const [recipieData, setRecipeData] = useState<any>([]);
    const [recipieKey, setRecipieKey] = useState('');
    const [bprType, setBprType] = useState("Y");
    const [trailClosed, setTrailClosed] = useState(true);
    const [responseMessage, setResponseMessage] = useState('');
    const state = useGetState();
    const token = state?.[STORE_KEYS.USER_DTL].token;
    const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;
    const maxDate = () => {
        var future = new Date();
        let dat = future.setDate(future.getDate() - 2);
        return dat
    }

    const minDate = () => {
        var future = new Date();
        let dat = future.setDate(future.getDate() - 3);
        return dat
    }

    const disabledate = (date: any) => {
        var past = new Date();
        past.setDate(past.getDate() - 365);
        return past;
    }

    const handleInDateChange = (e: any) => {
        setInDate(new Date(e))
        let ed = endDate.getTime();
        let sd = new Date(ed).getTime();
        if (ed < sd) {
            setErrorFalse(true);
        } else {
            setErrorFalse(false);
        }
    }

    const handleEndDateChange = (e: any) => {
        setEndDate(new Date(e));
        let ed = new Date(e);
        let sd = inDate;
        if (ed < sd) {
            setErrorFalse(true);
        } else {
            setErrorFalse(false);
        }
    }

    useEffect(() => {
        if (editing && props.trailKey !== '') {
            props.setNewKey(props.trailKey);
            const body = { trailKey: props.trailKey,token:token }
            TrailAnalysisService.getTrailAnalysisDetails(body)
                .then(async (response: any) => {
                    const result = response?.data.message[0];
                    const data = result[0];
                    const date = new Date();
                    date.setDate(date.getDate())
                    data[0].TrialClosureDate !== null ? setEndDate(new Date(data[0].TrialClosureDate)) : setEndDate((new Date(date)))
                    setLotNumber(data[0].LotNumber);
                    setBatchSize(data[0].TrialBatchSize);
                    setBprType(data[0].IsCPPBPRManualEntry);
                    const d = new Date(data[0].TrialStartDate)
                    setInDate(d);
                    setProcessKey(props.processData.find((i: any) => i.code === data[0].TrialProcessKey).code);
                    setCustomerKey(props.customersData.find((i: any) => i.code === data[0].TrialCustomerKey).code);
                    setProductsKey(props.productsData.find((i: any) => i.code === data[0].TrialProductKey).code);

                    const cppKeys = data[0].TrialCPPParamKeys.split(',');
                    const reverseCPP: any = [];
                    props.cppData.map((i: any) => {
                        if (cppKeys.includes(i.TrialCPPParamKey.toString())) {
                            reverseCPP.push(i)
                        }
                    })
                    setCppNames(reverseCPP);
                    const body = { "productKey": data[0].TrialProductKey, token:token }
                    TrailAnalysisService.getTrailAnalysisRecipie(body)
                        .then((response: any) => {
                            const result = response?.data.message[0];
                            const tmpTableData = result[0];
                            setRecipeData([
                                ...tmpTableData?.map((item: any) => ({ code: item.TrialRecipeKey, name: item.TrialRecipeName }))

                            ]);

                            setRecipieKey(result[0].find((i: any) => i.TrialRecipeKey === data[0].TrialRecipeKey).TrialRecipeKey);

                        })
                        .catch((err) => {
                            console.log(err);
                        });

                })

                .catch((err) => {
                    console.log(err);
                });
        }
    }, [props.trailKey, props.activeStep])

    useEffect(() => {
        if (props.trailKey === '') {
            reset();
        }
    }, [props.trailKey])

    const handleChangeProcess = (event: any) => {
        setProcessKey(event.target.value);
    };

    const handleChangeCustomer = (event: any) => {
        setCustomerKey(event.target.value);
        setProductsKey('');
        setRecipieKey('');
    }

    const handleChangeProducts = (event: any) => {
        setProductsKey(event.target.value);
        getRecipieList(event.target.value);
        setRecipieKey('');
    }

    const getRecipieList = (id: any) => {

        const data = { "productKey": id, token: token }
        TrailAnalysisService.getTrailAnalysisRecipie(data)
            .then((response: any) => {
                const result = response?.data.message[0];
                const tmpTableData = result[0];
                setRecipeData([
                    ...tmpTableData?.map((item: any) => ({ code: item.TrialRecipeKey, name: item.TrialRecipeName }))

                ]);
            })
            .catch((err) => {
                console.log(err);
            });
    }
    const reset = async () => {
        // props.setTrailKey('');
        const date = new Date();
        date.setDate(date.getDate());
        const d = new Date();
        setEndDate(new Date(d.setDate(d.getDate())))
        setInDate(date);
        setLotNumber('');
        setBatchSize('');
        setProcessKey('');
        setCppNames([]);
        setBprType('Y');
        setCustomerKey('');
        setProductsKey('');
        setRecipeData([]);
        setRecipieKey('');
        setResponseMessage('');
    }

    const options = {
        AD_SUCCESS: {
            message: 'New trial created',
            open: true,
            type: 'success'
        },
        AD_FAIL: {
            message: props.trailKey === '' ? 'Trial is already created for same customer,product & recipe.':'Closure date should be greater than start date',
            open: true,
            type: 'remark'
        },
    };
    
    const createTrailAnalysis = () => {
        setIsLoading(true);
        const cppKeys: any = []
        cppNames?.map((i: any) => cppKeys.push(i.TrialCPPParamKey));
        const body = {
            userId: loggedInUser,
            lotNumber: lotNumber,
            batchSize: batchSize,
            processKey: processKey,
            startDate: inDate,
            cppKeys: cppKeys,
            bprType: bprType,
            customerKey: customerKey,
            productKey: productsKey,
            recipieKey: recipieKey,
            endDate: props.trailKey !== '' ? endDate : null,
            type: props.trailKey !== '' ? 'Edit' : "Add",
            token: token
        }

        TrailAnalysisService.createTrailAnalysis(body)
            .then((response: any) => {

                const result = response?.data.message[0];
                const finalResult = result[0]
                if (finalResult[0].Result === "Error") {
                    props.drawerClose();
                    setResponseMessage(finalResult[0].ResultMessage);
                    props.setSnackbarOpts(options.AD_FAIL);
                    reset();
                }
                else {
                    reset();
                    props.setNewKey(finalResult[0].TrialKey)
                    props.handleNext();

                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const yesDisabled = (errorDate === true || customerKey === '' || processKey === '' || recipieKey === '' || productsKey === '' || batchSize === '' || lotNumber === '' || cppNames.length === 0 || batchSize < 0 || lotNumber < 0) ? true : false;

    const handleChangeCppNames = (id: any) => {
        let newSelected: any = [];
        if (props.presetKey === '') {
            const selectedIndex = cppNames.indexOf(id);
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(cppNames, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(cppNames.slice(1));
            } else if (selectedIndex === cppNames.length - 1) {
                newSelected = newSelected.concat(cppNames.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    cppNames.slice(0, selectedIndex),
                    cppNames.slice(selectedIndex + 1)
                );
            }
        }
        else {
            const selectedIndex = cppNames.findIndex((t: any) => t.TrialCPPParamKey === id.TrialCPPParamKey);
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(cppNames, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(cppNames.slice(1));
            } else if (selectedIndex === cppNames.length - 1) {
                newSelected = newSelected.concat(cppNames.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    cppNames.slice(0, selectedIndex),
                    cppNames.slice(selectedIndex + 1)
                );
            }
        }
        setCppNames(newSelected);
    }
    const handleChangeBPRType = (event: any) => {
        setBprType(event.target.value);
    }
    const handleChangeRecipie = (event: any) => {
        setRecipieKey(event.target.value);
    }
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const DropDownIcon = (prop: any) => (
        <img style={{ marginRight: '5px', marginTop: '-2px' }} src={DownArrow} {...prop} />
    );
    const renderButtons = () => {
        return (
            <div className={'btns noWrap'}>
                <ACGButton
                    name={'Cancel'}
                    handleClick={()=>{props.drawerClose();  reset(); props.handleReset()}}
                    secondary={true}
                    className={'first-btn'}
                    type="button"
                    variant="contained"
                />
                <ACGButton
                    name={'Next'}
                    handleClick={() => { createTrailAnalysis() }}
                    type="submit"
                    className="second-btn"
                    variant="contained"
                    disabled={yesDisabled ? true : false}
                    isLoading = {isLoading}
                />
            </div>
        );
    };
    return (
        <Container className="createReport">
            <Box>
                <div className='formwrap all-labelcls'>
                    <FormControl fullWidth>
                        <Grid container spacing={2}>
                            <Grid item xs={3} className="dateuichangetop">
                                <FormControl fullWidth>
                                    <Label label="Trail Start Date" className={'CMlabelDiv'} />
                                    {props.trailKey === '' ?
                                        <CustomDatePicker
                                            name="startDate"
                                            value={inDate}
                                            className="inputDiv"
                                            onChange={(val: any) => {
                                                handleInDateChange(val);
                                            }}
                                            inputFormat="dd/MM/yyyy"
                                            placeholder="Select Start Date"

                                        />
                                        :
                                        <TextInput id="outlined-basic"
                                            variant="outlined"
                                            sx={{ marginBottom: "20px", height: '20px' }}
                                            value={inDate.toString()}
                                            // size='50'
                                            disabled={props.trailKey === '' ? false : true}
                                        />
                                    }
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth >
                                    <Label label="Enter Lot Number" className={'CMlabelDiv'} />
                                    <TextInput id="outlined-basic"
                                        fullWidth
                                        disabled={props.trailKey === '' ? false : true}
                                        type={props.trailKey === '' ? "number" : undefined}
                                        variant="outlined"
                                        sx={{
                                            marginBottom: "20px", height: '20px', "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#ffffff",
                                            }
                                        }}
                                        value={lotNumber}
                                        onChange={(e: any) => {
                                            const value = e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3)
                                            setLotNumber(value.replace(/\D/g, ""));
                                        }}

                                        maxLength={3}
                                        InputProps={{
                                            inputProps: {
                                                min: 0, maxLength: 3, max: 3, inputMode: "numeric",
                                                pattern: "[0-9]*"
                                            }
                                        }}
                                       
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth>
                                    <Label label="Enter Batch Size" className={'CMlabelDiv'} />
                                    <TextInput
                                        fullWidth

                                        id="outlined-basic"
                                        type={props.trailKey === '' ? "number" : undefined}
                                        variant="outlined"
                                        sx={{ marginBottom: "20px", height: '20px' }}
                                        value={batchSize}
                                        onChange={(e: any) => {
                                            const value = e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3)
                                            setBatchSize(value.replace(/\D/g, ""));
                                        }}

                                        maxLength={3}
                                        InputProps={{
                                            inputProps: {
                                                min: 0, maxLength: 3, max: 3, inputMode: "numeric",
                                                pattern: "[0-9]*"
                                            }
                                        }}
                                        disabled={props.trailKey === '' ? false : true}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth>
                                    <Label label="Select Process" className={'CMlabelDiv'} />
                                    <DropDown
                                        onChange={handleChangeProcess}
                                        disabled={props.trailKey === '' ? false : true}
                                        className="inputDiv"
                                        name="dropdownModel"
                                        items={props.processData}
                                        placeHolder="Select Process"
                                        value={processKey}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <Label label="Select CPP's" className={'CMlabelDiv'} />
                                    <FormControl sx={{ width: '100%' }}>
                                        {cppNames?.length > 0 ? null : (
                                            <InputLabel
                                                style={{
                                                    fontFamily: 'Montserrat',
                                                    fontSize: '13px',
                                                    paddingTop: '0px',
                                                    paddingLeft: '12px',
                                                    color: '#b1c2df',
                                                    lineHeight: '1',
                                                    opacity: '0.7'
                                                }}
                                                id="demo-multiple-checkbox-label"
                                            >
                                                Select CPP's
                                            </InputLabel>
                                        )}
                                        <Select
                                            IconComponent={DropDownIcon}
                                            labelId="demo-multiple-checkbox-label"
                                            id="demo-multiple-checkbox"
                                            sx={{ marginBottom: "20px", backgroundColor: "#1d1e2c", borderRadius: 3, marginTop: '0px', color: "#b1c2df", lineHeight: "1" }}
                                            multiple
                                            value={cppNames}
                                            input={<OutlinedInput />}
                                            renderValue={(selected) => selected.map((x: any) => x.TrialCPPParamName).join(', ')}
                                            MenuProps={MenuProps}
                                        >
                                            {props.cppData?.map((i: any) => (
                                                <MenuItem value={i} onClick={() => handleChangeCppNames(i)}>
                                                    <Checkbox
                                                        checked={
                                                            cppNames.findIndex((item: any) => item.TrialCPPParamKey === i.TrialCPPParamKey) >= 0
                                                        }
                                                    />
                                                    <ListItemText primary={i.TrialCPPParamName} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth>
                                    <Label label="Plant Name" className={'CMlabelDiv'} />
                                    <TextField id="outlined-basic"
                                    className='plantNameTA'
                                        variant="outlined"
                                        sx={{ marginBottom: "20px", height: '40px', backgroundColor: "#1D1E2C", borderRadius: '10px', borderWidth: "1px", borderColor: "#2B2D42" }}
                                        value="ACG Lab"
                                        // size='50'
                                        disabled={true}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth>
                                    <Label label="Select Customer" className={'CMlabelDiv'} />
                                    <DropDown
                                        onChange={handleChangeCustomer}
                                        disabled={props.trailKey === '' ? false : true}
                                        className="inputDiv"
                                        name="dropdownModel"
                                        items={props.customersData}
                                        placeHolder="Select Customer"
                                        value={customerKey}
                                    />

                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth>
                                    <Label label="Select Products" className={'CMlabelDiv'} />
                                    <DropDown
                                        onChange={handleChangeProducts}
                                        disabled={props.trailKey === '' ? false : true}
                                        className="inputDiv"
                                        name="dropdownModel"
                                        items={props.productsData}
                                        placeHolder="Select Products"
                                        value={productsKey}
                                    />

                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth>
                                    <Label label="Select Recipe" className={'CMlabelDiv'} />
                                    <DropDown
                                        onChange={handleChangeRecipie}
                                        disabled={props.trailKey === '' ? false : true}
                                        className="inputDiv"
                                        name="dropdownModel"
                                        items={recipieData}
                                        placeHolder="Select Recipe"
                                        value={recipieKey}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <Label label="BPR Type" className={'CMlabelDiv'} />
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group" className='radiolabls labcolor'
                                
                                >
                                    <FormControlLabel style={{color: "#ffff"}} value="Y" control={<Radio onChange={handleChangeBPRType} checked={bprType === 'Y' ? true : false} />} label="BPR Manual Entry" disabled={props.trailKey === '' ? false : true} />
                                    <FormControlLabel style={{color: "#ffff"}} value="N" control={<Radio onChange={handleChangeBPRType} checked={bprType === 'N' ? true : false} />} label="BPR Auto Calculate" disabled={props.trailKey === '' ? false : true} />
                                </RadioGroup>
                            </Grid>
                            {
                                props.trailKey !== '' ?
                                    <>
                                        <Grid item xs={3}>
                                            <FormControl fullWidth className='colorlab'>
                                                <Label label="Check For The Closure" className={'CMlabelDiv'} />
                                                <FormControlLabel style={{color: "#ffff"}} control={<Checkbox checked={trailClosed} onChange={() => setTrailClosed(!trailClosed)} />} label="Trail Closed" />

                                            </FormControl>
                                        </Grid>
                                        {trailClosed ?
                                            <Grid item xs={3} className="dateuichangetop">
                                                <FormControl fullWidth>
                                                    <Label label="Trail Closure End Date" className={'CMlabelDiv'} />

                                                    <CustomDatePicker
                                                        name="endDate"
                                                        value={endDate}
                                                        className="inputDiv"
                                                        onChange={(val: any) => {
                                                            handleEndDateChange(val);
                                                        }}
                                                        inputFormat="dd/MM/yyyy"
                                                        placeholder="Select End Date"

                                                    />
                                                </FormControl>
                                            </Grid>
                                            : null}
                                    </>
                                    :
                                    null
                            }
                        </Grid>
                    </FormControl>
                </div>
                <div className='butondrower'>
                {renderButtons()}
                </div>
            </Box>
        </Container>
    );
}