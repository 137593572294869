import { SetStateAction, useEffect, useRef, useState } from 'react';
import {
    Checkbox,
    Container,
    FormControl,
    ListItemText,
    MenuItem,
    Grid,
    Select,
    Typography,
    Box
} from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import dateFormat from 'dateformat';
import { SnackBarOptions, Dateformat } from '../../../UserReport/DownloadableReport/CommonUtil';
import Attachments from '../../../../atoms/Attachments';
import DownArrow from '../../../../../assets/down-arrow.svg';
import Edit from '../../../../../assets/images/edit.svg';
import { Formik, Form, FormikProps, useFormikContext } from 'formik';
import useGetState from '../../../../utils/hooks/useGetState';
import { useDispatch } from 'react-redux';
import { executeACGAction, resetErr, updateScreenIdentifiers } from '../../../../store/slice';
import ACGButton from '../../../../atoms/Button';
import Label from '../../../../atoms/Label/index';
import TextInput from '../../../../atoms/TextInput';
import DropDown from '../../../../atoms/Dropdown';
import CustomDatePicker from '../../../../atoms/CustomDatePicker';
import AcgTimePicker from '../../../../atoms/TimePicker';
import { fetchBlobImage } from '../../../../utils/helper';
import { convertBlobFileToBase64 } from '../../../../utils/helper';
import Axios from 'axios';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../../constants/apiConstants';
import { trackErrorsInNewRelic } from '../../../../utils/newRelicFuncations';

type ComponentProps = {
    drawerClose: Function;
    setCurrTab: Function;
    setReload: Function;
    setSnackbarOpts: Function;
    dtlData: any;
    selectedRow: any;
    isEdit: boolean;
    setDtlData: Function;
    setSelectedRow: Function;
    assetList: any;
    categoryList: any;
    actionTypeList: any;
    serviceRequestKey: any;
    getServiceRequestList: Function;
    fetchServiceRequestOrderDetails: Function;
    assignmentData: any;
};
type ModuleType = {
    startTime: any;
    startDate: any;
    scName: string | '';
    scValue: string | '';
    scDesc: string | '';
};

const CreateModule = (props: ComponentProps) => {
    const { serviceRequestKey, drawerClose, setCurrTab, setReload = () => { }, setSnackbarOpts = () => { }, dtlData, isEdit, setDtlData = () => { }, selectedRow, setSelectedRow = () => { }, assetList, categoryList, actionTypeList, getServiceRequestList = () => { }, fetchServiceRequestOrderDetails = () => { }, assignmentData, } = props;
    const formRef = useRef<any>(null);
    const [assignUserList, setAssignUserList] = useState<any[]>([]);
    const [assignUserKey, setAssignUserKey] = useState<number|string|any>('');
    const [adding, setAdding] = useState('A');
    const [changedTD, setChangedTD] = useState(false);
    const [changedTT, setChangedTT] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [initialValues, setInitialValues] = useState({
        startTime: new Date(),
        startDate: new Date(),
        scName: '',
        scValue: '',
        scDesc: '',
    });
    const [editImage, setEditImage] = useState(false);
    const [fName, setFname] = useState('');
    const state = useGetState();
    const token = state?.[STORE_KEYS.USER_DTL].token;
    const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;
    const dispatch = useDispatch();
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250
            }
        }
    };

    const options = {
        AD_SUCCESS: {
            message: `User ${adding === "A" ? 'assigned' : 'updated'} successfully!`,
            open: true,
            type: 'success'
        },
    };

    useEffect(() => {
        const data = assignmentData[0];
        setAssignUserKey(data.WorkOrderAssignedUserKey ? data.WorkOrderAssignedUserKey : '' );
        const payload = {
            userId: loggedInUser,
            machineKey: data?.Machinekey,
        };
        Axios({
            url: BASEURL + '/' + ACTION_CODES.GET_SERVICE_REQUEST_USER_LIST,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const tmpTableData = response.data.data;
                    setAssignUserList([
                        ...tmpTableData?.map((item: any) => ({ code: item.UserKey, name: item.UserName }))

                    ]);
                }
            })
            .catch((error) => {
             trackErrorsInNewRelic({error: error})

                return {
                    status: error.response.status
                };
            });
    }, [])

    const isDisabled = assignUserKey === '' ? true : false;
    const renderButtons = () => {
        return (
            <div className={'btns noWrap'}>
                <ACGButton
                    name={'Cancel'}
                    handleClick={drawerClose}
                    secondary={true}
                    className={'first-btn'}
                    type="button"
                    variant="contained"
                />
                <ACGButton
                    name={'Submit'}
                    handleClick={() => { handleSubmit() }}
                    type="submit"
                    className="second-btn"
                    variant="contained"
                    disabled={isDisabled ? true : false}
                    isLoading = {isLoading}
                />
            </div>
        );
    };

    const handleSubmit = async () => {
        const bundle = {
            payload: {
                urlPath: ACTION_CODES.ADD_SERVICE_REQUEST_USER_ASSIGNMENT,
                requestType: 'POST',
                reqObj: {
                    userId: loggedInUser,
                    userKey: assignUserKey,
                    workOrderKey: assignmentData[0]?.MaintBDWorkOrderKey,
                    // targetDate: dateFormat(initialValues.startDate, Dateformat.YYYY_MM_DD) + ' ' + initialValues.startTime.toString().split(' ')[4],
                    targetDate: dateFormat(initialValues.startDate, Dateformat.YYYY_MM_DD),
                    type: assignmentData[0]?.TargetClosedDate === null ? 'Add' : 'Edit',
                }
            },
            uniqueScreenIdentifier: { serviceReqUserAssignment: true },
            storeKey: STORE_KEYS.ADD_SERVICE_REQUEST_USER_ASSIGNMENT
        };
        dispatch(executeACGAction(bundle));
    };

    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };
        setSnackbarOpts(snackbarError);
    };

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_SERVICE_REQUEST_USER_ASSIGNMENT]?.serviceReqUserAssignment) {
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_SERVICE_REQUEST_USER_ASSIGNMENT,
                    uniqueScreenIdentifier: {
                        serviceReqUserAssignment: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.ADD_SERVICE_REQUEST_USER_ASSIGNMENT]?.data]);

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_SERVICE_REQUEST_USER_ASSIGNMENT]?.serviceReqUserAssignment) {
            if (state?.[STORE_KEYS.ADD_SERVICE_REQUEST_USER_ASSIGNMENT]?.status === "Success") {
                setReload(true);
                setCurrTab(1);
                setSnackbarOpts(options.AD_SUCCESS);
                drawerClose();
                serviceRequestKey !== '' ? fetchServiceRequestOrderDetails(serviceRequestKey) : undefined;
                getServiceRequestList();
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_SERVICE_REQUEST_USER_ASSIGNMENT,
                    uniqueScreenIdentifier: {
                        serviceReqUserAssignment: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.ADD_SERVICE_REQUEST_USER_ASSIGNMENT]?.serviceReqUserAssignment]);

    useEffect(() => {
        if (state?.err) {
            handleSnackbarError(state?.err);
            dispatch(resetErr());
        }
    }, [state?.err]);

    const handleChangeAssignUser = (event: { target: { value: any; }; }) => {
        setAssignUserKey(event.target.value);
    }

    const handleChangeAssignType = (event: { target: { value: SetStateAction<string>; }; }) => {
        setAdding(event.target.value);
    };
    return (
        <Container className="createReport">
            <Formik
                initialValues={initialValues}
                onSubmit={(values: any) => {
                    // handleSubmit(values);
                }}
                validateOnBlur={false}
                validateOnChange={false}
                innerRef={formRef}
            >
                {(formikprops: FormikProps<ModuleType>) => {
                    const { values, handleChange, handleBlur, errors, touched, setFieldValue } = formikprops;
                    return (
                        <Form>
                            {/* <Label label="Please Choose" className={'labelDiv'} />
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group" className='radiolabls'

                            >
                                <FormControlLabel style={{color:'#a0a2b8'}} value="A" control={<Radio onChange={handleChangeAssignType} checked={assignmentData[0].TargetClosedDate === null ? true : false}  disabled={assignmentData[0]?.TargetClosedDate === null ? false : true}/>} label="Add" />
                                <FormControlLabel style={{color:'#a0a2b8'}} value="E" control={<Radio onChange={handleChangeAssignType} checked={assignmentData[0].TargetClosedDate !== null ? true : false}  disabled={assignmentData[0]?.TargetClosedDate === null ? true : false} />} label="Edit" />
                            </RadioGroup> */}
                            <Label label="Select Engineer &nbsp; *" className={'labelDiv'} />
                            <DropDown
                                onChange={handleChangeAssignUser}
                                className="inputDiv"
                                name="dropdownModel"
                                items={assignUserList}
                                placeHolder="Select Engineer"
                                value={assignUserKey}
                            />
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                                    <Label label="Targeted Date &nbsp; *" className={'labelDiv'} />
                                    <CustomDatePicker
                                        name="startDate"
                                        value={assignmentData[0]?.TargetClosedDate !== null && !changedTD ? new Date(assignmentData[0]?.TargetClosedDate) : values.startDate}
                                        className="inputDiv"
                                        onChange={(val: any) => {
                                            setFieldValue('startDate', val);
                                            setInitialValues({ ...initialValues, startDate: val });
                                            setChangedTD(true)
                                        }}
                                        inputFormat="dd/MM/yyyy"
                                        placeholder="Select Start Date"
                                        minDate={new Date()}
                                    // helperText={errors.startDate && touched.startDate ? errors.startDate : ''}
                                    />
                                </Grid>
                                {/* <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
                                            <Label label="Targeted Time" className={'labelDiv'} />
                                            <AcgTimePicker
                                                className="inputDiv"
                                                value={assignedKey !== '' && !changedTT ? initialValues.startTime : values.startTime}
                                                handleChange={(value: any) => {
                                                    setFieldValue('startTimeBR', value?.$d);
                                                    setInitialValues({ ...initialValues, startTime: value?.$d });
                                                    setChangedTT(true);
                                                }}
                                            />
                                        </Grid> */}
                            </Grid>
                            <Typography variant="body1" sx={{color:"#ffff", marginTop: 4}}>Note: * fields are mandatory! </Typography>

                            {renderButtons()}
                        </Form>
                    );
                }}
            </Formik>
        </Container>
    );
};
export default CreateModule;
