import { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import MapCustomTabs from '../../atoms/ButtonTabs/MapCustomTabs';
import StatusCard from '../../atoms/DashboardStatus/StatusCard';
import Machines from './Machines';
import Sop from './sop';
import Models from './Models';
import TotalModels from '../../../assets/images/TotalModels.svg';
import TotalAssigned from '../../../assets/images/navigationicons/MachineManagementIcon.svg';
import TotalUnassigned from '../../../assets/images/TotalUnassigned.svg';
import DrawerDropdown from '../../atoms/DrawerDropdown';
import RightDrawer from '../../atoms/tempDrawer/indexAlt';
import AddMachine from './AddMachine';
import AddModel from './AddModel';
import AddSop from './AddSop';
import ViewPdf from './ViewPdf';
import { executeACGAction } from '../../store/slice';
import { ACTION_CODES, STORE_KEYS } from '../../constants/apiConstants';
import { useDispatch } from 'react-redux';
import useGetState from '../../utils/hooks/useGetState';
import SnackbarAlert from '../../atoms/Snackbar';
import { SCREEN_TITLES } from '../../constants/StringConstants';

const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    }
};

const MachineManagement = () => {
    const [currTab, setCurrTab] = useState(0);
    const [createValue, setCreateValue] = useState('');
    const [createDrawerOpen, setCreateDrawerOpen] = useState(false);
    const [drawerHeader, setDrawerHeader] = useState('Create');
    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const [sopEditData, setSopEditData] = useState<{ edit: boolean, values: any }>({ edit: false, values: null })
    const [pdfData, setPdfData] = useState<string>("")
    const [toogleSopList,setToogleSopList]=useState(false)

    const state = useGetState();
    const dispatch = useDispatch();
    const getCreateValue = (ele: string, header: string) => {
        setDrawerHeader(header ?? 'Create');
        setCreateValue(ele);
        setCreateDrawerOpen(true);
    };

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_RPT]?.machineParamAdded || state?.[STORE_KEYS.ADD_RPT]?.paramsMapped) {
            setCurrTab(1);
        }
    }, [state?.[STORE_KEYS.ADD_RPT]?.machineParamAdded, state?.[STORE_KEYS.ADD_RPT]?.paramsMapped]);

    const tabs = ['Model', 'Machine', 'SOP'];
    //sop is removed

    const navigateTab = () => {
        switch (currTab) {
            case 0:
                return <Models getSummary={getSummary} />;
            case 1:
                return <Machines getSummary={getSummary} />;
            case 2:
                return <Sop toogleSopList={toogleSopList} getSummary={getSummary} handleEdit={handleEdit} getCreateValue={getCreateValue} setPdfData={setPdfData}/>;
            default:
                return <Models getSummary={getSummary} />;
        }
    };

    const getSummary = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.MACHINE_SMRY
                },
                uniqueScreenIdentifier: { smryRecd: true },
                storeKey: STORE_KEYS.MACHMODEL_SMRY
            })
        );
    };

    const handleEdit = (values: any) => {
        setCreateDrawerOpen(true)
        setDrawerHeader("Edit")
        setCreateValue("SOP")
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: ACTION_CODES.GET_EXISTING_SOP_DETAILS,
                    reqObj: {
                        SOPDetailsKey: values.SOPDetailsKey
                    }
                },
                uniqueScreenIdentifier: { modLoaded: true },
                storeKey: STORE_KEYS.EXISTING_SOP_DETAILS
            })
        );
    }

    useEffect(() => {
        getSummary();
    }, []);

    useEffect(() => {
        console.log(state?.[STORE_KEYS.EXISTING_SOP_DETAILS])
        if (state?.[STORE_KEYS.EXISTING_SOP_DETAILS]?.body?.data) {
            setSopEditData({ edit: true, values: state?.[STORE_KEYS.EXISTING_SOP_DETAILS]?.body?.data[0] });
        }

    }, [state?.[STORE_KEYS.EXISTING_SOP_DETAILS]])

    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);

    return (
        <div className="dashboard">
            <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
            <Container maxWidth="xl" style={{ marginTop: '60px' }}>
                <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                    <div style={{ flex: '80%' }}>
                        <div className="headinglabel">{SCREEN_TITLES.MACHINE_MANAGEMENT}</div>
                        <StatusCard
                            type="feature"
                            totalClients={[
                                {
                                    count: state?.[STORE_KEYS.MACHMODEL_SMRY]?.body?.data?.[0]?.TotalModels,
                                    handleClick: () => { },
                                    image: TotalModels,
                                    text: 'Total Models'
                                },
                                {
                                    count: state?.[STORE_KEYS.MACHMODEL_SMRY]?.body?.data?.[0]?.TotalMachines,
                                    handleClick: () => { },
                                    image: TotalAssigned,
                                    text: 'Total Machines'
                                },
                                {
                                    count: state?.[STORE_KEYS.MACHMODEL_SMRY]?.body?.data?.[0]?.UnassignedMachines,
                                    handleClick: () => { },
                                    image: TotalUnassigned,
                                    text: 'Unassigned Machines'
                                }
                            ]}
                        />
                    </div>
                    <div
                        style={{
                            flex: '10%',
                            marginLeft: '20px',
                            marginTop: '59px',
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                        className="dashboardDropdownDiv"
                    >
                        <DrawerDropdown click={getCreateValue} items={['Model', 'Machine', 'SOP']} />
                    </div>
                </div>

                <div style={{ marginTop: '35px', zIndex: '0' }} className='sopdash'>
                    <MapCustomTabs
                        navigateTab={navigateTab}
                        currTab={currTab}
                        setCurrTab={setCurrTab}
                        tabsArray={tabs}
                        className="tabs"
                        position="relative"
                        moduleName='Machine Management Tabs'
                    />
                </div>
                <RightDrawer
                    handlerOpen={() => { }}
                    handlerClose={() => {
                        setCreateDrawerOpen(false)
                        setSopEditData({ edit: false, values: null })
                    }}
                    status={createDrawerOpen}
                    drawerHeader={`${drawerHeader} ${createValue}`}

                    children={
                        createValue === 'Machine' ? (
                            <AddMachine
                                closeDrawer={() => setCreateDrawerOpen(false)}
                                setSnackbarOpts={setSnackbarOptions}
                            />
                        ) : createValue === 'Model' ?
                            <AddModel
                                setSnackbarOptions={setSnackbarOptions}
                                closeDrawer={() => setCreateDrawerOpen(false)}
                            /> : createValue === 'SOP' ?
                            <AddSop
                                setSnackbarOptions={setSnackbarOptions}
                                closeDrawer={() => {
                                setCreateDrawerOpen(false)
                                setSopEditData({ edit: false, values: null })
                                }}
                                sopEditData={sopEditData}
                                setToogleSopList={setToogleSopList}
                                /> : createValue === 'Pdf' ?
                                <ViewPdf
                                pdf={pdfData}
                                 closeDrawer={() => setCreateDrawerOpen(false)}
                                /> 
                                : 
                                null

                    }
                    type={createValue == "SOP" ? "" : "model"}
                />
            </Container>
        </div>
    );
};

export default MachineManagement;
