import { useState, useEffect, useRef } from 'react';
import { AnyIfEmpty, useDispatch } from 'react-redux';
import { Container } from '@mui/material';
import MapCustomTabs from '../../atoms/ButtonTabs/MapCustomTabs';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../constants/apiConstants';
import { executeACGAction, updateScreenIdentifiers } from '../../store/slice';
import useGetState from '../../utils/hooks/useGetState';
import { _noop } from '../../utils/common';
import '../Admin/ClientManagement/index.scss';
import SnackbarAlert from '../../atoms/Snackbar';
import OEEDashboardHomePage from './OEEDashboardHome';
import OEEDetails from './OEEDetails';
import OEEDT from './OEEDowntimeDetails';
import Axios from 'axios';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import moment from 'moment';
import RightDrawer from '../../../app/atoms/tempDrawer/indexAlt';
import AIInsights from './OEEInsights';
import { lastTwoMonthsDaterange, startDateOfMonth, calculateDateDifference } from '../../utils/helper';
import { trackPageActionLog } from '../../utils/newRelicFuncations';

const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    },
    AD_SUCCESS: {
        message: 'Work Order Deleted.',
        open: true,
        type: 'success'
    },
    RM_SUCCESS: {
        message: 'Component Life Cycle Entry Deleted.',
        open: true,
        type: 'success'
    },
    AD_SUCCESS_WO: {
        message: 'Work order closed. ',
        open: true,
        type: 'success'
    }
};
const SystemConfiguration = () => {
    const state = useGetState();
    const [currTab, setTabChange] = useState(0);
    const [tabs, setTabs] = useState<any>([]);
    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const [drawerHeader, setDrawerHeader] = useState('AI');
    const [createValue, setCreateValue] = useState('');
    const [createDrawerOpen, setCreateDrawerOpen] = useState(false);
    const date = new Date();
    date.setDate(1);
    const [inDate, setInDate] = useState(date);
    const d = new Date();
    const [endDate, setEndDate] = useState(new Date());
    const [plantsData, setPlantsData] = useState<any[]>([]);
    const [_machines, set_Machines] = useState<any>([]);
    const [selectedMachineModel, setSelectedMachineModel] = useState<any>('');
    const [_selectedMachineModel, setSelected_MachineModel] = useState<any>('');
    const [machineModel, setMachineModel] = useState<any>([]);
    const [machines, setMachines] = useState<any>([]);
    const [selectedMachine, setSelectedMachine] = useState('');
    const [selectedMachines, setSelectedMachines] = useState<any>([]);
    const dispatch = useDispatch();
    const token = state?.[STORE_KEYS.USER_DTL].token;
    const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;
    const AIInsightsShow = state[STORE_KEYS.USER_DTL]?.AIInsightVisibleFlag;
    const [selectedPlant, setSelectedPlant] = useState<any>(null);
    const [aIInsightsList, setAIInsightsList] = useState<any>([]);
    const [selectedInsight, setSelectedInsight] = useState<any>('');
    const [right, setRight] = useState(false);
    const [defaultData, setDefaultData] = useState<any>([]);
    const [showLoader, setShowLoader] = useState(false);
    const [checkedOption, setCheckedOption] = useState<any>("MTD");
    const [chart1, setChart1] = useState([]);
    const [chart2, setChart2] = useState([]);
    const [chart3, setChart3] = useState([]);
    const [chart4, setChart4] = useState([]);
    const [downtTimeCat, setDownTimeCat] = useState<any>([]);
    const [selectedDTCategory, setSelectedDTCategory] = useState([-1]);
    const [products, setProducts] = useState<any>([]);
    const [batches, setBatches] = useState<any>([]);
    const [selectedProduct, setSelectedProduct] = useState<any>([]);
    const [selectedBatch, setSelectedBatch] = useState<any>([]);
    const [detailsRedirection, setDetailsRedirection] = useState(false);
    const [datePDRange, setDatePDRange] = useState(false); 
    const [dateDTRange, setDateDTRange] = useState(false);
    const [selectedChart, setSelectedChart] = useState(null);
    const componentRef = useRef<any>();
    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);

    const setCurrTab = (val: number) => {
       const noDays = calculateDateDifference(inDate, endDate);
        if(noDays > 60) updateYTD(false);
        setTabChange(val);
    };

    useEffect(() => {
        if(detailsRedirection) {
            setCurrTab(selectedChart === 1 ? 2 : 1);
        }
    },[detailsRedirection])

    const getCreateValue = (ele: string) => {
        setSelectedInsight(aIInsightsList[0]?.code);
        setDrawerHeader('AI');
        setCreateValue("Insights");
        setCreateDrawerOpen(true);
    };

    // useEffect(() => {
    //     const _details = JSON.parse(state[STORE_KEYS.USER_DTL]?.features[18]?.SubMenus);
    //     const subMenu = _details?.map((i: any) => { return i.SubMenuName });
    //     setTabs(subMenu);

    // }, [state[STORE_KEYS.USER_DTL]])

    useEffect(() => {
        const _details = [
            {
                "SubMenuName": "Overview",
                "SubMenuCode": "OEEH",
                "IsEditable": "Y"
            },
            {
                "SubMenuName": "Production",
                "SubMenuCode": "OEEPD",
                "IsEditable": "Y"
            },
            {
                "SubMenuName": "Downtime",
                "SubMenuCode": "OEEDT",
                "IsEditable": "Y"
            }
        ]
        const subMenu = _details?.map((i: any) => { return i.SubMenuName });
        setTabs(subMenu);

        trackPageActionLog({
            actionName:"OEE Dashboard",
            action:"Landed on OEE Dashboard Overview page",
            LoggedInUser:state[STORE_KEYS.USER_DTL]?.EmailId,
        })
    }, [])

    const updateYTD = (type:any) => {
        if(type) {
            const range = lastTwoMonthsDaterange();
            setInDate(new Date(range.from));
            setEndDate(new Date(range.to));
        } else {
            const date = new Date();
            date.setDate(date.getDate() - 30)
            setInDate(date);
            setEndDate(new Date());
        }
    }

    const updateMTD = (type:any) => {
        if(type) {
            const range = startDateOfMonth();
            setInDate(new Date(range.from));
            setEndDate(new Date(range.to));
            
        } else {
            const date = new Date();
            date.setDate(date.getDate() - 30)
            setInDate(date);
            setEndDate(new Date());
        }
       
    }

    const handleCheckboxChange = (option: any) => {
        if (checkedOption === option) {
            setCheckedOption(null);
        } else {
            setCheckedOption(option);
        }
    };

    const handleChangeCategories = (id: any) => {
        let data: any;
        setSelectedDTCategory((prevSelected: any) => {
            if (prevSelected.includes(id)) {
                data = prevSelected.filter((optionId: any) => optionId !== id);
                return data;
            } else {
                if (prevSelected.length < 5) {
                    data = [...prevSelected, id];
                    return data;
                } else {
                    return prevSelected;
                }
            }
        });
    };

    const handleChangePlant = (event: any) => {
        trackPageActionLog({
            actionName:'OEE-Dashboard',
            action:'Plant name changed',
            additionalInfo: `Plant Name changed to ${event.target.value}`,
            LoggedInUser:state[STORE_KEYS.USER_DTL]?.EmailId,
        })
        setSelectedPlant(event.target.value);
        setSelectedMachineModel('');
        setSelectedMachine('');
    };

    const handleMachineModel = (event: any) => {
        trackPageActionLog({
            actionName:'OEE-Dashboard',
            action:'Machine Model changed',
            additionalInfo: `Machine Model to ${event.target.value}`,
            LoggedInUser:state[STORE_KEYS.USER_DTL]?.EmailId,
        })
        setSelectedMachineModel(event.target.value);
        setSelectedMachine('');
    }

    const handleChange = async (data: any) => {
        setSelectedDTCategory([-1]);
        setSelectedMachine(data);
    };

    const handleChangeCategory = async (id: any) => {
        let data: any;
        setSelectedDTCategory((prevSelected: any) => {
            if (prevSelected.includes(id)) {
                data = prevSelected.filter((optionId: any) => optionId !== id);
                return prevSelected.filter((optionId: any) => optionId !== id);
            } else {
                data = [...prevSelected, id];
                return [...prevSelected, id];
            }
        });
    };

    useEffect(() => {
        (async () => {
            const payload = {
                userId: loggedInUser,
            };
            await Axios({
                url: BASEURL + '/' + ACTION_CODES.API_PMEC_GET_PLANTS,
                method: 'post',
                data: payload,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
                .then((response) => {
                    if (response.status == 200 && response?.data) {
                        const result = response?.data?.message;
                        const tmpTableData = result[0][0];
                        setPlantsData([
                            ...tmpTableData?.map((item: any) => ({ code: item.PlantKey, name: item.PlantName }))])
                        setSelectedPlant(tmpTableData[0]?.PlantKey)
                    }
                })
                .catch((error) => {
                    if (window.newrelic) {
                        window.newrelic.noticeError(error?.message);
                    }
                    return {
                        status: error.response.status
                    };
                });

        })();
    }, []);

    useEffect(() => {
        if (selectedPlant) {
            const payload = {
                userId: loggedInUser,
                plantKey: selectedPlant
            };

            (async () => {
                await Axios({
                    url: BASEURL + '/' + ACTION_CODES.API_PMEC_GET_MACHINE_MODEL,
                    method: 'post',
                    data: payload,
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })
                    .then((response) => {
                        if (response.status == 200 && response?.data) {
                            const result = response?.data?.message;
                            const tmpTableData: any = result[0][0];
                            setMachineModel([
                                ...tmpTableData?.map((item: any) => ({ code: item.MachineModelKey, name: item.MachineModelName }))])
                            setSelectedMachineModel(tmpTableData[0]?.MachineModelKey)
                            fetchAIInsightsList(tmpTableData[0]?.MachineModelKey);
                        }
                    })
                    .catch((error) => {
                        if (window.newrelic) {
                            window.newrelic.noticeError(error?.message);
                        }
                        return {
                            status: error.response.status
                        };
                    });
            })();

        }
    }, [selectedPlant])

    const fetchAIInsightsList = async (model: any) => {
        const payload = {
            loggedInUserKey: loggedInUser,
            machineModelKey: model,
            plantKey: 'All'
        };
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.API_PMEC_GET_AI_INSIGHTS,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then(async (response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;
                    const tmpTableData = result[0][0];
                    setAIInsightsList([
                        ...tmpTableData?.map((item: any) => ({ code: item.PromptCode, name: item.PromptName }))])

                }
            })
            .catch((error) => {
                if (window.newrelic) {
                    window.newrelic.noticeError(error?.message);
                }
                return {
                    status: error
                };
            });
    }

    const fetchMachines = async (model: any) => {
        const payload = {
            userId: loggedInUser,
            model: selectedMachineModel,
            plantKey: selectedPlant
        };
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.API_PMEC_GET_MACHINES,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;
                   
                    const tmpTableData = result[0][0];
                    setMachines([
                        ...tmpTableData?.map((item: any) => ({ code: item.MachineKey, name: item.MachineName, alias: item.MachineSerialNo }))])
                }
            })
            .catch((error: any) => {
                if (window.newrelic) {
                    window.newrelic.noticeError(error?.message);
                }
                return {
                    status: error
                };
            });
    }

    useEffect(() => {
        if (machines.length > 0) {
            if(selectedMachine === '') {
                setSelectedMachine(machines[0]?.code);
            } else {
                setSelectedMachine(selectedMachine)
            }
        }
    }, [machines]);

    useEffect(() => {
        if (selectedMachineModel !== "" && selectedPlant ) {
            setMachines([]);
            fetchMachines(machineModel);
            setSelectedDTCategory([-1]);
            setChart4([]);
        }
    }, [inDate, endDate, selectedMachineModel, selectedPlant, checkedOption]);

    useEffect(() => {
        if (selectedMachineModel != "" ) {
            if(!detailsRedirection) setSelectedMachine('');
            setSelectedDTCategory([-1]);
            getDetailsChartsData();
            getCategoriesList();
        }
    }, [inDate, endDate, checkedOption, selectedMachineModel])

    useEffect(() => {
        if (selectedMachine != "") {
            setChart4([]);
            getDownTimeChartsData();
        }
    }, [inDate, endDate, checkedOption, selectedMachine])

    useEffect(() => {
        const controller = new AbortController();
        if (selectedDTCategory.length > 0 && selectedMachine != '') {
            getMachineDownTimeChartsData(controller.signal);
        }else{
            setChart4([])
        }

        // Cleanup function to abort request on unmount or change
    return () => controller.abort();
    }, [selectedDTCategory, selectedMachine])

    const getDetailsChartsData = async () => {
        const payload = {
            userId: loggedInUser,
            model: selectedMachineModel,
            plantKey: selectedPlant,
            type: checkedOption !== null ? checkedOption : 'Date',
            from: new Date(inDate).toISOString().split('T')[0],
            to: new Date(endDate).toISOString().split('T')[0],
        };
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.API_OEE_GET_PLANT_OEE,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;
                    const tmpTableData = result[0][0];
                    setChart1(tmpTableData);
                }
            })
            .catch((error: any) => {
                if (window.newrelic) {
                    window.newrelic.noticeError(error?.message);
                }
                return {
                    status: error
                };
            });

        await Axios({
            url: BASEURL + '/' + ACTION_CODES.API_OEE_GET_DT,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;
                    const tmpTableData = result[0][0];
                    setChart2(tmpTableData);
                }
            })
            .catch((error: any) => {
                if (window.newrelic) {
                    window.newrelic.noticeError(error?.message);
                }
                return {
                    status: error
                };
            });
    }

    const getDownTimeChartsData = async () => {
        const payload = {
            userId: loggedInUser,
            machine: selectedMachine,
            type: checkedOption !== null ? checkedOption : 'Date',
            from: new Date(inDate).toISOString().split('T')[0],
            to: new Date(endDate).toISOString().split('T')[0]
        };
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.API_OEE_GET_DETAILS,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;
                    const tmpTableData = result[0][0];
                    setChart3(tmpTableData);
                }
            })
            .catch((error: any) => {
                if (window.newrelic) {
                    window.newrelic.noticeError(error?.message);
                }
                return {
                    status: error
                };
            });
    }

    const getCategoriesList = async () => {
        const payload = {
            userId: loggedInUser,
            model: selectedMachineModel,
            plantKey: selectedPlant
        };
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.API_OEE_GET_CATEGORIES,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;
                    const tmpTableData = result[0][0];
                    setDownTimeCat(tmpTableData)
                }
            })
            .catch((error: any) => {
                if (window.newrelic) {
                    window.newrelic.noticeError(error?.message);
                }
                return {
                    status: error
                };
            });
    }

    const getMachineDownTimeChartsData = async (signal:any) => {
        const payload = {
            userId: loggedInUser,
            machine: selectedMachine,
            type: checkedOption !== null ? checkedOption : 'Date',
            from: new Date(inDate).toISOString().split('T')[0],
            to: new Date(endDate).toISOString().split('T')[0],
            catKey: selectedDTCategory.length === downtTimeCat.length ? '-1' : selectedDTCategory.toString()
        };
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.API_OEE_GET_MACHINE_DT,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            },
            signal: signal // Pass the abort signal
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;
                    const tmpTableData = result[0][0];
                    setChart4(tmpTableData);
                }
            })
            .catch((error: any) => {
                if (error.name === 'AbortError') {
                    // Request was cancelled, do nothing
                    return;
                }
                if (window.newrelic) {
                    window.newrelic.noticeError(error?.message);
                }
                return {
                    status: error
                };
            });
    }

    const navigateTab = () => {
        switch (currTab) {
            case 0:
                return <OEEDashboardHomePage
                    inDate={inDate}
                    setInDate={setInDate}
                    setSelectedMachine={setSelectedMachine}
                    endDate={endDate}
                    updateMTD={updateMTD}
                    updateYTD={updateYTD}
                    setEndDate={setEndDate}
                    plantsData={plantsData}
                    selectedPlant={selectedPlant}
                    machineModel={machineModel}
                    handleMachineModel={handleMachineModel}
                    handleChange={handleChange}
                    machines={machines}
                    selectedMachine={selectedMachine}
                    checkedOption={checkedOption}
                    setCheckedOption={setCheckedOption}
                    handleCheckboxChange={handleCheckboxChange}
                    handleChangeCategories={handleChangeCategories}
                    selectedMachineModel={selectedMachineModel}
                    handleChangePlant={handleChangePlant}
                    handleChangeCategory={handleChangeCategory}
                    selectedDTCategory={selectedDTCategory}
                    downtTimeCat={downtTimeCat}
                    chart1={chart1}
                    chart2={chart2}
                    chart3={chart3}
                    chart4={chart4}
                    AIInsightsShow={AIInsightsShow}
                    aIInsightsList={aIInsightsList}
                    getCreateValue={getCreateValue}
                    setDetailsRedirection={setDetailsRedirection}
                    setCurrTab={setCurrTab}
                    currTab={currTab}
                    setSelectedChart={setSelectedChart}
                />;
            case 1:
                return <OEEDetails
                    selectedPlant={selectedPlant}
                    machines={machines}
                    selectedMachine={selectedMachine}
                    machineModels={machineModel}
                    handleMachineModel={handleMachineModel}
                    selectedMachineModel={selectedMachineModel}
                    handleChange={handleChange}
                    inDate={inDate}
                    setInDate={setInDate}
                    setEndDate={setEndDate}
                    endDate={endDate}
                    updateMTD={updateMTD}
                    updateYTD={updateYTD}
                    products={products}
                    setProducts={setProducts}
                    batches={batches}
                    setBatches={setBatches}
                    selectedProduct={selectedProduct}
                    setSelectedProduct={setSelectedProduct}
                    selectedBatch={selectedBatch}
                    setSelectedBatch={setSelectedBatch}
                    setDetailsRedirection={setDetailsRedirection}
                    setCurrTab={setCurrTab}
                    handleCheckboxChange={handleCheckboxChange}
                    checkedOption={checkedOption}
                    setDatePDRange={setDatePDRange}
                    datePDRange={datePDRange}
                    detailsRedirection={detailsRedirection}
                />;
            case 2:
                return <OEEDT
                    selectedPlant={selectedPlant}
                    machines={machines}
                    selectedMachine={selectedMachine}
                    machineModels={machineModel}
                    handleMachineModel={handleMachineModel}
                    selectedMachineModel={selectedMachineModel}
                    handleChange={handleChange}
                    inDate={inDate}
                    setInDate={setInDate}
                    setEndDate={setEndDate}
                    endDate={endDate}
                    updateMTD={updateMTD}
                    updateYTD={updateYTD}
                    products={products}
                    setProducts={setProducts}
                    batches={batches}
                    setBatches={setBatches}
                    selectedProduct={selectedProduct}
                    setSelectedProduct={setSelectedProduct}
                    selectedBatch={selectedBatch}
                    setSelectedBatch={setSelectedBatch}
                    detailsRedirection={detailsRedirection}
                    handleCheckboxChange={handleCheckboxChange}
                    checkedOption={checkedOption}
                    setDateDTRange={setDateDTRange}
                    dateDTRange={dateDTRange}
                    setDetailsRedirection={setDetailsRedirection}
                />;
            default:
                return <h1>{currTab}</h1>;
        }
    };

    const refreshInights = async (isFilter = false) => {
        setDefaultData([]);
        // setShowLoader(true);

        const formData = new FormData();
        formData.append("insight_category", selectedInsight);
        await Axios({
            url: BASEURL + '/genaiapp/chat/create_thread',
            method: 'post',
            data: formData,
            headers: { "Content-Type": "multipart/form-data" }
        })
            .then(async (response) => {
                if (response.status == 200) {
                    // setShowLoader(false);
                    const aiData: any = [];
                    aiData['isUser'] = false;
                    const obj = { [selectedInsight]: [] }
                    const isCategoryPresent = defaultData.some((item: any) => item.hasOwnProperty(selectedInsight));
                    if (!isCategoryPresent) {
                        setDefaultData([...defaultData, obj]);
                    } else {
                        setDefaultData([obj]);
                    }

                }
            })
            .catch((error) => {
                if (window.newrelic) {
                    window.newrelic.noticeError(error?.message);
                }
                return {
                    status: error
                };
            });
    };

    const handleDownloadPdf = async () => {
        const element = componentRef.current;

        // Style adjustments for PDF rendering
        const originalFontSize = element.style.fontSize;
        const originalHeight = element.style.height;
        const originalOverflow = element.style.overflow;
        element.style.fontSize = '8px';
        element.style.height = `${element.scrollHeight}px`;
        element.style.overflow = 'unset';

        // Convert each image to base64
        const images = element.querySelectorAll('img');
        await Promise.all(Array.from(images).map(async (img: any) => {
            if (img.src.includes('172.16.123.78:7010')) {
                img.src = img.src.replace('http://172.16.123.78:7010', window.location.origin);
            }
            if (!img.complete) {
                await new Promise((resolve) => {
                    img.onload = resolve;
                    img.onerror = resolve;
                });
            }
        }));

        try {
            // Generate PDF with html2canvas
            const canvas = await html2canvas(element, {
                backgroundColor: null,
                scale: 2,
                scrollX: 0,
                scrollY: 0,
                useCORS: true,
                allowTaint: false,
            });

            const imageData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');

            const downloadDate = new Date().toLocaleString('en-GB');
            const fromDate = moment(inDate).format('DD/MM/YYYY');
            const toDate = moment(endDate).format('DD/MM/YYYY');
            const model = machineModel.filter((el: any) => el.code === _selectedMachineModel);
            const machinesName = _machines
                .filter((machine: any) => selectedMachines.includes(machine?.code))
                .map((machine: any) => machine?.name)
                .join(', ');;

            // Set font size for the header
            pdf.setFontSize(10);

            // Add text for Download Date, From Date, To Date, Model
            pdf.text(`Download Date & Time: ${downloadDate}`, 10, 10);
            pdf.text(`From Date: ${fromDate ?? "-"}`, 10, 15);
            pdf.text(`To Date: ${toDate ?? "-"}`, 10, 20);
            pdf.text(`Model: ${model[0]?.name ?? "-"}`, 10, 25);

            // Split the long Machines string to fit within page width
            const machineText = `Machines: ${machinesName ?? "-"}`;
            const margin = 10;
            const pageWidth = pdf.internal.pageSize.getWidth();
            const textWidth = pageWidth - 2 * margin;  // Account for margin on both sides

            const machineLines = pdf.splitTextToSize(machineText, textWidth);

            // Add machines text to the PDF with wrapping
            let currentY = 30;
            machineLines.forEach((line: any) => {
                pdf.text(line, margin, currentY);
                currentY += 5;  // Adjust line height as necessary
            });

            const pageHeight = pdf.internal.pageSize.getHeight();

            let imgHeight = (canvas.height * pageWidth) / canvas.width;
            let position = 0;

            while (position < imgHeight) {
                pdf.addImage(imageData, 'PNG', 0, -position + currentY, pageWidth, imgHeight);
                position += pageHeight;
                if (position < imgHeight) {
                    pdf.addPage();
                }
            }

            const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
            pdf.save(`AI-insights_${timestamp}.pdf`);

        } finally {
            // Restore original styles
            element.style.fontSize = originalFontSize;
            element.style.height = originalHeight;
            element.style.overflow = originalOverflow;
        }
    };

    return (
        <div className="dashboard clcMain">
            <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
            <Container maxWidth="xl" style={{ marginTop: '60px' }}>
                <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                    <div style={{ flex: '80%', display: 'flex' }}>
                        <div className="headinglabel" style={{ marginTop: '5px' }}>
                            OEE-Dashboard
                        </div>
                        <div
                            style={{
                                flex: '10%',
                                marginLeft: '20px',
                                // marginTop: '10px',
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                            className="dashboardDropdownDiv"
                        >
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: '35px', zIndex: '0' }}>
                    <MapCustomTabs
                        navigateTab={navigateTab}
                        currTab={currTab}
                        setCurrTab={setCurrTab}
                        tabsArray={tabs}
                        className="tabs"
                        position="relative"
                        moduleName='OEE Dashboard v2 Tabs'
                    // type="nonCurve"
                    />

                </div>
            </Container>
            <RightDrawer
                alertType={createValue}
                handlerOpen={() => { }}
                handlerClose={() => { setCreateDrawerOpen(false); setDrawerHeader('AI'); setSelectedInsight(''); setDefaultData([]); }}
                status={createDrawerOpen}
                drawerHeader={`${drawerHeader} ${createValue}`}
                right={right}
                setRight={setRight}
                defaultData={defaultData}
                refreshInights={refreshInights}
                handleDownloadPdf={handleDownloadPdf}
                children={
                    drawerHeader === 'AI' && createValue === "Insights" ? (
                        <AIInsights
                            aIInsightsList={aIInsightsList}
                            machineModel={machineModel}
                            selectedInsight={selectedInsight}
                            setSelectedInsight={setSelectedInsight}
                            right={right}
                            defaultData={defaultData}
                            setDefaultData={setDefaultData}
                            showLoader={showLoader}
                            setShowLoader={setShowLoader}
                            handleDownloadPdf={handleDownloadPdf}
                            componentRef={componentRef}
                            selectedMachineModel={_selectedMachineModel}
                            setSelectedMachineModel={setSelected_MachineModel}
                            setSelectedMachines={setSelectedMachines}
                            selectedMachines={selectedMachines}
                            machines={_machines}
                            setMachines={set_Machines}
                            refreshInights={refreshInights}
                        />)
                        :
                        null
                }
            />
        </div>
    );
};

export default SystemConfiguration;
