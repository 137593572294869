import { useState, useEffect, useRef } from 'react';
import { batch, useDispatch } from 'react-redux';
import { Container } from '@mui/material';
import moment from 'moment';
import MapCustomTabs from '../../../app/atoms/ButtonTabs/MapCustomTabs';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../app/constants/apiConstants';
import { executeACGAction, updateScreenIdentifiers, updateActiveScreenIdentifiers } from '../../../app/store/slice';
import useGetState from '../../../app/utils/hooks/useGetState';
import { _noop } from '../../../app/utils/common';
import { reGroup, playSound } from "../../../app/utils/helper";
import '../Admin/ClientManagement/index.scss';
import DrawerDropdown from '../../../app/atoms/DrawerDropdown';
import RightDrawer from '../../../app/atoms/tempDrawer/indexAlt';
import GBDashboard from './Logs/Config';
import GBActiveDashboard from './Logs/Config/activeIndex';
import Filter from './FilterConfg';
import ReportTypeFilter from './ReportTypeConfigFIlter';
import GoldenlistFilter from './GoldenBatchListConfigFIlter';
import LoadParameters from './LoadConfig';
import GoldenBatchList from './GoldenBatchList/Config';
import QualityAttributes from './QualityAttributes/Config';
import EditConfig from './QualityAttributes/BulkEdit';
import SnackbarAlert from '../../atoms/Snackbar';
import { Chart, registerables, ArcElement } from "chart.js";
Chart.register(...registerables);
Chart.register(ArcElement);
import CreateGoldenBatchConfig from './CreateConfig';
import { renameObjKeys } from '../Login/helper';
import Label from '../../atoms/Label/index';
import DropDown from '../../atoms/Dropdown';
import soundFile from '../../../assets/files/mixkit-alert-bells-echo-765.mp3';
import { trackButtonEvent, trackPageActionLog } from '../../utils/newRelicFuncations';

// const tabs = ['Active Golden Batch', 'Historical Golden Batch', 'Golden Batches List'];
const options = {
  DEFAULT: {
    message: '',
    open: false,
    type: ''
  }
};

const Dateformat = {
  YYYY_MM_DD: 'yyyy-mm-dd',
  YYYY_MM_DD_HH_MM_SS: 'yyyy-mm-dd hh:mm:ss',
  YYYY_MM_DD_HH_MM_SS_L: 'yyyy-mm-ddTHH:MM:ss.l',
}

const SystemConfiguration = () => {
  const [currTab, setTabChange] = useState(0);
  const [radioValue, setRadioValue] = useState('All');
  const [drawerHeader, setDrawerHeader] = useState('Create');
  const [alertType, setAlertType] = useState('');
  const [clientList, setClientList] = useState<any[]>([]);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [createValue, setCreateValue] = useState('');
  const [appliedFilters, setAppliedFilters] = useState(false);
  const [clearFilter, setClearFilter] = useState(false);
  const [clearGBLimitFilter, setClearGBLimitFilter] = useState(false);
  const [createDrawerOpen, setCreateDrawerOpen] = useState(false);
  const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
  const [plantKey, setPlantKey] = useState<any>('');
  const [machineModelKey, setMachineModelKey] = useState<any>('');
  const [machineModelName, setMachineModelName] = useState<any>('');
  const [status, setStatus] = useState('Active');
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [defVal, setDefVal] = useState<any>([]);
  const [hSliderT, setHSliderT] = useState<any>([]);
  const [hSliderActiveT, setHSliderActiveT] = useState<any>([]);
  const [vSliderT, setVSliderT] = useState<number>(0);
  const [vSliderB, setVSliderB] = useState<number>(0);
  const [vSliderActiveT, setVSliderActiveT] = useState<number>(0);
  const [vSliderActiveB, setVSliderActiveB] = useState<number>(0);
  const [serialData, setSerialData] = useState<any>([]);
  const [serialActiveData, setSerialActiveData] = useState<any>([]);
  const [monitoringProductData, setMonitoringProductData] = useState<any>([]);
  const [recipeData, setRecipeData] = useState<any>([]);
  const [batchData, setBatchData] = useState<any>([]);
  const [parameterData, setParameterData] = useState<any>([]);
  const [parameterActiveData, setParameterActiveData] = useState<any>([]);
  const [selectedSerial, setSelectedSerial] = useState('');
  const [selectedActiveSerial, setSelectedActiveSerial] = useState<any>('');
  const [selectedProduct, setSelectedProduct] = useState('');
  const [selectedActiveProduct, setSelectedActiveProduct] = useState<any>('');
  const [selectedBatch, setSelectedBatch] = useState('');
  const [selectedActiveBatch, setSelectedActiveBatch] = useState('');
  const [selectedRecipe, setSelectedRecipe] = useState('');
  const [selectedParameter, setSelectedParameter] = useState('');
  const [selectedActiveParameter, setSelectedActiveParameter] = useState('');
  const [parameterName, setParameterName] = useState('');
  const [parameterActiveName, setParameterActiveName] = useState('');
  const [chart1Page, setChart1Page] = useState<number>(0);
  const [chart1ActivePage, setChart1ActivePage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalActivePages, setTotalActivePages] = useState<number>(0);
  const [configPlants, setConfigPlants] = useState<any>([]);
  const [configMachineModel, setConfigMachineModel] = useState<any>([]);
  const [configMachineParams, setConfigMachineParams] = useState<any>([]);
  const [configMachineRecipes, setConfigMachineRecipes] = useState<any>([]);
  const [configMachineProcess, setConfigMachineProcess] = useState<any>([]);
  const [configMachineSubProcess, setConfigMachineSubProcess] = useState<any>([]);
  const [configBatches, setConfigBatches] = useState<any>([]);
  const [configMasterBatches, setConfigMasterBatches] = useState<any>([]);
  const [reportType, setReportType] = useState([{ 'code': 1, name: 'BQS' }, { 'code': 2, name: 'FBE' }])
  const [reportKey, setReportKey] = useState(1);
  const [productData, setProductsData] = useState<any[]>([]);
  const [goldenRecipe, setGoldenRecipe] = useState<any>([]);
  const [goldenRecipeKey, setGoldenRecipeKey] = useState<any>('');
  const [goldenBatchList, setGoldenBatchList] = useState<any>([]);
  const [qualityAttributeList, setQualityAttributeList] = useState<any>([]);
  const [filteredGoldenRecipeKey, setFilteredGoldenRecipeKey] = useState<any>('');
  const [filteredGoldenMachineModel, setFilteredGoldenMachineModel] = useState<any>('');
  const [limitParameters, setLimitParameters] = useState<any>([]);
  const [phasesTab, setPhasesTab] = useState<any>([]);
  const [autoFillProducts, setAutoFillProducts] = useState<any>([]);
  const [autoFillProcesss, setAutoFillProcess] = useState<any>([]);
  const [autoFillSubProcess, setAutoFillSubProcess] = useState<any>([]);
  const [autoFillRecipes, setAutoFillRecipes] = useState<any>([]);
  const [applyListFilter, setApplyListFilter] = useState<boolean>(false);
  const [filteredProcessKey, setFilteredProcessKey] = useState('');
  const [isGBLoading, setIsGBLoading] = useState(false);
  const [isQALoading, setIsQALoading] = useState(false);
  const [activeAlerts, setActiveAlerts] = useState<any>([]);
  const [prevActiveAlerts, setPrevActiveAlerts] = useState<any>([]);
  const [newActiveAlertItems, setNewActiveAlertItems] = useState(new Set());
  const [isError, setIsError] = useState(false);
  const [tabs,setTabs]=useState([]);
  const [tabsPermissions, setTabsPermissions] = useState<Record<string, string>>({});

  const dispatch = useDispatch();
  const state = useGetState();
  const token = state?.[STORE_KEYS.USER_DTL].token;
  const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;
  const audioRef = useRef(new Audio(soundFile));
  const prevFilterActiveRef = useRef<{ page: number; batch: string; param: string } | null | any>(null);
  const currentFilterActiveRef = useRef<{ page: number; batch: string; param: string, machine: number } | null | any>(null);
  const prevFilterRef = useRef<{ page: number; batch: string; param: string } | null | any>(null);
  const currentFilterRef = useRef<{ page: number; batch: string; param: string } | null | any>(null);
  const currentActiveAlertsRef = useRef<any>([]);
  const prevActiveAlertsRef = useRef<any>([]);

  useEffect(() => {
    if(machineModelKey != "") {
    clearAppliedFilter();
    }
  },[machineModelKey])

  useEffect(() => {
    const key = serialActiveData.find((i: any) => i.code === selectedActiveSerial)?.bk;
    currentFilterActiveRef.current = { page: chart1ActivePage, batch: key, param: selectedActiveParameter, machineKey: selectedActiveSerial };
    prevFilterActiveRef.current = currentFilterActiveRef.current;
  }, [chart1ActivePage, selectedActiveBatch, selectedActiveParameter, selectedActiveSerial]);

  useEffect(() => {
    currentFilterRef.current = machineModelName.includes("BQS") ? { page: chart1Page, batch: selectedBatch, param: selectedParameter, machine: selectedActiveSerial } : { page: chart1Page, batch: selectedBatch, param: selectedParameter, machine: selectedActiveSerial };
    prevFilterRef.current = currentFilterRef.current;
  }, [selectedParameter, selectedBatch, chart1Page, selectedSerial]);

  useEffect(() => {
    currentActiveAlertsRef.current = activeAlerts;
    const newItemsSet = new Set();

    // Check for newly added items
    activeAlerts.forEach((item: any) => {
      if (!prevActiveAlerts.some((prevItem: any) => prevItem.GBAlertId === item.GBAlertId)) {
        newItemsSet.add(item.id);
      }
    });

    // Update new items set and previous data state
    setNewActiveAlertItems(newItemsSet);
    setPrevActiveAlerts(activeAlerts);
  }, [activeAlerts])

  const isAnyItemPresent = (array1: any, array2: any) => {
    const set = new Set(array1.map((item: any) => JSON.stringify(item)));
    return array2.some((item: any) => set.has(JSON.stringify(item)));
  };

  useEffect(() => {
    const isEqual = isAnyItemPresent(currentActiveAlertsRef.current, prevActiveAlertsRef.current)
    if ((!isEqual && prevActiveAlertsRef.current.length !== 0 && activeAlerts.length > 0) || (prevActiveAlertsRef.current.length === 0 && currentActiveAlertsRef.current.length > 0 && activeAlerts.length > 0)) {
      playSound(audioRef);
    }
    prevActiveAlertsRef.current = currentActiveAlertsRef.current;
  }, [currentActiveAlertsRef.current])

  useEffect(() => {
    if (!createDrawerOpen && appliedFilters) {
      setChart1Page(0);
    }
  }, [createDrawerOpen])

  const getCreateValue = (ele: string) => {
    trackButtonEvent("Golden Batch Dashboard","Golden Batch Dashboard",ele,state[STORE_KEYS.USER_DTL]?.EmailId)
    setFilteredProcessKey('');
    setApplyListFilter(false);
    setGoldenRecipeKey('');
    setFilteredGoldenRecipeKey('');
    setSelectedRow([]);
    setDrawerHeader('Create');
    if (ele === "Define Golden Batch") {
      setCreateValue("Golden Batch");
    } else {
      setCreateValue(ele);
    }
    setCreateDrawerOpen(true);
  };
  const setCurrTab = (val: number) => {
    setQualityAttributeList([]);
    clearGBLimit();
    if (val === 0) setStatus("Active");
    else if (val === 1) setStatus("Historical");
    if (val === 2) setDrawerHeader('Create');
    setTabChange(val);
    if (radioValue == 'All' && currTab === 2) {
      setRadioValue('All');
    }
  };

  useEffect(() => {
    clearGBLimit();
    getLimitParameters();
    dispatch(
      updateScreenIdentifiers({
        storeKey: STORE_KEYS.GOLDEN_BATCH_DATA,
        uniqueScreenIdentifier: {
          goldenbatchAdded: !state?.[STORE_KEYS.GOLDEN_BATCH_DATA]?.body?.goldenbatchAdded,
          data: []
        },

      })
    )
    dispatch(
      updateScreenIdentifiers({
        storeKey: STORE_KEYS.GOLDEN_BATCH_DATA_FBE,
        uniqueScreenIdentifier: {
          goldenbatchAddedFBE: !state?.[STORE_KEYS.GOLDEN_BATCH_DATA_FBE]?.body?.goldenbatchAddedFBE,
          data: []
        },

      })
    )
  }, []);

  useEffect(() => {
    if (machineModelKey != "") {
      if (configMachineModel.find((item: any) => item.code === machineModelKey).name.includes('BQS')) {
        getGoldenRecipeBQS();
        getGoldenBatchListBQS(-1, -1, -1, machineModelKey)
      }
      else { getGoldenBatchListFBE(machineModelKey, -1) }

      getMachineSerial('Active', null, null, plantKey, machineModelKey, machineModelName);
      getProducts(plantKey, machineModelKey, machineModelName);
      setSelectedActiveParameter('');
      setParameterActiveData([]);
    }
  }, [machineModelKey])

  const getLimitParameters = () => {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'GET',
          urlPath: ACTION_CODES.API_GET_GOLDENBATCH_LIMIT_PARAMETERS,
        },
        uniqueScreenIdentifier: { gbLimit: true },
        storeKey: STORE_KEYS.API_GET_GOLDENBATCH_LIMIT_PARAMETERS
      })
    );
  };

  useEffect(() => {
    if (state?.[STORE_KEYS.API_GET_GOLDENBATCH_LIMIT_PARAMETERS]?.gbLimit) {
      const rptsData = state?.[STORE_KEYS.API_GET_GOLDENBATCH_LIMIT_PARAMETERS]?.body?.data;
      setLimitParameters([
        ...rptsData?.map((item: any) => ({ code: item.GBLimitTypesCodes, name: item.GBLimitTypes, key: item.GBLimitTypesKey }))

      ]);

      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.API_GET_GOLDENBATCH_LIMIT_PARAMETERS,
          uniqueScreenIdentifier: {
            gbLimit: false
          }
        })
      );
    }
  }, [state?.[STORE_KEYS.API_GET_GOLDENBATCH_LIMIT_PARAMETERS]?.gbLimit]);

  const getGoldenBatchListBQS = (key1: any, key2: any, key3: any, key4: any) => {
    setIsGBLoading(true)
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.API_GET_GOLDENBATCH_CONFIG_LIST_BQS,
          reqObj: {
            "LoggedUserKey": loggedInUser,
            "PlantKey": plantKey,
            "CustProductKey": key1 ? key1 : -1,
            "RecipeKey": key2 ? key2 : -1,
            "MachineKey": key3 ? key3 : -1,
            "MachineModelKey": machineModelKey ? machineModelKey : key4,

          }
        },
        storeKey: STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_LIST_BQS
      })
    );
  }

  useEffect(() => {
    if (state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_LIST_BQS]?.body?.data) {
      const list = state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_LIST_BQS]?.body?.data?.map((item: any) => {
        return item;
      });
      setGoldenBatchList(list);
      setIsGBLoading(false)
    }

  }, [state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_LIST_BQS]]);

  const getGoldenBatchListFBE = (key1: any, key2: any) => {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.API_GET_GOLDENBATCH_CONFIG_LIST_FBE,
          reqObj: {
            "LoggedUserKey": loggedInUser,
            "PlantKey": plantKey,
            "MachineModelKey": key1,
            "ProcessKey": key2 ? key2 : -1,
          }
        },
        storeKey: STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_LIST_FBE
      })
    );
  }

  useEffect(() => {
    if (state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_LIST_FBE]?.body?.data) {
      const list = state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_LIST_FBE]?.body?.data?.map((item: any) => {
        return item;
      });
      setGoldenBatchList(list);
    }

  }, [state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_LIST_FBE]]);

  const getGoldenRecipeBQS = () => {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.API_GET_GOLDENBATCH_RECIPE_BQS,
          reqObj: {
            "LoggedUserKey": loggedInUser,
            "PlantKey": plantKey,
            "MachineModelKey": machineModelKey,
            "CustProductKey": -1
          }
        },
        storeKey: STORE_KEYS.API_GET_GOLDENBATCH_RECIPE_BQS
      })
    );
  }

  useEffect(() => {
    if (state?.[STORE_KEYS.API_GET_GOLDENBATCH_RECIPE_BQS]?.body?.data) {
      const list = state?.[STORE_KEYS.API_GET_GOLDENBATCH_RECIPE_BQS]?.body?.data?.map((item: any) => {
        const newVal = renameObjKeys(item, { RecipeKey: 'code', RecipeName: 'name', BatchKey: 'code2' });
        return newVal;
      });
      setGoldenRecipe(list);
    }

  }, [state?.[STORE_KEYS.API_GET_GOLDENBATCH_RECIPE_BQS]]);

  const getQualityAttributesBQS = (key: any) => {
    setIsQALoading(true)
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.API_GET_GOLDENBATCH_QA_BQS,
          reqObj: {
            "LoggedUserKey": loggedInUser,
            "RecipeKey": key ? key : -1
          }
        },
        storeKey: STORE_KEYS.API_GET_GOLDENBATCH_QA_BQS
      })
    );
  }

  useEffect(() => {
    if (state?.[STORE_KEYS.API_GET_GOLDENBATCH_QA_BQS]?.body?.data) {
      const list = state?.[STORE_KEYS.API_GET_GOLDENBATCH_QA_BQS]?.body?.data?.map((item: any) => {
        return item;
      });
      setQualityAttributeList(list);
      setIsQALoading(false)
    }

  }, [state?.[STORE_KEYS.API_GET_GOLDENBATCH_QA_BQS]]);

  const getQualityAttributesFBE = (key1: any, key2: any, key3: any, key4: any = null, key5: any = null, key6: any = null, key7: any = null) => {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.API_GET_GOLDENBATCH_QA_FBE,
          reqObj: {
            "LoggedUserKey": loggedInUser,
            "CustProductKey": key1,
            "SubProcessKey": key2,
            "RecipeKey": key3,
            'PhaseKey': -1,
            "AutofillProcessKey": key4,
            "AutofillCustProductKey": key5,
            "AutofillSubProcessKey": key6,
            "AutofillRecipeKey": key7
          }
        },
        storeKey: STORE_KEYS.API_GET_GOLDENBATCH_QA_FBE
      })
    );
  }

  useEffect(() => {
    if (state?.[STORE_KEYS.API_GET_GOLDENBATCH_QA_FBE]?.body?.data) {
      const list = state?.[STORE_KEYS.API_GET_GOLDENBATCH_QA_FBE]?.body?.data?.map((item: any) => {
        return item;
      });
      setQualityAttributeList(reGroup(list, 'PhaseKey'));
    }

  }, [state?.[STORE_KEYS.API_GET_GOLDENBATCH_QA_FBE]]);

  const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);

  useEffect(() => {
    setParameterName(parameterData.find((i: any) => { return i.code === selectedParameter })?.name);
    setParameterActiveName(parameterActiveData.find((i: any) => { return i.code === selectedActiveParameter })?.name);
  }, [selectedParameter, selectedActiveParameter]);

  const navigateTab = () => {
    switch (currTab) {

      case 0:
        return (
          <GBActiveDashboard
            selectedRecipe={recipeData.find((item: any) => item.code == selectedRecipe)}
            selectedBatch={batchData.find((item: any) => item.code == selectedBatch)}
            appliedFilters={appliedFilters}
            clearFilter={clearFilter}
            setEditDrawerOpen={setCreateDrawerOpen}
            setCreateValue={setCreateValue}
            setDrawerHeader={setDrawerHeader}
            chart1ActivePage={chart1ActivePage}
            setChart1ActivePage={setChart1ActivePage}
            updateActivePage={updateActivePage}
            totalPages={totalActivePages}
            clearAppliedFilter={clearAppliedFilter}
            parameterActiveName={parameterActiveName}
            selectedSerial={selectedSerial}
            setDefVal={setDefVal}
            defVal={defVal}
            hSliderActiveT={hSliderActiveT}
            setHSliderActiveT={setHSliderActiveT}
            setVSliderActiveB={setVSliderActiveB}
            setVSliderActiveT={setVSliderActiveT}
            vSliderActiveB={vSliderActiveB}
            vSliderActiveT={vSliderActiveT}
            machineModelName={machineModelName}
            status={"Active"}
            setSelectedActiveSerial={setSelectedActiveSerial}
            serialActiveData={serialActiveData}
            selectedActiveSerial={selectedActiveSerial}
            selectedActiveParameter={selectedActiveParameter}
            setSelectedActiveParameter={setSelectedActiveParameter}
            parameterActiveData={parameterActiveData}
            fetchParameters={fetchParameters}
            activeAlerts={activeAlerts}
            getActiveBatchAlertsData={getActiveBatchAlertsData}
            newActiveAlertItems={newActiveAlertItems}
          />
        )
      case 1:
        return (
          <GBDashboard
            selectedRecipe={recipeData.find((item: any) => item.code == selectedRecipe)}
            selectedBatch={batchData.find((item: any) => item.code == selectedBatch)}
            appliedFilters={appliedFilters}
            clearFilter={clearFilter}
            setEditDrawerOpen={setCreateDrawerOpen}
            setCreateValue={setCreateValue}
            setDrawerHeader={setDrawerHeader}
            chart1Page={chart1Page}
            updatePage={updatePage}
            totalPages={totalPages}
            clearAppliedFilter={clearAppliedFilter}
            parameterName={parameterName}
            selectedSerial={selectedSerial}
            setDefVal={setDefVal}
            defVal={defVal}
            hSliderT={hSliderT}
            setHSliderT={setHSliderT}
            setVSliderB={setVSliderB}
            setVSliderT={setVSliderT}
            vSliderB={vSliderB}
            vSliderT={vSliderT}
            machineModelName={machineModelName}
            status={"Historical"}
          />
        )

      case 2:
        return (
          <GoldenBatchList
            currTab={currTab}
            setCurrTab={setCurrTab}
            setSelectedRow={setSelectedRow}
            selectedRow={selectedRow}
            setSnackbarOpts={setSnackbarOptions}
            setAlertType={setAlertType}
            setCreateDrawerOpen={setCreateDrawerOpen}
            setDrawerHeader={setDrawerHeader}
            setCreateValue={setCreateValue}
            reportKey={reportKey}
            goldenBatchList={goldenBatchList}
            setReportKey={setReportKey}
            setGoldenRecipeKey={setGoldenRecipeKey}
            getGoldenBatchListBQS={getGoldenBatchListBQS}
            getGoldenBatchListFBE={getGoldenBatchListFBE}
            setFilteredGoldenRecipeKey={setFilteredGoldenRecipeKey}
            getQualityAttributesBQS={getQualityAttributesBQS}
            getQualityAttributesFBE={getQualityAttributesFBE}
            machineModelName={machineModelName}
            plantKey={plantKey}
            machineModelKey={machineModelKey}
            setPhasesTab={setPhasesTab}
            setAutoFillProducts={setAutoFillProducts}
            setAutoFillProcess={setAutoFillProcess}
            setAutoFillSubProcess={setAutoFillSubProcess}
            setAutoFillRecipes={setAutoFillRecipes}
            phasesTab={phasesTab}
            setApplyListFilter={setApplyListFilter}
            applyListFilter={applyListFilter}
            setFilteredProcessKey={setFilteredProcessKey}
            getProducts={getProducts}
            isLoading={isGBLoading}
            getProcess={getProcess}
            isEditAccess={tabsPermissions?.GBL=="Y"}
          />
        )

      case 3:
        return (
          <QualityAttributes
            currTab={currTab}
            setCurrTab={setCurrTab}
            setClearFilter={setClearGBLimitFilter}
            setSelectedRow={setSelectedRow}
            selectedRow={selectedRow}
            setSnackbarOpts={setSnackbarOptions}
            setAlertType={setAlertType}
            setCreateDrawerOpen={setCreateDrawerOpen}
            setDrawerHeader={setDrawerHeader}
            setCreateValue={setCreateValue}
            reportKey={reportKey}
            qualityAttributeList={qualityAttributeList}
            setReportKey={setReportKey}
            clearFilter={clearGBLimitFilter}
            clearGBLimit={clearGBLimit}
            setFilteredGoldenRecipeKey={setFilteredGoldenRecipeKey}
            isLoading={isQALoading}
          />
        )
      default:
        return (
          <GBActiveDashboard
            selectedRecipe={recipeData.find((item: any) => item.code == selectedRecipe)}
            selectedBatch={batchData.find((item: any) => item.code == selectedBatch)}
            appliedFilters={appliedFilters}
            clearFilter={clearFilter}
            setEditDrawerOpen={setCreateDrawerOpen}
            setCreateValue={setCreateValue}
            setDrawerHeader={setDrawerHeader}
            chart1ActivePage={chart1ActivePage}
            setChart1ActivePage={setChart1ActivePage}
            updateActivePage={updateActivePage}
            totalPages={totalActivePages}
            clearAppliedFilter={clearAppliedFilter}
            parameterActiveName={parameterActiveName}
            selectedSerial={selectedSerial}
            setDefVal={setDefVal}
            defVal={defVal}
            hSliderActiveT={hSliderActiveT}
            setHSliderActiveT={setHSliderActiveT}
            setVSliderActiveB={setVSliderActiveB}
            setVSliderActiveT={setVSliderActiveT}
            vSliderActiveB={vSliderActiveB}
            vSliderActiveT={vSliderActiveT}
            machineModelName={machineModelName}
            status={"Active"}
            setSelectedActiveSerial={setSelectedActiveSerial}
            serialActiveData={serialActiveData}
            selectedActiveSerial={selectedActiveSerial}
            selectedActiveParameter={selectedActiveParameter}
            setSelectedActiveParameter={setSelectedActiveParameter}
            parameterActiveData={parameterActiveData}
            fetchParameters={fetchParameters}
          />
        )
    }
  };

  useEffect(() => {
    getPlant();
  }, [])

  useEffect(() => {
    if (localStorage.getItem("token") !== null && state[STORE_KEYS.USER_DTL] !== null) {
      if (status === 'Active' && (selectedActiveBatch === "" || selectedActiveBatch === undefined)) {
        const interval = setInterval(() => getMachineSerial('Active', null, null, plantKey, machineModelKey, machineModelName), 60000);
        return () => clearInterval(interval);
      }
    }
  }, [status, machineModelKey]);

  useEffect(() => {
    if (selectedActiveParameter !== "") {
      getGoldenBatchData(currentFilterActiveRef.current.page);
      getActiveBatchAlertsData(currentFilterActiveRef.current.machineKey);
      const interval = setInterval(() => { getGoldenBatchData(currentFilterActiveRef.current.page); getActiveBatchAlertsData(currentFilterActiveRef.current.machineKey); }, 60000);
      return () => clearInterval(interval);
    }
  }, [selectedActiveParameter, state?.[STORE_KEYS.API_POST_GOLDENBATCH_QA_BQS]])

  const getGoldenBatchData = async (pno: number) => {
    if (prevFilterRef.current.batch != null) {
      machineModelName.includes("BQS") ?
        dispatch(
          executeACGAction({
            payload: {
              requestType: 'POST',
              urlPath: ACTION_CODES.GOLDEN_BATCH_DATA,
              reqObj:
                status === "Active" ?
                  {
                    "BatchKey": currentFilterActiveRef.current.batch === "" ? parseInt(selectedActiveBatch) : parseInt(currentFilterActiveRef.current.batch),
                    "MMParamKey": currentFilterActiveRef.current.batch === "" ? selectedActiveParameter : currentFilterActiveRef.current.param,
                    "PageNo": pno === 0 ? 1 : pno
                  }
                  : {
                    "BatchKey": parseInt(currentFilterRef.current.batch),
                    "MMParamKey": currentFilterRef.current.param,
                    "PageNo": pno === 0 ? 1 : pno
                  }

            },
            uniqueScreenIdentifier: status === "Active" ? { goldenbatchActiveAdded: true } : { goldenbatchAdded: true },
            storeKey: status === "Active" ? STORE_KEYS.GOLDEN_BATCH_ACTIVE_DATA : STORE_KEYS.GOLDEN_BATCH_DATA
          })
        ) :
        dispatch(
          executeACGAction({
            payload: {
              requestType: 'POST',
              urlPath: ACTION_CODES.GOLDEN_BATCH_DATA_FBE,
              reqObj: status === "Active" ?
                {
                  "BatchStatus": status,
                  "MachineKey": 1,
                  "MMParamKey": currentFilterActiveRef.current.batch === "" ? selectedActiveParameter : currentFilterActiveRef.current.param,
                  "PageNo": pno === 0 ? 1 : pno,
                  "BatchKey": currentFilterActiveRef.current.batch === "" ? parseInt(selectedActiveBatch) : parseInt(currentFilterActiveRef.current.batch)

                }
                :
                {
                  "BatchStatus": status,
                  "MachineKey": selectedSerial,
                  "MMParamKey": currentFilterRef.current.param,
                  "PageNo": pno === 0 ? 1 : pno,
                  "BatchKey": parseInt(currentFilterRef.current.batch)
                }
            },
            uniqueScreenIdentifier: status === "Active" ? { goldenbatchActiveAddedFBE: true } : { goldenbatchAddedFBE: true },
            storeKey: status === "Active" ? STORE_KEYS.GOLDEN_BATCH_DATA_ACTIVE_FBE : STORE_KEYS.GOLDEN_BATCH_DATA_FBE
          })
        )
    }
  };

  useEffect(() => {
    if (state?.[STORE_KEYS.GOLDEN_BATCH_ACTIVE_DATA]?.body?.data && selectedActiveParameter !== "") {
      const gbData = state?.[STORE_KEYS.GOLDEN_BATCH_ACTIVE_DATA]?.body?.data;
      const newStoreActiveData: any = [];
      if (gbData.length === 0) setChart1ActivePage(1);
      setTotalActivePages(gbData[0]?.TotalPages);
      gbData.slice(0).reverse()?.map((item: any) => {
        const dateTimeStr = item?.EventTime.replace('T', ' ').replace('.000Z', '');
        const [datePart, timePart] = dateTimeStr.split(' ');
        const [year, month, day] = datePart.split('-');
        const formattedDate = `${day}/${month}/${year} ${timePart}`;
        newStoreActiveData.push({
          date: formattedDate,
          upperLimit: item?.OptimumUpperLimit,
          lowerLimit: item?.OptimumLowerLimit,
          actualValue: item?.ActualValue,
          machineRunStatus: item?.MachineRunStatus,
          alarmAndAlerts: item?.AlarmAvail ? item?.AlarmAvail.split('-')[0] : '',
          isAlarm: item?.AlarmAvail && item?.AlarmAvail.split('-')[1] === "AM" ? true : false,
          productName: item?.ProductName,
          batchStart: item?.BatchStartTime,
          batchend: item?.BatchEndTime,
          setValue: item?.SelectedBatchSetValue,
          refSetValue: item?.RefBatchSetValue,
        });
      });

      dispatch(
        updateActiveScreenIdentifiers({
          storeKey: STORE_KEYS.GOLDEN_BATCH_ACTIVE_DATA,
          uniqueActiveScreenIdentifier: {
            goldenbatchActiveAdded: false,
            active: newStoreActiveData
          },
        })
      )
    }
    else {
      dispatch(
        updateActiveScreenIdentifiers({
          storeKey: STORE_KEYS.GOLDEN_BATCH_ACTIVE_DATA,
          uniqueActiveScreenIdentifier: {
            goldenbatchActiveAdded: false,
            active: []
          },
        })
      )
    }
  }, [state?.[STORE_KEYS.GOLDEN_BATCH_ACTIVE_DATA]?.goldenbatchActiveAdded, selectedActiveParameter]);

  useEffect(() => {
    if (state?.[STORE_KEYS.GOLDEN_BATCH_DATA_ACTIVE_FBE]?.body?.data && selectedActiveParameter !== "") {
      const gbData = state?.[STORE_KEYS.GOLDEN_BATCH_DATA_ACTIVE_FBE]?.body?.data;
      const newStoreActiveData: any = []
      if (gbData.length === 0) setChart1ActivePage(1);
      setTotalActivePages(gbData[0]?.TotalPages);
      gbData.slice(0).reverse()?.map((item: any, index: number) => {
        const dateTimeStr = item?.EventTime.replace('T', ' ').replace('.000Z', '');
        const [datePart, timePart] = dateTimeStr.split(' ');
        const [year, month, day] = datePart.split('-');
        const formattedDate = `${day}/${month}/${year} ${timePart}`;
        newStoreActiveData.push({
          date: formattedDate,
          upperLimit: item?.OptimumUpperLimit,
          lowerLimit: item?.OptimumLowerLimit,
          actualValue: item?.ActualValue,
          machineRunStatus: item?.MachineRunStatus,
          alarmAndAlerts: item?.AlarmAvail ? item?.AlarmAvail.split('-')[0] : '',
          isAlarm: item?.AlarmAvail && item?.AlarmAvail.split('-')[1] === "AM" ? true : false,
          phase: item?.PhaseKey,
          phaseName: item?.PhaseName,
          productName: item?.ProductName,
          color: item?.PhaseColour
        })
      })

      dispatch(
        updateActiveScreenIdentifiers({
          storeKey: STORE_KEYS.GOLDEN_BATCH_DATA_ACTIVE_FBE,
          uniqueActiveScreenIdentifier: {
            goldenbatchActiveAddedFBE: false,
            active: newStoreActiveData
          },

        })
      )
    } else {
      dispatch(
        updateActiveScreenIdentifiers({
          storeKey: STORE_KEYS.GOLDEN_BATCH_DATA_ACTIVE_FBE,
          uniqueActiveScreenIdentifier: {
            goldenbatchActiveAddedFBE: false,
            active: []
          },

        })
      )
    }
  }, [state?.[STORE_KEYS.GOLDEN_BATCH_DATA_ACTIVE_FBE]?.goldenbatchActiveAddedFBE, selectedActiveParameter]);

  useEffect(() => {
    if (state?.[STORE_KEYS.GOLDEN_BATCH_DATA]?.body?.data && selectedParameter !== "") {

      const gbData = state?.[STORE_KEYS.GOLDEN_BATCH_DATA]?.body?.data;
      const newStoreData: any = [];
      if (gbData.length === 0) setChart1Page(1);
      setTotalPages(gbData[0]?.TotalPages);
      gbData.slice(0).reverse()?.map((item: any) => {
        const dateTimeStr = item?.EventTime.replace('T', ' ').replace('.000Z', '');
        const [datePart, timePart] = dateTimeStr.split(' ');
        const [year, month, day] = datePart.split('-');
        const formattedDate = `${day}/${month}/${year} ${timePart}`;
        newStoreData.push({
          date: formattedDate,
          upperLimit: item?.OptimumUpperLimit,
          lowerLimit: item?.OptimumLowerLimit,
          actualValue: item?.ActualValue,
          machineRunStatus: item?.MachineRunStatus,
          alarmAndAlerts: item?.AlarmAvail ? item?.AlarmAvail.split('-')[0] : '',
          isAlarm: item?.AlarmAvail && item?.AlarmAvail.split('-')[1] === "AM" ? true : false,
          productName: item?.ProductName,
          batchStart: item?.BatchStartTime,
          batchend: item?.BatchEndTime,
          setValue: item?.SelectedBatchSetValue,
          refSetValue: item?.RefBatchSetValue
        });
      });

      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.GOLDEN_BATCH_DATA,
          uniqueScreenIdentifier: {
            goldenbatchAdded: false,
            data: newStoreData
          },
        })
      )
    }
  }, [state?.[STORE_KEYS.GOLDEN_BATCH_DATA]?.body?.data]);

  useEffect(() => {
    if (state?.[STORE_KEYS.GOLDEN_BATCH_DATA_FBE]?.body?.data && selectedParameter !== "") {
      const gbData = state?.[STORE_KEYS.GOLDEN_BATCH_DATA_FBE]?.body?.data;
      const data: any = state?.[STORE_KEYS.GOLDEN_BATCH_DATA_FBE]?.body?.data ? state?.[STORE_KEYS.GOLDEN_BATCH_DATA_FBE]?.body?.data : [];
      const newStoreData: any = []
      if (gbData.length === 0) setChart1Page(1);
      setTotalPages(gbData[0]?.TotalPages);
      gbData.slice(0).reverse()?.map((item: any, index: number) => {
        const dateTimeStr = item?.EventTime.replace('T', ' ').replace('.000Z', '');
        const [datePart, timePart] = dateTimeStr.split(' ');
        const [year, month, day] = datePart.split('-');
        const formattedDate = `${day}/${month}/${year} ${timePart}`;
        newStoreData.push({
          date: formattedDate,
          upperLimit: item?.OptimumUpperLimit,
          lowerLimit: item?.OptimumLowerLimit,
          actualValue: item?.ActualValue,
          // batchStatus: item?.MachineStaus,
          machineRunStatus: item?.MachineRunStatus,
          alarmAndAlerts: item?.AlarmAvail ? item?.AlarmAvail.split('-')[0] : '',
          isAlarm: item?.AlarmAvail && item?.AlarmAvail.split('-')[1] === "AM" ? true : false,
          phase: item?.PhaseKey,
          phaseName: item?.PhaseName,
          color: item?.PhaseColour
        })
      })

      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.GOLDEN_BATCH_DATA_FBE,
          uniqueScreenIdentifier: {
            goldenbatchAddedFBE: false,
            data: newStoreData
          },

        })
      )
    }
  }, [state?.[STORE_KEYS.GOLDEN_BATCH_DATA_FBE]?.body?.data]);

  const getActiveBatchAlertsData = async (key: any = selectedActiveSerial) => {
    if (status === "Active") {
      dispatch(
        executeACGAction({
          payload: {
            requestType: 'POST',
            urlPath: ACTION_CODES.GOLDEN_BATCH_ACTIVE_ALERTS_DATA,
            reqObj:
            {
              "LoggedUserKey": parseInt(loggedInUser),
              "MachineKey": parseInt(key),
            }

          },
          uniqueScreenIdentifier: { goldenbatchActiveAlert: true },
          storeKey: STORE_KEYS.GOLDEN_BATCH_ACTIVE_ALERTS_DATA
        })
      )
    }
  };

  useEffect(() => {
    if (state?.[STORE_KEYS.GOLDEN_BATCH_ACTIVE_ALERTS_DATA]?.body?.data) {
      const rptsData = state?.[STORE_KEYS.GOLDEN_BATCH_ACTIVE_ALERTS_DATA]?.body?.data;
      setActiveAlerts(rptsData);
      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.GOLDEN_BATCH_ACTIVE_ALERTS_DATA,
          uniqueScreenIdentifier: {
            goldenbatchActiveAlert: false
          }
        })
      );
    }
  }, [state?.[STORE_KEYS.GOLDEN_BATCH_ACTIVE_ALERTS_DATA]?.goldenbatchActiveAlert]);

  useEffect(() => {
    if (state[STORE_KEYS.POST_GB_ACTIVE_ALERT_RESPONSE]?.sentResponse) {
      if (state[STORE_KEYS.POST_GB_ACTIVE_ALERT_RESPONSE]?.body?.data[0]?.Result === "Success") {
        getActiveBatchAlertsData();
        dispatch(
          updateScreenIdentifiers({
            storeKey: STORE_KEYS.POST_GB_ACTIVE_ALERT_RESPONSE,
            uniqueScreenIdentifier: {
              sentResponse: false
            }
          })
        );
      }
    }
  }, [state[STORE_KEYS.POST_GB_ACTIVE_ALERT_RESPONSE]?.sentResponse]);

  const getMachineSerial = (data1: any, data2: any, data3: any, pk: any, mmKey: any, mmName: any) => {
    const payload = mmName.includes("BQS") ? {
      "LoggedUserKey": loggedInUser,
      "BatchStatus": data1,
      "FromDate": data1 === "Active" ? null : data2,
      "ToDate": data1 === "Active" ? null : data3,
      "PlantKey": pk,
      "MachineModelKey": mmKey,
    } : {
      "LoggedUserKey": loggedInUser,
      "PlantKey": pk,
      "MachineModelKey": mmKey,
      "BatchStatus": data1,
      "FromDate": data1 === "Active" ? null : data2,
      "ToDate": data1 === "Active" ? null : data3
    }
    if (mmName.includes("BQS")) {
      dispatch(
        executeACGAction({
          payload: {
            requestType: 'POST',
            urlPath: ACTION_CODES.GET_GB_SERIAL_DATA,
            reqObj: payload
          },
          uniqueScreenIdentifier: { gbSerial: true },
          storeKey: ACTION_CODES.GET_GB_SERIAL_DATA
        })
      );
    } else {
      dispatch(
        executeACGAction({
          payload: {
            requestType: 'POST',
            urlPath: ACTION_CODES.GET_GB_SERIAL_DATA_FBE,
            reqObj: payload
          },
          uniqueScreenIdentifier: { gbSerial: true },
          storeKey: ACTION_CODES.GET_GB_SERIAL_DATA_FBE
        })
      );
    }
  };
  useEffect(() => {
    if (state?.[STORE_KEYS.GET_GB_SERIAL_DATA]?.gbSerial) {
      const rptsData = state?.[STORE_KEYS.GET_GB_SERIAL_DATA]?.body?.data;
      if (status === "Active" && currTab === 0) {
        setSerialActiveData([
          ...rptsData?.map((item: any) => ({ code: item.MachineKey, name: item.LineMachineName, bk: item.BatchKey }))

        ]);
        const defaultActiveData: any = rptsData[0];
        setSelectedActiveSerial(defaultActiveData ? defaultActiveData?.MachineKey : '');
        setSelectedActiveBatch(defaultActiveData?.BatchKey);
        setSelectedActiveProduct(defaultActiveData?.ProductName);
        if (defaultActiveData != undefined) fetchParameters(defaultActiveData?.MachineKey, machineModelName);
      } else {
        setSerialData([
          ...rptsData?.map((item: any) => ({ code: item.MachineKey, name: item.LineMachineName }))
        ]);
      }
      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.GET_GB_SERIAL_DATA,
          uniqueScreenIdentifier: {
            gbSerial: false
          }
        })
      );
    }
  }, [state?.[STORE_KEYS.GET_GB_SERIAL_DATA]?.gbSerial]);

  useEffect(() => {
    if (state?.[STORE_KEYS.GET_GB_SERIAL_DATA_FBE]?.gbSerial) {
      const rptsData = state?.[STORE_KEYS.GET_GB_SERIAL_DATA_FBE]?.body?.data;
      if (status === "Active" && currTab === 0) {
        setSerialActiveData([
          ...rptsData?.map((item: any) => ({ code: item.MachineKey, name: item.LineMachineName, bk: item.BatchKey }))

        ]);
        const defaultActiveData: any = rptsData[0];
        setSelectedActiveSerial(defaultActiveData ? defaultActiveData?.MachineKey : '');
        setSelectedActiveBatch(defaultActiveData?.BatchKey);
        setSelectedActiveProduct(defaultActiveData?.ProductName);
        if (defaultActiveData != undefined) fetchParameters(defaultActiveData?.MachineKey, machineModelName);
      } else {
        setSerialData([
          ...rptsData?.map((item: any) => ({ code: item.MachineKey, name: item.LineMachineName }))

        ]);
      }
      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.GET_GB_SERIAL_DATA_FBE,
          uniqueScreenIdentifier: {
            gbSerial: false
          }
        })
      );
    }
  }, [state?.[STORE_KEYS.GET_GB_SERIAL_DATA_FBE]?.gbSerial]);

  const fetchMontoringProducts = (statusType: any, key: any, mmName: any) => {
    if (mmName.includes("BQS")) {
      dispatch(
        executeACGAction({
          payload: {
            requestType: 'POST',
            urlPath: ACTION_CODES.GET_GB_PRODUCT_DATA,
            reqObj: {
              "LoggedUserKey": loggedInUser,
              "PlantKey": plantKey,
              "MachineModelKey": machineModelKey,
              "BatchStatus": statusType,
              "MachineKey": key,
              "FromDate": statusType === "Active" ? null : from,
              "ToDate": statusType === "Active" ? null : to
            }
          },
          uniqueScreenIdentifier: { gbProduct: true },
          storeKey: STORE_KEYS.GET_GB_PRODUCT_DATA
        })
      );
    } else {
      dispatch(
        executeACGAction({
          payload: {
            requestType: 'POST',
            urlPath: ACTION_CODES.GET_GB_PRODUCT_DATA_FBE,
            reqObj: {
              "LoggedUserKey": loggedInUser,
              "PlantKey": plantKey,
              "MachineModelKey": machineModelKey,
              "BatchStatus": statusType,
              "MachineKey": key,
              "FromDate": statusType === "Active" ? null : from,
              "ToDate": statusType === "Active" ? null : to
            }
          },
          uniqueScreenIdentifier: { gbProduct: true },
          storeKey: STORE_KEYS.GET_GB_PRODUCT_DATA_FBE
        })
      );
    }
  };

  useEffect(() => {
    if (state?.[STORE_KEYS.GET_GB_PRODUCT_DATA]?.gbProduct) {
      const rptsData = state?.[STORE_KEYS.GET_GB_PRODUCT_DATA]?.body?.data;
      setMonitoringProductData([
        ...rptsData?.map((item: any) => ({ code: item.CustProductKey, name: item.ProductName }))

      ]);

      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.GET_GB_PRODUCT_DATA,
          uniqueScreenIdentifier: {
            gbProduct: false
          }
        })
      );
    }
  }, [state?.[STORE_KEYS.GET_GB_PRODUCT_DATA]?.gbProduct]);

  useEffect(() => {
    if (state?.[STORE_KEYS.GET_GB_PRODUCT_DATA_FBE]?.gbProduct) {
      const rptsData = state?.[STORE_KEYS.GET_GB_PRODUCT_DATA_FBE]?.body?.data;
      setMonitoringProductData([
        ...rptsData?.map((item: any) => ({ code: item.CustProductKey, name: item.ProductName }))

      ]);

      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.GET_GB_PRODUCT_DATA_FBE,
          uniqueScreenIdentifier: {
            gbProduct: false
          }
        })
      );
    }
  }, [state?.[STORE_KEYS.GET_GB_PRODUCT_DATA_FBE]?.gbProduct]);

  const getPlant = () => {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.API_GET_GOLDENBATCH_CONFIG_FILTER_PLANT_LIST,
          reqObj: {
            "LoggedUserKey": loggedInUser
          }
        },
        storeKey: STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_PLANT_LIST
      })
    );
  }

  useEffect(() => {
    if (state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_PLANT_LIST]?.body?.data) {
      const list = state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_PLANT_LIST]?.body?.data?.map((item: any) => {
        const newVal = renameObjKeys(item, { PlantKey: 'code', PlantName: 'name' });
        return newVal;
      });
      setConfigPlants(list);
      setPlantKey(list[0]?.code)
      getMachineModel(list[0]?.code);
    }
  }, [state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_PLANT_LIST]]);

  const getProducts = (PKey: any = plantKey, MMKey: any = machineModelKey, type: string = machineModelName) => {
    if (type.includes('BQS')) {
      dispatch(
        executeACGAction({
          payload: {
            requestType: 'POST',
            urlPath: ACTION_CODES.API_GET_GOLDENBATCH_CONFIG_PRODUCT_LIST_BQS,
            reqObj: {
              "LoggedUserKey": loggedInUser,
              "PlantKey": PKey,
              "MachineModelKey": MMKey
            }
          },
          uniqueScreenIdentifier: { bqsProducts: true },
          storeKey: STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_PRODUCT_LIST_BQS
        })
      );
    } else {
      dispatch(
        executeACGAction({
          payload: {
            requestType: 'POST',
            urlPath: ACTION_CODES.API_GET_GOLDENBATCH_CONFIG_PRODUCT_LIST_FBE,
            reqObj: {
              "LoggedUserKey": loggedInUser,
              "PlantKey": PKey,
              "MachineModelKey": MMKey
            }
          },
          uniqueScreenIdentifier: { fbeProducts: true },
          storeKey: STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_PRODUCT_LIST_FBE
        })
      );
    }

  }

  useEffect(() => {
    if (state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_PRODUCT_LIST_BQS]?.bqsProducts) {
      const result = state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_PRODUCT_LIST_BQS]?.body?.data;
      const list = result?.map((item: any) => {
        const newVal = renameObjKeys(item, { CustProductKey: 'code', ProductName: 'name' });
        return newVal;
      });
      setProductsData(list);
      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_PRODUCT_LIST_BQS,
          uniqueScreenIdentifier: { bqsProducts: false }
        })
      );
    }
  }, [state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_PRODUCT_LIST_BQS]?.bqsProducts]);

  useEffect(() => {
    if (state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_PRODUCT_LIST_FBE]?.fbeProducts) {
      const result = state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_PRODUCT_LIST_FBE]?.body?.data;
      const list = result?.map((item: any) => {
        const newVal = renameObjKeys(item, { CustProductKey: 'code', ProductName: 'name' });
        return newVal;
      });
      setProductsData(list);
      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_PRODUCT_LIST_FBE,
          uniqueScreenIdentifier: { fbeProducts: false },
        })
      );
    }
  }, [state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_PRODUCT_LIST_FBE]?.fbeProducts]);

  const getMachineModel = (key: any) => {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.API_GET_GOLDENBATCH_CONFIG_FILTER_MACHINEMODEL_LIST,
          reqObj: {
            "LoggedUserKey": loggedInUser,
            "PlantKey": key
          }
        },
        storeKey: STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_MACHINEMODEL_LIST
      })
    );
  }

  useEffect(() => {
    if (state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_MACHINEMODEL_LIST]?.body?.data) {
      const list = state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_MACHINEMODEL_LIST]?.body?.data?.map((item: any) => {
        const newVal = renameObjKeys(item, { MachineModelKey: 'code', MachineModelName: 'name', MachineModelCode: 'alias' });
        return newVal;
      });
      setConfigMachineModel(list);
      setMachineModelKey(list[0]?.code);
      setMachineModelName(list[0]?.alias)
    }

  }, [state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_MACHINEMODEL_LIST]])

  const getMachine = (key1: any, key2: any) => {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.API_GET_GOLDENBATCH_CONFIG_FILTER_MACHINE_LIST,
          reqObj: {
            "LoggedUserKey": loggedInUser,
            "PlantKey": plantKey,
            "MachineModelKey": machineModelKey,
            "CustProductKey": key1 ? key1 : -1,
            "RecipeKey": key2
          }
        },
        storeKey: STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_MACHINE_LIST
      })
    );
  }

  useEffect(() => {
    if (state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_MACHINE_LIST]?.body?.data) {
      const list = state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_MACHINE_LIST]?.body?.data?.map((item: any) => {
        const newVal = renameObjKeys(item, { MachineKey: 'code', LineMachineName: 'name' });
        return newVal;
      });
      setConfigMachineParams(list);
    }

  }, [state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_MACHINE_LIST]])

  const getFBEMachine = (key1: any, key2: any, from: any, to: any) => {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.API_GET_GOLDENBATCH_CONFIG_FILTER_FBE_MACHINE_LIST,
          reqObj: {
            "LoggedUserKey": loggedInUser,
            "PlantKey": plantKey,
            "MachineModelKey": machineModelKey,
            "CustProductKey": key1 ? key1 : -1,
            "RecipeKey": key2,
            "FromDate": from.toLocaleString().split(',')[0],
            "ToDate": to.toLocaleString().split(',')[0]
          }
        },
        storeKey: STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_FBE_MACHINE_LIST
      })
    );
  }

  useEffect(() => {
    if (state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_FBE_MACHINE_LIST]?.body?.data) {
      const list = state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_FBE_MACHINE_LIST]?.body?.data?.map((item: any) => {
        const newVal = renameObjKeys(item, { MachineKey: 'code', LineMachineName: 'name' });
        return newVal;
      });
      setConfigMachineParams(list);
    }

  }, [state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_FBE_MACHINE_LIST]])

  const getRecipes = (key: any) => {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.API_GET_GOLDENBATCH_CONFIG_FILTER_RECIPES_LIST,
          reqObj: {
            "LoggedUserKey": loggedInUser,
            "PlantKey": plantKey,
            "MachineModelKey": machineModelKey,
            "CustProductKey": key
          }
        },
        storeKey: STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_RECIPES_LIST
      })
    );
  }

  useEffect(() => {
    if (state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_RECIPES_LIST]?.body?.data) {
      const list = state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_RECIPES_LIST]?.body?.data?.map((item: any) => {
        const newVal = renameObjKeys(item, { RecipeKey: 'code', RecipeName: 'name' });
        return newVal;
      });
      setConfigMachineRecipes(list);
    }

  }, [state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_RECIPES_LIST]])

  const getFBERecipes = (key1: any) => {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.API_GET_GOLDENBATCH_CONFIG_FILTER_FBE_RECIPES_LIST,
          reqObj: {
            "LoggedUserKey": loggedInUser,
            "PlantKey": plantKey,
            "MachineModelKey": machineModelKey,
            "CustProductKey": key1 ? key1 : -1,
            "Type": selectedRow?.length !== 0 ? "Edit" : "Add"
          }
        },
        storeKey: STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_FBE_RECIPES_LIST
      })
    );
  }

  useEffect(() => {
    if (state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_FBE_RECIPES_LIST]?.body?.data) {
      const list = state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_FBE_RECIPES_LIST]?.body?.data?.map((item: any) => {
        const newVal = renameObjKeys(item, { RecipeKey: 'code', RecipeName: 'name' });
        return newVal;
      });
      setConfigMachineRecipes(list);
    }

  }, [state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_FBE_RECIPES_LIST]])

  const getBatchesLot = (key1: any, key2: any, from: any, to: any, key3: any, key4: any) => {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.API_GET_GOLDENBATCH_CONFIG_FILTER_BATCHES_LOT_LIST,
          reqObj: {
            "LoggedUserKey": loggedInUser,
            "PlantKey": plantKey,
            "MachineModelKey": machineModelKey,
            "CustProductKey": key1 ? key1 : -1,
            "RecipeKey": key2,
            "FromDate": from,
            "ToDate": to,
            "MachineKey": key3,
            "BatchNumber": key4,
          }
        },
        storeKey: STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_BATCHES_LOT_LIST
      })
    );
  }

  useEffect(() => {
    if (state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_BATCHES_LOT_LIST]?.body?.data) {
      const list = state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_BATCHES_LOT_LIST]?.body?.data?.map((item: any) => {
        const newVal = renameObjKeys(item, { BatchKey: 'code', BatchNo: 'name' });
        return newVal;
      });
      setConfigBatches(list);
    }

  }, [state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_BATCHES_LOT_LIST]])

  const getProcess = (key1: any) => {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.API_GET_GOLDENBATCH_CONFIG_PROCESS_LIST_FBE,
          reqObj: {
            "LoggedUserKey": loggedInUser,
            "PlantKey": plantKey,
            "MachineModelKey": machineModelKey,
            "CustProductKey": key1 ? key1 : null
          }
        },
        storeKey: STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_PROCESS_LIST_FBE
      })
    );
  }

  useEffect(() => {
    if (state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_PROCESS_LIST_FBE]?.body?.data) {
      const list = state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_PROCESS_LIST_FBE]?.body?.data?.map((item: any) => {
        const newVal = renameObjKeys(item, { ProcessKey: 'code', ProcessName: 'name' });
        return newVal;
      });
      setConfigMachineProcess(list);
    }

  }, [state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_PROCESS_LIST_FBE]])

  const getSubProcess = (key1: any) => {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.API_GET_GOLDENBATCH_CONFIG_SUB_PROCESS_LIST_FBE,
          reqObj: {
            "LoggedUserKey": loggedInUser,
            "PlantKey": plantKey,
            "MachineModelKey": machineModelKey,
            "ProcessKey": key1
          }
        },
        storeKey: STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_SUB_PROCESS_LIST_FBE
      })
    );
  }

  useEffect(() => {
    if (state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_SUB_PROCESS_LIST_FBE]?.body?.data) {
      const list = state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_SUB_PROCESS_LIST_FBE]?.body?.data?.map((item: any) => {
        const newVal = renameObjKeys(item, { SubProcessKey: 'code', SubProcessName: 'name' });
        return newVal;
      });
      setConfigMachineSubProcess(list);
    }

  }, [state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_SUB_PROCESS_LIST_FBE]])

  const getBatches = (key1: any, key2: any, key3: any, from: any, to: any) => {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.API_GET_GOLDENBATCH_CONFIG_FILTER_BATCHES_LIST,
          reqObj: {
            "CustProductKey": key1 ? key1 : -1,
            "RecipeKey": key2,
            "MachineKey": key3 ? key3 : -1,
            "FromDate": from.toLocaleString().split(',')[0],
            "ToDate": to.toLocaleString().split(',')[0]
          }
        },
        storeKey: STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_BATCHES_LIST
      })
    );
  }

  useEffect(() => {
    if (state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_BATCHES_LIST]?.body?.data) {
      const list = state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_BATCHES_LIST]?.body?.data?.map((item: any) => {
        const newVal = renameObjKeys(item, { BatchKey: 'code', BatchNo: 'name' });
        return newVal;
      });
      setConfigBatches(list);
    }

  }, [state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_BATCHES_LIST]])

  const getMasterBatches = (key1: any, key2: any, from: any, to: any, key3: any,) => {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.API_GET_GOLDENBATCH_CONFIG_FILTER_FBE_MASTER_BATCHES_LIST,
          reqObj: {
            "LoggedUserKey": loggedInUser,
            "PlantKey": plantKey,
            "MachineModelKey": machineModelKey,
            "CustProductKey": key1 ? key1 : -1,
            "RecipeKey": key2,
            "MachineKey": key3 ? key3 : -1,
            "FromDate": from.toLocaleString().split(',')[0],
            "ToDate": to.toLocaleString().split(',')[0]
          }
        },
        storeKey: STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_FBE_MASTER_BATCHES_LIST
      })
    );
  }

  useEffect(() => {
    if (state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_FBE_MASTER_BATCHES_LIST]?.body?.data) {
      const list = state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_FBE_MASTER_BATCHES_LIST]?.body?.data?.map((item: any) => {
        const newVal = renameObjKeys(item, { BatchNumber: 'code', BatchNumberValue: 'name' });
        return newVal;
      });
      setConfigMasterBatches(list);
    }

  }, [state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_FBE_MASTER_BATCHES_LIST]])

  const fetchRecipe = (statusType: any, key: any, mmName: any) => {
    if (mmName.includes("BQS")) {
      dispatch(
        executeACGAction({
          payload: {
            requestType: 'POST',
            urlPath: ACTION_CODES.GET_GB_RECIPE_DATA,
            reqObj: {
              "LoggedUserKey": loggedInUser,
              "PlantKey": plantKey,
              "MachineModelKey": machineModelKey,
              "MachineKey": selectedSerial,
              "CustProductKey": key,
              "BatchStatus": statusType,
              "FromDate": statusType === "Active" ? null : from,
              "ToDate": statusType === "Active" ? null : to,

            }
          },
          uniqueScreenIdentifier: { gbRecipe: true },
          storeKey: STORE_KEYS.GET_GB_RECIPE_DATA
        })
      );
    } else {
      dispatch(
        executeACGAction({
          payload: {
            requestType: 'POST',
            urlPath: ACTION_CODES.GET_GB_RECIPE_DATA_FBE,
            reqObj: {
              "LoggedUserKey": loggedInUser,
              "PlantKey": plantKey,
              "MachineModelKey": machineModelKey,
              "MachineKey": selectedSerial,
              "CustProductKey": key,
              "BatchStatus": statusType,
              "FromDate": statusType === "Active" ? null : from,
              "ToDate": statusType === "Active" ? null : to,

            }
          },
          uniqueScreenIdentifier: { gbRecipe: true },
          storeKey: STORE_KEYS.GET_GB_RECIPE_DATA_FBE
        })
      );
    }
  };

  useEffect(() => {
    if (state?.[STORE_KEYS.GET_GB_RECIPE_DATA]?.gbRecipe) {
      const rptsData = state?.[STORE_KEYS.GET_GB_RECIPE_DATA]?.body?.data;
      setRecipeData([
        ...rptsData?.map((item: any) => ({ code: item.RecipeKey, name: item.RecipeName }))

      ]);

      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.GET_GB_RECIPE_DATA,
          uniqueScreenIdentifier: {
            gbRecipe: false
          }
        })
      );
    }
  }, [state?.[STORE_KEYS.GET_GB_RECIPE_DATA]?.gbRecipe]);

  useEffect(() => {
    if (state?.[STORE_KEYS.GET_GB_RECIPE_DATA_FBE]?.gbRecipe) {
      const rptsData = state?.[STORE_KEYS.GET_GB_RECIPE_DATA_FBE]?.body?.data;
      setRecipeData([
        ...rptsData?.map((item: any) => ({ code: item.RecipeKey, name: item.RecipeName }))

      ]);

      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.GET_GB_RECIPE_DATA_FBE,
          uniqueScreenIdentifier: {
            gbRecipe: false
          }
        })
      );
    }
  }, [state?.[STORE_KEYS.GET_GB_RECIPE_DATA_FBE]?.gbRecipe]);

  const fetchBatch = (statusType: any, key: any, mmName: any) => {
    if (mmName.includes("BQS")) {
      dispatch(
        executeACGAction({
          payload: {
            requestType: 'POST',
            urlPath: ACTION_CODES.GET_GB_BATCH_FILTER_DATA,
            reqObj: {
              "LoggedUserKey": loggedInUser,
              "MachineKey": parseInt(selectedSerial),
              "CustProductKey": selectedProduct,
              "RecipeKey": parseInt(key),
              "BatchStatus": statusType,
              "FromDate": statusType === "Active" ? null : from,
              "ToDate": statusType === "Active" ? null : to,
              "PlantKey": plantKey,
              "MachineModelKey": machineModelKey
            }
          },
          uniqueScreenIdentifier: { gbBatch: true },
          storeKey: STORE_KEYS.GET_GB_BATCH_FILTER_DATA
        })
      );
    } else {
      dispatch(
        executeACGAction({
          payload: {
            requestType: 'POST',
            urlPath: ACTION_CODES.GET_GB_BATCH_FILTER_DATA_FBE,
            reqObj: {
              "LoggedUserKey": loggedInUser,
              "PlantKey": plantKey,
              "MachineModelKey": machineModelKey,
              "BatchStatus": statusType,
              "MachineKey": parseInt(selectedSerial),
              "CustProductKey": selectedProduct,
              "RecipeKey": parseInt(key),
              "FromDate": statusType === "Active" ? null : from,
              "ToDate": statusType === "Active" ? null : to,

            }
          },
          uniqueScreenIdentifier: { gbBatch: true },
          storeKey: STORE_KEYS.GET_GB_BATCH_FILTER_DATA_FBE
        })
      );
    }
  };

  useEffect(() => {
    if (state?.[STORE_KEYS.GET_GB_BATCH_FILTER_DATA]?.gbBatch) {
      const rptsData = state?.[STORE_KEYS.GET_GB_BATCH_FILTER_DATA]?.body?.data;
      setBatchData([
        ...rptsData?.map((item: any) => ({ code: item.BatchKey, name: item.BatchNo }))

      ]);

      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.GET_GB_BATCH_FILTER_DATA,
          uniqueScreenIdentifier: {
            gbBatch: false
          }
        })
      );
    }
  }, [state?.[STORE_KEYS.GET_GB_BATCH_FILTER_DATA]?.gbBatch]);

  useEffect(() => {
    if (state?.[STORE_KEYS.GET_GB_BATCH_FILTER_DATA_FBE]?.gbBatch) {
      const rptsData = state?.[STORE_KEYS.GET_GB_BATCH_FILTER_DATA_FBE]?.body?.data;
      setBatchData([
        ...rptsData?.map((item: any) => ({ code: item.BatchKey, name: item.BatchNo }))

      ]);

      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.GET_GB_BATCH_FILTER_DATA_FBE,
          uniqueScreenIdentifier: {
            gbBatch: false
          }
        })
      );
    }
  }, [state?.[STORE_KEYS.GET_GB_BATCH_FILTER_DATA_FBE]?.gbBatch]);

  const fetchParameters = (key: any, mmName: any) => {
    if (mmName.includes("BQS")) {
      dispatch(
        executeACGAction({
          payload: {
            requestType: 'POST',
            urlPath: ACTION_CODES.GET_GB_PARAMAETER_FILTER_DATA,
            reqObj: {
              "MachineKey": parseInt(key),
            }
          },
          uniqueScreenIdentifier: { gbParameter: true },
          storeKey: STORE_KEYS.GET_GB_PARAMAETER_FILTER_DATA
        })
      );
    } else {
      dispatch(
        executeACGAction({
          payload: {
            requestType: 'POST',
            urlPath: ACTION_CODES.GET_GB_PARAMAETER_FILTER_DATA_FBE,
            reqObj: {
              "MachineKey": parseInt(machineModelKey),
            }
          },
          uniqueScreenIdentifier: { gbParameter: true },
          storeKey: STORE_KEYS.GET_GB_PARAMAETER_FILTER_DATA_FBE
        })
      );
    }
  };
  
  useEffect(() => {
    const gbParamData = state?.[STORE_KEYS.GET_GB_PARAMAETER_FILTER_DATA];
  
    if (gbParamData?.gbParameter) {
      const rptsData = gbParamData?.body?.data;
  
      if (status === "Active") {
        setParameterActiveData([
          ...rptsData?.map((item: any) => ({ code: item.MMParamKey, name: item.ParamName }))
        ]);
  
        const defaultActiveData = rptsData[0];
        if (selectedActiveParameter === "") {
          setSelectedActiveParameter(defaultActiveData ? defaultActiveData?.MMParamKey : '');
        }
      } else {
        setParameterData([
          ...rptsData?.map((item: any) => ({ code: item.MMParamKey, name: item.ParamName }))
        ]);
      }
  
      // Now, update the screen identifier to false after processing
      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.GET_GB_PARAMAETER_FILTER_DATA,
          uniqueScreenIdentifier: {
            gbParameter: false
          }
        })
      );
    }
  }, [state?.[STORE_KEYS.GET_GB_PARAMAETER_FILTER_DATA]?.gbParameter]);
  
  useEffect(() => {
    const gbParamDataFBE = state?.[STORE_KEYS.GET_GB_PARAMAETER_FILTER_DATA_FBE];
    if (gbParamDataFBE?.gbParameter) {
      const rptsData = gbParamDataFBE?.body?.data;
  
      if (status === "Active") {
        setParameterActiveData([
          ...rptsData?.map((item: any) => ({ code: item.MMParamKey, name: item.ParamName }))
        ]);
  
        const defaultActiveData = rptsData[0];
        if (selectedActiveParameter === "") {
          setSelectedActiveParameter(defaultActiveData ? defaultActiveData?.MMParamKey : '');
        }
      } else {
        setParameterData([
          ...rptsData?.map((item: any) => ({ code: item.MMParamKey, name: item.ParamName }))
        ]);
      }
  
      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.GET_GB_PARAMAETER_FILTER_DATA_FBE,
          uniqueScreenIdentifier: {
            gbParameter: false
          }
        })
      );
    }
  }, [state?.[STORE_KEYS.GET_GB_PARAMAETER_FILTER_DATA_FBE]?.gbParameter]);
  
  const clearAppliedFilter = () => {
    setDefVal([0, 0]);
    setHSliderT([0, 0]);
    if(currTab == 0) {setStatus('Active');}
    else if(currTab == 1) {setStatus('Historical');}
    else {setStatus('Create')}
    setFrom(null);
    setTo(null);
    setSelectedSerial('');
    setSelectedProduct('');
    setMonitoringProductData([]);
    setRecipeData([]);
    setSelectedRecipe('');
    setSelectedBatch('');
    setBatchData([]);
    setParameterData([]);
    setSelectedParameter('');
    setTotalPages(0);
    setChart1Page(0);
    setAppliedFilters(false);
    setProductsData([]);
    setSerialData([]);
    dispatch(
      updateScreenIdentifiers({
        storeKey: STORE_KEYS.GOLDEN_BATCH_DATA,
        uniqueScreenIdentifier: {
          goldenbatchAdded: !state?.[STORE_KEYS.GOLDEN_BATCH_DATA]?.body?.goldenbatchAdded,
          data: []
        },

      })
    )
    dispatch(
      updateScreenIdentifiers({
        storeKey: STORE_KEYS.GOLDEN_BATCH_DATA_FBE,
        uniqueScreenIdentifier: {
          goldenbatchAddedFBE: !state?.[STORE_KEYS.GOLDEN_BATCH_DATA_FBE]?.body?.goldenbatchAddedFBE,
          data: []
        },

      })
    )
  }

  const switchGraphFilter = () => {
    setDefVal([0, 0]);
    setHSliderT([0, 0]);
    setFrom(null);
    setTo(null);
    setSelectedSerial('');
    setRecipeData([]);
    setSelectedRecipe('');
    setSelectedBatch('');
    setBatchData([]);
    setParameterData([]);
    setSelectedParameter('');
    setTotalPages(0);
    setChart1Page(0);
    setAppliedFilters(false);
    dispatch(
      updateScreenIdentifiers({
        storeKey: STORE_KEYS.GOLDEN_BATCH_DATA,
        uniqueScreenIdentifier: {
          goldenbatchAdded: !state?.[STORE_KEYS.GOLDEN_BATCH_DATA]?.body?.goldenbatchAdded,
          data: []
        },

      })
    )
    dispatch(
      updateScreenIdentifiers({
        storeKey: STORE_KEYS.GOLDEN_BATCH_DATA_FBE,
        uniqueScreenIdentifier: {
          goldenbatchAddedFBE: !state?.[STORE_KEYS.GOLDEN_BATCH_DATA_FBE]?.body?.goldenbatchAddedFBE,
          data: []
        },

      })
    )
  }

  const updatePage = (pageNo: number, operator: string, count: number) => {
    if (operator === '+') {
      setChart1Page(pageNo === 0 ? pageNo + count + 1 : pageNo + count);
      getGoldenBatchData(pageNo === 0 ? pageNo + count + 1 : pageNo + count);
    } else {
      setChart1Page(pageNo - count);
      getGoldenBatchData(pageNo - count);
    }
  }

  const updateActivePage = (pageNo: number, operator: string, count: number) => {
    if (operator === '+') {
      setChart1ActivePage(pageNo === 0 ? pageNo + count + 1 : pageNo + count);
      getGoldenBatchData(pageNo === 0 ? pageNo + count + 1 : pageNo + count);
    } else {
      setChart1Page(pageNo - count);
      setChart1ActivePage(pageNo - count);
    }
  }

  const clearGBLimit = () => {
    setClearGBLimitFilter(false);
    setFilteredGoldenMachineModel('');
    setQualityAttributeList([]);
  }

  const updatePlant = (e: any) => {
    setPlantKey(e.target.value);
    setConfigMachineModel([]);
    getMachineModel(e.target.value);
    setMachineModelKey('');
    setQualityAttributeList([]);
  }

  useEffect(() => {
    if (machineModelKey === "") {
      setActiveAlerts([]);
      setPrevActiveAlerts([]);
    }
  }, [machineModelKey])

  const updateMachineModel = (e: any) => {
    setNewActiveAlertItems(new Set());
    clearAppliedFilter();
    const mmName = configMachineModel.find((item: any) => item.code === e.target.value).name;
    const mmAlias = configMachineModel.find((item: any) => item.code === e.target.value).alias;
    setCurrTab(0);
    setGoldenBatchList([]);
    setMachineModelKey(e.target.value);
    getMachineSerial('Active', null, null, plantKey, e.target.value, mmName);
    setMachineModelName(mmAlias);
    getProducts(plantKey, e.target.value, configMachineModel.find((item: any) => item.code === e.target.value).name);
    // if (configMachineModel.find((item: any) => item.code === e.target.value).name.includes('BQS')) {
    //   getGoldenBatchListBQS(-1, -1, -1, e.target.value)
    // }
    // else { getGoldenBatchListFBE(e.target.value, -1) }
    setSelectedActiveParameter('');
    setSelectedActiveSerial('');
    setSelectedActiveProduct('');
    setSelectedActiveBatch('');
    setSerialActiveData([]);
    setParameterActiveData([]);
    setTotalActivePages(0);
    setChart1ActivePage(0);
  }

//   useEffect(() => {
//     console.log(JSON.parse(state[STORE_KEYS.USER_DTL]?.features[11]?.SubMenus))
//     const _details = JSON.parse(state[STORE_KEYS.USER_DTL]?.features[11]?.SubMenus);
//     const newPermissions = _details?.reduce((acc: Record<string, boolean>, i: any) => {
//       acc[i.SubMenuCode] = i.IsEditable;
//       return acc;
//     }, {});
//     setTabsPermissions(newPermissions || {});
    
//     const subMenu = _details?.map((i: any) => { return i.SubMenuName });
//     setTabs(subMenu);

// }, [state[STORE_KEYS.USER_DTL]])

useEffect(() => {

  const _details = [
    {
        "SubMenuName": "Active Golden Batch",
        "SubMenuCode": "AGB",
        "IsEditable": "Y"
    },
    {
        "SubMenuName": "Historical Golden Batch",
        "SubMenuCode": "HGB",
        "IsEditable": "Y"
    },
    {
        "SubMenuName": "Golden Batch List",
        "SubMenuCode": "GBL",
        "IsEditable": "Y"
    }
]
  const newPermissions:any = _details?.reduce((acc: Record<string, boolean>, i: any) => {
    acc[i.SubMenuCode] = i.IsEditable;
    return acc;
  }, {});
  setTabsPermissions(newPermissions || {});
  
  const subMenu:any = _details?.map((i: any) => { return i.SubMenuName });
  setTabs(subMenu);

}, [])

useEffect(()=>{
  trackPageActionLog({
    actionName:'Golden Batch Dashboard',
    action:'Landed on Golden Batch Dashboard',
    LoggedInUser:state[STORE_KEYS.USER_DTL]?.EmailId,
})
},[])

  return (
    <div className="dashboard PMEC-Dash goldbatch">
      <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
      <Container maxWidth="xl" style={{ marginTop: '60px' }}>
        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <div style={{ flex: '80%', display: 'flex' }}>
            <div className="headinglabel mb10" style={{ marginBottom: '15px' }}>
              Golden Batch Dashboard
            </div>

            <div
              style={{
                flex: '10%',
                marginLeft: '20px',
                // marginTop: '10px',
                display: 'flex',
                justifyContent: 'flex-end'
              }}
              className="dashboardDropdownDiv"
            >
              <div className='plantmodel' style={{ color: "#e0e0e0" }}>
                <Label label="Plant Name &nbsp; *" className={'CMlabelDiv'} />
                <DropDown
                  onChange={(e: any) => { updatePlant(e) }}
                  className="inputDiv"
                  name="dropdownModel"
                  items={configPlants}
                  placeHolder="Select Plant"
                  value={plantKey}
                />
                <Label label="Machine Model &nbsp; *" className={'CMlabelDiv'} />
                <DropDown
                  onChange={(e: any) => { updateMachineModel(e) }}
                  className="inputDiv"
                  name="dropdownModel"
                  items={configMachineModel}
                  placeHolder="Select Machine Model"
                  value={machineModelKey}
                />
              </div>
              {tabsPermissions?.GBL=="Y"?<DrawerDropdown isDisabled={machineModelKey === '' ? true : false} click={getCreateValue} items={['Define Golden Batch']} />
           :null}
               </div>
          </div>
        </div>
        <div style={{ marginTop: '10px', zIndex: '0', position: 'relative' }}>
          <MapCustomTabs
            navigateTab={navigateTab}
            currTab={currTab}
            setCurrTab={setCurrTab}
            tabsArray={tabs}
            className="tabs"
            position="relative"
          // type="nonCurve"
          moduleName='Golden Batch Dashboard Tabs'
          />
        </div>
      </Container>
      <RightDrawer
        alertType={createValue}
        handlerOpen={() => { }}
        handlerClose={() => { setCreateDrawerOpen(false); setDrawerHeader('Create'); setConfigBatches([]); setConfigMachineParams([]); setConfigMachineRecipes([]); setConfigMachineSubProcess([]); }}
        status={createDrawerOpen}
        drawerHeader={`${drawerHeader} ${createValue}`}
        children={
          drawerHeader === 'Create' || drawerHeader === 'Edit' || drawerHeader === 'Add' || drawerHeader === 'View' && createDrawerOpen ? (
            createValue === 'Golden Batch' ?
              (<CreateGoldenBatchConfig
                machineModelKey={machineModelKey}
                machineModelName={machineModelName}
                productData={productData}
                configMachineParams={configMachineParams}
                configMachineRecipes={configMachineRecipes}
                configBatches={configBatches}
                getMachineModel={getMachineModel}
                getMachine={getMachine}
                getFBEMachine={getFBEMachine}
                getRecipes={getRecipes}
                getBatches={getBatches}
                getBatchesLot={getBatchesLot}
                drawerClose={() => {
                  setCreateDrawerOpen(false);
                  setSelectedRow([]);
                  setConfigBatches([]);
                  setConfigMachineParams([]);
                  setConfigMachineRecipes([]);
                  setConfigMasterBatches([]);
                  setConfigMachineSubProcess([]);
                }}
                currTab={currTab}
                setCurrTab={setCurrTab}
                setSnackbarOpts={setSnackbarOptions}
                getGoldenBatchListBQS={getGoldenBatchListBQS}
                getGoldenRecipeBQS={getGoldenRecipeBQS}
                selectedRow={selectedRow}
                getProcess={getProcess}
                getSubProcess={getSubProcess}
                configMachineSubProcess={configMachineSubProcess}
                setConfigMachineSubProcess={setConfigMachineSubProcess}
                configMachineProcess={configMachineProcess}
                setConfigMachineProcess={setConfigMachineProcess}
                setConfigMasterBatches={setConfigMasterBatches}
                setConfigMachineParams={setConfigMachineParams}
                configMasterBatches={configMasterBatches}
                getMasterBatches={getMasterBatches}
                getFBERecipes={getFBERecipes}
                setConfigBatches={setConfigBatches}
                setConfigMachineRecipes={setConfigMachineRecipes}
                getGoldenBatchListFBE={getGoldenBatchListFBE}
                isFilter={false}
                filteredProcessKey={filteredProcessKey}
                setFilteredProcessKey={setFilteredProcessKey}
                getProducts={getProducts}
              />)
              : createValue === 'Limit Config' ?
                (
                  <EditConfig
                    drawerClose={() => { setCreateDrawerOpen(false); setSelectedRow([]); setConfigBatches([]); setConfigMachineParams([]); setConfigMachineRecipes([]); }}
                    currTab={currTab}
                    setDrawerHeader={setDrawerHeader}
                    drawerHeader={drawerHeader}
                    setCurrTab={setCurrTab}
                    selectedRow={selectedRow}
                    setSnackbarOpts={setSnackbarOptions}
                    qualityAttributeList={qualityAttributeList}
                    goldenRecipe={goldenRecipe}
                    plantKey={plantKey}
                    machineModelKey={machineModelKey}
                    machineModelName={machineModelName}
                    filteredGoldenRecipeKey={filteredGoldenRecipeKey}
                    setQualityAttributeList={setQualityAttributeList}
                    limitParameters={limitParameters}
                    getQualityAttributesBQS={getQualityAttributesBQS}
                    setFilteredGoldenRecipeKey={setFilteredGoldenRecipeKey}
                    phasesTab={phasesTab}
                    autoFillProducts={autoFillProducts}
                    autoFillProcess={autoFillProcesss}
                    setAutoFillProcess={setAutoFillProcess}
                    autoFillSubProcess={autoFillSubProcess}
                    setAutoFillSubProcess={setAutoFillSubProcess}
                    autoFillRecipes={autoFillRecipes}
                    setAutoFillRecipes={setAutoFillRecipes}
                    goldenRecipeKey={goldenRecipeKey}
                    getQualityAttributesFBE={getQualityAttributesFBE}
                    getGoldenBatchListFBE={getGoldenBatchListFBE}
                    getGoldenBatchListBQS={getGoldenBatchListBQS}
                    isError={isError}
                    setIsError={setIsError}
                  />
                )
                : createValue === 'Config Limit' ?
                  (
                    <LoadParameters
                      drawerClose={() => { setCreateDrawerOpen(false); setSelectedRow([]); setConfigBatches([]); setConfigMachineParams([]); setConfigMachineRecipes([]); }}
                      currTab={currTab}
                      setCurrTab={setCurrTab}
                      setSnackbarOpts={setSnackbarOptions}
                      reportType={reportType}
                      reportKey={reportKey}
                      setReportKey={setReportKey}
                      selectedRow={selectedRow}
                      goldenRecipe={goldenRecipe}
                      machineModelName={machineModelName}
                      setFilteredGoldenRecipeKey={setFilteredGoldenRecipeKey}
                      setGoldenRecipeKey={setGoldenRecipeKey}
                      goldenRecipeKey={goldenRecipeKey}
                      getQualityAttributesBQS={getQualityAttributesBQS}
                      getGoldenBatchListBQS={getGoldenBatchListBQS}
                      setClearGBLimitFilter={setClearGBLimitFilter}
                    />
                  ) : null
          ) : createValue === 'Parameter Limit Update Filter' ?
            (
              <ReportTypeFilter
                drawerClose={() => { setCreateDrawerOpen(false); }}
                currTab={currTab}
                setCurrTab={setCurrTab}
                setSnackbarOpts={setSnackbarOptions}
                reportType={reportType}
                reportKey={reportKey}
                setReportKey={setReportKey}
                createValue={createValue}
                goldenRecipe={goldenRecipe}
                goldenBatchList={goldenBatchList}
                setFilteredGoldenRecipeKey={setFilteredGoldenRecipeKey}
                filteredGoldenRecipeKey={filteredGoldenRecipeKey}
                getQualityAttributesBQS={getQualityAttributesBQS}
                configMachineModel={configMachineModel}
                filteredGoldenMachineModel={filteredGoldenMachineModel}
                setFilteredGoldenMachineModel={setFilteredGoldenMachineModel}
                setClearGBLimitFilter={setClearGBLimitFilter}
                machineModelKey={machineModelKey}
              />
            ) : createValue === 'Golden Batches Filter' && machineModelName.includes("BQS") ?
              (
                <GoldenlistFilter
                  drawerClose={() => { setCreateDrawerOpen(false); }}
                  currTab={currTab}
                  setCurrTab={setCurrTab}
                  setSnackbarOpts={setSnackbarOptions}
                  reportType={reportType}
                  reportKey={reportKey}
                  setReportKey={setReportKey}
                  createValue={createValue}
                  goldenRecipe={goldenRecipe}
                  setFilteredGoldenRecipeKey={setFilteredGoldenRecipeKey}
                  filteredGoldenRecipeKey={filteredGoldenRecipeKey}
                  getQualityAttributesBQS={getQualityAttributesBQS}
                  configMachineModel={configMachineModel}
                  filteredGoldenMachineModel={filteredGoldenMachineModel}
                  setFilteredGoldenMachineModel={setFilteredGoldenMachineModel}
                  setClearGBLimitFilter={setClearGBLimitFilter}
                  machineModelKey={machineModelKey}
                  machineModelName={machineModelName}
                />
              )
              : createValue === 'Golden Batches Filter' && !machineModelName.includes("BQS") ?
                (
                  <CreateGoldenBatchConfig
                    machineModelKey={machineModelKey}
                    machineModelName={machineModelName}
                    productData={productData}
                    configMachineParams={configMachineParams}
                    configMachineRecipes={configMachineRecipes}
                    configBatches={configBatches}
                    getMachineModel={getMachineModel}
                    getMachine={getMachine}
                    getFBEMachine={getFBEMachine}
                    getRecipes={getRecipes}
                    getBatches={getBatches}
                    getBatchesLot={getBatchesLot}
                    drawerClose={() => {
                      setCreateDrawerOpen(false);
                      setSelectedRow([]);
                      setConfigBatches([]);
                      setConfigMachineParams([]);
                      setConfigMachineRecipes([]);
                      setConfigMasterBatches([]);
                      setConfigMachineSubProcess([]);
                    }}
                    currTab={currTab}
                    setCurrTab={setCurrTab}
                    setSnackbarOpts={setSnackbarOptions}
                    getGoldenBatchListBQS={getGoldenBatchListBQS}
                    getGoldenRecipeBQS={getGoldenRecipeBQS}
                    selectedRow={selectedRow}
                    getProcess={getProcess}
                    getSubProcess={getSubProcess}
                    configMachineSubProcess={configMachineSubProcess}
                    setConfigMachineSubProcess={setConfigMachineSubProcess}
                    configMachineProcess={configMachineProcess}
                    setConfigMachineProcess={setConfigMachineProcess}
                    setConfigMasterBatches={setConfigMasterBatches}
                    setConfigMachineParams={setConfigMachineParams}
                    configMasterBatches={configMasterBatches}
                    getMasterBatches={getMasterBatches}
                    getFBERecipes={getFBERecipes}
                    setConfigBatches={setConfigBatches}
                    setConfigMachineRecipes={setConfigMachineRecipes}
                    getGoldenBatchListFBE={getGoldenBatchListFBE}
                    isFilter={true}
                    setApplyListFilter={setApplyListFilter}
                    filteredProcessKey={filteredProcessKey}
                    setFilteredProcessKey={setFilteredProcessKey}
                  />
                )
                : <Filter
                  from={from}
                  setFrom={setFrom}
                  to={to}
                  setTo={setTo}
                  chart1Page={chart1Page}
                  status={status}
                  setStatus={setStatus}
                  serialData={serialData}
                  batchData={batchData}
                  setSerialData={setSerialData}
                  setBatchData={setBatchData}
                  recipeData={recipeData}
                  setRecipeData={setRecipeData}
                  parameterData={parameterData}
                  setParameterData={setParameterData}
                  selectedBatch={selectedBatch}
                  setSelectedBatch={setSelectedBatch}
                  selectedParameter={selectedParameter}
                  setSelectedParameter={setSelectedParameter}
                  selectedSerial={selectedSerial}
                  setSelectedSerial={setSelectedSerial}
                  selectedRecipe={selectedRecipe}
                  setSelectedRecipe={setSelectedRecipe}
                  fetchRecipe={fetchRecipe}
                  fetchBatch={fetchBatch}
                  fetchParameters={fetchParameters}
                  clearAppliedFilter={clearAppliedFilter}
                  appliedFilters={appliedFilters}
                  setAppliedFilters={setAppliedFilters}
                  drawerClose={() => { setCreateDrawerOpen(false); setDrawerHeader('Create'); }}
                  getGoldenBatchData={getGoldenBatchData}
                  switchGraphFilter={switchGraphFilter}
                  selectedProduct={selectedProduct}
                  setSelectedProduct={setSelectedProduct}
                  monitoringProductData={monitoringProductData}
                  setMonitoringProductData={setMonitoringProductData}
                  fetchMontoringProducts={fetchMontoringProducts}
                  getMachineSerial={getMachineSerial}
                  plantKey={plantKey}
                  machineModelKey={machineModelKey}
                  machineModelName={machineModelName}
                />
        }
      />
    </div>
  );
};

export default SystemConfiguration;
