import React, { useState, useEffect } from 'react';
import { Container, Card, Typography } from '@mui/material';
import styles from './index.module.css'; // Import the CSS module
import CustomDatePicker from '../../../atoms/CustomDatePicker';
import DropDown from '../../../atoms/Dropdown';
import Tooltip from '@mui/material/Tooltip';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import DownArrow from '../../../../assets/down-arrow.svg';
import { _noop } from '../../../utils/common';
import '../../Admin/ClientManagement/index.scss';
import { Checkbox } from '@mui/material';
import ProductivityChart from './Charts/productivity';
import OperatorVisibilityChart from './Charts/operatorVIsibility';
import PlantVisibilityChart from './Charts/Visavali/VisavaliGraph';
import { dateISO, timeISO } from '../../../utils/helper';
import NoData from '../../../../assets/nodata.svg';
import Loader from '../../../atoms/Loader';
import { trackPageActionLog } from '../../../utils/newRelicFuncations';
import useGetState from '../../../utils/hooks/useGetState';
import { STORE_KEYS } from '../../../constants/apiConstants';
import HelpIcon from '@mui/icons-material/Help';
import InfoIcon from '@mui/icons-material/InfoRounded';
import InfoIconn from '@mui/icons-material/InfoOutlined';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const DropDownIcon = (prop: any) => (
    <img style={{ marginRight: '5px', marginTop: '-2px' }} src={DownArrow} {...prop} />
);

const OEEDashboardHomePage = (props: any) => {
    const state = useGetState()
    const [plantVisibilityData, setplantVisibilityData] = useState<any>([]);
    const [machineEventHoverData, setMachineEventHoverData] = useState<any>([]);
    const [machineShifttimeData, setMachineShifttimeData] = useState<any>([]);
    const [visavaliGraphLegendData, setVisavaliGraphLegendData] = useState<any>([]);

    useEffect(() => {
        const _data: any = Object.values(props.plantVisibility)[0]
        if (props.plantVisibility && Object.keys(props.plantVisibility).length > 0) {
            const formattedData: any = [], formattedHoverData: any = [];
            const machineKeys = Object.keys(props.plantVisibility);
            machineKeys.forEach((machineKey) => {
                const data = props.plantVisibility[machineKey];
                const formattedMachineData: any = [];
                let status;

                const formattedMachineHoverData: any = [];

                data.forEach((i: any) => {
                    if (i.MachineRunStatus === "Outbatch") {
                        status = "OB";
                    } else if (i.MachineRunStatus === "UnplannedDT") {
                        status = "UPDT";
                    } else if (i.MachineRunStatus === "PlannedDT") {
                        status = "PDT";
                    } else {
                        status = "RUN";
                    }

                    formattedMachineData.push([dateISO(i.StatusStartTime), status, dateISO(i.StatusEndTime)]);
                    formattedMachineHoverData.push([`Status: ${i.MachineRunStatus} <br/> Batch Number: ${i.BatchNumber} <br/> Product Name: ${i.ProductName} <br/> Operator Name: ${i.OperatorName} <br/> Description: ${i.DTCategoryDesc} <br/> Time:`]);
                });

                formattedData.push({ [machineKey]: formattedMachineData });
                formattedHoverData.push({ [machineKey]: formattedMachineHoverData });
            });
            setplantVisibilityData(formattedData);
            setMachineEventHoverData(formattedHoverData);
            setMachineShifttimeData([{ starttime: timeISO(_data[0].StatusStartTime), endtime: timeISO(_data[_data.length - 1].StatusEndTime) }]);
            setVisavaliGraphLegendData([
                { code: "RUN", title: "Run", color: "#65C54F" },
                { code: "OB", title: "OutBatch", color: "yellow" },
                { code: "PDT", title: "Planned Downtime", color: "#FFA71F" },
                { code: "UPDT", title: "Unplanned Downtime", color: "#D71635" }
            ]);
        }
    }, [props.plantVisibility]);

    return (
        <div className="modelComponents">
            <div>
                {props.currTab == 0 && <Container
                    maxWidth="xl"
                    className={styles.cardContainer} // Use the class from CSS module
                    style={{
                        paddingLeft: '3px',
                        paddingRight: '3px',
                    }}
                >
                    <div className='filterdiv' style={{ background: "#24263a", borderRadius: "0px" }}>
                        <div className='width_filter'>
                            <div className='lableTitle'>Select Date</div>
                            <CustomDatePicker
                                name="dateFromSelect"
                                value={props.defaultDate}
                                maxDate={new Date()}
                                className="inputDiv"
                                onChange={(val: any) => {
                                    props.setDefaultDate(val);
                                    props.setSelectedMachines([]);
                                }}
                                inputFormat="dd/MM/yyyy"
                                placeholder="Select Date"
                                minDate={new Date("01-01-2023")}

                            />
                        </div>
                        <div className='width_filter'>
                            <div className='lableTitle'>Select Plant</div>
                            <DropDown
                                onChange={props.handleChangePlant}
                                className="inputDiv dropdownbx longtxt padingLR"
                                name="dropdownModel"
                                items={props.plantsData}
                                placeHolder="Select Plant"
                                value={props.selectedPlant}
                            />
                        </div>
                        <div className='width_filter'>
                            <div className='lableTitle'>Select Machine Model</div>
                            <DropDown
                                onChange={props.handleMachineModel}
                                className="inputDiv dropdownbx padingLR"
                                name="dropdownModel"
                                items={props.machineModel}
                                placeHolder="Select Machine Model"
                                value={props.selectedMachineModel}
                            />
                        </div>
                        <div className='width_filter widthIncrease minWidthCheckBox'>
                            <div className='lableTitle'>Select Machine Serial No.</div>
                            <FormControl sx={{ width: "100%" }}>
                                <Select
                                    IconComponent={DropDownIcon}
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    className='chekboxmulti'
                                    //label=""
                                    multiple
                                    value={props.selectedMachines}
                                    sx={{ marginBottom: "10px", backgroundColor: "#1d1e2c", borderRadius: 3, marginTop: '10px', color: "#b1c2df" }}
                                    placeholder='Please select machines'
                                    // input={<OutlinedInput />}
                                    renderValue={(selected: any) => {
                                        const filteredMachines = props.selectedMachines.filter((mkey: any) =>
                                            selected.some((item: any) => item === mkey)
                                        );
                                        return filteredMachines.map((x: any) => {
                                            return (props.machines.find((i: any) => x === i.MachineKey))?.MachineSerialNo
                                        }).join(', ')
                                    }
                                    }
                                    MenuProps={MenuProps}

                                >
                                    {props.machines?.map((i: any) => (
                                        <MenuItem value={i} onClick={() => props.handleChange(i.MachineKey)} className='ulSelectProduct'>
                                            <Checkbox
                                                checked={props.selectedMachines?.includes(i.MachineKey)}

                                            />
                                            <ListItemText primary={i.MachineSerialNo} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className='width_filter widthIncrease'>
                            <div className='lableTitle'>Select Shift</div>
                            <DropDown
                                onChange={(e: any) => props.handleChangeShift(e.target.value)}
                                className="inputDiv dropdownbx longtxt"
                                name="dropdownModel"
                                items={props.shifts}
                                placeHolder="Select Shift"
                                value={props.selectedShift}
                            />
                        </div>

                    </div>

                    <Card className={styles.cardStyle} style={{ height: '80vh' }}> {/* Apply the card style */}
                        <div className='rightgraph mtforty' style={{ height: '95%', width: '100%', overflow: "auto" }}>
                            <h3 className='titleGraph font14'>Plant Visibility(Live)</h3>
                            {(!props.selectedMachines || props.selectedMachines.length === 0 || !props.selectedShift) ? (
                                <div className="nodataRow" style={{ textAlign: "center", color: "#e0e0e0", marginTop: "100px" }}>
                                    <img src={NoData} alt="no data" />
                                    <Typography sx={{ mt: '2%' }}>No data to show</Typography>
                                </div>
                            ) : props.isLoading ? <Loader /> : (
                                <div>
                                    {Object.keys(props.plantVisibility).map((machineSerialNo, index) => {
                                        return (
                                            <div className='performanceChart' style={{ width: "100%", display: "flex" }}>
                                                <div style={{ display: "inline-flex", alignItems: "center", overflow: "auto", width: "100%" }}>
                                                    <div style={{ width: "12%" }}> <p style={{ color: '#ffff', marginRight: 10, marginTop: 70, fontSize: 12 }}>{machineSerialNo}</p></div>
                                                    <div style={{ width: "82%" }}>
                                                        <PlantVisibilityChart
                                                            chartId={machineSerialNo}
                                                            key={machineSerialNo}
                                                            barChartData={plantVisibilityData.find((data: any) => data[machineSerialNo])?.[machineSerialNo] || []}
                                                            hoverdata={machineEventHoverData.find((data: any) => data[machineSerialNo])?.[machineSerialNo] || []}
                                                            machineShifttimeData={machineShifttimeData}
                                                            legendData={visavaliGraphLegendData}
                                                        // showLegend={index === props.plantVisibility.length - 1 ? true : false}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                    </Card>

                    <div className='filterdiv mt10'>
                        <div className='width_filter'>
                            <div className='lableTitle'>From<Tooltip title={<Typography fontSize={10} className='tooltiptxt'>Timeline for data shown will be extended to include all batches or operator login time started within the selected date range.
                            </Typography>} arrow>
                                <InfoIconn sx={{ justifyContent: 'center', color: '#ffffff', marginLeft: "2px" }} />
                            </Tooltip></div>
                            <CustomDatePicker
                                name="dateFromSelect"
                                value={props.inDate}
                                maxDate={new Date()}
                                className="inputDiv"
                                onChange={(val: any) => {
                                    props.setInDate(val);
                                    trackPageActionLog({
                                        actionName: 'OEE-Dashboard',
                                        action: 'From date changed',
                                        additionalInfo: `From Date changed to ${val}`,
                                        LoggedInUser: state[STORE_KEYS.USER_DTL]?.EmailId,
                                    })
                                    props.setSelectedMultipleDateMachine('');
                                }}
                                inputFormat="dd/MM/yyyy"
                                placeholder="Select From Date"
                                minDate={new Date("01-01-2023")}

                            />
                        </div>
                        <div className='width_filter'>
                            <div className='lableTitle'>To</div>
                            <CustomDatePicker
                                name="dateFromSelect"
                                value={props.endDate}
                                minDate={props.inDate}
                                maxDate={new Date()}
                                className="inputDiv"
                                onChange={(val: any) => {
                                    props.setEndDate(val);
                                    trackPageActionLog({
                                        actionName: 'OEE-Dashboard',
                                        action: 'To date changed',
                                        additionalInfo: `To Date changed to ${val}`,
                                        LoggedInUser: state[STORE_KEYS.USER_DTL]?.EmailId,
                                    })
                                    props.setSelectedMultipleDateMachine('')
                                }}
                                inputFormat="dd/MM/yyyy"
                                placeholder="Select End Date"
                            />

                        </div>

                    </div>
                    <Card className={styles.cardStyle} style={{ height: '90vh' }}> {/* Apply the card style */}
                        <div className='rightgraph' style={{ height: '100%' }}>
                            <h3 className='titleGraph font14'>Productivity</h3>
                            <div style={{width: "100%", display: "flex", justifyContent: "flex-end"}}>
                            <div style={{display: 'block', width: "30%"}}>
                                <div className='lableTitle'>Select Machine Serial No.</div>
                                <DropDown
                                    onChange={(e: any) => props.handleChangeMultipleDate(e.target.value)}
                                    className="inputDiv dropdownbx longtxt"
                                    name="dropdownModel"
                                    items={props.multipleDateMachines}
                                    placeHolder="Select Machine Serial No."
                                    value={props.selectedMultipleDateMachine}
                                />
                                </div>
                            </div>
                            {props.selectedMultipleDateMachine === "" ?
                                <div className="nodataRow" style={{ textAlign: "center", color: "#e0e0e0", marginTop: "100px" }}>
                                    <img src={NoData} alt="no data" />
                                    <Typography sx={{ mt: '2%' }}>No data to show</Typography>
                                </div>
                                :
                                <div className='performanceChart colorbgGraphHover'>
                                    <ProductivityChart
                                        chart1={props.productivity}
                                        setCurrTab={props.setCurrTab}
                                        setSelectedMultipleDateMachine={props.setSelectedMultipleDateMachine}
                                        machines={props.machines}
                                        setDetailsRedirection={props.setDetailsRedirection}
                                        setSelectedChart={props.setSelectedChart}
                                        setInDate={props.setInDate}
                                        setEndDate={props.setEndDate}
                                    />
                                </div>
                            }
                        </div>

                        <div className='rightgraph' style={{ height: '100%' }}>
                            <h3 className='titleGraph font14'>Operator Visibility</h3>
                            <div style={{width: "100%", display: "flex", justifyContent: "flex-end"}}>
                                <div style={{display: 'block', width: "30%"}}>
                                <div className='lableTitle'>Select Operator</div>
                                <DropDown
                                    onChange={(e: any) => props.handleChangeOperator(e.target.value)}
                                    className="inputDiv dropdownbx longtxt"
                                    name="dropdownModel"
                                    items={props.operators}
                                    placeHolder="Select Operator"
                                    value={props.selectedOperator}
                                />
                                </div>
                            </div>
                        {props.selectedOperator === "" ?
                            <div className="nodataRow" style={{ textAlign: "center", color: "#e0e0e0", marginTop: "100px" }}>
                                <img src={NoData} alt="no data" />
                                <Typography sx={{ mt: '2%' }}>No data to show</Typography>
                            </div>
                            :
                            <div className='performanceChart machineDownTime colorbgGraphHover'>
                                <OperatorVisibilityChart
                                    chart1={props.operatorVisibility}
                                    setCurrTab={props.setCurrTab}
                                    setSelectedMultipleDateMachine={props.setSelectedMultipleDateMachine}
                                    machines={props.machines}
                                    setDetailsRedirection={props.setDetailsRedirection}
                                    setSelectedChart={props.setSelectedChart}
                                    setInDate={props.setInDate}
                                    setEndDate={props.setEndDate}
                                    setTabChange={props.setTabChange}
                                    setSelectedMultipleOperators={props.setSelectedMultipleOperators}
                                    selectedMultipleOperators={props.selectedMultipleOperators}
                                    selectedOperator={props.selectedOperator}
                                    setIsRedirect={props.setIsRedirect}
                                    isRedirect={props.isRedirect}
                                />
                            </div>
                        }
                    </div>
                </Card>
                </Container>}
        </div>
        </div >
    );
};

export default OEEDashboardHomePage;
