import { Container, Card } from '@mui/material';
import styles from './index.module.css'; // Import the CSS module
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import Breadcrumb from '../../../atoms/BreadCrumb';
import Axios from 'axios';
import { ArcElement, Chart, registerables } from "chart.js";
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ACTION_CODES, BASEURL, STORE_KEYS } from '../../../../app/constants/apiConstants';
import useGetState from '../../../../app/utils/hooks/useGetState';
import DownArrow from '../../../../assets/down-arrow.svg';
import BackArrowIcon from '../../../../assets/images/BackArrowIcon.svg';
import CustomDatePicker from '../../../atoms/CustomDatePicker';
import DropDown from '../../../atoms/Dropdown';
import SnackbarAlert from '../../../atoms/Snackbar';
import { formatOnlyDate } from '../../../utils/formatTime';
import '../../Admin/ClientManagement/index.scss';
import Stack2 from "./GraphOEEKPI/peretochart";
import Stack4 from "./GraphOEEKPI/stacked";
import Stack1 from "./GraphOEEKPI/stackedLine";
import Stack3 from "./GraphOEEKPI/stackedLine2";
import { trackErrorsInNewRelic, trackPageActionLog } from '../../../utils/newRelicFuncations';
import { calculateDateDifference, lastTwoMonthsDaterange, startDateOfMonth  } from '../../../utils/helper';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const DropDownIcon = (prop: any) => (
    <img style={{ marginRight: '5px', marginTop: '-2px' }} src={DownArrow} {...prop} />
);

Chart.register(...registerables);
Chart.register(ArcElement);
const tabs = ['Machine List'];
const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    }
};
const SystemConfiguration = (props: any) => {
    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const date = new Date();
    date.setDate(date.getDate() - 14)
    const state = useGetState();
    const token = state?.[STORE_KEYS.USER_DTL].token;
    const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;
    const [presetKey, setPresetKey] = useState('');

    const [shifts, setShifts] = useState<any>([]);
    const [selectedShift, setSelectedShift] = useState<any>([]);
    const [alarmTrends, setAlarmTrends] = useState<any>([]);
    const [apq, setAPQ] = useState<any>([]);
    const [oeeDetails, setOEEDetails] = useState<any>([]);
    const [avlMetrics, setAvlMetrics] = useState<any>([]);
    const [selectAllProduct, setSelectAllProduct] = useState<boolean>(false);
    const [selectAllBatch, setSelectAllBatch] = useState<boolean>(false);
    const [selectAllShift, setSelectAllShift] = useState<boolean>(false);
    const userEmail = state[STORE_KEYS.USER_DTL]?.EmailId;

    useEffect(() => {
        if(props.checkedOption == "YTD" && !props.detailsRedirection){
            updateYTD();
        } else if(props.checkedOption == "MTD" && !props.detailsRedirection) {
            updateMTD();
        }
        props.setDetailsRedirection(false);
    }, []);

    const updateYTD = () => {
        const range = lastTwoMonthsDaterange();
        props.setInDate(new Date(range.from));
        props.setEndDate(new Date(range.to));
    }

    const updateMTD = () => {
        const range = startDateOfMonth();
        props.setInDate(new Date(range.from));
        props.setEndDate(new Date(range.to));
    }

    const handleChangeUpdateModel = () => {
        props.setSelectedProduct([]);
        props.setSelectedBatch([]);
        setSelectedShift([]);
        setSelectAllProduct(true);
        setSelectAllBatch(true);
        setSelectAllShift(true);
        setOEEDetails([]);
        setAvlMetrics([]);
        setAlarmTrends([]);
        setAPQ([]);
        setShifts([]);
        props.setBatches([]);
        props.setProducts([]);
    };

    const handleChangeUpdateMachine = () => {
        props.setSelectedProduct([]);
        props.setSelectedBatch([]);
        setSelectedShift([]);
        setSelectAllProduct(true);
        setSelectAllBatch(true);
        setSelectAllShift(true);
        setOEEDetails([]);
        setAvlMetrics([]);
        setAlarmTrends([]);
        setAPQ([]);
        setShifts([]);
        props.setBatches([]);
        props.setProducts([]);
    };

    useEffect(() => {
        (async () => {
            const rangeDays = calculateDateDifference(props.inDate, props.endDate);
            if (rangeDays > 60) {
                props.setDatePDRange(true);
                return 
            } else {
            props.setDatePDRange(false);
            fetchDashboardProducts(parseInt(props.selectedMachine), props.inDate, props.endDate, parseInt(props.selectedMachineModel));
            setSelectAllProduct(true);
            setSelectAllBatch(true);
            setSelectAllShift(true);
            }
        })();

    }, [props.selectedMachine, props.selectedMachineModel, props.inDate, props.endDate]);

    useEffect(() => {
        if(props.checkedOption != null) {
            props.setSelectedProduct([]);
            props.setSelectedBatch([]);
            setSelectedShift([]);
            setSelectAllProduct(true);
            setSelectAllBatch(true);
            setSelectAllShift(true);
            setOEEDetails([]);
            setAvlMetrics([]);
            setAlarmTrends([]);
            setAPQ([]);
            setShifts([]);
            props.setBatches([]);
            props.setProducts([]);
        }
    },[props.checkedOption])

    const fetchDashboardProducts = async (mk: any, val1: any, val2: any, modelKey: any) => {
        const rangeDays = calculateDateDifference(props.inDate, props.endDate);
        if (rangeDays > 60) {
            return 
        } 
        const payload:any = {
            userId: loggedInUser,
            machineKey: mk,
            start: formatOnlyDate(val1),
            end: formatOnlyDate(val2),
            modelKey: modelKey
        };
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.API_PMEC_GET_DASHBOARD_PRODUCTS,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;
                    const tmpTableData = result[0][0];
                    props.setProducts(tmpTableData);
                }
            })
            .catch((error) => {
                trackErrorsInNewRelic({ error: error })

                return {
                    status: error
                };
            });
    }
    const fetchBatches = async (val3: any) => {

        const payload = {
            userId: loggedInUser,
            machineKey: props.selectedMachine,
            modelKey: props.selectedMachineModel,
            start: formatOnlyDate(props.inDate),
            end: formatOnlyDate(props.endDate),
            productKeys: val3
        };
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.API_PMEC_GET_BATCHES,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;
                    const tmpTableData = result[0][0];
                    props.setBatches(tmpTableData);
                }
            })
            .catch((error) => {
                trackErrorsInNewRelic({ error: error })

                return {
                    status: error
                };
            });
    }

    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);

    const handleChangeProduct = (id: any) => {
        setSelectAllShift(true);
        setShifts([]);
        setSelectedShift([]);
        setSelectAllBatch(true);
        setSelectAllShift(true);
        setOEEDetails([]);
        setAvlMetrics([]);
        setAlarmTrends([]);
        setAPQ([]);
        setShifts([]);
        props.setBatches([])
        let newSelected: any = [];
        if (presetKey === '') {
            const selectedIndex = props.selectedProduct.indexOf(id);
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(props.selectedProduct, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(props.selectedProduct.slice(1));
            } else if (selectedIndex === props.selectedProduct.length - 1) {
                newSelected = newSelected.concat(props.selectedProduct.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    props.selectedProduct.slice(0, selectedIndex),
                    props.selectedProduct.slice(selectedIndex + 1)
                );
            }
        }
        else {
            const selectedIndex = props.selectedProduct.findIndex((t: any) => t.BatchNo === id.BatchNo);
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(props.selectedProduct, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(props.selectedProduct.slice(1));
            } else if (selectedIndex === props.selectedProduct.length - 1) {
                newSelected = newSelected.concat(props.selectedProduct.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    props.selectedProduct.slice(0, selectedIndex),
                    props.selectedProduct.slice(selectedIndex + 1)
                );
            }
        }

        props.setSelectedProduct(newSelected);
        if (newSelected.length) {
            let pk: any = [];
            newSelected.map((item: any) => pk.push(item.ProductKey))
            fetchBatches(pk.toString());
        }

    }
    const handleChangeBatch = (id: any) => {
        setSelectAllShift(true);
        setShifts([]);
        setSelectedShift([]);
        setOEEDetails([]);
        setAvlMetrics([]);
        setAlarmTrends([]);
        setAPQ([]);
        setShifts([]);
        let newSelected: any = [];
        if (presetKey === '') {
            const selectedIndex = props.selectedBatch.indexOf(id);
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(props.selectedBatch, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(props.selectedBatch.slice(1));
            } else if (selectedIndex === props.selectedBatch.length - 1) {
                newSelected = newSelected.concat(props.selectedBatch.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    props.selectedBatch.slice(0, selectedIndex),
                    props.selectedBatch.slice(selectedIndex + 1)
                );
            }
        }
        else {
            const selectedIndex = props.selectedBatch.findIndex((t: any) => t.BatchNo === id.BatchNo);
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(props.selectedBatch, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(props.selectedBatch.slice(1));
            } else if (selectedIndex === props.selectedBatch.length - 1) {
                newSelected = newSelected.concat(props.selectedBatch.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    props.selectedBatch.slice(0, selectedIndex),
                    props.selectedBatch.slice(selectedIndex + 1)
                );
            }
        }

        props.setSelectedBatch(newSelected);
        if (newSelected.length) fetchShifts(newSelected);

    }

    const fetchShifts = async (sft: any) => {
        const btc: any = [];
        sft.map((i: any) => btc.push(i.BatchNo));

        const payload = {
            machineKey: props.selectedMachine,
            products: props.selectedProduct.map((i: any) => i.ProductKey).toString(),
            batches: btc.length > 0 ? btc.toString() : sft.map((i: any) => { return i.ShiftName }).toString(),
            start: formatOnlyDate(props.inDate),
            end: formatOnlyDate(props.endDate),
        };
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.API_PMEC_GET_SHIFTS,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;
                    const tmpTableData = result[0][0];
                    setShifts(tmpTableData);
                }
            })
            .catch((error) => {
                trackErrorsInNewRelic({ error: error })

                return {
                    status: error
                };
            });
    }
    const handleChangeShift = (id: any) => {
        setOEEDetails([]);
        setAvlMetrics([]);
        setAlarmTrends([]);
        setAPQ([]);
        let newSelected: any = [];
        if (presetKey === '') {
            const selectedIndex = selectedShift.indexOf(id);
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selectedShift, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selectedShift.slice(1));
            } else if (selectedIndex === selectedShift.length - 1) {
                newSelected = newSelected.concat(selectedShift.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selectedShift.slice(0, selectedIndex),
                    selectedShift.slice(selectedIndex + 1)
                );
            }
        }
        else {
            const selectedIndex = selectedShift.findIndex((t: any) => t.BatchNo === id.BatchNo);
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selectedShift, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selectedShift.slice(1));
            } else if (selectedIndex === selectedShift.length - 1) {
                newSelected = newSelected.concat(selectedShift.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selectedShift.slice(0, selectedIndex),
                    selectedShift.slice(selectedIndex + 1)
                );
            }
        }

        setSelectedShift(newSelected);
        fetchKPIData(newSelected);

    }

    const fetchKPIData = async (sft: any) => {
        const shifts: any = [], batchs: any = [];
        sft.map((i: any) => shifts.push(i.ShiftName));
        props.selectedBatch.map((i: any) => batchs.push(i.BatchNo));
        const payload = {
            machineKey: props.selectedMachine,
            products: props.selectedProduct.map((i: any) => i.ProductKey).toString(),
            batchs: batchs.length > 0 ? batchs.toString() : props.selectedBatch.map((i: any) => { return i.BatchNo }).toString(),
            shifts: shifts.length > 0 ? shifts.toString() : sft.map((i: any) => { return i.ShiftName }).toString(),
            start: formatOnlyDate(props.inDate),
            end: formatOnlyDate(props.endDate)
        };
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.API_PMEC_ALARM_TREND,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;
                    const tmpTableData = result[0][0];
                    setAlarmTrends(tmpTableData);
                }
            })
            .catch((error) => {
                trackErrorsInNewRelic({ error: error })

                return {
                    status: error
                };
            });
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.API_PMEC_GET_APQ,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;
                    const tmpTableData = result[0][0];
                    setAPQ(tmpTableData);
                }
            })
            .catch((error) => {
                trackErrorsInNewRelic({ error: error })

                return {
                    status: error
                };
            });
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.API_PMEC_GET_OEE_SHIFT_DETAILS,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;
                    const tmpTableData = result[0][0];
                    setOEEDetails(tmpTableData);
                }
            })
            .catch((error) => {
                trackErrorsInNewRelic({ error: error })

                return {
                    status: error
                };
            });
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.API_PMEC_GET_AVL_METRICS,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;
                    const tmpTableData = result[0][0];
                    setAvlMetrics(tmpTableData);
                }
            })
            .catch((error) => {
                trackErrorsInNewRelic({ error: error })

                return {
                    status: error
                };
            });
    }

    useEffect(() => {
        setAPQ([]);
        setAlarmTrends([]);
        setOEEDetails([]);
        setAvlMetrics([]);
        const sb: any = [];
        if (selectAllProduct && props.products) {
            props.products.map((i: any) => sb.push(i));
            props.setSelectedProduct(sb);
            if ((sb.length > 0 && props.selectedMachine != '') || (props.detailsRedirection && props.selectedMachine != '')) fetchBatches('All')
        } else {
            props.setSelectedProduct([]);
        }
    }, [selectAllProduct, props.products, props.selectedMachine]);

    useEffect(() => {
        setAPQ([]);
        setAlarmTrends([]);
        setOEEDetails([]);
        setAvlMetrics([]);
        const sb: any = [];
        if (selectAllBatch && props.batches) {
            props.batches.map((i: any) => sb.push(i));
            props.setSelectedBatch(sb);
            if (sb.length > 0) fetchShifts(sb)
        } else {
            props.setSelectedBatch([]);
        }
    }, [selectAllBatch, props.batches])

    useEffect(() => {
        const sb: any = [];
        if (selectAllShift && shifts) {
            shifts.map((i: any) => sb.push(i));
            setSelectedShift(sb);
            if (sb.length > 0) fetchKPIData(sb);

        } else {
            setSelectedShift([]);
            setAPQ([]);
            setAlarmTrends([]);
            setOEEDetails([]);
            setAvlMetrics([]);
        }
    }, [selectAllShift, shifts])

    return (
        <div className="modelComponents">
            <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
            <Container
                maxWidth="xl"
                className={styles.cardContainer} // Use the class from CSS module
                style={{
                    paddingLeft: '3px',
                    paddingRight: '3px',
                }}
            >
                <div className='filterdiv production_dashboard'>
                <div className='filterdiv production_dashboardBottom'>
                    <div className='widthmindiv' style={{width: props.checkedOption === null ? '18%' : '12%'}}>
                        <div style={{ width: '100% !important' }}>
                            <div className='lableTitle'>Please Select</div>
                            <Tooltip title="Date range between 1st of every month to current date." className='textfont'>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={props.checkedOption === 'MTD'}
                                        onChange={() => { props.handleCheckboxChange('MTD'); props.updateMTD(props.checkedOption === 'MTD' ? false : true); }}
                                        sx={{
                                            color: 'white',
                                            '&.Mui-checked': {
                                                color: 'white',
                                            },
                                            '&:focus': {
                                                outline: '2px solid #ffffff',
                                                outlineOffset: '2px',
                                            },
                                            '& .MuiSvgIcon-root': {
                                                color: 'white',
                                            }
                                        }}
                                        color="primary"
                                    />
                                }
                                label="MTD"
                                sx={{ color: '#ffffff' }}
                            />
                            </Tooltip>
                            <Tooltip title="For better visualization only last 60 days data is displayed">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={props.checkedOption === 'YTD'}
                                        onChange={() => { props.handleCheckboxChange('YTD'); props.updateYTD( props.checkedOption === 'YTD' ? false : true); }}
                                        sx={{
                                            color: 'white',
                                            '&.Mui-checked': {
                                                color: 'white',
                                            },
                                            '&:focus': {
                                                outline: '2px solid #ffffff',
                                                outlineOffset: '2px',
                                            },
                                            '& .MuiSvgIcon-root': {
                                                color: 'white',
                                            }
                                        }}
                                        color="primary"
                                    />
                                }
                                label="YTD"
                                sx={{ color: '#ffffff' }}
                            />
                            </Tooltip>
                        </div>
                    </div>
                    <div className='width_filter clscolordot'>

                        <div className='lableTitle'>Select Machine Model</div>
                        <DropDown
                            onChange={(e) => { props.handleMachineModel(); handleChangeUpdateModel() }}
                            className="inputDiv dropdownbx"
                            name="dropdownModel"
                            items={props.machineModels}
                            placeHolder="Select Machine Model"
                            value={props.selectedMachineModel}
                        />
                    </div>
                    <div className='width_filter clscolordot'>
                        <div className='lableTitle'>Select Machine</div>
                        <DropDown
                            onChange={(e) => { 
                                props.handleChange(e.target.value); 
                                handleChangeUpdateMachine()
                                const machineData = props.machines.find((item:any)=>item.code == e.target.value)
                                trackPageActionLog({
                                    actionName:"OEE Dashboard Production",
                                    action:`User changed the machine to ${machineData.name}`,
                                    LoggedInUser:state[STORE_KEYS.USER_DTL]?.EmailId,
                                })
                             }}
                            className="inputDiv dropdownbx"
                            name="dropdownModel"
                            items={props.machines}
                            placeHolder="Select Machine."
                            value={props.selectedMachine}
                        />
                    </div>
                    {props.checkedOption === null && <div className='width_filter dateWidth'>
                        <div className='lableTitle'>From</div>
                        <CustomDatePicker
                            name="dateFromSelect"
                            value={props.inDate}
                            maxDate={new Date()}
                            className="inputDiv"
                            onChange={(val: any) => {
                                props.setInDate(val);
                                fetchDashboardProducts(props.selectedMachine, val, props.endDate, props.selectedMachineModel);
                                props.setBatches([])
                                props.setProducts([])
                                props.setSelectedBatch([]);
                                setSelectedShift([]);
                                props.setSelectedProduct([])
                                setSelectAllProduct(true);
                                setSelectAllBatch(true);
                                setSelectAllShift(true);
                                setOEEDetails([]);
                                setAvlMetrics([]);
                                setAlarmTrends([]);
                                setAPQ([]);
                                setShifts([]);
                            }}
                            inputFormat="dd/MM/yyyy"
                            placeholder="Select From Date"
                            minDate={new Date("01-01-2023")}
                        />
                        {props.datePDRange && (
                                            <div style={{ marginTop: '10px' }}>
                                                <span
                                                    style={{
                                                        color: '#F06868',
                                                        fontSize: '11px',
                                                        fontWeight: 600,
                                                        letterSpacing: 0,
                                                        marginTop: '20px',
                                                        marginBottom: '15px',
                                                        fontFamily: 'Montserrat',
                                                        marginLeft: '5%'
                                                    }}
                                                >
                                                    {'Only last 60 days data can be selected. '}
                                                </span>
                                            </div>
                                        )}
                    </div>}

                    {props.checkedOption === null && <div className='width_filter dateWidth'>
                        <div className='lableTitle'>To</div>
                        <CustomDatePicker
                            name="dateFromSelect"
                            value={props.endDate}
                            minDate={props.inDate}
                            maxDate={new Date()}
                            className="inputDiv"
                            onChange={(val: any) => {
                                props.setEndDate(val);
                                fetchDashboardProducts(props.selectedMachine, props.inDate, val, props.selectedMachineModel);
                                props.setBatches([]);
                                props.setProducts([])
                                props.setSelectedBatch([]);
                                setSelectedShift([]);
                                props.setSelectedProduct([])
                                setSelectAllProduct(true);
                                setSelectAllBatch(true);
                                setSelectAllShift(true);
                                setOEEDetails([]);
                                setAvlMetrics([]);
                                setAlarmTrends([]);
                                setAPQ([]);
                                setShifts([]);
                            }}
                            inputFormat="dd/MM/yyyy"
                            placeholder="Select End Date"
                        />
                               {props.datePDRange && (
                                            <div style={{ marginTop: '10px' }}>
                                                <span
                                                    style={{
                                                        color: '#F06868',
                                                        fontSize: '11px',
                                                        fontWeight: 600,
                                                        letterSpacing: 0,
                                                        marginTop: '20px',
                                                        marginBottom: '15px',
                                                        fontFamily: 'Montserrat',
                                                        marginLeft: '5%'
                                                    }}
                                                >
                                                    {'Only last 60 days data can be selected. '}
                                                </span>
                                            </div>
                                        )}
                    </div>}
                    <div className='width_filter productinput_scroll'>
                        <div className='lableTitle'>Select Products</div>
                        <Select
                            IconComponent={DropDownIcon}
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            className='muti_boxcheck'
                            label=""
                            multiple
                            fullWidth
                            value={props.selectedProduct}
                            sx={{ marginBottom: "10px", backgroundColor: "#1d1e2c", borderRadius: 3, marginTop: '10px', color: "#b1c2df" }}
                            placeholder='Please select Products'
                            // input={<OutlinedInput />}
                            renderValue={(selected) => selected.map((x: any) => x.ProductName).join(', ')}
                            MenuProps={MenuProps}

                        >
                            <MenuItem value={"All"} onClick={() => { setSelectAllProduct(!selectAllProduct); }}>
                                <Checkbox
                                    checked={
                                        selectAllProduct
                                    }

                                />
                                <ListItemText primary="Select All" />
                            </MenuItem>
                            {props.products?.map((i: any) => (
                                <MenuItem value={i} onClick={() => handleChangeProduct(i)} className='ulSelectProduct'>
                                    <Checkbox
                                        checked={
                                            props.selectedProduct.findIndex((item: any) => item.ProductKey === i.ProductKey) >= 0
                                        }

                                    />
                                    <ListItemText primary={i.ProductName} />
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    <div className='width_filter'>
                        <div className='lableTitle'>Select Batch</div>
                        <Select
                            IconComponent={DropDownIcon}
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            className='muti_boxcheck'
                            label=""
                            multiple
                            fullWidth
                            value={props.selectedBatch}
                            sx={{ marginBottom: "10px", backgroundColor: "#1d1e2c", borderRadius: 3, marginTop: '10px', color: "#b1c2df" }}
                            placeholder='Please select Batches'
                            // input={<OutlinedInput />}
                            renderValue={(selected) => selected.map((x: any) => x.McBatchNo).join(', ')}
                            MenuProps={MenuProps}

                        >
                            <MenuItem value={"all"} onClick={() => { setSelectAllBatch(!selectAllBatch); }}>
                                <Checkbox
                                    checked={
                                        selectAllBatch
                                    }

                                />
                                <ListItemText primary="Select All" />
                            </MenuItem>
                            {props.batches?.map((i: any) => (
                                <MenuItem value={i} onClick={() => handleChangeBatch(i)} className='ulSelectBatchRecipe'>
                                    <Checkbox
                                        checked={
                                            props.selectedBatch.findIndex((item: any) => item.BatchNo === i.BatchNo) >= 0
                                        }

                                    />
                                    <ListItemText primary={i.McBatchNo} />
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    <div className='width_filter'>
                        <div className='lableTitle'>Select Shift</div>

                        <Select
                            IconComponent={DropDownIcon}
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            className='muti_boxcheck'
                            label=""
                            multiple
                            fullWidth
                            value={selectedShift}
                            sx={{ marginBottom: "10px", backgroundColor: "#1d1e2c", borderRadius: 3, marginTop: '10px', color: "#b1c2df" }}
                            placeholder='Please select Batches'
                            // input={<OutlinedInput />}
                            renderValue={(selected) => selected.map((x: any) => x.ShiftName).join(', ')}
                            MenuProps={MenuProps}

                        >

                            <MenuItem value={"all"} onClick={() => setSelectAllShift(!selectAllShift)}>
                                <Checkbox
                                    checked={
                                        selectAllShift
                                    }

                                />
                                <ListItemText primary="Select All" />
                            </MenuItem>
                            {shifts?.map((i: any) => (
                                <MenuItem value={i} onClick={() => handleChangeShift(i)} className='ulSelectBatchRecipe'>
                                    <Checkbox
                                        checked={
                                            selectedShift.findIndex((item: any) => item.ShiftName === i.ShiftName) >= 0
                                        }

                                    />
                                    <ListItemText primary={i.ShiftName} />
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </div>
                </div>
                <div className='OEEProdGraph batchWiseGraph'>
                    <div className='leftgraphPD'>
                        <h3 className='titleGraph font14'>Batchwise A,P,Q</h3>
                        <div className='blockcls'>
                            <div className='innerKPIs'>
                                <div className='valuesKPIs' style={{ color: "#00ffFF" }}>{apq[0]?.OverAllOEE?.toFixed(2)}</div>
                                <div className='textKPIs'>Overall OEE %</div>
                            </div>
                            <div className='innerKPIs'>
                                <div className='valuesKPIs' style={{ color: "#ffc107" }}>{apq[0]?.OverAllAvailability?.toFixed(2)}</div>
                                <div className='textKPIs'>Overall Availability %</div>
                            </div>
                            <div className='innerKPIs'>
                                <div className='valuesKPIs' style={{ color: "#0d6efd" }}>{apq[0]?.OverAllPerformance?.toFixed(2)}</div>
                                <div className='textKPIs'>Overall Performance %</div>
                            </div>
                            <div className='innerKPIs'>
                                <div className='valuesKPIs' style={{ color: "#198754" }}>{apq[0]?.OverAllQuality?.toFixed(2)}</div>
                                <div className='textKPIs'>Overall Quality %</div>
                            </div>
                        </div>
                        <div>
                            <Stack1 apq={apq} />
                        </div>
                    </div>
                </div>
                <div className='graphcontainer'>
                    <div className='leftgraph'>
                        <h3 className='titleGraph font14'>Alarm Trends</h3>
                        <div>
                            <Stack2 alarmTrends={alarmTrends} />
                        </div>
                    </div>
                    <div className='rightgraph'>
                        <h3 className='titleGraph font14'>Performance</h3>
                        <div className='performanceChart'><Stack3 oeeDetails={oeeDetails} />
                        </div>
                    </div>
                </div>
                <div className='OEEProdGraph availabilityMatrix'>
                    <div className='leftgraphPD'>
                        <h3 className='titleGraph font14'>Availability Metrics %</h3>
                        <div className='blockcls' style={{ justifyContent: "space-around" }}>
                            <div className='innerKPIs'>
                                <div className='valuesKPIs' style={{ color: "#008000" }}>{avlMetrics[0]?.OverallRunTimePer?.toFixed(2)}</div>
                                <div className='textKPIs'>Overall Runtime %</div>
                            </div>
                            <div className='innerKPIs'>
                                <div className='valuesKPIs' style={{ color: "#0dcaf0" }}>{avlMetrics[0]?.OverallPlannedDownTimePer?.toFixed(2)}</div>
                                <div className='textKPIs'>Overall Planned Downtime %</div>
                            </div>
                            <div className='innerKPIs'>
                                <div className='valuesKPIs' style={{ color: "#ffc107" }}>{avlMetrics[0]?.OverallUnPlannedDowntimePer?.toFixed(2)}</div>
                                <div className='textKPIs'>Overall Unplanned Downtime %</div>
                            </div>
                        </div>
                        <div>
                            <Stack4 style={{ width: "100%" }}
                                avlMetrics={avlMetrics}
                                plantKey={props.selectedPlant}
                                machineKey={props.selectedMachine}
                                machineModelKey={props.selectedMachineModel}
                                startDate={props.inDate}
                                toDate={props.endDate}
                                productKey={props.selectedProduct}
                                setDetailsRedirection={props.setDetailsRedirection}
                                setCurrTab={props.setCurrTab}
                                setSelectedProduct={props.setSelectedProduct}
                                setSelectedBatch={props.setSelectedBatch}
                                selectedBatch={props.selectedBatch}
                                selectedProduct={props.selectedProduct}
                            />
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default SystemConfiguration;
