import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  LineController,
  BarController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { getRandomHexColor, lastTwoMonthsDaterange } from '../../../../utils/helper';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { exit } from 'process';
import { useHistory } from 'react-router-dom';
import { calculateDateDifference } from '../../../../utils/helper';
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

const Stacked = (props: any) => {
  const history = useHistory();
  const metricName: any = props?.chart1?.map((i: any) => { return i.MetricName });
  const uniqueArray = metricName.reduce((acc: any, value: any) => {
    if (!acc.includes(value)) {
      acc.push(value);
    }
    return acc;
  }, []);

  const groupedData = props?.chart1.reduce((acc: any, curr: any) => {
    const { MachineSerialNo, MetricName, MetricNamePerc, TargetValue } = curr;
    const defaultData = uniqueArray.reduce((acc: any, current: any) => {
      acc[current] = 0;
      return acc;
    }, {});
    if (!acc[MachineSerialNo]) {
      acc[MachineSerialNo] = {
        TargetValue,
        data: defaultData
      };
    }

    acc[MachineSerialNo].data[MetricName] += MetricNamePerc;
    return acc;
  }, {});

  const machines = Object.keys(groupedData);
  const labels = machines;

  const dataSet: any = [];

  dataSet.push({
    label: 'Target Value',
    data: machines.map((machine) => groupedData[machine]?.TargetValue),
    backgroundColor: '#0dcaf0',
    borderColor: '#0dcaf0',
    borderWidth: 1,
    type: 'line' as const,
    yAxisID: 'y1',
  });
  uniqueArray.map((metric: any) => {
    const color = props.chart1.find((i: any) => i.MetricName === metric);
    dataSet.push({
      label: metric,
      data: machines.map((machine) => groupedData[machine]?.data[metric]),
      backgroundColor: color.MetricColor,
      borderColor: '#ffc107',
      borderWidth: 0,
      yAxisID: 'y',
    });
  });

  const chartData = {
    labels,
    datasets: dataSet,
  };

  const options = {
    plugins: {
      datalabels: { color: 'white', display: false },
      legend: {
        display: false,
      },
      tooltip: {
        intersect: false,
        callbacks: {
          label: function (tooltipItem: any) {
            let label = tooltipItem.dataset.label || '';
            label += ': ' + tooltipItem.raw + '%';
            return [label];
          },
        },
      },
    },
    responsive: true,
    layout: {
      padding: {
        bottom: 20,
      },
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: 'white',
        },
        grid: {
          color: 'transparent',
        },
      },
      y: {
        stacked: true,
        display: true,
        position: 'left' as const,
        ticks: {
          color: 'white',
        },
        grid: {
          color: '#878f99',
        },
        title: {
          display: true,
          text: 'Metrics % and Target Value',
          color: 'white',
          font: {
            size: 12,
          },
        },
      },
    },
    onClick: (e: any, elements: any, chart: any) => {
      if (chart && chart?.tooltip && chart?.tooltip?.title && chart?.tooltip?.title?.length > 0) {
        const checkedPoint = chart.tooltip.title[0];
        const mkey = props?.machines.find((i: any) => i.alias === checkedPoint);
        if (mkey) {
          const noDays = calculateDateDifference(props.inDate, props.endDate);
          if (noDays > 60) {
            const range = lastTwoMonthsDaterange();
            props.setInDate(new Date(range.from));
            props.setEndDate(new Date(range.to));
          }
          props.setSelectedChart(2);
          props.setSelectedMachine(mkey?.code);
          props.setDetailsRedirection(true);
        } else {
          console.error('Machine not found for alias:', checkedPoint);
        }
      } else {
        console.warn('Tooltip title is empty or undefined');
      }
    },
  };
    
  const renderCustomLegend = () => {
    return (
      // <ul style={{ display: 'ruby', justifyContent: 'center', listStyleType: 'none', padding: 0 }} className='legendcls'>
        <ul style={{ display: 'block', maxHeight: "15vh", height: "100%", overflow: "auto", justifyContent: 'center', listStyleType: 'none', padding: 0, paddingBottom: '10px' }} className='legendcls'>
        {chartData.datasets.map((dataset: any, index: any) => (
          <li key={index} style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
            <span style={{
              backgroundColor: dataset.backgroundColor as string,
              width: '35px',
              height: '10px',
              display: 'inline-block',
              marginRight: '5px'
            }}></span>
            <span style={{ color: '#e0e0e0', fontFamily: 'montserrat', fontSize: '10px' }}>{dataset.label}</span>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="barchartPMEC noheight poee">
      <Chart style={{cursor: "pointer", marginBottom: "0px"}} type="bar" data={chartData} options={options} />
      {renderCustomLegend()}    
     {/* <div>
      {renderCustomLegend()}
      </div>
      <div className='plantOEEChart'>

      <Chart style={{cursor: "pointer"}} type="bar" data={chartData} options={options} />
      </div> */}
    </div>
  );
};

export default Stacked;
