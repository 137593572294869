import { SetStateAction, useEffect, useRef, useState } from 'react';
import {
    Checkbox,
    Container,
    FormControl,
    ListItemText,
    MenuItem,
    Grid,
    Select,
    Typography,
    Box
} from '@mui/material';
import Radio from '@mui/material/Radio';
import InputLabel from '@mui/material/InputLabel';
import { UTCTimeFormat } from '../../../../utils/formatTime';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import dateFormat from 'dateformat';
import { SnackBarOptions, Dateformat } from '../../../UserReport/DownloadableReport/CommonUtil';
import Attachments from '../../../../atoms/Attachments';
import DownArrow from '../../../../../assets/down-arrow.svg';
import Edit from '../../../../../assets/images/edit.svg';
import { Formik, Form, FormikProps, useFormikContext } from 'formik';
import useGetState from '../../../../utils/hooks/useGetState';
import { useDispatch } from 'react-redux';
import { executeACGAction, resetErr, updateScreenIdentifiers } from '../../../../store/slice';
import ACGButton from '../../../../atoms/Button';
import Label from '../../../../atoms/Label/index';
import TextInput from '../../../../atoms/TextInput';
import DropDown from '../../../../atoms/Dropdown';
import CustomDatePicker from '../../../../atoms/CustomDatePicker';
import CustomDateTimePicker from '../../../../atoms/CustomDateTimePicker';
import AcgTimePicker from '../../../../atoms/TimePicker';
import { fetchBlobImage } from '../../../../utils/helper';
import { convertBlobFileToBase64 } from '../../../../utils/helper';
import Axios from 'axios';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../../constants/apiConstants';
import { trackErrorsInNewRelic } from '../../../../utils/newRelicFuncations';

type ComponentProps = {
    drawerClose: Function;
    setCurrTab: Function;
    setReload: Function;
    setSnackbarOpts: Function;
    dtlData: any;
    selectedRow: any;
    isEdit: boolean;
    setDtlData: Function;
    setSelectedRow: Function;
    assetList: any;
    categoryList: any;
    actionTypeList: any;
    serviceRequestKey: any;
    getServiceRequestList: Function;
    fetchServiceRequestOrderDetails: Function;
    assignmentData: any;
};
type ModuleType = {
    startDateMS: any;
    startDateMC: any;
    scName: string | '';
    scValue: string | '';
    scDesc: string | '';
};

const CreateModule = (props: ComponentProps) => {
    const { serviceRequestKey, drawerClose, setCurrTab, setReload = () => { }, setSnackbarOpts = () => { }, dtlData, isEdit,  setDtlData = () => { }, selectedRow, setSelectedRow = () => { }, assetList, categoryList, actionTypeList, getServiceRequestList = () => { }, fetchServiceRequestOrderDetails = () => { }, assignmentData, } = props;
    const formRef = useRef<any>(null);
    const [formError, setFormError] = useState({ modelCode: '', modelName: '', paramsUpload: '', modelImage: '', hasError: false });
    const [maintenanceAction, setMaintenanceAction] = useState('');
    const [actionTypeKey, setActionTypeKey] = useState<number | string>('');
    const [sparePartsList, setSparepartsList] = useState<any[]>([]);
    const [partsNames, setPartsNames] = useState<any[]>([]);
    const [changeMSD, setChangeMSD] = useState(false);
    const [changeMCD, setChangeMCD] = useState(false);
    const [rootCauseList, setRootCauseList] = useState<any[]>([]);
    const [rootCauseKey, setRootCauseKey] = useState('');
    const [rootCauseSubList, setRootCauseSubList] = useState<any[]>([]);
    const [rootCauseSubKey, setRootCauseSubKey] = useState('');
    const [rootCauseDescription, setRootCauseDescription] = useState('');
    const [selectedModelImage, setSelectedModelImage] = useState<any>([]);
    const [initialValues, setInitialValues] = useState({
        startDateMS: new Date(),
        startDateMC: new Date(),
        scName: '',
        scValue: '',
        scDesc: '',
    });
    const [editImage, setEditImage] = useState(false);
    const [fName, setFname] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [breakdownKey, setBreakdownKey] = useState('');
    const state = useGetState();
    const token = state?.[STORE_KEYS.USER_DTL].token;
    const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;
    const dispatch = useDispatch();
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250
            }
        }
    };

    const options = {
        AD_SUCCESS: {
            message: `Service request action ${assignmentData[0]?.MaintActionTypeKey === null ? 'added' : 'updated'} successfully!`,
            open: true,
            type: 'success'
        },
    };

    const isDisabled = initialValues.startDateMC < initialValues.startDateMS || maintenanceAction === '' || !/\S/.test(maintenanceAction) || actionTypeKey === '' || rootCauseKey === '' || rootCauseSubKey === '' || rootCauseDescription === ''|| !/\S/.test(rootCauseDescription) ? true : false;
    useEffect (()=> {
        getSpareParts();
        getRootCauseList();
    },[]);

    useEffect(() => {
        if (assignmentData[0]?.MaintActionTypeKey !== null) {
            const tmpTableData = assignmentData
            if (tmpTableData && tmpTableData.length) {
                setMaintenanceAction(tmpTableData[0].MaintActionDesc);
                setActionTypeKey(tmpTableData[0].MaintActionTypeKey);
                setSelectedModelImage(dtlData[0].ActionTakenImagePath);
                setFname(tmpTableData[0].ActionTakenImagePath);
                setRootCauseDescription(tmpTableData[0].MaintRootCauseDesc);
                const s = getSpareParts();
                const r = getRootCauseList();
                const reverseSpareKeys: SetStateAction<any[]> = [];
                const reversRootCauseListKeys: SetStateAction<any[]> = [];
                const reversRootCauseSubListKeys = [];
                if (tmpTableData[0].MaintSparePartKeys) {
                    const spareKeys = tmpTableData[0].MaintSparePartKeys.split(',');
                    s.then((res: any) =>
                        res?.map((i: any) => {
                            if (spareKeys.includes(i.code.toString())) {
                                reverseSpareKeys.push(i);
                            }
                        })
                    );
                    setPartsNames(reverseSpareKeys);
                } else setPartsNames([]);
                setRootCauseKey(tmpTableData[0].MaintRootCauseCategoryKey);
                const rs = getRootCauseSubList(tmpTableData[0].MaintRootCauseCategoryKey);
                setRootCauseSubKey(tmpTableData[0].MaintRootCauseSubCategoryKey);
                setInitialValues({
                    ...initialValues,
                    startDateMS: new Date(UTCTimeFormat(tmpTableData[0].MaintStartTime)),
                    startDateMC: new Date(UTCTimeFormat(tmpTableData[0].MaintCompletedTime)),
                })

            }
        }
    }, [])
    const renderButtons = () => {
        return (
            <div className={'btns noWrap'}>
                <ACGButton
                    name={'Cancel'}
                    handleClick={drawerClose}
                    secondary={true}
                    className={'first-btn'}
                    type="button"
                    variant="contained"
                />
                <ACGButton
                    name={'Submit'}
                    handleClick={() => { handleSubmit() }}
                    type="submit"
                    className="second-btn"
                    variant="contained"
                    disabled={isDisabled ? true : false}
                    isLoading = {isLoading}
                />
            </div>
        );
    };

    const handleSubmit = async () => {
        const sp: any[] = [];
        partsNames.map(i => { sp.push(i.code) });
        let strImage;
        if (selectedModelImage.length > 0 && typeof (selectedModelImage) !== "string") {
            const base64Image = await convertBlobFileToBase64(selectedModelImage[0]);
            strImage = base64Image?.replace(/^data:image\/[a-z]+;base64,/, "");
        }
        const bundle = {
            payload: {
                urlPath: ACTION_CODES.ADD_SERVICE_REQUEST_USER_ACTION,
                requestType: 'POST',
                reqObj: {
                    userId: loggedInUser,
                    workOrderKey: assignmentData[0]?.MaintBDWorkOrderKey,
                    maintenanceAction:maintenanceAction,
                    actionKey: actionTypeKey,
                    spareparts: sp.toString(),
                    rootCauseSubKey: rootCauseSubKey,
                    rootCauseDescription: rootCauseDescription,
                    image: strImage ? strImage : null,
                    MsDate: dateFormat(initialValues.startDateMS, Dateformat.YYYY_MM_DD_HH_MM_SS),
                    McDate: dateFormat(initialValues.startDateMC, Dateformat.YYYY_MM_DD_HH_MM_SS),      
                    type: assignmentData[0]?.MaintActionTypeKey === null ? 'Add' : 'Edit',
                    fName: breakdownKey ? fName.replace(/'/g, "''") : null,
                    updateRequire: fName === selectedModelImage ? false : true
                }
            },
            uniqueScreenIdentifier: { serviceReqUserAction: true },
            storeKey: STORE_KEYS.ADD_SERVICE_REQUEST_USER_ACTION
        };
        dispatch(executeACGAction(bundle));
    };

    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };
        setSnackbarOpts(snackbarError);
    };

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_SERVICE_REQUEST_USER_ACTION]?.serviceReqUserAction) {
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_SERVICE_REQUEST_USER_ACTION,
                    uniqueScreenIdentifier: {
                        serviceReqUserAction: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.ADD_SERVICE_REQUEST_USER_ACTION]?.data]);

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_SERVICE_REQUEST_USER_ACTION]?.serviceReqUserAction) {
            if (state?.[STORE_KEYS.ADD_SERVICE_REQUEST_USER_ACTION]?.status === "Success") {
                setReload(true);
                setCurrTab(1);
                setSnackbarOpts(options.AD_SUCCESS);
                drawerClose();
                serviceRequestKey !== '' ? fetchServiceRequestOrderDetails(serviceRequestKey) : undefined;
                getServiceRequestList();
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_SERVICE_REQUEST_USER_ACTION,
                    uniqueScreenIdentifier: {
                        serviceReqUserAction: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.ADD_SERVICE_REQUEST_USER_ACTION]?.serviceReqUserAction]);

    useEffect(() => {
        if (state?.err) {
            handleSnackbarError(state?.err);
            dispatch(resetErr());
        }
    }, [state?.err]);

    const handleChangeActionType = (event: { target: { value: any; }; }) => {
        setActionTypeKey(event.target.value);
    }
    const DropDownIcon = (prop: any) => (
        <img style={{ marginRight: '5px', marginTop: '-2px' }} src={DownArrow} {...prop} />
    );
    const handleChangeSpareparts = (id: any) => {
        let newSelected: SetStateAction<any[]> = [];
        const selectedIndex = partsNames.findIndex((t) => t.code === id.code);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(partsNames, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(partsNames.slice(1));
        } else if (selectedIndex === partsNames.length - 1) {
            newSelected = newSelected.concat(partsNames.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                partsNames.slice(0, selectedIndex),
                partsNames.slice(selectedIndex + 1)
            );
        }
        setPartsNames(newSelected);
    }
    const getSpareParts = async () => {
        let finalResult;
        const payload = {
            userId: loggedInUser,
            subComponentKey: assignmentData[0]?.MaintSubComponentKey
        };
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.GET_BREAKDOWN_SPARE_PARTS,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then(async (response) => {
                if (response.status == 200 && response?.data) {
                    const tmpTableData = response.data.data;
                    setSparepartsList([
                        ...tmpTableData?.map((item: any) => ({ code: item.MaintSparePartKey, name: item.MaintSparePartName }))

                    ]);
                    finalResult = [...tmpTableData?.map((item: any) => ({ code: item.MaintSparePartKey, name: item.MaintSparePartName }))]
                }
            })
            .catch((error) => {
             trackErrorsInNewRelic({error: error})
                return {
                    status: error.response.status
                };
            });
        return finalResult;
    }
    const handleChangeRootCauseType = (event: { target: { value: any; }; }) => {
        setRootCauseSubKey('');
        setRootCauseKey(event.target.value);
        getRootCauseSubList(event.target.value);
    }
    const getRootCauseList = async () => {
        let finalResult;
        const payload = {
            userId: loggedInUser,
            subComponentKey: assignmentData[0]?.MaintSubComponentKey
        };
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.GET_BREAKDOWN_ROOT_CAUSE,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then(async (response) => {
                if (response.status == 200 && response?.data) {
                    const tmpTableData = response.data.data;
                    setRootCauseList([
                        ...tmpTableData?.map((item: any) => ({ code: item.MaintRootCauseCategoryKey, name: item.MaintRootCauseCategoryName }))

                    ]);
                    finalResult = [
                        ...tmpTableData?.map((item: any) => ({ code: item.MaintRootCauseCategoryKey, name: item.MaintRootCauseCategoryName }))

                    ];
                }
            })
            .catch((error) => {
             trackErrorsInNewRelic({error: error})

                return {
                    status: error.response.status
                };
            });
        return finalResult;
    }
    const getRootCauseSubList = async (rKey: number) => {
        const payload = {
            userId: loggedInUser,
            rootCauseKey: rKey
        };
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.GET_BREAKDOWN_ROOT_CAUSE_SUB,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then(async (response) => {
                if (response.status == 200 && response?.data) {
                    const tmpTableData = response.data.data;
                    setRootCauseSubList([
                        ...tmpTableData?.map((item: any) => ({ code: item.MaintRootCauseSubCategoryKey, name: item.MaintRootCauseSubCategoryName }))

                    ]);
                }
            })
            .catch((error) => {
             trackErrorsInNewRelic({error: error})
                return {
                    status: error.response.status
                };
            });
    }
    const handleChangeRootCauseSubType = (event: { target: { value: any; }; }) => {
        setRootCauseSubKey(event.target.value);
    }
    return (
        <Container className="createReport">
            <Formik
                initialValues={initialValues}
                onSubmit={(values: any) => {
                    // handleSubmit(values);
                }}
                validateOnBlur={false}
                validateOnChange={false}
                innerRef={formRef}
            >
                {(formikprops: FormikProps<ModuleType>) => {
                    const { values, handleChange, handleBlur, errors, touched, setFieldValue } = formikprops;
                    return (
                        <Form>
                            {/* <Label label="Please Choose" className={'labelDiv'} />
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group" className='radiolabls'

                            >
                                <FormControlLabel style={{color:'#a0a2b8'}} value="A" control={<Radio checked={assignmentData[0].MaintActionTypeKey === null ? true : false}  disabled={assignmentData[0]?.MaintActionTypeKey === null ? false : true}/>} label="Add" />
                                <FormControlLabel style={{color:'#a0a2b8'}} value="E" control={<Radio checked={assignmentData[0].MaintActionTypeKey !== null ? true : false}  disabled={assignmentData[0]?.MaintActionTypeKey === null ? true : false} />} label="Edit" />
                            </RadioGroup> */}
                            <Label label="Maintenance Action &nbsp; *" className={'labelDiv'} />
                                    <TextInput
                                        variant="outlined"
                                        fullWidth
                                        type="text"
                                        name="gtId"
                                        value={maintenanceAction}
                                        autoComplete="off"
                                        formInput="inputDiv"
                                        placeholder='Maintenance Action'
                                        onChange={(e: any) => {
                                            setMaintenanceAction(e.target.value)
                                        }}
                                        maxLength={300}
                                    />
                            <Label label="Select Action Type &nbsp; *" className={'labelDiv'} />
                                    <DropDown
                                        onChange={handleChangeActionType}
                                        className="inputDiv"
                                        name="dropdownModel"
                                        items={actionTypeList}
                                        placeHolder="Select Action Type"
                                        value={actionTypeKey}
                                    />
                                    {actionTypeKey === 2 ?
                                        <>
                                            <Label label="Select Parts Used" className={'labelDiv'} />
                                            <FormControl sx={{ width: '100%' }}>
                                                {partsNames?.length > 0 ? null : (
                                                    <InputLabel
                                                        style={{
                                                            fontFamily: 'Montserrat',
                                                            fontSize: '13px',
                                                            paddingTop: '5px',
                                                            paddingLeft: '34px',
                                                            color: '#b1c2df',
                                                            opacity: '0.7'
                                                        }}
                                                        id="demo-multiple-checkbox-label"
                                                    >
                                                        Select Parts Used
                                                    </InputLabel>
                                                )}
                                                <Select
                                                    IconComponent={DropDownIcon}
                                                    labelId="multiple-checkbox-label"
                                                    placeholder="Select Parameters"
                                                    id="multiple-checkbox"
                                                    multiple
                                                    displayEmpty
                                                    label=""
                                                    // style={{ backgroundColor: '#1d1e2c', color: '#b1c2df' }}
                                                    style={{
                                                        backgroundColor: '#1d1e2c',
                                                        color: '#b1c2df',
                                                        fontFamily: 'Montserrat',
                                                        fontSize: '13px',
                                                        borderRadius: '10px',
                                                    }}
                                                    className="inputDiv"
                                                    name="dropdownMachineParams"
                                                    value={partsNames}
                                                    // input={<OutlinedInput />}
                                                    MenuProps={MenuProps}
                                                    renderValue={(selected) => selected.length > 0 && selected.map((x) => x.name).join(', ')}
                                                >
                                                    {sparePartsList?.map((machine: any) =>
                                                    (
                                                        <MenuItem key={machine.code} value={machine} onClick={() => handleChangeSpareparts(machine)}>
                                                            <Checkbox
                                                                checked={partsNames.findIndex(item => item.code === machine.code) >= 0}
                                                            />
                                                            <ListItemText primary={machine.name} />
                                                        </MenuItem>
                                                    ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </>
                                        : null}
                                        <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                                            <Label label="Maintenance Started Date &nbsp; *" className={'labelDiv'} />
                                            
                                            <CustomDateTimePicker
                                            name="dateTimeFromSelect"
                                            value={assignmentData[0].MaintActionTypeKey !== null && !changeMSD ? initialValues.startDateMS : values.startDateMS}
                                            className="inputDiv"
                                            onChange={(val: any) => {
                                                setInitialValues({ ...initialValues, startDateMS: val });
                                                setFieldValue('startDateMS', val);

                                                setChangeMSD(true);
                                            }}
                                            inputFormat="dd/MM/yyyy hh:mm aa"
                                            placeholder="Select From Date"
                                        />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
                                            <Label label="Maintenance Completed Date &nbsp; *" className={'labelDiv'} />
                                            <CustomDateTimePicker
                                            name="dateTimeFromSelect"
                                            value={assignmentData[0].MaintActionTypeKey !== '' && !changeMCD ? initialValues.startDateMC : values.startDateMC}
                                            className="inputDiv"
                                            onChange={(val: any) => {
                                                setInitialValues({ ...initialValues, startDateMC: val });
                                                setFieldValue('startDateMC', val);
                                                setChangeMCD(true);
                                            }}
                                            inputFormat="dd/MM/yyyy hh:mm aa"
                                            placeholder="Select From Date"
                                            minDate={initialValues.startDateMS}
                                        />
                                        </Grid>
                                    <Label label="Root Cause Category &nbsp; *" className={'labelDiv'} />
                                    <DropDown
                                        onChange={handleChangeRootCauseType}
                                        className="inputDiv"
                                        name="dropdownModel"
                                        items={rootCauseList}
                                        placeHolder="Root Cause Category"
                                        value={rootCauseKey}
                                    />
                                    <Label label="Root Cause Sub Category &nbsp; *" className={'labelDiv'} />
                                    <DropDown
                                        onChange={handleChangeRootCauseSubType}
                                        className="inputDiv"
                                        name="dropdownModel"
                                        items={rootCauseSubList}
                                        placeHolder="Root Cause Sub Category"
                                        value={rootCauseSubKey}
                                    />

                                    <Label label="Root Cause Description &nbsp; *" className={'labelDiv'} />
                                    <TextInput
                                        // multiline
                                        // maxRows={4}
                                        // sx={{ margin: '0px', padding: '2px', height: "6rem" }}
                                        variant="outlined"
                                        fullWidth
                                        type="text"
                                        name="gtId"
                                        value={rootCauseDescription}
                                        autoComplete="off"
                                        formInput="inputDiv"
                                        placeholder='Root Cause Description'
                                        onChange={(e: any) => {
                                            setRootCauseDescription(e.target.value)
                                        }}
                                        inputProps={{ maxLength: 50, shrink: true }}
                                    />
                                    <Grid item xs={3} lg={1}>
                                        {assignmentData[0]?.MaintActionTypeKey === null ?
                                            <>
                                                <Label label="Upload Image" className={'labelDiv'} />
                                                <Attachments
                                                    viewOnly={false}
                                                    setError={selectedModelImage}
                                                    isSetFile={setSelectedModelImage}
                                                />
                                                {selectedModelImage?.length === 0 ? <div>
                                                    {formError.modelImage &&
                                                        <Typography sx={{ mt: '2%', color: '#F06868', fontSize: '12px' }}> {formError.modelImage}</Typography>
                                                    }
                                                </div> : null}
                                            </>
                                            :
                                            editImage
                                                ?
                                                <Grid item xs={3} lg={1}>
                                                    <Label label="Upload Image" className={'labelDiv'} />
                                                    <Attachments
                                                        viewOnly={false}
                                                        setError={selectedModelImage}
                                                        isSetFile={setSelectedModelImage}
                                                    />
                                                </Grid>
                                                :
                                                <Box className="attachment_wrapper" style={{ marginTop: 30 }}>
                                                    {<img onClick={() => setEditImage(true)} src={Edit} alt="X" className="closeBtn" />}
                                                    <>
                                                        <img
                                                            src={selectedModelImage ? fetchBlobImage(dtlData[0].ActionTakenImagePath)?.url : undefined}
                                                            alt={'avtar'}
                                                        />

                                                    </>
                                                </Box>

                                        }
                                    </Grid>
                                    <Typography variant="body1" sx={{color:"#ffff", marginTop: 2}}>Note: * fields are mandatory! </Typography>
                            {renderButtons()}
                        </Form>
                    );
                }}
            </Formik>
        </Container>
    );
};
export default CreateModule;
