import { Button, Grid, Container, Box } from '@mui/material';
import DropDown from '../../../../../atoms/Dropdown';
import Label from '../../../../../atoms/Label/index';
import '../../../../Admin/FeatureManagement/CreateReport/index.scss';
import MachineOverviewCard from '../../../../../atoms/MachineOverviewCard';
import SearchBar from '../../../../../atoms/SearchBar';
import Dropdown from '../../../../../atoms/Dropdown';
import { useEffect, useState } from 'react';
import './index.scss';
import { useDispatch } from 'react-redux';
import { executeACGAction, updateScreenIdentifiers } from '../../../../../store/slice';
import { ACTION_CODES, STORE_KEYS } from '../../../../../constants/apiConstants';
import useGetState from '../../../../../utils/hooks/useGetState';
import { renameObjKeys } from '../../../../Login/helper';
import FilterDrawer from '../../../../../atoms/FilterDrawer';
import Loader from '../../../../../atoms/Loader';
import LogLogo from '../../../../../../assets/images/CondnMonLog.svg';
import ViewReportLogo from '../../../../../../assets/images/ViewReport.svg';

import RightDrawer from '../../../../../atoms/tempDrawer/indexAlt';
import CreateAlertDrawer from '../../../../../atoms/CreateAlertDrawer/CreateAlertDrawer';
import ViewAlertDrawer from '../../../../../atoms/ViewAlertDrawer/ViewAlertDrawer';
import AlertLogDrawer from '../../../../../atoms/AlertLogDrawer/AlertLogDrawer';
import AlertDropdown from '../../../../../atoms/DrawerDropdown';
import MenuDropDown from '../../../../../atoms/MenuDropDown/MenuDropDown';
import DrawerDropdown from '../../../../../atoms/DrawerDropdown';
import CustomAlertBtn from '../../../../../atoms/CustomAlertBtn/CustomAlertBtn';
import { trackButtonEvent, trackPageActionLog } from '../../../../../utils/newRelicFuncations';

type Props = {
    data: any;
};
const Parameters = (props: Props) => {

    const { data } = props;
    const [grps, setGrps] = useState<any>([]);
    const [grp, setGrp] = useState<any>(-1);
    const [params, setParams] = useState<any>([]);
    const [paramsData, setParamsData] = useState<any>([]);
    const [searchTxt, setSearchTxt] = useState<any>('');
    const [filterOpen, setFilterOpen] = useState<any>(false);
    const [filterGrp, setFilterGrp] = useState<any>(-1);
    //drawer states
    const [createDrawerOpen, setCreateDrawerOpen] = useState(false);
    const [createValue, setCreateValue] = useState('');
    const [AlertType, setALertType] = useState('');
    const [editDetails, setEditDetails] = useState({ isEdit: false, values: null })
    const [totalAlertCounts,setTotalAlertCounts]=useState<number>(0)

    const stateVal = useGetState();
    const dispatch = useDispatch();
    const state = useGetState();
    const userEmail = state[STORE_KEYS.USER_DTL]?.EmailId;
    const getGrps = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: `${ACTION_CODES.GET_PARAMGRP}/${data?.MachineKey}`
                },
                uniqueScreenIdentifier: { grpsRecd: true },
                storeKey: STORE_KEYS.PARAMGRPS
            })
        );
    };
    const getParams = (grpKey?: any) => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: ACTION_CODES.GET_PARAMS,
                    reqObj: {
                        machineKey: data?.MachineKey,
                        machMdlParamGrpKey: grpKey ? grpKey : -1
                    }
                },
                uniqueScreenIdentifier: { paramsRecd: true },
                storeKey: STORE_KEYS.PARAMS
            })
        );
    };
    useEffect(() => {

        trackPageActionLog({
            actionName:'OEE-Dashboard',
            action:'Landed on OEE Dashboard page',
            LoggedInUser:state[STORE_KEYS.USER_DTL]?.EmailId,
        })
    }, [])
    useEffect(() => {
        getGrps();
        getParams();
    }, []);
    useEffect(() => {
        if (stateVal?.[STORE_KEYS.PARAMGRPS]?.grpsRecd) {
            const grpsList = stateVal?.[STORE_KEYS.PARAMGRPS]?.body?.data?.map((item: any) => {
                const newVal = renameObjKeys(item, { MachMdlParamGrpKey: 'code', GroupName: 'name' });
                return newVal;
            });
            setGrps(grpsList);
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.PARAMGRPS,
                    uniqueScreenIdentifier: {
                        grpsRecd: false
                    }
                })
            );
        }
    }, [stateVal?.[STORE_KEYS.PARAMGRPS]?.grpsRecd]);
    useEffect(() => {
        if (stateVal?.[STORE_KEYS.PARAMS]?.paramsRecd) {
            setParamsData(stateVal?.[STORE_KEYS.PARAMS]?.body?.data);
            if (searchTxt !== '') {
                const paramsList = stateVal?.[STORE_KEYS.PARAMS]?.body?.data?.filter((item: any) =>
                    item?.ParamName?.toLowerCase()?.includes(searchTxt?.toLowerCase())
                );
                setParams(paramsList);
            } else {
                setParams(stateVal?.[STORE_KEYS.PARAMS]?.body?.data);
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.PARAMS,
                    uniqueScreenIdentifier: {
                        paramsRecd: false
                    }
                })
            );
        }
    }, [stateVal?.[STORE_KEYS.PARAMS]?.paramsRecd]);
    const handleSearch = (event: any) => {
        setSearchTxt(event.target.value);
        if (searchTxt !== '') {
            const list = paramsData?.filter((parm: any) =>
                parm?.ParamName?.toLowerCase()?.includes(event.target.value.toLowerCase())
            );
            setParams(list);
        }
    };
    const resetInput = () => {
        setSearchTxt('');
        setParams(stateVal?.[STORE_KEYS.PARAMS]?.body?.data);
    };

    const clearFilter = () => {
        setFilterGrp(-1);
        getParams(-1);
        setFilterOpen(false);
    };
    const applyFilter = () => {
        getParams(filterGrp);
        setFilterOpen(false);
    };

    const handleMenuClick = (drawerStatus: any, CreateValue: any, ALertType: any) => {
        setCreateDrawerOpen(drawerStatus);
        setCreateValue(CreateValue)
        setALertType(ALertType)
    }

    const DropdownOptions = [
        {
            label: "Create Alert", click: () => {
                setEditDetails({ isEdit: false, values: null })
                handleMenuClick(true, "", "Create Alert");
                trackButtonEvent("Machine Monitoring","Parameters tabs","Create Alert",state[STORE_KEYS.USER_DTL]?.EmailId)
            }
        },
        { label: "View Alerts", click: () => { handleMenuClick(true, "Custom Alerts", "View Alerts") } },
         // { label: "Alert Logs", click: () => { handleMenuClick(true, "Custom Alerts", "Alert Logs") } },
    ]

    const handleEdit = (values: any) => {
        setEditDetails({ isEdit: true, values: values })
        setCreateDrawerOpen(false)
        handleMenuClick(true, "", "Create Alert")
         }

    return (
        <div className="overviewContainer">
            <div className="overviewContainerFlexMain">
                <div className="FlexOne">
                    <div className="topRow">
                        <div>
                            <h6 style={{ color: '#DADCEE', fontSize: '16px', marginBottom: '3px' }}>Parameter List</h6>
                            <h6 style={{ color: 'rgb(156 194 255 / 70%)', fontSize: '12px', marginTop: '0px' }}>
                                {`${params?.length} Parameters`}
                            </h6>
                        </div>
                        {/* <div>
                            <h6 style={{ fontSize: '12px', color: '#B1C2DF', marginTop: '15px' }}>
                                Last Updated: 4 Nov, 2022 | 18:02 IST
                            </h6>
                        </div> */}

                        <div style={{ width: '200px', marginRight: '15%' }}>
                            <Dropdown
                                onChange={(event) => {
                                    setGrp(event.target.value);
                                    getParams(event.target.value);
                                }}
                                className="inputDiv"
                                name="dropdownClient"
                                items={grps}
                                // placeHolder="All Parameters"
                                value={grp}
                                helperText={''}
                            />
                        </div>

                        <div className="searchDiv margintp">
                            <SearchBar
                                searchText={searchTxt}
                                setSearchText={handleSearch}
                                resetSearchText={resetInput}
                                searchLabel={'Search for Parameter'}
                            />
                        </div>
                        {/* <DrawerDropdown click={ () => {}} items={['Client', 'Plant']} /> */}
                        <CustomAlertBtn DropdownOptions={DropdownOptions} />
                        <div>
                            {/* <img style={{ marginRight: '20px' }} src={LogLogo} alt="condnLogo" />
                            <img src={ViewReportLogo} alt="reportLogo" /> */}
                        </div>
                    </div>
                    <br />
                </div>
            </div>
            {/* {stateVal?.isLoading && <Loader />} */}
            <Grid container spacing={2}>
                {params?.length > 0 ? (
                    params?.map((ele: any, idx: any) => {
                        const index = idx + 1;
                        return (
                            <Grid item xs={12} lg={2.3} md={3} sm={6} key={index}>
                                <MachineOverviewCard data={ele} />
                            </Grid>
                        );
                    })
                ) : (
                    <div style={{ fontSize: '14px', marginLeft: '20px', color: '#B1C2DF' }}>
                        {'Parameters not available'}
                    </div>
                )}
            </Grid>
            <FilterDrawer
                handlerClose={() => {
                    setFilterOpen(false);
                    setFilterGrp(-1);
                }}
                status={filterOpen}
                drawerHeader={'Filter'}
                children={
                    <Container className="createReport">
                        <Box style={{ minHeight: '75vh' }}>
                            <Label label="Parameter Group" className={'labelDiv'} />
                            <DropDown
                                onChange={(event: any) => {
                                    setFilterGrp(event.target.value);
                                }}
                                name="dropdownMd"
                                className="inputDiv"
                                items={grps}
                                value={filterGrp}
                            />
                        </Box>
                    </Container>
                }
                clearFn={clearFilter}
                applyFn={applyFilter}
            />

            <RightDrawer
                alertType={createValue}
                handlerOpen={() => { }}
                handlerClose={() => { setCreateDrawerOpen(false); }}
                status={createDrawerOpen}
                subHeader={AlertType=='View Alerts' ? `${totalAlertCounts} Counts`:undefined}
                drawerHeader={AlertType.includes("Create") ? editDetails.isEdit ? "Edit Alert" : "Create Alert" : AlertType}
                children={
                    AlertType.includes("Create") ?
                        <CreateAlertDrawer MachineKey={data?.MachineKey} TabName="MachineSetting" editDetails={editDetails} handlerClose={() => { setCreateDrawerOpen(false); }} /> : AlertType.includes("View")
                            ? <ViewAlertDrawer setTotalAlertCounts={setTotalAlertCounts} MachineKey={data?.MachineKey} handleEdit={handleEdit} TabName="MachineSetting" /> : AlertType.includes("Logs")
                                ? <AlertLogDrawer MachineKey={data?.MachineKey} /> : null
                }
            />

        </div>
    );
};

export default Parameters;
