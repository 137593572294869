import { useState, useEffect } from 'react';
import { Container } from '@mui/material'
import useGetState from '../../utils/hooks/useGetState';
import { _noop } from '../../utils/common';

import './index.scss';
import MapCustomTabs from '../../atoms/ButtonTabs/MapCustomTabs';
import Reports from './Report';
import Dashboard from './Dashboard';
import DownloadableReports from './DownloadableReport';
import { SCREEN_TITLES } from '../../constants/StringConstants';

const UserReportsListing = () => {

    const tabs = ['Reports', 'Dashboards'];

    const [currTab, setCurrTab] = useState(0);

    const state = useGetState();

    const navigateTab = () => {
        switch (currTab) {
            case 0:
                return <Reports />;
            default:
                return <Dashboard />;
        }
    };

    return (
        <div className="dashboard">
            <Container maxWidth="xl" style={{ marginTop: '60px' }}>
                <div className='headinglabel'>{SCREEN_TITLES.USR_REPORTS_AND_DAHBOARDS}</div>
                <div style={{ marginTop: '35px', zIndex: '0' }}>
                </div>
                <div style={{ marginTop: '15px', zIndex: '0' }}>
                    <MapCustomTabs
                        navigateTab={navigateTab}
                        currTab={currTab}
                        setCurrTab={setCurrTab}
                        tabsArray={tabs}
                        className="tabs"
                        position="relative"
                        moduleName='Reports and Dashboard Tabs'
                    />
                </div>
            </Container>
        </div>
    );
};

export default UserReportsListing;