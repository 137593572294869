import { Container } from '@mui/material';
import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import styles from './index.module.css'; // Import the CSS module
import Axios from 'axios';
import { ArcElement, Chart, registerables } from "chart.js";
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ACTION_CODES, BASEURL, STORE_KEYS } from '../../../../app/constants/apiConstants';
import useGetState from '../../../../app/utils/hooks/useGetState';
import DownArrow from '../../../../assets/down-arrow.svg';
import CustomDatePicker from '../../../atoms/CustomDatePicker';
import DropDown from '../../../atoms/Dropdown';
import SnackbarAlert from '../../../atoms/Snackbar';
import { formatOnlyDate } from '../../../utils/formatTime';
import '../../Admin/ClientManagement/index.scss';
import Treemap from "./GraphOEEKPI/treemap";
import ParetoChart1 from "./GraphOEEKPI/pareto";
import { trackErrorsInNewRelic, trackPageActionLog } from '../../../utils/newRelicFuncations';
import { calculateDateDifference, lastTwoMonthsDaterange, startDateOfMonth } from '../../../utils/helper';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const DropDownIcon = (prop: any) => (
    <img style={{ marginRight: '5px', marginTop: '-2px' }} src={DownArrow} {...prop} />
);

Chart.register(...registerables);
Chart.register(ArcElement);

const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    }
};
const SystemConfiguration = (props: any) => {
    const history = useHistory();
    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const state = useGetState();
    const token = state?.[STORE_KEYS.USER_DTL].token;
    const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;
    const [presetKey, setPresetKey] = useState('');
    const [products, setProducts] = useState<any>([]);
    const [batches, setBatches] = useState<any>([]);
    const [selectedProduct, setSelectedProduct] = useState<any>([]);
    const [selectedBatch, setSelectedBatch] = useState<any>([]);
    const [shifts, setShifts] = useState<any>([]);
    const [selectedShift, setSelectedShift] = useState<any>([]);
    const [dtAnalysis, setDTAnalysis] = useState<any>([]);
    const [plannedDowntime, setPlannedDowntime] = useState<any>([]);
    const [unplannedDowntime, setUnplannedDowntime] = useState<any>([]);
    const [selectAllProduct, setSelectAllProduct] = useState<boolean>(false);
    const [selectAllBatch, setSelectAllBatch] = useState<boolean>(false);
    const [selectAllShift, setSelectAllShift] = useState<boolean>(false);
    const userEmail = state[STORE_KEYS.USER_DTL]?.EmailId;

    useEffect(() => {
        if(props.checkedOption == "YTD" && !props.detailsRedirection){
            updateYTD();
        } else if(props.checkedOption == "MTD" && !props.detailsRedirection) {
            updateMTD();
        }
        props.setDetailsRedirection(false);
    }, []);

    const updateYTD = () => {
        const range = lastTwoMonthsDaterange();
        props.setInDate(new Date(range.from));
        props.setEndDate(new Date(range.to));
    }

    const updateMTD = () => {
        const range = startDateOfMonth();
        props.setInDate(new Date(range.from));
        props.setEndDate(new Date(range.to));
    }

    const handleChangeUpdateModel = () => {
        setSelectedProduct([]);
        setSelectedBatch([]);
        setSelectedShift([]);
        setSelectAllProduct(true);
        setSelectAllBatch(true);
        setSelectAllShift(true);
        setDTAnalysis([]);
        setUnplannedDowntime([]);
        setPlannedDowntime([]);
        setShifts([]);
        setBatches([]);
        setProducts([]);
    };

    const handleChangeUpdateMachine = () => {
        setSelectedProduct([]);
        setSelectedBatch([]);
        setSelectedShift([]);
        setSelectAllProduct(true);
        setSelectAllBatch(true);
        setSelectAllShift(true);
        setPlannedDowntime([]);
        setUnplannedDowntime([]);
        setDTAnalysis([]);
        setShifts([]);
        setBatches([]);
        setProducts([]);
    };

    useEffect(() => {
        (async () => {
            const rangeDays = calculateDateDifference(props.inDate, props.endDate);
            if (rangeDays > 60) {
                props.setDateDTRange(true);
                return
            } else {
                props.setDateDTRange(false);
                fetchDashboardProducts(parseInt(props.selectedMachine), props.inDate, props.endDate, parseInt(props.selectedMachineModel));
                setSelectAllProduct(true);
                setSelectAllBatch(true);
                setSelectAllShift(true);
            }
        })();

    }, [props.selectedMachine, props.selectedMachineModel, props.inDate, props.endDate, props.detailsRedirection]);

    useEffect(() => {
        if (props.checkedOption != null) {
            setSelectedProduct([]);
            setSelectedBatch([]);
            setSelectedShift([]);
            setSelectAllProduct(true);
            setSelectAllBatch(true);
            setSelectAllShift(true);
            setPlannedDowntime([]);
            setUnplannedDowntime([]);
            setDTAnalysis([]);
            setShifts([]);
            setBatches([]);
            setProducts([]);
        }
    }, [props.checkedOption])

    const fetchDashboardProducts = async (mk: any, val1: any, val2: any, modelKey: any) => {
        const payload = {
            userId: loggedInUser,
            machineKey: mk,
            start: formatOnlyDate(val1),
            end: formatOnlyDate(val2),
            modelKey: modelKey
        };
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.API_PMEC_GET_DASHBOARD_PRODUCTS,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;
                    const tmpTableData = result[0][0];
                    setProducts(tmpTableData);
                }
            })
            .catch((error) => {
                trackErrorsInNewRelic({ error: error })

                return {
                    status: error
                };
            });
    }
    const fetchBatches = async (val3: any) => {

        const payload = {
            userId: loggedInUser,
            machineKey: props.selectedMachine,
            modelKey: props.selectedMachineModel,
            start: formatOnlyDate(props.inDate),
            end: formatOnlyDate(props.endDate),
            productKeys: val3
        };
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.API_PMEC_GET_BATCHES,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;
                    const tmpTableData = result[0][0];
                    setBatches(tmpTableData);
                }
            })
            .catch((error) => {
                trackErrorsInNewRelic({ error: error })

                return {
                    status: error
                };
            });
    }

    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);

    const handleChangeProduct = (id: any) => {
        setSelectAllShift(true);
        setShifts([]);
        setSelectedShift([]);
        setSelectAllBatch(true);
        setSelectAllShift(true);
        setDTAnalysis([]);
        setUnplannedDowntime([]);
        setPlannedDowntime([]);
        setShifts([]);
        setBatches([])
        let newSelected: any = [];
        if (presetKey === '') {
            const selectedIndex = selectedProduct.indexOf(id);
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selectedProduct, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selectedProduct.slice(1));
            } else if (selectedIndex === selectedProduct.length - 1) {
                newSelected = newSelected.concat(selectedProduct.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selectedProduct.slice(0, selectedIndex),
                    selectedProduct.slice(selectedIndex + 1)
                );
            }
        }
        else {
            const selectedIndex = selectedProduct.findIndex((t: any) => t.BatchNo === id.BatchNo);
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selectedProduct, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selectedProduct.slice(1));
            } else if (selectedIndex === selectedProduct.length - 1) {
                newSelected = newSelected.concat(selectedProduct.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selectedProduct.slice(0, selectedIndex),
                    selectedProduct.slice(selectedIndex + 1)
                );
            }
        }

        setSelectedProduct(newSelected);
        if (newSelected.length) {
            let pk: any = [];
            newSelected.map((item: any) => pk.push(item.ProductKey))
            fetchBatches(pk.toString());
        }

    }
    const handleChangeBatch = (id: any) => {
        setSelectAllShift(true);
        setShifts([]);
        setSelectedShift([]);
        setDTAnalysis([]);
        setUnplannedDowntime([]);
        setPlannedDowntime([]);
        setShifts([]);
        let newSelected: any = [];
        if (presetKey === '') {
            const selectedIndex = selectedBatch.indexOf(id);
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selectedBatch, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selectedBatch.slice(1));
            } else if (selectedIndex === selectedBatch.length - 1) {
                newSelected = newSelected.concat(selectedBatch.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selectedBatch.slice(0, selectedIndex),
                    selectedBatch.slice(selectedIndex + 1)
                );
            }
        }
        else {
            const selectedIndex = selectedBatch.findIndex((t: any) => t.BatchNo === id.BatchNo);
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selectedBatch, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selectedBatch.slice(1));
            } else if (selectedIndex === selectedBatch.length - 1) {
                newSelected = newSelected.concat(selectedBatch.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selectedBatch.slice(0, selectedIndex),
                    selectedBatch.slice(selectedIndex + 1)
                );
            }
        }

        setSelectedBatch(newSelected);
        if (newSelected.length) fetchShifts(newSelected);

    }

    const fetchShifts = async (sft: any) => {
        const btc: any = [];
        sft.map((i: any) => btc.push(i.BatchNo));

        const payload = {
            machineKey: props.selectedMachine,
            products: selectedProduct.map((i: any) => i.ProductKey).toString(),
            batches: btc.length > 0 ? btc.toString() : sft.map((i: any) => { return i.ShiftName }).toString(),
            start: formatOnlyDate(props.inDate),
            end: formatOnlyDate(props.endDate),
        };
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.API_PMEC_GET_SHIFTS,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;
                    const tmpTableData = result[0][0];
                    setShifts(tmpTableData);
                }
            })
            .catch((error) => {
                trackErrorsInNewRelic({ error: error })

                return {
                    status: error
                };
            });
    }
    const handleChangeShift = (id: any) => {
        setDTAnalysis([]);
        setUnplannedDowntime([]);
        setPlannedDowntime([]);
        let newSelected: any = [];
        if (presetKey === '') {
            const selectedIndex = selectedShift.indexOf(id);
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selectedShift, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selectedShift.slice(1));
            } else if (selectedIndex === selectedShift.length - 1) {
                newSelected = newSelected.concat(selectedShift.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selectedShift.slice(0, selectedIndex),
                    selectedShift.slice(selectedIndex + 1)
                );
            }
        }
        else {
            const selectedIndex = selectedShift.findIndex((t: any) => t.BatchNo === id.BatchNo);
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selectedShift, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selectedShift.slice(1));
            } else if (selectedIndex === selectedShift.length - 1) {
                newSelected = newSelected.concat(selectedShift.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selectedShift.slice(0, selectedIndex),
                    selectedShift.slice(selectedIndex + 1)
                );
            }
        }

        setSelectedShift(newSelected);
        fetchKPIData(newSelected);

    }

    const fetchKPIData = async (sft: any) => {
        const shifts: any = [], batchs: any = [];
        sft.map((i: any) => shifts.push(i.ShiftName));
        selectedBatch.map((i: any) => batchs.push(i.BatchNo));
        const payload = {
            machineKey: props.selectedMachine,
            products: selectedProduct.map((i: any) => i.ProductKey).toString(),
            batchs: batchs.length > 0 ? batchs.toString() : selectedBatch.map((i: any) => { return i.BatchNo }).toString(),
            shifts: shifts.length > 0 ? shifts.toString() : sft.map((i: any) => { return i.ShiftName }).toString(),
            start: formatOnlyDate(props.inDate),
            end: formatOnlyDate(props.endDate)
        };
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.API_PMEC_GET_DT_ANALYSIS,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;
                    const tmpTableData = result[0][0];
                    setDTAnalysis(tmpTableData);
                }
            })
            .catch((error) => {
                trackErrorsInNewRelic({ error: error })

                return {
                    status: error
                };
            });
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.API_PMEC_GET_DT_DETAILS,
            method: 'post',
            data: { ...payload, DownTimeTypeCode: 'PDT' },
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;
                    const tmpTableData = result[0][0];
                    setPlannedDowntime(tmpTableData);
                }
            })
            .catch((error) => {
                return {
                    status: error
                };
            });
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.API_PMEC_GET_DT_DETAILS,
            method: 'post',
            data: { ...payload, DownTimeTypeCode: 'UPDT' },
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;
                    const tmpTableData = result[0][0];
                    setUnplannedDowntime(tmpTableData);
                }
            })
            .catch((error) => {
                trackErrorsInNewRelic({ error: error })
                return {
                    status: error
                };
            });
    }

    useEffect(() => {
        setPlannedDowntime([]);
        setUnplannedDowntime([]);
        setDTAnalysis([]);
        const sb: any = [];
        if (selectAllProduct && products) {
            products.map((i: any) => sb.push(i));
            const filteredProducts = products.filter((product: any) => props.selectedProduct.includes(product.ProductKey))
            setSelectedProduct(props.detailsRedirection ? filteredProducts : sb);
            if ((sb.length > 0 && props.selectedMachine != '' )|| (props.detailsRedirection && props.selectedMachine != '')) fetchBatches('All')
        } else {
            setSelectedProduct([]);
        }
    }, [selectAllProduct, products, props.selectedMachine]);

    useEffect(() => {
        setPlannedDowntime([]);
        setUnplannedDowntime([]);
        setDTAnalysis([]);
        const sb: any = [];
        if (selectAllBatch && batches) {
            batches.map((i: any) => sb.push(i));
            const filteredBatches = batches.filter((product: any) => props.selectedBatch.includes(product.BatchNo))

            setSelectedBatch(props.detailsRedirection ? filteredBatches : sb);
            if (sb.length > 0 || props.detailsRedirection) fetchShifts(props.detailsRedirection ? filteredBatches : sb)
        } else {
            setSelectedBatch([]);
        }
    }, [selectAllBatch, batches])

    useEffect(() => {
        const sb: any = [];
        if (selectAllShift && shifts) {
            shifts.map((i: any) => sb.push(i));
            setSelectedShift(sb);
            if (sb.length > 0) fetchKPIData(sb);

        } else {
            setSelectedShift([]);
            setPlannedDowntime([]);
            setUnplannedDowntime([]);
            setDTAnalysis([]);
        }
    }, [selectAllShift, shifts])

    return (
        <div className="modelComponents">
            <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
            <Container
                maxWidth="xl"
                className={styles.cardContainer} // Use the class from CSS module
                style={{
                    paddingLeft: '3px',
                    paddingRight: '3px',
                }}
            >
                <div className='filterdiv production_dashboard'>
                <div className='filterdiv production_dashboardBottom'>
                    <div className='widthmindiv' style={{ width: props.checkedOption === null ? '18%' : '12%' }}>
                        <div style={{ width: '100% !important' }}>
                            <div className='lableTitle'>Please Select</div>
                            <Tooltip title="Date range between 1st of every month to current date." className='textfont'>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={props.checkedOption === 'MTD'}
                                        onChange={() => { props.handleCheckboxChange('MTD'); props.updateMTD(props.checkedOption === 'MTD' ? false : true); }}
                                        sx={{
                                            color: 'white',
                                            '&.Mui-checked': {
                                                color: 'white',
                                            },
                                            '&:focus': {
                                                outline: '2px solid #ffffff',
                                                outlineOffset: '2px',
                                            },
                                            '& .MuiSvgIcon-root': {
                                                color: 'white',
                                            }
                                        }}
                                        color="primary"
                                    />
                                }
                                label="MTD"
                                sx={{ color: '#ffffff' }}
                            />
                            </Tooltip>
                            <Tooltip title="For better visualization only last 60 days data is displayed">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={props.checkedOption === 'YTD'}
                                        onChange={() => { props.handleCheckboxChange('YTD'); props.updateYTD(props.checkedOption === 'YTD' ? false : true ); }}
                                        sx={{
                                            color: 'white',
                                            '&.Mui-checked': {
                                                color: 'white',
                                            },
                                            '&:focus': {
                                                outline: '2px solid #ffffff',
                                                outlineOffset: '2px',
                                            },
                                            '& .MuiSvgIcon-root': {
                                                color: 'white',
                                            }
                                        }}
                                        color="primary"
                                    />
                                }
                                label="YTD"
                                sx={{ color: '#ffffff' }}
                            />
                            </Tooltip>
                        </div>
                    </div>
                    <div className='width_filter clscolordot'>
                        <div className='lableTitle'>Select Machine Model</div>
                        <DropDown
                            onChange={(e) => { props.handleMachineModel(); handleChangeUpdateModel() }}
                            className="inputDiv dropdownbx"
                            name="dropdownModel"
                            items={props.machineModels}
                            placeHolder="Select Machine Model"
                            value={props.selectedMachineModel}
                        />
                    </div>
                    <div className='width_filter clscolordot'>
                        <div className='lableTitle'>Select Machine</div>
                        <DropDown
                            onChange={(e) => { 
                                props.handleChange(e.target.value); 
                                handleChangeUpdateMachine();
                                const machineData = props.machines.find((item:any)=>item.code == e.target.value)
                                trackPageActionLog({
                                    actionName:"OEE Dashboard Downtime",
                                    action:`User changed the machine to ${machineData.name}`,
                                    LoggedInUser:state[STORE_KEYS.USER_DTL]?.EmailId,
                                })
                            }}
                            className="inputDiv dropdownbx"
                            name="dropdownModel"
                            items={props.machines}
                            placeHolder="Select Machine."
                            value={props.selectedMachine}
                        />
                    </div>
                    {props.checkedOption === null && <div className='width_filter dateWidth'>
                        <div className='lableTitle'>From</div>
                        <CustomDatePicker
                            name="dateFromSelect"
                            value={props.inDate}
                            maxDate={new Date()}
                            className="inputDiv"
                            onChange={(val: any) => {
                                props.setInDate(val);
                                fetchDashboardProducts(props.selectedMachine, val, props.endDate, props.selectedMachineModel);
                                setBatches([])
                                setProducts([])
                                setSelectedBatch([]);
                                setSelectedShift([]);
                                setSelectedProduct([])
                                setSelectAllProduct(true);
                                setSelectAllBatch(true);
                                setSelectAllShift(true);
                                setPlannedDowntime([]);
                                setUnplannedDowntime([]);
                                setDTAnalysis([]);
                                setShifts([]);
                            }}
                            inputFormat="dd/MM/yyyy"
                            placeholder="Select From Date"
                            minDate={new Date("01-01-2023")}
                        />
                        {props.dateDTRange && (
                            <div style={{ marginTop: '10px' }}>
                                <span
                                    style={{
                                        color: '#F06868',
                                        fontSize: '11px',
                                        fontWeight: 600,
                                        letterSpacing: 0,
                                        marginTop: '20px',
                                        marginBottom: '15px',
                                        fontFamily: 'Montserrat',
                                        marginLeft: '5%'
                                    }}
                                >
                                    {'Only last 60 days data can be selected. '}
                                </span>
                            </div>
                        )}
                    </div>}
                    {props.checkedOption === null && <div className='width_filter dateWidth'>
                        <div className='lableTitle'>To</div>
                        <CustomDatePicker
                            name="dateFromSelect"
                            value={props.endDate}
                            minDate={props.inDate}
                            maxDate={new Date()}
                            className="inputDiv"
                            onChange={(val: any) => {
                                props.setEndDate(val);
                                fetchDashboardProducts(props.selectedMachine, props.inDate, val, props.selectedMachineModel);
                                setBatches([]);
                                setProducts([])
                                setSelectedBatch([]);
                                setSelectedShift([]);
                                setSelectedProduct([])
                                setSelectAllProduct(true);
                                setSelectAllBatch(true);
                                setSelectAllShift(true);
                                setPlannedDowntime([]);
                                setUnplannedDowntime([]);
                                setDTAnalysis([]);
                                setShifts([]);
                            }}
                            inputFormat="dd/MM/yyyy"
                            placeholder="Select End Date"
                        />
                        {props.dateDTRange && (
                            <div style={{ marginTop: '10px' }}>
                                <span
                                    style={{
                                        color: '#F06868',
                                        fontSize: '11px',
                                        fontWeight: 600,
                                        letterSpacing: 0,
                                        marginTop: '20px',
                                        marginBottom: '15px',
                                        fontFamily: 'Montserrat',
                                        marginLeft: '5%'
                                    }}
                                >
                                    {'Only last 60 days data can be selected. '}
                                </span>
                            </div>
                        )}
                    </div>}
                    <div className='width_filter selectproductdetails'>
                        <div className='lableTitle'>Select Products</div>
                        <Select
                            IconComponent={DropDownIcon}
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            className='muti_boxcheck'
                            label=""
                            multiple
                            fullWidth
                            value={selectedProduct}
                            sx={{ marginBottom: "10px", backgroundColor: "#1d1e2c", borderRadius: 3, marginTop: '10px', color: "#b1c2df" }}
                            placeholder='Please select Batches'
                            // input={<OutlinedInput />}
                            renderValue={(selected) => selected.map((x: any) => x.ProductName).join(', ')}
                            MenuProps={MenuProps}

                        >
                            <MenuItem value={"All"} onClick={() => { setSelectAllProduct(!selectAllProduct); }}>
                                <Checkbox
                                    checked={
                                        selectAllProduct
                                    }

                                />
                                <ListItemText primary="Select All" />
                            </MenuItem>
                            {products?.map((i: any) => (
                                <MenuItem value={i} onClick={() => handleChangeProduct(i)} className='ulSelectProduct'>
                                    <Checkbox
                                        checked={
                                            selectedProduct.findIndex((item: any) => item.ProductKey === i.ProductKey) >= 0
                                        }

                                    />
                                    <ListItemText primary={i.ProductName} />
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    <div className='width_filter'>
                        <div className='lableTitle'>Select Batch</div>
                        <Select
                            IconComponent={DropDownIcon}
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            className='muti_boxcheck'
                            label=""
                            multiple
                            fullWidth
                            value={selectedBatch}
                            sx={{ marginBottom: "10px", backgroundColor: "#1d1e2c", borderRadius: 3, marginTop: '10px', color: "#b1c2df" }}
                            placeholder='Please select Batches'
                            // input={<OutlinedInput />}
                            renderValue={(selected) => selected.map((x: any) => x?.McBatchNo).join(', ')}
                            MenuProps={MenuProps}

                        >
                            <MenuItem value={"all"} onClick={() => { setSelectAllBatch(!selectAllBatch); }}>
                                <Checkbox
                                    checked={
                                        selectAllBatch
                                    }

                                />
                                <ListItemText primary="Select All" />
                            </MenuItem>
                            {batches?.map((i: any) => (
                                <MenuItem value={i} onClick={() => handleChangeBatch(i)} className='ulSelectBatchRecipe'>
                                    <Checkbox
                                        checked={
                                            selectedBatch?.findIndex((item: any) => item.BatchNo === i.BatchNo) >= 0
                                        }

                                    />
                                    <ListItemText primary={i.McBatchNo} />
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    <div className='width_filter'>
                        <div className='lableTitle'>Select Shift</div>

                        <Select
                            IconComponent={DropDownIcon}
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            className='muti_boxcheck'
                            label=""
                            multiple
                            fullWidth
                            value={selectedShift}
                            sx={{ marginBottom: "10px", backgroundColor: "#1d1e2c", borderRadius: 3, marginTop: '10px', color: "#b1c2df" }}
                            placeholder='Please select Batches'
                            // input={<OutlinedInput />}
                            renderValue={(selected) => selected.map((x: any) => x.ShiftName).join(', ')}
                            MenuProps={MenuProps}

                        >

                            <MenuItem value={"all"} onClick={() => setSelectAllShift(!selectAllShift)}>
                                <Checkbox
                                    checked={
                                        selectAllShift
                                    }

                                />
                                <ListItemText primary="Select All" />
                            </MenuItem>
                            {shifts?.map((i: any) => (
                                <MenuItem value={i} onClick={() => handleChangeShift(i)} className='ulSelectBatchRecipe'>
                                    <Checkbox
                                        checked={
                                            selectedShift.findIndex((item: any) => item.ShiftName === i.ShiftName) >= 0
                                        }

                                    />
                                    <ListItemText primary={i.ShiftName} />
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </div>
                </div>
                <div className='graphcontainer heightgraphs'>
                    <div style={{ width: "100%" }}>
                        <h3 className='titleGraph font14'>OEE Downtime Details</h3>
                        <div className='treemapcls'>
                            {dtAnalysis ? <Treemap dtAnalysis={dtAnalysis} /> : null}
                        </div>
                    </div>
                </div>

                <div className='graphcontainer heightgraphs'>
                    <div className='leftgraph'>
                        <h3 className='titleGraph font14' style={{ color: "#e0e0e0" }}>Planned Downtime</h3>
                        <div>
                            <ParetoChart1 downtime={plannedDowntime} />
                        </div>
                    </div>
                    <div className='rightgraph'>
                        <h3 className='titleGraph font14'>Unplanned Downtime</h3>
                        <div>
                            <ParetoChart1 downtime={unplannedDowntime} />
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default SystemConfiguration;
