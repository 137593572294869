import { Container, Card } from '@mui/material';
import styles from './index.module.css'; // Import the CSS module
import CustomDatePicker from '../../../atoms/CustomDatePicker';
import DropDown from '../../../atoms/Dropdown';
import Tooltip from '@mui/material/Tooltip';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import DownArrow from '../../../../assets/down-arrow.svg';
import { useState, useEffect, useRef } from 'react';
import ACGButton from '../../../atoms/Button';
import { _noop } from '../../../utils/common';
import '../../Admin/ClientManagement/index.scss';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import Stack from './Charts/stackedDetails';
import Stack2 from './Charts/stackedCapacityUtilize';
import Trendline1 from './Charts/trendLineDetails';
import Trendline2 from './Charts/trendLineMachineDetails';
import { getLastTwoMonths } from '../../../utils/helper';
import { trackPageActionLog } from '../../../utils/newRelicFuncations';
import useGetState from '../../../utils/hooks/useGetState';
import { STORE_KEYS } from '../../../constants/apiConstants';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const DropDownIcon = (prop: any) => (
    <img style={{ marginRight: '5px', marginTop: '-2px' }} src={DownArrow} {...prop} />
);

const OEEDashboardHomePage = (props: any) => {
    const state = useGetState()
    useEffect(() => {
        props.setDetailsRedirection(false);
    }, []);

    return (
        <div className="modelComponents">
            <div>
                {props.currTab == 0 && <Container
                    maxWidth="xl"
                    className={styles.cardContainer} // Use the class from CSS module
                    style={{
                        paddingLeft: '3px',
                        paddingRight: '3px',
                    }}
                >
                    <div className='filterdiv' style={{ background: "#24263a", borderRadius: "0px" }}>
                        <div className='width_filter' style={{ width: '14%' }}>
                            <div className='lableTitle'>Please Select</div>
                            <Tooltip title="Date range between 1st of every month to current date." className='textfont'>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={props.checkedOption === 'MTD'}
                                        onChange={(e: any) => { props.handleCheckboxChange('MTD'); props.updateMTD(props.checkedOption === 'MTD' ? false : true); }}
                                        sx={{
                                            color: 'white',
                                            '&.Mui-checked': {
                                                color: 'white',
                                            },
                                            '&:focus': {
                                                outline: '2px solid #ffffff',
                                                outlineOffset: '2px',
                                            },
                                            '& .MuiSvgIcon-root': {
                                                color: 'white',
                                            }
                                        }}
                                        color="primary"
                                    />
                                }
                                label="MTD"
                                sx={{ color: '#ffffff', fontSize: "14px"  }}
                            />
                            </Tooltip>
                            {/* <Tooltip title="For better visualization only last 60 days data is displayed" className='textfont' > */}
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={props.checkedOption === 'YTD'}
                                            onChange={() => { props.handleCheckboxChange('YTD'); props.updateYTD(props.checkedOption === 'YTD' ? false : true); }}
                                            sx={{
                                                color: 'white',
                                                '&.Mui-checked': {
                                                    color: 'white',
                                                },
                                                '&:focus': {
                                                    outline: '2px solid #ffffff',
                                                    outlineOffset: '2px',
                                                },
                                                '& .MuiSvgIcon-root': {
                                                    color: 'white',
                                                }
                                            }}
                                            color="primary"
                                        />
                                    }
                                    label="YTD"
                                    sx={{ color: '#ffffff', fontSize: "14px" }}
                                />
                            {/* </Tooltip> */}
                        </div>
                        {props.checkedOption === null && <div className='width_filter'>
                            <div className='lableTitle'>From</div>
                            <CustomDatePicker
                                name="dateFromSelect"
                                value={props.inDate}
                                maxDate={new Date()}
                                className="inputDiv"
                                onChange={(val: any) => {
                                    props.setInDate(val);
                                    props.setSelectedMachine('');
                                    trackPageActionLog({
                                        actionName:'OEE-Dashboard',
                                        action:'From date changed',
                                        additionalInfo: `From Date changed to ${val}`,
                                        LoggedInUser:state[STORE_KEYS.USER_DTL]?.EmailId,
                                    })
                                }}
                                inputFormat="dd/MM/yyyy"
                                placeholder="Select From Date"
                                minDate={new Date("01-01-2023")}

                            />
                        </div>}
                        {props.checkedOption === null && <div className='width_filter'>
                            <div className='lableTitle'>To</div>
                            <CustomDatePicker
                                name="dateFromSelect"
                                value={props.endDate}
                                minDate={props.inDate}
                                maxDate={new Date()}
                                className="inputDiv"
                                onChange={(val: any) => {
                                    props.setEndDate(val);
                                    props.setSelectedMachine('')
                                    trackPageActionLog({
                                        actionName:'OEE-Dashboard',
                                        action:'To date changed',
                                        additionalInfo: `To Date changed to ${val}`,
                                        LoggedInUser:state[STORE_KEYS.USER_DTL]?.EmailId,
                                    })
                                }}
                                inputFormat="dd/MM/yyyy"
                                placeholder="Select End Date"
                            />

                        </div>}
                        <div className='width_filter'>
                            <div className='lableTitle'>Select Plant</div>
                            <DropDown
                                onChange={props.handleChangePlant}
                                className="inputDiv dropdownbx longtxt padingLR"
                                name="dropdownModel"
                                items={props.plantsData}
                                placeHolder="Select Plant"
                                value={props.selectedPlant}
                            />
                        </div>
                        <div className='width_filter'>
                            <div className='lableTitle'>Select Machine Model</div>
                            <DropDown
                                onChange={props.handleMachineModel}
                                className="inputDiv dropdownbx padingLR"
                                name="dropdownModel"
                                items={props.machineModel}
                                placeHolder="Select Machine Model"
                                value={props.selectedMachineModel}
                            />
                        </div>
                        <div className='width_filter' style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                            {props.AIInsightsShow === "Y" && <ACGButton name="AI Insights" disabled={props.machineModel === "" || props.aIInsightsList.length === 0} className='AiInsightsBtn' onClick={(e: any) => { props.getCreateValue(e) }} />}
                        </div>
                    </div>
                    <Card className={styles.cardStyle} style={{ height: '80vh' }}> {/* Apply the card style */}
                        <div className='rightgraph' style={{ height: '98%' }}>
                            <h3 className='titleGraph font14'>Plant OEE</h3>
                            <div className='performanceChart'>
                                <Stack
                                    chart1={props.chart1}
                                    setCurrTab={props.setCurrTab}
                                    setSelectedMachine={props.setSelectedMachine}
                                    machines={props.machines}
                                    setDetailsRedirection={props.setDetailsRedirection}
                                    setSelectedChart={props.setSelectedChart}
                                    setInDate={props.setInDate}
                                    setEndDate={props.setEndDate}
                                />
                            </div>
                        </div>

                        <div className='rightgraph' style={{ height: '98%' }}>
                            <h3 className='titleGraph font14'>Plant Capacity Utilisation</h3>
                            <div className='performanceChart'>
                                <Stack2 chart2={props.chart2}
                                    setCurrTab={props.setCurrTab}
                                    setSelectedMachine={props.setSelectedMachine}
                                    machines={props.machines}
                                    setDetailsRedirection={props.setDetailsRedirection}
                                    setSelectedChart={props.setSelectedChart}
                                />
                            </div>
                        </div>
                    </Card>
                    <div className='filterdiv mt10'>
                        <div className='width_filter widthIncrease'>
                            <div className='lableTitle'>Select Machine Serial No.</div>
                            <DropDown
                                onChange={(e: any) => props.handleChange(e.target.value)}
                                className="inputDiv dropdownbx longtxt"
                                name="dropdownModel"
                                items={props.machines}
                                placeHolder="Select Machine Serial No."
                                value={props.selectedMachine}
                            />
                        </div>
                       
                    </div>
                    <Card className={styles.cardStyle} style={{ height: '86vh' }}> {/* Apply the card style */}
                        <div className='rightgraph' style={{ height: '98%' }}>
                            <h3 className='titleGraph font14'>OEE Parameters</h3>
                            <div className='performanceChart oEEParam'>
                                <Trendline1 oeeDetails={props.chart3} style={{ width: "100%" }} />
                            </div>
                        </div>

                        <div className='rightgraph' style={{ height: '98%' }}>
                            <div style={{display:"flex",justifyContent:"space-between"}}>
                            <h3 className='titleGraph font14'>Machine Downtime</h3>
                            <div className='width_filter widthIncrease widthDropbox' style={{width:"250px"}}>
                            <div className='lableTitle'>Select Downtime Categories(Max -5)</div>
                            <FormControl sx={{ width: '100%' }}>
                                <Select
                                    IconComponent={DropDownIcon}
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    className='chekboxmulti'
                                    //label=""
                                    multiple
                                    disabled={props.selectedMachine === "" ? true : false}
                                    value={props.selectedDTCategory}
                                    sx={{ minWidth:"400px !important",marginBottom: "10px", backgroundColor: "#1d1e2c", borderRadius: 3, marginTop: '10px', color: "#b1c2df" }}
                                    placeholder='Please select machines'
                                    // input={<OutlinedInput />}
                                    renderValue={(selected: any) => {
                                        const filteredOperators = props.selectedDTCategory.filter((opr: any) =>
                                            selected.some((item: any) => item === opr)
                                        );
                                        return filteredOperators.map((x: any) => {
                                            return (props.downtTimeCat.find((i: any) => x === i.CustDTCategoryKey))?.DTCategoryDesc
                                        }).join(', ')
                                    }
                                    }
                                    MenuProps={MenuProps}

                                >
                                    {props.downtTimeCat?.map((i: any) => (
                                        <MenuItem value={i} onClick={() => props.handleChangeCategories(i.CustDTCategoryKey)} className='ulSelectProduct'>
                                            <Checkbox
                                                checked={props.selectedDTCategory.includes(i.CustDTCategoryKey)}

                                            />
                                            <ListItemText primary={i.DTCategoryDesc} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                            </div>
                                
                            <div className='performanceChart machineDownTime'>
                                <Trendline2 oeeDetails={props.chart4} style={{ width: "100%" }} />
                            </div>
                        </div>
                    </Card>
                </Container>}
            </div>
        </div>
    );
};

export default OEEDashboardHomePage;
