import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Container } from '@mui/material';
import MapCustomTabs from '../../../app/atoms/ButtonTabs/MapCustomTabs';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../app/constants/apiConstants';
import { executeACGAction, updateScreenIdentifiers } from '../../../app/store/slice';
import Axios from 'axios';
import FMEAService from './fmeaServices';
import useGetState from '../../../app/utils/hooks/useGetState';
import { _noop } from '../../../app/utils/common';
import '../Admin/ClientManagement/index.scss';
import DrawerDropdown from '../../../app/atoms/DrawerDropdown';
import RightDrawer from '../../../app/atoms/tempDrawer/indexAlt';
import { SCREEN_TITLES } from '../../../app/constants/StringConstants';
import CreateCustomAlert from "./CustomAlerts/CreateConfig";
import CustomConfig from './CustomAlerts/Config';
import FailureIndicatorConfig from './CreateFailureIndicatorConfig';
import PotentialCausesConfig from './CreatePotentialCausesConfig';
import AssignNotification from './AssignNotificationConfig';
import ViewDetails from './ViewFMEADetails';
import SnackbarAlert from '../../atoms/Snackbar';

const tabs = ['Failure Mode List'];
const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    }
};
const initialQuery: any = { combinator: 'and', rules: [] };
const SystemConfiguration = () => {
    const [currTab, setTabChange] = useState(0);
    const [radioValue, setRadioValue] = useState('All');
    const [drawerHeader, setDrawerHeader] = useState('Create');
    const [createValue, setCreateValue] = useState('');
    const [createDrawerOpen, setCreateDrawerOpen] = useState(false);
    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const [reload, setReload] = useState(false);
    const [dtlData, setDtlData] = useState<any>({});
    const [selectedRow, setSelectedRow] = useState<any>();
    const [notificationData, setNotificationData] = useState([]);
    const [customerKey, setCustomerKey] = useState("");
    const [customersData, setCustomersData] = useState<any[]>([]);
    const [grpKey, setGrpKey] = useState('');
    const [customAlertsData, setCustomAlertsData] = useState([]);
    const [presetKey, setPresetKey] = useState('');
    const [customKey, setCustomKey] = useState('');
    const [view, setView] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteGrp, setDeleteGrp] = useState(true);
    const [editableData, setEditableData] = useState([]);
    const [editableCustomData, setEditableCustomData] = useState([]);
    const [deletePresetAlert] = useState(true);
    const [deleteKey, setDeleteKey] = useState('');
    const date = new Date();
    date.setDate(date.getDate() - 6)
    const [inDate, setInDate] = useState(date);
    const d = new Date();
    const [endDate, setEndDate] = useState(new Date())
    const [errorDate, setErrorFalse] = useState(false);
    const [plantsData, setPlantsData] = useState([]);
    const [selectedPlantsName, setSelectedPlantsName] = useState<any>([]);
    const [failureIndicatorsData, setFailureIndicatorsData] = useState<any>([]);
    const [occuranceCriteria, setOccuranceCriteria] = useState<any>([]);
    const [detectionCriteria, setDetectionCriteria] = useState<any>([]);
    const [assignedNotifications, setAssignedNotifications] = useState<any>([]);
    const [potentialCauses, setPotentialCauses] = useState<any>([]);
    const [selectedCasualParameter, setSelectedCasualParameter] = useState('');
    const [recommandList, setRecommndList] = useState<any>([]);
    const [customizedQuery2, setCustomizedQuery2] = useState<any>([]);
    const [casualParameterKey, setCasualParameterKey] = useState('');
    const [recommendKey, setRecommendKey] = useState('');
    const [queryData, setQueryData] = useState<any>([]);
    const [custQuery, setCustQuery] = useState<any>([]);
    const [queryParameters, setParameters] = useState<any[]>([]);
    const [newEntry, setNewEntry] = useState<boolean>(true);
    const [groupKeyNames, setGroupKeyNames] = useState<any[]>([]);
    const [conditionJSONData, setConditionJSONData] = useState<any[]>([]);
    const [machineKey, setMachineKey] = useState('');
    const [mainOperators, setMainOperators] = useState<any[]>([]);
    const [isFailureModeLoading,setIsFailmureModeLoading]=useState(false)

    const dispatch = useDispatch();
    const state = useGetState();
    const token = state?.[STORE_KEYS.USER_DTL].token;
    const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;
    const getCreateValue = (ele: string) => {
        setDrawerHeader('Create');
        setCreateValue(ele);
        setCreateDrawerOpen(true);
        setGrpKey('');
        setPresetKey('');
        setCustomKey('');
    };

    const setCurrTab = (val: number) => {
        setTabChange(val);
        if (radioValue == 'All' && currTab === 1) {
            setRadioValue('All');
        }
    };

    useEffect(() => {
        fetchNotificationGroups();
        FMEAService.getFMEAOperators({ token: token })
            .then((response: any) => {
                const result = response?.data?.message[0];
                setMainOperators(result[0]);
            })
            .catch((err) => {
                console.log(err);
            });
        const body = { userId: loggedInUser, token: token }
        FMEAService.getFMEACustomers(body)
            .then((response: any) => {
                const result = response?.data.message[0];
                const removeAll: any = [];
                result[0].map((i: any) => {
                    if (i.CustomerName !== "All") {
                        removeAll.push(i);
                    }
                })
                setCustomersData([
                    ...removeAll.map((item: any) => ({ code: item.CustomerKey, name: item.CustomerName }))
                ]);
            })
            .catch((err) => {
                console.log(err);
            });
        FMEAService.getFMEAOccurance(body)
            .then((response: any) => {
                const result = response?.data.message[0];
                const removeAll: any = [];
                result[0].map((i: any) => {
                    if (i.CustomerName !== "All") {
                        removeAll.push(i);
                    }
                })
                setOccuranceCriteria([
                    ...removeAll.map((item: any) => ({ code: item.OccurenceKey, name: item.OccurenceCriteria }))
                ]);
            })
            .catch((err) => {
                console.log(err);
            });
        FMEAService.getFMEADetectionCriteria(body)
            .then((response: any) => {
                const result = response?.data.message[0];
                const removeAll: any = [];
                result[0].map((i: any) => {
                    if (i.CustomerName !== "All") {
                        removeAll.push(i);
                    }
                })
                setDetectionCriteria([
                    ...removeAll.map((item: any) => ({ code: item.DetectionKey, name: item.DetectionCriteria }))
                ]);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const fetchCustomAlerts = () => {
        setIsFailmureModeLoading(true)
        const body = { userId: loggedInUser, token: token }
        FMEAService.getAllCustomRuleConditions(body)
            .then((response: any) => {
                const result = response.data.message[0];
                setCustomAlertsData(result[0]);
            })
            .catch((err) => {
                console.log(err);
            }).finally(()=>{
                setIsFailmureModeLoading(false)
            })
    }

    useEffect(() => {
        fetchCustomAlerts();
    }, []);

    const fetchCustomAlertDetails = async (id: any) => {
        const body = { userId: loggedInUser, ruleKey: id, token: token }
        fetchFailureMode(body);
        getPotentialCause(body);

        FMEAService.getFMEACustomAlertDetails(body)
            .then((response: any) => {
                const result = response.data.message[0];
                setEditableCustomData(result[0]);
            })
            .catch((err) => {
                console.log(err);
            });

        FMEAService.getFailureModeNotificationGroups(body)
            .then((response: any) => {
                const result = response.data.message[0];
                setAssignedNotifications(result[0]);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const fetchFailureMode = async (body: any) => {
        await FMEAService.getFMEAExsistingFailureIndicators(body)
            .then(async (response: any) => {
                const result = await response.data.message[0];
                setFailureIndicatorsData(result[0]);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const getPotentialCause = async (body: any) => {
        await FMEAService.getExsistingPotentialCauses(body)
            .then(async (response: any) => {
                const result = await response.data.message[0];
                setPotentialCauses(result[0]);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const fetchNotificationGroups = () => {
        const body = { userId: loggedInUser, token: token }
        FMEAService.getFMEACustomerNotificationGroups(body)
            .then((response: any) => {
                const result = response?.data.message[0];
                setNotificationData(result[0]);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const reset = () => {

    }
    const navigateTab = () => {
        switch (currTab) {

            case 0:
                return (
                    <CustomConfig
                        customAlertsData={customAlertsData}
                        fetchCustomAlerts={fetchCustomAlerts}
                        customKey={customKey}
                        setCustomKey={setCustomKey}
                        deleteModalOpen={deleteModalOpen}
                        deleteGrp={deleteGrp}
                        deleteKey={customKey}
                        deletePresetAlert={deletePresetAlert}
                        setDtlData={setDtlData}
                        dtlData={dtlData}
                        selectedRow={selectedRow}
                        setSelectedRow={() => setSelectedRow}
                        setCreateDrawerOpen={setCreateDrawerOpen}
                        setCreateValue={setCreateValue}
                        setDrawerHeader={setDrawerHeader}
                        setSnackbarOpts={setSnackbarOptions}
                        fetchCustomAlertDetails={(e: any) => fetchCustomAlertDetails(e)}
                        failureIndicatorsData={failureIndicatorsData}
                        setCustomizedQuery2={setCustomizedQuery2}
                        setPotentialCauses={setPotentialCauses}
                        setNewEntry={setNewEntry}
                        newEntry={newEntry}
                        setMachineKey={setMachineKey}
                        isLoading={isFailureModeLoading}
                    />
                )

            default:
                return (
                    <CustomConfig
                        customAlertsData={customAlertsData}
                        fetchCustomAlerts={fetchCustomAlerts}
                        customKey={customKey}
                        setCustomKey={setCustomKey}
                        deleteModalOpen={deleteModalOpen}
                        deleteGrp={deleteGrp}
                        deleteKey={customKey}
                        deletePresetAlert={deletePresetAlert}
                        setDtlData={setDtlData}
                        dtlData={dtlData}
                        selectedRow={selectedRow}
                        setSelectedRow={() => setSelectedRow}
                        setCreateDrawerOpen={setCreateDrawerOpen}
                        setCreateValue={setCreateValue}
                        setDrawerHeader={setDrawerHeader}
                        setSnackbarOpts={setSnackbarOptions}
                        fetchCustomAlertDetails={(e: any) => fetchCustomAlertDetails(e)}
                        failureIndicatorsData={failureIndicatorsData}
                        setCustomizedQuery2={setCustomizedQuery2}
                        setPotentialCauses={setPotentialCauses}
                        setNewEntry={setNewEntry}
                        newEntry={newEntry}
                        setMachineKey={setMachineKey}
                        isLoading={isFailureModeLoading}
                    />
                );
        }
    };

    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);

    return (
        <div className="dashboard">
            <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
            <Container maxWidth="xl" style={{ marginTop: '60px' }}>
                <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                    <div style={{ flex: '80%', display: 'flex' }}>
                        <div className="headinglabel" style={{ marginTop: '5px' }}>
                            {SCREEN_TITLES.FMEA_ANALYSIS}
                        </div>
                        <div
                            style={{
                                flex: '10%',
                                marginLeft: '20px',
                                // marginTop: '10px',
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                            className="dashboardDropdownDiv"
                        >
                            <DrawerDropdown click={getCreateValue} items={['Failure Mode Entry']} />
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: '35px', zIndex: '0' }}>
                    <MapCustomTabs
                        navigateTab={navigateTab}
                        currTab={currTab}
                        setCurrTab={setCurrTab}
                        tabsArray={tabs}
                        className="tabs"
                        position="relative"
                        moduleName='FMEA Tabs'
                    // type="nonCurve"
                    />
                </div>
            </Container>
            <RightDrawer
                alertType={createValue}
                handlerOpen={() => { }}
                handlerClose={async () => { setCreateDrawerOpen(false); setDrawerHeader('Create'); setCustomizedQuery2([]); setRecommndList([]); setNewEntry(true); setGroupKeyNames([]); setCustQuery([]); }}
                status={createDrawerOpen}
                drawerHeader={`${createValue != "View FMEA" && (createValue != "Assign Notification") ? (createValue === "Failure Indicator" && failureIndicatorsData.length) ? 'Edit' : drawerHeader : ""} ${createValue}`}

                children={
                    drawerHeader === 'Create' || drawerHeader === 'Edit' ? (
                        (
                            createValue === "Failure Mode Entry" ?
                                <CreateCustomAlert
                                    customKey={customKey}
                                    drawerClose={() => { setCreateDrawerOpen(false); }}
                                    setCurrTab={setCurrTab}
                                    setReload={setReload}
                                    setDtlData={setDtlData}
                                    dtlData={dtlData}
                                    setSnackbarOpts={setSnackbarOptions}
                                    isEdit={false}
                                    selectedRow={selectedRow}
                                    setSelectedRow={setSelectedRow}
                                    customersData={customersData}
                                    setCustomKey={setCustomKey}
                                    fetchCustomAlerts={fetchCustomAlerts}
                                    editableCustomData={editableCustomData}
                                    failureIndicatorsData={failureIndicatorsData}
                                    fetchFailureMode={fetchFailureMode}
                                />

                                :
                                createValue === "Failure Indicator" ?
                                    <FailureIndicatorConfig
                                        customKey={customKey}
                                        drawerClose={() => { setCreateDrawerOpen(false); }}
                                        setCurrTab={setCurrTab}
                                        setReload={setReload}
                                        setDtlData={setDtlData}
                                        dtlData={dtlData}
                                        setSnackbarOpts={setSnackbarOptions}
                                        isEdit={false}
                                        selectedRow={selectedRow}
                                        setSelectedRow={setSelectedRow}
                                        customersData={customersData}
                                        setCustomKey={setCustomKey}
                                        fetchCustomAlerts={fetchCustomAlerts}
                                        editableCustomData={editableCustomData}
                                        failureIndicatorsData={failureIndicatorsData}
                                        casualEntry={false}
                                        mainOperators={mainOperators}
                                    />
                                    :
                                    createValue === "Causal Parameter" ?
                                        <PotentialCausesConfig
                                            customKey={customKey}
                                            drawerClose={() => { setCreateDrawerOpen(false); }}
                                            setCurrTab={setCurrTab}
                                            setReload={setReload}
                                            setDtlData={setDtlData}
                                            dtlData={dtlData}
                                            setSnackbarOpts={setSnackbarOptions}
                                            isEdit={false}
                                            selectedRow={selectedRow}
                                            setSelectedRow={setSelectedRow}
                                            customersData={customersData}
                                            setCustomKey={setCustomKey}
                                            fetchCustomAlerts={fetchCustomAlerts}
                                            editableCustomData={editableCustomData}
                                            failureIndicatorsData={failureIndicatorsData}
                                            occuranceCriteria={occuranceCriteria}
                                            detectionCriteria={detectionCriteria}
                                            potentialCauses={potentialCauses}
                                            selectedCasualParameter={selectedCasualParameter}
                                            recList={recommandList}
                                            casualParameterKey={casualParameterKey}
                                            recommendKey={recommendKey}
                                            queryData={queryData}
                                            queryParameters={queryParameters}
                                            custQuery={custQuery}
                                            newEntry={newEntry}
                                            groupKeyNames={groupKeyNames}
                                            mainOperators={mainOperators}
                                        />
                                        :
                                        createValue === "Assign Notification" ?
                                            <AssignNotification
                                                customKey={customKey}
                                                drawerClose={() => { setCreateDrawerOpen(false); }}
                                                setCurrTab={setCurrTab}
                                                setReload={setReload}
                                                setDtlData={setDtlData}
                                                dtlData={dtlData}
                                                setSnackbarOpts={setSnackbarOptions}
                                                isEdit={false}
                                                selectedRow={selectedRow}
                                                setSelectedRow={setSelectedRow}
                                                setCustomKey={setCustomKey}
                                                fetchCustomAlerts={fetchCustomAlerts}
                                                editableCustomData={editableCustomData}
                                                notificationData={notificationData}
                                                assignedNotifications={assignedNotifications}
                                            />
                                            :
                                            createValue === "View FMEA" ?
                                                <ViewDetails
                                                    customKey={customKey}
                                                    drawerClose={() => { setCreateDrawerOpen(false); setCustomizedQuery2([]); setRecommndList([]); setPotentialCauses([]); setNewEntry(true); setCustQuery([]); }}
                                                    setCurrTab={setCurrTab}
                                                    setReload={setReload}
                                                    setDtlData={setDtlData}
                                                    dtlData={dtlData}
                                                    setSnackbarOpts={setSnackbarOptions}
                                                    isEdit={false}
                                                    selectedRow={selectedRow}
                                                    setSelectedRow={setSelectedRow}
                                                    setCustomKey={setCustomKey}
                                                    fetchCustomAlerts={fetchCustomAlerts}
                                                    editableCustomData={editableCustomData}
                                                    notificationData={notificationData}
                                                    assignedNotifications={assignedNotifications}
                                                    potentialCauses={potentialCauses}
                                                    failureIndicatorsData={failureIndicatorsData}
                                                    setCreateValue={setCreateValue}
                                                    setSelectedCasualParameter={setSelectedCasualParameter}
                                                    setGroupKeyNames={setGroupKeyNames}
                                                    setRecommndList={setRecommndList}
                                                    recommandList={recommandList}
                                                    setRecommendKey={setRecommendKey}
                                                    setCasualParameterKey={setCasualParameterKey}
                                                    setQueryData={setQueryData}
                                                    setParameters={setParameters}
                                                    setCustQuery={setCustQuery}
                                                    queryData={queryData}
                                                    queryParameters={queryParameters}
                                                    custQuery={custQuery}
                                                    setNewEntry={setNewEntry}
                                                    customizedQuery2={customizedQuery2}
                                                    setCustomizedQuery2={setCustomizedQuery2}
                                                    setDrawerHeader={setDrawerHeader}
                                                    getPotentialCause={getPotentialCause}
                                                    fetchFailureMode={fetchFailureMode}
                                                    setConditionJSONData={setConditionJSONData}
                                                    conditionJSONData={conditionJSONData}
                                                    setPotentialCauses={setPotentialCauses}
                                                    machineKey={machineKey}
                                                />
                                                :
                                                null
                        )
                    ) : null
                }
            />

        </div>
    );
};

export default SystemConfiguration;
