import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Container, Grid, Card } from '@mui/material';
import AcgButton from '../../../atoms/Button/index';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { BC_YUPSCHEMA } from './schema';
import Label from '../../../atoms/Label/index';
import TextInput from '../../../atoms/TextInput';
import { useDispatch } from 'react-redux';
import './index.scss';
import useGetState from '../../../utils/hooks/useGetState';
import { ACTION_CODES, STORE_KEYS } from '../../../constants/apiConstants';
import { executeACGAction, resetErr, updateScreenIdentifiers } from '../../../store/slice';
import AcgTimePicker from '../../../atoms/TimePicker';
import CustomDatePicker from '../../../atoms/CustomDatePicker';
import RadioButton from '../../../atoms/Radiobutton';
import CustomTableToolbar from '../../../atoms/CustomTableToolbar';
import CustomTable from '../../../atoms/CustomTable/index';
import { applySortFilter } from '../../../utils/helper';
import dateFormat from 'dateformat';

type Props = {
    drawerClose: Function;
    setCurrTab: Function;
    setReload: Function;
    setSnackbarOpts: Function;
};
const options = {
    AD_SUCCESS: {
        message: 'Broadcast scheduled successfully!',
        open: true,
        type: 'success'
    }
};

const TABLE_HEAD = [{ id: 'CustomerName', label: 'Client', alignRight: false }];

const ScheduleBroadcast = (props: Props) => {
    const { drawerClose, setCurrTab, setReload = () => { }, setSnackbarOpts = () => { } } = props;
    const formRef = useRef<any>(null);
    const [validationSchema, setValidationSchema] = useState({});
    const state = useGetState();
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const [initialValues] = useState({
        message: '',
        startDate: new Date(),
        startTime: new Date(),
        endDate: new Date(),
        endTime: new Date(),
        bcType: 'Global'
    });
    const [page, setPage] = useState<number>(0);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('CustomerName');
    const [selected, setSelected] = useState<any>([]);
    const [filterName, setFilterName] = useState<string>('');
    const [tableData, setTableData] = useState<any>([]);
    const [chngCl, setChngCl] = useState(false);
    const resetInput = () => {
        setPage(0);
        setFilterName('');
    };
    const filteredList = applySortFilter(tableData, order, filterName, orderBy);

    useEffect(() => {
        const setData = state?.[STORE_KEYS.BC_CLIENTS]?.body?.data?.map((cl: any, index: any) => {
            const record = { ...cl };
            record.id = record?.CustomerKey;
            return record;
        });
        setTableData(setData);
    }, []);

    const handleRequestSort = (event: any, property: any) => {
        setPage(0);
        setFilterName('');
        const isAsc = orderBy === event && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(event);
    };

    const handleSelectAllClick = (event: any) => {
        setChngCl(true);
        if (event.target.checked) {
            const newSelecteds = [];
            const entireSet = [];
            for (let i = 0; i < tableData.length; i++) {
                newSelecteds.push(tableData[i].id);
                entireSet.push(tableData[i]);
            }
            const toRemove = tableData.slice(page, tableData.length);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (requestedBy: string) => {
        setChngCl(true);
        const selectedIndex = selected.indexOf(requestedBy);
        let newSelected: any[] = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, requestedBy);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    };

    const showRow = (row: any) => {
        const rowId = row['CustomerKey'];
        const isSelected = selected.length && selected.indexOf(rowId) !== -1;
        if (isSelected) {
            setSelected([...selected.filter((item: number) => item !== rowId)]);
        } else {
            setSelected([...selected, rowId]);
        }
    };
    const handleFilterByName = (event: any) => {
        setOrderBy('CustomerName');
        setFilterName(event.target.value);
    };
    const renderButtons = (isDisabled: boolean) => {
        return (
            <div className={'btns noWrap'}>
                <AcgButton
                    name={'Cancel'}
                    handleClick={drawerClose}
                    secondary={true}
                    className={'first-btn'}
                    type="button"
                    variant="contained"
                />
                <AcgButton
                    name={'Submit'}
                    handleClick={() => { }}
                    type="submit"
                    className="second-btn"
                    variant="contained"
                    isLoading = {isLoading}
                    disabled={isDisabled}
                />
            </div>
        );
    };
    useEffect(() => {
        setValidationSchema(Yup.object().shape(BC_YUPSCHEMA));
    }, []);

    const handleSubmit = async (values: any) => {
        if(isLoading) return
        setIsLoading(true)
        if ((selected?.length > 0 && values.bcType === 'Customer') || values.bcType === 'Global') {
            const startTm = new Date(
                values?.startDate?.setHours(
                    values?.startTime?.getHours(),
                    values?.startTime?.getMinutes(),
                    values?.startTime?.getSeconds()
                )
            );
            const endTm = new Date(
                values?.endDate?.setHours(
                    values?.endTime?.getHours(),
                    values?.endTime?.getMinutes(),
                    values?.endTime?.getSeconds()
                )
            );

            const bundle = {
                payload: {
                    urlPath: ACTION_CODES.ADD_BC,
                    requestType: 'POST',
                    reqObj: {
                        broadcastMessage: values?.message.replace(/'/g, "''"),
                        startDateTime: `${dateFormat(startTm, 'yyyy-mm-dd')}T${dateFormat(startTm, 'HH:MM:ss')}`,
                        endDateTime: `${dateFormat(endTm, 'yyyy-mm-dd')}T${dateFormat(endTm, 'HH:MM:ss')}`,
                        broadcastType: values?.bcType,
                        customerKeys: values?.bcType === 'Global' ? '-1' : selected?.toString()
                    }
                },
                uniqueScreenIdentifier: { bcAdded: true },
                storeKey: STORE_KEYS.ADD_RPT
            };
            dispatch(executeACGAction(bundle));
        }
    };

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_RPT]?.bcAdded) {
            if (state?.[STORE_KEYS.ADD_RPT]?.statusCode === 200) {
                setReload(true);
                setCurrTab(0);
                setSnackbarOpts(options.AD_SUCCESS);
                drawerClose();
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_RPT,
                    uniqueScreenIdentifier: {
                        bcAdded: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.ADD_RPT]?.bcAdded]);

    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };
        setSnackbarOpts(snackbarError);
    };

    useEffect(() => {
        if (state?.err) {
            handleSnackbarError(state?.err);
            dispatch(resetErr());
        }
    }, [state?.err]);

    return (
        <Container className="scheduleBc">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values: any) => {
                    handleSubmit(values);
                }}
                validateOnBlur={false}
                validateOnChange={false}
                innerRef={formRef}
            >
                {(formikprops: any) => {
                    const { values, handleChange, handleBlur, errors, isValid, touched, setFieldValue } = formikprops;

                    return (
                        <Form>
                            <Label label="Message" className={'labelDiv'} />
                            <TextInput
                                variant="outlined"
                                autoFocus
                                fullWidth
                                type="text"
                                name="message"
                                value={values.message}
                                autoComplete="off"
                                formInput="longInputDiv"
                                multiline={true}
                                height={'fit-content'}
                                onChange={(e: any) => {
                                    handleChange(e);
                                }}
                                onBlur={handleBlur}
                                placeholder="Enter Message"
                                helperText={errors.message && touched.message ? errors.message : ''}
                            />
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
                                    <Label label="Start Date" className={'labelDiv'} />
                                    <CustomDatePicker
                                        name="startDate"
                                        value={values.startDate}
                                        className="inputDiv"
                                        onChange={(val: any) => {
                                            setFieldValue('startDate', val);
                                        }}
                                        inputFormat="dd/MM/yyyy"
                                        placeholder="Select Start Date"
                                        helperText={errors.startDate && touched.startDate ? errors.startDate : ''}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
                                    <Label label="Start Time" className={'labelDiv'} />
                                    <AcgTimePicker
                                        className="inputDiv"
                                        value={values.startTime}
                                        handleChange={(value: any) => {
                                            setFieldValue('startTime', value?.$d);
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
                                    <Label label="End Date" className={'labelDiv'} />
                                    <CustomDatePicker
                                        name="endDate"
                                        value={values.endDate}
                                        className="inputDiv"
                                        onChange={(val: any) => {
                                            setFieldValue('endDate', val);
                                        }}
                                        inputFormat="dd/MM/yyyy"
                                        placeholder="Select End Date"
                                        helperText={errors.endDate ? errors.endDate : ''}
                                    />
                                    {errors?.endDate && (
                                        <div>
                                            <span
                                                style={{
                                                    color: '#F06868',
                                                    fontSize: '11px',
                                                    fontWeight: 600,
                                                    letterSpacing: 0,
                                                    marginBottom: '-23px',
                                                    fontFamily: 'Montserrat',
                                                    marginLeft: '5%'
                                                }}
                                            >
                                                {errors?.endDate}
                                            </span>
                                        </div>
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
                                    <Label label="End Time" className={'labelDiv'} />
                                    <AcgTimePicker
                                        className="inputDiv"
                                        value={values.endTime}
                                        handleChange={(value: any) => {
                                            setFieldValue('endTime', value?.$d);
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Label label="Broadcast Type" className={'labelDiv'} />
                            <RadioButton
                                options={[
                                    { value: 'Global', label: 'Global' },
                                    { value: 'Customer', label: 'Client' }
                                ]}
                                onChange={(e: any) => {
                                    setFieldValue('bcType', e?.currentTarget?.value);
                                }}
                                defaultValue={'0'}
                                rowDirection={true}
                                value={values.bcType}
                            />
                            {values?.bcType === 'Customer' && (
                                <>
                                    <Label label="Select Client" className={'labelDiv'} />
                                    <Container
                                        maxWidth="xl"
                                        style={{
                                            paddingLeft: '3px',
                                            paddingRight: '3px',
                                            marginTop: '10px'
                                        }}
                                    >
                                        <Card
                                            style={{
                                                borderRadius: '0px',
                                                backgroundColor: '#2b2d42',
                                                overflow: 'inherit'
                                            }}
                                        >
                                            <CustomTableToolbar
                                                drawerOpen={() => { }}
                                                numSelected={selected?.length}
                                                filterName={filterName}
                                                onFilterName={handleFilterByName}
                                                displayInput={true}
                                                text={null}
                                                filterButton={false}
                                                customButton={false}
                                                resetInput={resetInput}
                                            />
                                        </Card>
                                    </Container>
                                    <CustomTable
                                        selectable={true}
                                        headers={TABLE_HEAD}
                                        body={filteredList}
                                        filteredUsers={filteredList}
                                        filterName={filterName}
                                        selected={selected}
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                        onSelectAllClick={handleSelectAllClick}
                                        rowChange={(event: string) => handleClick(event)}
                                        rowCount={filteredList?.length}
                                        onPageChange={handleChangePage}
                                        page={page}
                                        setPageNumber={setPage}
                                        selectRow={showRow}
                                        isRequestPage={true}
                                        link={true}
                                        noDataText={'No Client data available'}
                                    />
                                </>
                            )}
                            {values.bcType === 'Customer' && selected?.length == 0 && chngCl && (
                                <div>
                                    <span
                                        style={{
                                            color: '#F06868',
                                            fontSize: '11px',
                                            fontWeight: 600,
                                            letterSpacing: 0,
                                            marginBottom: '-23px',
                                            fontFamily: 'Montserrat',
                                            marginLeft: '5%'
                                        }}
                                    >
                                        Please select client
                                    </span>
                                </div>
                            )}
                            {renderButtons((values.message === '' || values.startDate === '' || values.startTime === '' || values.endDate === '' || values.endTime === '' || values.Customer === '' || values.bcType === '' || values.Global === '') ? true : false)}
                        </Form>
                    );
                }}
            </Formik>
        </Container>
    );
};

export default ScheduleBroadcast;
