import { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import Axios from 'axios';
import FD from 'js-file-download';
import { useDispatch } from "react-redux";
import Table from "../../../../atoms/StickyHeaderTable";
import { executeACGAction, reset, resetStoreKey, updateScreenIdentifiers } from "../../../../store/slice";
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../../constants/apiConstants';
import ACGButton from '../../../../atoms/Button'
import Attachments from '../../../../atoms/ExcelAttachments';
import useGetState from '../../../../utils/hooks/useGetState';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import DropDown from '../../../../atoms/Dropdown';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import FormControl from '@mui/material/FormControl';
import Label from '../../../../atoms/Label/index';
import Grid from '@mui/material/Grid';
import "../../ta.css"
import { trackButtonEvent } from '../../../../utils/newRelicFuncations';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function CreateForm(props: any) {
    const dispatch = useDispatch();
    const editing = props.componentLSKey !== '' ? true : false;
    const [machineModelKey, setMachineModelKey] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState<any>([]);
    const [templateError, setTemplateError] = useState<boolean>(false);
    const [isSetFile, setIsSetFile] = useState<any>([]);
    const [templateSuccess, setTemplateSuccess] = useState(false);
    const [sheetData, setSheetData] = useState<any>([]);
    const [sheetResult, setSheetResult] = useState<any>([]);
    const state = useGetState();
    const token = state?.[STORE_KEYS.USER_DTL].token;
    const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;
    const [hasNoError, setHasNoError] = useState(false)

    useEffect(() => {
        if (sheetResult.length) {
            const checkHasNoError = sheetResult.every((item: any) => item.Result != "Error")
            setHasNoError(checkHasNoError)
        }
    }, [sheetResult])

    useEffect(() => {
        if (props.componentLSKey === '') {
            reset();
        }
    }, [props.componentLSKey])

    const handleChangeMachineModel = (event: any) => {
        setSheetResult([]);
        setMachineModelKey(event.target.value);
    };

    const reset = async () => {
        setMachineModelKey('');
    }

    const options = {
        AD_SUCCESS: {
            message: 'New component life cycle created ',
            open: true,
            type: 'success'
        },
        AD_FAIL: {
            message: 'Component life cycle already exsist',
            open: true,
            type: 'remark'
        },
    };

    const createNewEntry = (type: string) => {
        trackButtonEvent("Component Life Cycle","Component Life Cycle Entry","Create CLM Entry",state[STORE_KEYS.USER_DTL]?.EmailId)
        setIsLoading(true);
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: ACTION_CODES.POST_CLC_COMPONENT_LIFE_CYCLE_DETAILS,
                    reqObj: {
                        "LoggedUserKey": loggedInUser,
                        "MachineModelKey": machineModelKey,
                        "MacCompLifeCyJson": sheetData,
                        "AddEditActivity": type,
                        "NumberOfRows": sheetData.length
                    }
                },
                uniqueScreenIdentifier: { clcPostDetails: true },
                storeKey: STORE_KEYS.POST_CLC_COMPONENT_LIFE_CYCLE_DETAILS
            })
        );
    };

    useEffect(() => {
        if (state?.[STORE_KEYS.POST_CLC_COMPONENT_LIFE_CYCLE_DETAILS]?.clcPostDetails) {
            const response = state?.[STORE_KEYS.POST_CLC_COMPONENT_LIFE_CYCLE_DETAILS]?.body?.data;
            setSheetResult(response);
            setTemplateSuccess(false);
            props.getCLCList(props.filteredMachine);
            props.getAllMachineModel();
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.POST_CLC_COMPONENT_LIFE_CYCLE_DETAILS,
                    uniqueScreenIdentifier: {
                        clcPostDetails: false
                    }
                })
            );
            props.setAdded(true);
        }
    }, [state?.[STORE_KEYS.POST_CLC_COMPONENT_LIFE_CYCLE_DETAILS]?.clcPostDetails]);

    const yesDisabled = (machineModelKey === '' || !templateSuccess) ? true : false;

    const renderButtons = () => {
        return (
            <div className={'btns noWrap'}>
                <ACGButton
                    name={'Cancel'}
                    handleClick={() => { props.drawerClose(); reset() }}
                    secondary={true}
                    className={'first-btn'}
                    type="button"
                    variant="contained"
                />
                {!hasNoError ? <ACGButton
                    name={'Submit'}
                    handleClick={() => { createNewEntry("Add") }}
                    type="submit"
                    className="second-btn"
                    variant="contained"
                    disabled={yesDisabled ? true : false}
                    isLoading = {isLoading}
                /> : null}
            </div>
        );
    };

    const uploadFile = (f: any) => {
        setSheetResult([]);
        const reader = new FileReader();
        if (reader.readAsBinaryString) {
            reader.onload = (e) => {
                processExcel(reader.result);
            };
            reader.readAsBinaryString(f[0]);
        }
    }

    function processExcel(data: any) {
        const allRows: any = [];
        let isError: boolean = false;
        const workbook = XLSX.read(data, { type: 'binary' });
        const firstSheet = workbook.SheetNames[0];
        const excelRows = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheet]);
        excelRows.map(async (item: any) => {
            if (Object.keys(item).length !== 8) {
                isError = true;
                return
            } else {
                const data = Object.values(item)
                const obj = {
                    AssemblyName: data[1],
                    ComponentName: data[2],
                    CalendarLifeInHours: data[3],
                    AlertsBasisOperatingHours: data[4],
                    OperatingLifeInHours: data[5],
                    ReplacementAlertInHours: data[6],
                    PostDueReminderInHours: data[7]
                }
                await allRows.push(obj);
            }
        })
        if (isError) {
            setTemplateError(true);
            setSelectedTemplate([]);
        } else {
            setTemplateError(false);
            setSelectedTemplate([]);
            setTemplateSuccess(true);
            setSheetData(allRows);
        }
    }

    return (
        <Container className="createReport clc-cls">
            <Box>
                <div className='formwrap all-labelcls clclabel'>
                    <FormControl fullWidth>
                        <Grid container spacing={2}>
                            <Grid item md={4}>
                                <FormControl fullWidth>
                                    <Label label="Select Machine Model" className={'CMlabelDiv'} />
                                    <DropDown
                                        onChange={handleChangeMachineModel}
                                        disabled={props.componentLSKey === '' ? false : true}
                                        className="inputDiv"
                                        name="dropdownModel"
                                        items={props.machineModelData}
                                        placeHolder="Select Machine Model"
                                        value={machineModelKey}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={4}>
                                <FormControl fullWidth>
                                    <Label label="Download Template" className={'CMlabelDiv'} />
                                    <Button sx={{ width: "100px" }} >
                                        <a href={"https://stbtffuturednadlsqa.blob.core.windows.net/smartconnected-files/life_cycle_component_new_template.xlsx?sp=racwle&st=2024-07-09T13:07:26Z&se=2025-07-08T21:07:26Z&spr=https&sv=2022-11-02&sr=c&sig=9xfDXquPJRY5MEOIawH6IuvF%2BMYHGivOGHpMn4xYZyo%3D"} download >
                                            <DownloadIcon style={{ width: "30px", height: "30px", color: "#ffffff" }} />
                                        </a>
                                    </Button>
                                </FormControl>
                            </Grid>
                            <Grid item md={4}>
                                <FormControl fullWidth>
                                    <Label label="Upload Template" className={'CMlabelDiv'} />
                                    <Attachments
                                        viewOnly={false}
                                        setSelectedTemplate={setSelectedTemplate}
                                        isSetFile={isSetFile}
                                        uploadFile={uploadFile}
                                        type={['.xlsx', '.xls']}
                                        templateError={templateError}
                                        setTemplateError={setTemplateError}
                                        selectedTemplate={selectedTemplate}
                                        templateSuccess={templateSuccess}
                                    />
                                </FormControl>
                            </Grid>
                            {sheetResult.length > 0 ? <Grid item md={12}>
                                <Table sheetResult={sheetResult} />
                            </Grid> : null}
                        </Grid>
                    </FormControl>
                </div>
                <div className='butondrower'>
                    {renderButtons()}
                </div>
            </Box>
        </Container>
    );
}