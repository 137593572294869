import { useState, useEffect, useRef, useMemo } from 'react';
import { Grid } from '@mui/material';
import AcgButton from '../../../../atoms/Button/index';
import { ACTION_CODES, STORE_KEYS } from '../../../../constants/apiConstants';
import { executeACGAction, resetErr, updateScreenIdentifiers } from '../../../../store/slice';
import { useDispatch } from 'react-redux';
import useGetState from '../../../../utils/hooks/useGetState';
import { _noop } from '../../../../utils/common';
import Box from '@mui/material/Box';
import SearchBar from '../../../../atoms/SearchBar';
import Accordion from '../../../../atoms/Accordion';
import Label from '../../../../atoms/Label/index';
import CustomRadioGroup from '../../../../atoms/CustomRadioGroup';
import {
    extractCustKeyPlantKeys,
    extractPlantKeysOnly,
    formatAccordionData,
    formatPlants
} from '../../../../utils/formatData';

type Props = {
    drawerClose: Function;
    setCurrTab: Function;
    setReload: Function;
    setSnackbarOpts: Function;
    setStep: Function;
    isEdit: boolean;
    dtlData: any;
    data?: any;
    drawerHeader?: any;
    block?: boolean;
    type?: string;
    editFn?: Function;
    deleteFn?: Function;
    step1Vals: any;
};
const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    },
    AD_USER: {
        message: 'User created successfully!',
        open: true,
        type: 'success'
    },
    ED_USER: {
        message: 'User edited successfully!',
        open: true,
        type: 'success'
    },
    SAME_USER: {
        message: 'User Already Exist. Please Login',
        open: true,
        type: 'success'
    },
};

type AssignFeatureType = {
    features: any;
};

const AssignFeature = (props: Props) => {
    const {
        drawerClose,
        setCurrTab,
        setSnackbarOpts,
        setStep,
        isEdit,
        dtlData,
        step1Vals,
        data,
        block = false,
        type = 'Role',
        editFn = () => { },
        deleteFn = () => { }
    } = props;

    const [allPlantsList, setPlantsList] = useState<any>([]);
    const [selectedTotalCount, setSelectedTotalCount] = useState<any>(0);
    const [radioValue, setRadioValue] = useState<any>('ALL');
    const [isLoading, setIsLoading] = useState(false);
    const state = useGetState();
    const dispatch = useDispatch();

    const [assignUserAccess, setAssignUserAccess] = useState<any>([]);

    const handleRadioValue = (val: string) => {
        setRadioValue(val);
    };

    const handleSubmit = () => {
        setIsLoading(true);
        const reqPayload = {
            payload: {
                urlPath: isEdit ? ACTION_CODES.EDIT_USER : ACTION_CODES.ADD_USER,
                requestType: isEdit ? 'PUT' : 'POST',
                reqObj: {
                    email: data.email,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    phoneCountryKey: data.dropdownCountryCode,
                    phoneNo: data.mobile,
                    custRoleKey: data.dropdownRole,
                    userKey: data.userKey.toString()
                }
            },
            uniqueScreenIdentifier: { userAdded: true },
            storeKey: STORE_KEYS.ADD_RPT
        };
        dispatch(executeACGAction(reqPayload));
    };

    useEffect(() => {
        if (isEdit) setRadioValue(dtlData?.actualData?.[0]?.PlantKey === -1 ? 'ALL' : 'CLIENT');
    }, [dtlData?.actualData?.[0]?.PlantKey]);

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_RPT]?.userAdded) {
            if (state?.[STORE_KEYS.ADD_RPT]?.statusCode === 200) {
                const CustomerKey = step1Vals.dropdownClient;
                const reqPayload = {
                    payload: {
                        urlPath: ACTION_CODES.ADD_ACCESS_TO_USER,
                        requestType: 'POST',
                        reqObj: {
                            userKey: state?.[STORE_KEYS.ADD_RPT]?.body?.data?.UserKey,
                            userAccess:
                                data.radioValue === 'ACG'
                                    ? radioValue !== 'ALL'
                                        ? [...assignUserAccess]
                                        : [
                                            {
                                                CustomerKey: -1,
                                                PlantKeys: [-1]
                                            }
                                        ]
                                    : [
                                        {
                                            CustomerKey: CustomerKey,
                                            PlantKeys: [...assignUserAccess]
                                        }
                                    ]
                        }
                    },
                    uniqueScreenIdentifier: { accessAdded: true, userAdded: false },
                    storeKey: STORE_KEYS.ADD_RPT
                };
                dispatch(executeACGAction(reqPayload));
                setSnackbarOpts(options.AD_USER);
            }
        }
    }, [state?.[STORE_KEYS.ADD_RPT]?.userAdded]);

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_RPT]?.userAdded) {
            if (state?.[STORE_KEYS.ADD_RPT]?.statusCode === 202) {
                setSnackbarOpts(options.SAME_USER);
                setCurrTab(3);
                drawerClose();
            }
        }
    }, [state?.[STORE_KEYS.ADD_RPT]?.userAdded]);

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_RPT]?.userEdited) {
            if (state?.[STORE_KEYS.ADD_RPT]?.statusCode === 200) {
                const CustomerKey = step1Vals.dropdownClient;
                const reqPayload = {
                    payload: {
                        urlPath: ACTION_CODES.ADD_ACCESS_TO_USER,
                        requestType: 'POST',
                        reqObj: {
                            userKey: state?.[STORE_KEYS.ADD_RPT]?.body?.data?.UserKey,
                            userAccess:
                                data.radioValue === 'ACG'
                                    ? radioValue !== 'ALL'
                                        ? [...assignUserAccess]
                                        : [
                                            {
                                                CustomerKey: -1,
                                                PlantKeys: [-1]
                                            }
                                        ]
                                    : [
                                        {
                                            CustomerKey: CustomerKey,
                                            PlantKeys: [...assignUserAccess]
                                        }
                                    ]
                        }
                    },
                    uniqueScreenIdentifier: { accessEdited: true, userEdited: false },
                    storeKey: STORE_KEYS.ADD_RPT
                };
                dispatch(executeACGAction(reqPayload));
                setSnackbarOpts(options.ED_USER);
            }
        }
    }, [state?.[STORE_KEYS.ADD_RPT]?.userEdited]);

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_RPT]?.accessAdded) {
            if (state?.[STORE_KEYS.ADD_RPT]?.statusCode === 200) {
                setCurrTab(3);
                drawerClose();
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_RPT,
                    uniqueScreenIdentifier: {
                        accessAdded: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.ADD_RPT]?.accessAdded]);

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_RPT]?.accessEdited) {
            if (state?.[STORE_KEYS.ADD_RPT]?.statusCode === 200) {
                setCurrTab(3);
                drawerClose();
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_RPT,
                    uniqueScreenIdentifier: {
                        userEdited: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.ADD_RPT]?.accessEdited]);

    useEffect(() => {
        getUserAccessDetails();
    }, []);

    useEffect(() => {
        const tmpTableData = state[STORE_KEYS.GET_PLANTS_OF_CUSTOMER]?.body?.data;
        if (tmpTableData) {
            if (isEdit && dtlData?.actualData) {
                const __selectedData: Array<any> = dtlData?.actualData?.map((__item: any) => {
                    return { ...__item, isSelected: true };
                });
                const merge = (a: any, b: any, k: any) =>
                    a?.filter((d: any) => !b.find((c: any) => d[k] == c[k])).concat(b);
                const __merged = merge(tmpTableData, __selectedData, 'PlantKey');
                const formatedData =
                    dtlData?.IsACGUser === 'Y' && data?.radioValue === 'ACG'
                        ? formatAccordionData(__merged)
                        : formatPlants(__merged);
                setPlantsList(formatedData);
            } else {
                const __formatedData =
                    data.radioValue === 'ACG' ? formatAccordionData(tmpTableData) : formatPlants(tmpTableData);
                setPlantsList(__formatedData);
            }
        }
    }, [state[STORE_KEYS.GET_PLANTS_OF_CUSTOMER]?.body?.data, dtlData?.actualData]);

    const getUserAccessDetails = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: `${ACTION_CODES.GET_PLANTS_OF_CUSTOMER}/${data?.radioValue == 'ACG' ? -1 : data.dropdownClient}`,
                },
                storeKey: STORE_KEYS.GET_PLANTS_OF_CUSTOMER
            })
        );
    };

    const handleAssignUserAccess = (__plant: any) => {
        const __data =
            data?.radioValue === 'ACG' || (isEdit && dtlData?.IsACGUser === 'Y')
                ? extractCustKeyPlantKeys(__plant)
                : extractPlantKeysOnly(__plant);

        if (__data?.length) {
            if (data?.radioValue === 'ACG' || (isEdit && dtlData?.IsACGUser === 'Y')) {
                const __count = __data?.reduce((acc: any, curr: any) => {
                    const count = acc?.PlantKeys?.length ? acc?.PlantKeys?.length : acc + curr?.PlantKeys?.length;
                    return count;
                }, 0);
                setSelectedTotalCount(__count);
            } else {
                setSelectedTotalCount(__data?.length);
            }
        } else {
            setSelectedTotalCount(null);
        }
        setAssignUserAccess(__data);
    };

    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };

        setSnackbarOpts(snackbarError);
    };

    useEffect(() => {
        if (state?.err) {
            handleSnackbarError(state?.err);
            dispatch(resetErr());
        }
    }, [state?.err]);

    const renderButtons = () => {
        return (
            <div className={'btns noWrap'}>
                <AcgButton
                    name="Back"
                    handleClick={() => setStep(0)}
                    type="button"
                    secondary={true}
                    className="first-btn"
                    variant="contained"
                />
                <AcgButton
                    name={'Submit'}
                    handleClick={handleSubmit}
                    type="submit"
                    className="second-btn"
                    variant="contained"
                    isLoading = {isLoading}
                />
            </div>
        );
    };

    const list = () => (
        <div className="detailDrawerDiv">
            <Box sx={{ width: 200 }} role="presentation" className={'standardBox'}>
                {/* {children} */}
                {'ACG' !== data?.radioValue ? (
                    <div className="userDetailDiv">
                        <div className="detailDivChild firstChild">
                            <p>User Type</p>
                            <h6>Client</h6>
                        </div>
                        <div className={'detailDivChild firstChild'}>
                            <p>Client</p>
                            <h6>{data.clientName}</h6>
                        </div>
                        <div className={'detailDivChild'}>
                            <p>Role</p>
                            <h6>{data?.roleName}</h6>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className="userDetailDiv clientDivTop">
                            <div className={'detailDivChild firstChild'}>
                                <p>Users Type</p>
                                <h6>{data?.radioValue}</h6>
                            </div>

                            <div className={'detailDivChild '}>
                                <p>Role</p>
                                <h6>{data?.roleName}</h6>
                            </div>
                        </div>
                        <div className="userDetailDiv clientDivBottom" style={{ height: '0px' }}></div>
                    </div>
                )}
                {(data?.radioValue === 'ACG' || data?.IsACGUser === 'Y') && (
                    <div className="accordionDiv" style={{ marginTop: '20px' }}>
                        <Label label="Level of Access" />
                        <CustomRadioGroup
                            value={radioValue}
                            fields={[
                                { label: 'All', value: 'ALL' },
                                { label: 'Select Client', value: 'CLIENT' }
                            ]}
                            onChange={handleRadioValue}
                        />
                    </div>
                )}
                {(data?.radioValue === 'ACG' && radioValue === 'CLIENT') || data?.radioValue !== 'ACG' ? (
                    <>
                        <div className="accordionDiv" style={{ marginTop: '20px' }}>
                            <h5>Plant Access ({selectedTotalCount})</h5>
                            {allPlantsList?.length && (
                                <Accordion
                                    data={allPlantsList}
                                    edit={true}
                                    onSelecteChange={(e: any) => handleAssignUserAccess(e)}
                                    isSearch={data?.radioValue === 'ACG' && radioValue === 'CLIENT'}
                                />
                            )}
                        </div>
                    </>
                ) : null}
            </Box>
        </div>
    );

    return (
        <>
            {list()}
            {renderButtons()}
        </>
    );
};

export default AssignFeature;
