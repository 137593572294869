import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { Typography } from '@mui/material';
import Label from '../../../../atoms/Label/index';
import TextInput from '../../../../atoms/TextInput';
import DownArrow from '../../../../../assets/down-arrow.svg';
import CustomDatePicker from '../../../../atoms/CustomDatePicker';
import DropDown from '../../../../atoms/Dropdown';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
// import CustomDatePicker from '../../../../atoms/CustomDatePicker';
import ACGButton from '../../../../atoms/Button';
import useGetState from '../../../../utils/hooks/useGetState';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../../constants/apiConstants';
import "../../ta.css";
import { Grid } from '@mui/material';
import moment from 'moment';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export default function CreateForm(props: any) {
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const DropDownIcon = (prop: any) => (
    <img style={{ marginRight: '5px', marginTop: '-2px' }} src={DownArrow} {...prop} />
  );
  const submitHandler = () => {
    props.drawerClose();
    props.closeWorkOrder();
  }
  const renderButtons = () => {
    return (
      <div className={'btns noWrap'} style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
        <ACGButton
          name={'Cancel'}
          handleClick={props.drawerClose}
          secondary={true}
          className={'first-btn'}
          type="button"
          variant="contained"
        />
        <ACGButton
          name={'Submit'}
          handleClick={() => { submitHandler() }}
          type="submit"
          className="second-btn"
          variant="contained"
          disabled={props.replacementDate === "" || props.replacementDetails === "" ? true : false}
          isLoading = {isLoading}
        />
      </div>
    );
  };

  return (
    <Container className="createReport">
      <Box>
        <div className='formwrap all-labelcls'>
          <FormControl fullWidth>
            <>
              <Label label="Replacement Details &nbsp; *" className={'labelDiv'} />
              <TextInput
                variant="outlined"
                fullWidth
                type="text"
                name="gtId"
                value={props.replacementDetails}
                autoComplete="off"
                formInput="inputDiv"
                placeholder='Replacement Detail'
                onChange={(e: any) => {
                  props.setReplacementDetails(e.target.value)
                }}
                maxLength={500}
              />
              <Label label="Replacement Completion Date &nbsp; *" className={'labelDiv'} />
              <CustomDatePicker
                name="dateTimeFromSelect"
                value={props.replacementDate}
                className="inputDiv"
                onChange={(val: any) => {
                  props.setReplacementDate(moment(val).format("DD MMM, yyyy"));
                }}
                inputFormat="dd/MM/yyyy"
                placeholder="Select From Date"
                maxDate={new Date()}
              />
            </>
          </FormControl>
        </div>
      </Box>
      <Grid style={{ display: "flex", marginTop: 20 }}>
        {renderButtons()}
      </Grid>
    </Container>
  );
};
